import { Image } from 'plotly.js'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { PlotDataRange } from './getVisiblePoints'
import usePlotLayoutImageQuery from './usePlotLayoutImageQuery'
import useZoomThresholdSampleImages from './useZoomThresholdSampleImages'

// Geneated using https://shoonia.github.io/1x1/#aaaaaaff
const SINGLE_GREY_PIXEL_DATA_URL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjWLVq1X8ABv4C/jWwqIMAAAAASUVORK5CYII='

const DEFAULT_PLOT_WIDTH = 600
export interface UsePlotLayoutImagesProps extends PlotDataRange {
    plotPixelWidth?: number
    enabled?: boolean
}

export const usePlotLayoutImages = ({
    plotData,
    range,
    plotPixelWidth,
    enabled = true,
}: UsePlotLayoutImagesProps): Partial<Image>[] => {
    const {
        cellVisualizations: {
            cellImagesFilter: { displayImages, imageSize },
        },
    } = useCellVisualizationsSlice()

    const shouldDisplayImages = displayImages && enabled

    // Precompute zoom thresholds (if needed), and get points to show images for
    const points = useZoomThresholdSampleImages(enabled, plotData, range, plotPixelWidth)

    // Fetch images as DataURLs
    const pointQueryData = usePlotLayoutImageQuery({
        points,
        enabled,
    })

    // Plotly handles image sizes in the plot coordinate space
    // But we let users choose based on size in pixels in the browser
    // Convert between the two below
    const plotWidth = ((range?.x2 ?? 0) - (range?.x1 ?? 0))
    const correctedImageSize =
        (imageSize * plotWidth) / (plotPixelWidth || DEFAULT_PLOT_WIDTH)

    // Note: the urls should only be DataURLs
    //
    // Plotly re-uses image elements between renders
    //
    // And if the source URL changes for an image in the list, it will show the old image
    // at a new location and look quite odd until Plotly asynchronously loads the correct image
    //
    // See this video for an example of what happens
    // https://www.loom.com/share/00b340022abd466b93cec50a0f4fffc9
    const images = shouldDisplayImages
        ? pointQueryData.map((item) => {
              return {
                  x: item.point.x,
                  y: item.point.y,
                  xref: 'x',
                  yref: 'y',
                  xanchor: 'center',
                  yanchor: 'middle',
                  sizex: correctedImageSize,
                  sizey: correctedImageSize,
                  source: item.dataURL ? item.dataURL : SINGLE_GREY_PIXEL_DATA_URL,
                  opacity: item.dataURL ? 1 : 0.75,
                  layer: 'above',
              } as Partial<Image>
          })
        : []

    return images
}

export default usePlotLayoutImages
