import useEventsManager from 'redux/slices/hooks/useEventsManager'

import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import {
  createSessionFromFile,
  saveSession,
  createSessionFromRuns,
  VisualizationSessionConfig,
  getSessions,
  GetSessionsParams,
  deleteSession,
  DeleteSessionParams,
} from 'utils/api'
import { readOnlySessionIds } from 'redux/types'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import useScatterPlotScreenshot from '../plot/useScatterPlotScreenshot'

const validStatus = (code: number) => code >= 200 && code < 300

type SaveVersionConfigParams = {
  name?: string
  projectCode?: string
  isCopy?: boolean
}

const useSessionApiLogic = () => {
  const { showProgress, showSuccess, showError, clearNotification } = useNotificationSlice()
  const eventsManager = useEventsManager()

  const { sessionId, versionId, updateVersion, updateSessionAndVersion } =
    useCellVisualizationUrlParams()

  const { cellVisualizations, setIsDirty } = useCellVisualizationsSlice()
  const getScreenshot = useScatterPlotScreenshot()

  return {
    /**
     * Returns true if delete was successful
     */
    deleteSession: async (params: DeleteSessionParams & { name: string }): Promise<boolean> => {
      const { id, name } = params
      let output = ''
      showProgress(`Deleting ${name}...`)
      try {
        const { status } = await deleteSession({ id })
        if (validStatus(status)) {
          showSuccess(`${name} successfully deleted`)
          return true
        }
        throw new Error()
      } catch {
        output = `Error when attempting to delete "${name}"`
      }

      if (output) {
        showError(output)
      }

      return false
    },
    getSessions: async (params?: GetSessionsParams) => {
      let output = ''
      showProgress('Retrieving sessions...')
      try {
        const { data, status } = await getSessions(params)
        if (validStatus(status)) {
          clearNotification()
          return data.data
        }
        throw new Error()
      } catch {
        output = 'Error when attempting to retrieve sessions'
      }

      if (output) {
        showError(output)
      } else {
        clearNotification()
      }

      return []
    },
    /**
     * Sends the current cell visualization state (known as version_config in the backend)
     * where a new version_id is generated for this session and returned
     */
    saveVersionConfig: async (params?: SaveVersionConfigParams) => {
      /** @TODO Replace this with something more robust
       * This is a temporary solution to prevent users from saving over a demo dataset
       * Implemented as part of DVDF-364
       */
      const isSavePermitted = readOnlySessionIds.indexOf(String(sessionId)) === -1
      if (!isSavePermitted) {
        showError('Cannot save a demo dataset. Please contact demo team.')
        throw new Error('Cannot save a demo dataset')
      }

      const { name, projectCode, isCopy } = params ?? {}
      let output = ''
      showProgress(`Saving ${cellVisualizations.fileName || ''}...`)
      try {
        if (versionId === undefined) {
          throw new Error('Missing versionId')
        }

        // Get the latest screenshot of the plot
        const plotImgSrc = await getScreenshot()

        setIsDirty(false)
        // Omitting fields that are for the specific instance of the app or are already stored in the backend
        const result = await saveSession({
          state: {
            ...cellVisualizations,
            name,
            projectCode,
            zoomThresholds: undefined,
            cellsData: undefined,
            cellIdMap: undefined,
            fileName: undefined,
            dataRevision: undefined,
            plotImgSrc,
          },
          sessionId,
          versionId,
          isCopy,
        })

        if (result?.version_id === undefined) {
          throw new Error('No data returned')
        }

        const newVersionId = parseInt(result.version_id, 10)
        updateVersion(newVersionId)
      } catch {
        // @TODO Handle error responses more cleanly and show the user a way to recover
        // Especially if there is a ConflictError (i.e. saving over a stale version)
        output = 'Error when attempting to save'
      }

      if (output) {
        showError(output)
      } else {
        showSuccess(`${name ?? ''} Saved Successfully`)
        eventsManager.sendSaveEvent(projectCode)
      }
    },

    uploadNewBlobToCloud: async (params: { dataFromBlob?: Blob; fileName?: string }) => {
      const { dataFromBlob, fileName } = params
      eventsManager.sendExportEvent(fileName)
      let uploadBlobError = ''

      showProgress(`Uploading ${fileName}...`)

      if (dataFromBlob && fileName) {
        try {
          const { data, status } = await createSessionFromFile(dataFromBlob, fileName)
          if (validStatus(status) && data?.session_id !== undefined) {
            // @TODO assuming version id is 1.  We should actually return it from the backend
            updateSessionAndVersion(data.session_id, 1)
          } else {
            throw new Error()
          }
        } catch {
          uploadBlobError = 'Error when attempting to upload data'
        }
      }

      if (uploadBlobError) {
        showError(uploadBlobError)
      } else {
        showSuccess(`Uploaded ${fileName} successfully`)
      }
    },

    createSessionFromRuns: async (params: Partial<VisualizationSessionConfig>) => {
      showProgress('Creating session...')

      let error = ''
      try {
        const { data, status } = await createSessionFromRuns({
          max_cell_count: 25000,
          run_ids: [],
          morphometric_model: '',
          projection_model: '',
          ...params,
        })
        if (validStatus(status) && data?.session_id !== undefined) {
          // @TODO assuming version id is 1.  We should actually return it from the backend
          updateSessionAndVersion(data.session_id, 1)
        } else {
          throw new Error()
        }
      } catch {
        error = 'Error when attempting to create session'
      }

      if (error) {
        showError(error)
      } else {
        showSuccess('Session created successfully')
      }
    },
  }
}

export const useSessionApi = (): ReturnType<typeof useSessionApiLogic> => useSessionApiLogic()

export default useSessionApi
