import * as ROUTES from 'constants/routes'
import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { AssignmentInd, BarChart, Edit, Event, GetApp, Notes, Search } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LABEL_SUMMARY_URL } from 'components/runs/RunDetailsPanel'
import { LinkListItem, RouterLinkListItem } from 'components/shared/CustomListItems'
import React from 'react'
import { LabelingTaskWithActions } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import EditInstructionsPopover from './dialogs/EditInstructionsPopover'
import EditNotesPopover from './dialogs/EditNotesPopover'
import SetAssigneePopover from './dialogs/SetAssigneePopover'
import SetCellClassesToLabelPopover from './dialogs/SetCellClassesToLabelPopover'
import SetDueDatePopover from './dialogs/SetDueDatePopover'
import LabelingTaskActionHistory from './LabelingTaskActionHistory'
import LabelingTaskPopoverListItem from './LabelingTaskPopoverListItem'
import LabelingTaskReviewHistory from './LabelingTaskReviewHistory'

const Root = styled('div')({
    '& .MuiListItem-root': {
        // Make list items more compact
        paddingTop: 0,
        paddingBottom: 0,
    },

    '& .MuiListItemIcon-root': {
        minWidth: '30px',
    },
})

const LabelHeader = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
}))

interface Props {
    labelingTasks?: LabelingTask[]
    handleUpdate: (updatedTasks: LabelingTaskWithActions[]) => void
    onDownload: () => void
}

function LabelingTaskDetailsPanel(props: Props): JSX.Element {
    const { permissions } = useAuthTokens()
    const { labelingTasks, handleUpdate, onDownload } = props

    const singleRunId = labelingTasks?.length === 1 ? labelingTasks[0].getRunId() : null
    const taskId = labelingTasks?.length === 1 ? labelingTasks[0]?.getTaskId() : undefined

    if (!labelingTasks || labelingTasks.length === 0) {
        return (
            <div>
                <p>Select labeling tasks to show actions</p>
            </div>
        )
    }

    function handleClose(tasks?: LabelingTaskWithActions[]) {
        if (tasks) {
            handleUpdate(tasks)
        }
    }

    return (
        <Root>
            {labelingTasks.length === 1 && (
                <>
                    <LabelHeader variant="h6">Labeling Instructions</LabelHeader>
                    {labelingTasks[0].getLabelingInstructions() && (
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                            {labelingTasks[0].getLabelingInstructions()}
                        </Typography>
                    )}
                    {!labelingTasks[0].getLabelingInstructions() && (
                        <Typography variant="body1">No instructions</Typography>
                    )}
                    <LabelHeader variant="h6">Labeling Notes</LabelHeader>
                    {labelingTasks[0].getLabelingNotes() && (
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                            {labelingTasks[0].getLabelingNotes()}
                        </Typography>
                    )}
                    {!labelingTasks[0].getLabelingNotes() && (
                        <Typography variant="body1">No notes</Typography>
                    )}
                </>
            )}

            <LabelHeader variant="h6">
                With the {labelingTasks.length > 1 ? labelingTasks.length : ''} selected task
                {labelingTasks.length > 1 ? 's' : ''}:
            </LabelHeader>

            <List component="nav">
                <LabelingTaskPopoverListItem
                    tasks={labelingTasks}
                    handleUpdate={handleClose}
                    popupId="assigneePopover"
                    label="Set assignee (A)"
                    hotkey="a"
                    iconEl={AssignmentInd}
                    popoverEl={SetAssigneePopover}
                />
                <LabelingTaskPopoverListItem
                    tasks={labelingTasks}
                    handleUpdate={handleClose}
                    popupId="dueDatePopover"
                    label="Set due date (U)"
                    hotkey="u"
                    iconEl={Event}
                    popoverEl={SetDueDatePopover}
                />
                {permissions.has('labeling:tasks:create') && (
                    <LabelingTaskPopoverListItem
                        tasks={labelingTasks}
                        handleUpdate={handleClose}
                        popupId="instructionsPopover"
                        label="Edit instructions (I)"
                        hotkey="i"
                        iconEl={Notes}
                        popoverEl={EditInstructionsPopover}
                    />
                )}
                <LabelingTaskPopoverListItem
                    tasks={labelingTasks}
                    handleUpdate={handleClose}
                    popupId="notesPopover"
                    label="Edit notes (N)"
                    hotkey="n"
                    iconEl={Notes}
                    popoverEl={EditNotesPopover}
                />
                {permissions.has('labeling:tasks:create') && (
                    <LabelingTaskPopoverListItem
                        tasks={labelingTasks}
                        handleUpdate={handleClose}
                        popupId="classesPopover"
                        label="Edit classes to label (C)"
                        hotkey="c"
                        iconEl={Edit}
                        popoverEl={SetCellClassesToLabelPopover}
                    />
                )}
                <ListItem button component="a" onClick={(_event: React.MouseEvent) => onDownload()}>
                    <ListItemIcon>
                        <GetApp />
                    </ListItemIcon>
                    <ListItemText primary="Download task data (CSV)" />
                </ListItem>
                {singleRunId && (
                    <>
                        <LinkListItem
                            url={LABEL_SUMMARY_URL + singleRunId}
                            label="See label distribution"
                            iconEl={BarChart}
                        />
                        {permissions.has('cells:read') && (
                            <RouterLinkListItem
                                to={`${ROUTES.CELL_BROWSING}?sortOrderKey=RUN_ID_ASC&runIds=${singleRunId}`}
                                label="Browse cells"
                                iconEl={Search}
                            />
                        )}
                    </>
                )}
            </List>
            {taskId !== undefined && (
                <>
                    <LabelingTaskReviewHistory taskId={taskId} />
                    <LabelingTaskActionHistory taskId={taskId} />
                </>
            )}
        </Root>
    )
}

export default LabelingTaskDetailsPanel
