import { Table, tableFromIPC } from 'apache-arrow'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { getArrowFile, getSession, SessionPostData } from 'utils/api'

const getTableFromDataUrl = async (dataUrl: string): Promise<Table | null> => {
    const arrowResults = await getArrowFile(dataUrl)
    const { status: arrowFileStatus } = arrowResults

    if (arrowFileStatus === 200) {
        const t = await tableFromIPC(arrowResults)
        return t
    }

    return null
}

type GetSessionDataParams = {
    sessionId?: number
    versionId?: number
}

export type SessionData = { cellsData?: CellInfo[]; sessionData?: SessionPostData }

export const getSessionData = async (params: GetSessionDataParams): Promise<SessionData> => {
    const { sessionId, versionId } = params

    if (!sessionId) return {}

    const {
        status: sessionStatus,
        data: { data: sessionData },
    } = await getSession(sessionId, versionId)

    if (sessionStatus === 200 && sessionData?.data_url) {
        const table = await getTableFromDataUrl(sessionData.data_url)

        if (table) {
            const cellsData = table.toArray() as CellInfo[]

            return { cellsData, sessionData }
        }
    }

    return {}
}

export default getSessionData
