import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import useFlags from 'components/shared/useFlags'
import React, { useState } from 'react'
import { imageCountDefault, PaginationParams, PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { getSliceFromPagination } from 'utils/helpers'
import CellImagePagination from '../cellSelectionDrawer/CellImagePagination'
import { PlotCellImages } from '../cellSelectionDrawer/PlotCellImages'
import { ChangeIconButton, DeleteIconButton, MergeIconButton } from '../shared'
import { EditPinnedNamePopover } from './EditPinnedNamePopover'
import { TrainClassifierButton } from './TrainClassifierButton'

interface PinnedGroupsProps {
  onMergeClick?: (pcg: PinnedCellGroup) => void
}

export const PinnedGroups = ({ onMergeClick }: PinnedGroupsProps): JSX.Element => {
  const { cellVisualizationsTrainClassifierEnabled, demoEnabled, disableCurrentSelection } =
    useFlags()
  const showSavedHeader = disableCurrentSelection === 'no' || demoEnabled === 'yes'
  const isTrainClassifierEnabled = cellVisualizationsTrainClassifierEnabled === 'yes'
  const showTrainClassifier = demoEnabled === 'no' && isTrainClassifierEnabled

  const {
    cellVisualizations: { pinnedCells, mergedPinnedCells },
    setPinnedGroupActive,
    setMergedPinnedCells,
    deletePinnedGroup,
    setPinnedGroupPagination,
    setPinnedGroupHighlighted,
  } = useCellVisualizationsSlice()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [targetPinnedCell, setTargetPinnedCell] = useState<PinnedCellGroup>()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const eventsManager = useEventsManager()

  const handleChangeIconClick =
    (pinnedCellGroup: PinnedCellGroup) => (e: React.MouseEvent<HTMLElement>) => {
      setTargetPinnedCell(pinnedCellGroup)
      setAnchorEl(e.currentTarget)
    }

  const handleDeleteIconClick = (pinnedCellGroup: PinnedCellGroup) => () => {
    setTargetPinnedCell(pinnedCellGroup)
    setOpenDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setTargetPinnedCell(undefined)
  }

  const handleDeleteConfirm = () => {
    if (targetPinnedCell) {
      deletePinnedGroup({ targetPinnedCellId: targetPinnedCell.id, eventsManager })
      if (mergedPinnedCells)
        setMergedPinnedCells({
          pinnedCells: mergedPinnedCells.filter((x) => x.id !== targetPinnedCell.id),
          eventsManager,
        })
    }
    closeDeleteDialog()
  }

  const handlePaginationChange =
    (pinnedCellGroup: PinnedCellGroup) => (pagination: PaginationParams) => {
      setPinnedGroupPagination({ pinnedGroupId: pinnedCellGroup.id, pagination })
    }

  // if seperate pinnedGroups have the same id, they have been merged and should be shown here as one group
  // TODO: This code hurts my eyes. Make it butter? Maybe use Immer?
  const mergedPinnedCellGroups = pinnedCells?.reduce((acc, pinnedCellGroup) => {
    const existingPinnedCellGroupIndex = acc.findIndex((x) => x.id === pinnedCellGroup.id)
    if (existingPinnedCellGroupIndex > -1) {
      return acc.map((accPinnedCellGroup, i) => {
        if (i === existingPinnedCellGroupIndex) {
          const points = mergedPinnedCells?.find((x) => x.id === accPinnedCellGroup.id)?.cells
            .points
          return {
            ...accPinnedCellGroup,
            cells: {
              ...accPinnedCellGroup.cells,
              points,
            },
          }
        }
        return accPinnedCellGroup
      })
    }
    return [...acc, pinnedCellGroup]
  }, [] as PinnedCellGroup[])

  const enableMergeButton = mergedPinnedCellGroups && mergedPinnedCellGroups?.length > 1

  return (
    <>
      <Card elevation={0}>
        {showTrainClassifier && (
          <Box display="flex" justifyContent="center">
            <TrainClassifierButton />
          </Box>
        )}
        {showSavedHeader ? (
          <CardHeader
            title={<Typography variant="h5">Saved Selections</Typography>}
            sx={{ my: '8px', WebkitScrollSnapPointsY: '0' }}
          />
        ) : null}
        {mergedPinnedCellGroups?.map((pinnedCellGroup) => {
          const pinnedGroupId = pinnedCellGroup.id
          const { startIndex, endIndex } = getSliceFromPagination({
            count: pinnedCellGroup.cells.points?.length ?? 0,
            page: pinnedCellGroup.pagination?.page ?? 0,
            itemsPerPage: pinnedCellGroup.pagination?.cellsPerPage ?? imageCountDefault,
          })
          const { points } = pinnedCellGroup.cells
          return (
            <CardContent
              onMouseOver={() =>
                setPinnedGroupHighlighted({
                  pinnedGroupId,
                  isHighlighted: true,
                })
              }
              onMouseOut={() =>
                setPinnedGroupHighlighted({
                  pinnedGroupId,
                  isHighlighted: false,
                })
              }
              key={pinnedGroupId}
              sx={{ my: '8px', py: '0' }}
            >
              <Paper variant="outlined" sx={{ p: 1, pb: 1 }}>
                <Stack direction="row" justifyContent="space-between" sx={{ alignItems: 'center' }}>
                  <FormGroup>
                    <Stack key={pinnedGroupId} direction="row" justifyContent="space-between">
                      <FormControlLabel
                        checked={pinnedCellGroup.active}
                        control={
                          <Checkbox
                            data-testid="pinnedImagesCheckbox"
                            onChange={(e) =>
                              setPinnedGroupActive({
                                pinnedGroupId,
                                active: e.target.checked,
                              })
                            }
                          />
                        }
                        label={pinnedCellGroup.name}
                      />
                      <ChangeIconButton onClick={handleChangeIconClick(pinnedCellGroup)} />
                      <DeleteIconButton onClick={handleDeleteIconClick(pinnedCellGroup)} />
                      <MergeIconButton
                        iconButtonProps={{ disabled: !enableMergeButton }}
                        data-testid="mergeButton"
                        tooltip="Merge two or more selections"
                        onClick={() => onMergeClick && onMergeClick(pinnedCellGroup)}
                      />
                    </Stack>
                  </FormGroup>
                </Stack>
                {pinnedCellGroup.active && (
                  <>
                    <PlotCellImages cells={points?.slice(startIndex, endIndex)} />
                    <Box data-testid={`${pinnedCellGroup.name}_pinnedText`} justifyContent="end">
                      <CellImagePagination
                        count={points?.length ?? 0}
                        onPaginationChange={handlePaginationChange(pinnedCellGroup)}
                        defaultPage={pinnedCellGroup.pagination?.page}
                        defaultItemsPerPage={pinnedCellGroup.pagination?.cellsPerPage}
                      />
                    </Box>
                  </>
                )}
              </Paper>
            </CardContent>
          )
        })}
      </Card>
      <EditPinnedNamePopover
        anchorEl={anchorEl}
        pinnedCell={targetPinnedCell ?? { id: -1, name: '', cells: {} }}
        onClose={() => setAnchorEl(null)}
      />
      <DeepcellDialog
        open={openDeleteDialog}
        handleConfirm={handleDeleteConfirm}
        handleCancel={closeDeleteDialog}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Delete"
      >
        <DialogContentText>{`Are you sure you want to delete ${targetPinnedCell?.name}?`}</DialogContentText>
      </DeepcellDialog>
    </>
  )
}

export default PinnedGroups
