import { useAuth0 } from '@auth0/auth0-react'
import { Card, Stack, Typography } from '@mui/material'
import { DeepcellDialog } from 'components/shared'
import SuccessIcon from 'components/shared/icons/SuccessIcon'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'

export type ClassifierSuccessOutput = {
  classifierName?: string
  sessionName?: string
  trainedDate?: string
}

interface TrainClassifierSuccessDialogProps {
  open?: boolean
  onClose(): void
  output?: ClassifierSuccessOutput
}
export const TrainClassifierSuccessDialog = (
  props: TrainClassifierSuccessDialogProps
): JSX.Element => {
  const { open, onClose, output } = props
  const { user } = useAuthWrapper(useAuth0)

  const { classifierName, sessionName, trainedDate } = output ?? {}
  return (
    <DeepcellDialog
      handleCancel={onClose}
      handleConfirm={onClose}
      open={!!open}
      showCancelButton={false}
    >
      <Stack spacing={2} sx={{ width: 400, px: 1 }}>
        <SuccessIcon sx={{ width: '1.5em', height: '1.5em', alignSelf: 'center' }} />
        <Typography align="center" fontSize="16px" fontWeight={500}>
          All classifiers have been trained for sorting successfully!
        </Typography>
        <Typography align="center" fontSize="12px" color={(t) => t.palette.text.secondary}>
          You can copy the below for future refernce to use for sorting on the instrument
        </Typography>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography>Classifier Name: {classifierName}</Typography>
            <Typography>Session Name: {sessionName}</Typography>
            <Typography>Trained Date: {trainedDate}</Typography>
            <Typography>Trained By: {user.email}</Typography>
          </Stack>
        </Card>
      </Stack>
    </DeepcellDialog>
  )
}

export default TrainClassifierSuccessDialog
