import { ReviewOracleResult } from '@deepcell/cell_data_api_proto'
import { CellClass, CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import ContentLoading from 'components/shared/ContentLoading'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import firebase from 'firebase'
import Moment from 'moment'
import { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useLabelingMicrotaskSlice from 'redux/slices/hooks/useLabelingMicrotaskSlice'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { ConflictError, submitMicrotaskResult } from 'utils/api'
import { base64StrToMessage, CellClassEncoderDecoder, messageToBase64Str } from 'utils/proto-utils'
import MicrotaskCellDisplayOptions from './MicrotaskCellDisplayOptions'
import MicrotaskCellGrid from './MicrotaskCellGrid'
import {
    LeftPanel,
    MicrotaskAccordion,
    MicrotaskAccordionDetails,
    MicrotaskAccordionHeader,
    MicrotaskAccordionHeaderTaskHeader,
    MicrotaskFiller,
    RightPanel,
} from './sharedComponents'
import { MicrotaskPageProps } from './sharedTypes'
import TaskInfoAccordion from './TaskInfoAccordion'

Moment.locale('en')
momentLocalizer()

function ReviewMicrotaskPage(props: MicrotaskPageProps): JSX.Element {
    const {
        setSelectedCellIds,
        labelingMicrotask: { progress: progressRaw, selectedCellIds },
    } = useLabelingMicrotaskSlice()
    const progress = progressRaw ?? 0

    const analytics = firebase.analytics()
    analytics.logEvent('labeling/review_microtask_page/show')

    const mutation = useMutation(submitMicrotaskResult)

    const { displayNotification } = useNotificationSlice()

    const { taskId } = useParams<{ taskId: string }>()
    const { microtask, handleGoToNextMicrotask, labelingTask } = props

    const [submitPending, setSubmitPending] = useState<boolean>(false)

    const cellItems = microtask?.getCellItemsList() ?? []
    const cellClasses = microtask?.getCellClassesList()

    // There is only one cell class to review at a time for the review microtask
    const cellClassToReview = cellClasses ? cellClasses[0] : CellClass.CLASS_NOT_DEFINED
    const cellClassToReviewName = CellClassEncoderDecoder.convertToString(cellClassToReview)

    async function handleSubmitHelper() {
        if (submitPending) {
            return
        }

        setSubmitPending(true)

        const currentIndex = microtask?.getMicrotaskIndex()

        if (currentIndex === undefined) return

        const resultProto = new ReviewOracleResult()
        const exceptions = selectedCellIds.map((cellIdStr) => {
            const exception = new ReviewOracleResult.ReviewOracleException()
            exception.setCellId(base64StrToMessage(cellIdStr, CellId))
            return exception
        })

        resultProto.setExceptionsList(exceptions)

        const params = {
            labelingTaskId: parseInt(taskId, 10),
            microtaskIndex: currentIndex,
            resultProto: messageToBase64Str(resultProto),
        }
        let errorMessage = ''
        try {
            await mutation.mutateAsync(params)
            setSelectedCellIds([])
            handleGoToNextMicrotask()
        } catch (err) {
            if (err instanceof ConflictError) {
                errorMessage =
                    `The microtask you submitted was already done on task ${taskId}.` +
                    'This should not happen -- please refresh the page'
            } else {
                errorMessage = `${err}`
            }
        } finally {
            if (errorMessage) {
                displayNotification({ message: errorMessage, type: 'error' })
            }
            setSubmitPending(false)
        }
    }

    // Wrap the helper function.
    // This is a callback for useHotKeys, which doesn't handle handlers that return promises
    function handleSubmit() {
        handleSubmitHelper()
    }

    useHotkeys('space', handleSubmit, {}, [handleSubmit])

    return (
        <>
            <LeftPanel>
                <MicrotaskAccordionHeaderTaskHeader>
                    <Typography variant="h5">Review Cells Labeled</Typography>
                    <Typography variant="h4">{cellClassToReviewName}</Typography>
                </MicrotaskAccordionHeaderTaskHeader>
                <MicrotaskAccordion expanded>
                    <MicrotaskAccordionHeader>
                        <Typography variant="h6">1) Review Cells</Typography>
                    </MicrotaskAccordionHeader>

                    <MicrotaskAccordionDetails>
                        <Typography variant="body1">
                            These cells were labeled as {cellClassToReviewName}.
                            <br />
                            Choose all cells that are incorrect.&nbsp;
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            {selectedCellIds.length > 0 && (
                                <>{selectedCellIds.length} cells picked.</>
                            )}
                        </Typography>
                    </MicrotaskAccordionDetails>
                </MicrotaskAccordion>
                <MicrotaskFiller />
                <DeepcellPrimaryButton
                    contained
                    onClick={(_event) => handleSubmit()}
                    disabled={submitPending}
                >
                    Submit (Space Bar)
                </DeepcellPrimaryButton>
            </LeftPanel>
            <MicrotaskCellGrid cellItems={cellItems} />
            <RightPanel id="reviewMicrotaskPageRightPanel">
                <TaskInfoAccordion
                    title={`Review ${labelingTask?.getRunId()}`}
                    value={progress * 100}
                >
                    <Typography variant="body1">Review each cell class for errors.</Typography>
                </TaskInfoAccordion>
                <MicrotaskFiller />
                <MicrotaskCellDisplayOptions />
                <Dialog
                    open={submitPending}
                    container={document.getElementById('reviewMicrotaskPageRightPanel')}
                >
                    <DialogTitle>Submitting...</DialogTitle>
                    <DialogContent>
                        <ContentLoading />
                    </DialogContent>
                </Dialog>
            </RightPanel>
        </>
    )
}

export default ReviewMicrotaskPage
