import { LabelingTaskAction } from '@deepcell/cell_data_api_proto'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LabelingTaskActionTypeEncoderDecoder } from '../../../utils/proto-utils'
import { formatDateTime } from '../../shared/date-utils'
import SYSTEM_USER from '../constants'
import useGetLabelingTask from './useGetLabelingTask'

interface Props {
    taskId: number
}

function LabelingTaskActionHistory(props: Props): JSX.Element {
    const { taskId } = props
    const { data: result } = useGetLabelingTask(taskId)
    const actions = result
        ?.getActionsList()
        .reverse()
        .filter((action: LabelingTaskAction) => action.getUser() !== SYSTEM_USER)

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ mt: 2 }} variant="h6">
                Task history:
            </Typography>
            <List>
                {actions?.map((action: LabelingTaskAction) => (
                    <ListItem alignItems="flex-start" key={action.getActionId()}>
                        <ListItemText
                            primary={LabelingTaskActionTypeEncoderDecoder.convertToString(
                                action.getActionType()
                            )}
                            secondary={
                                <>
                                    <Typography variant="body1">{action.getUser()}</Typography>
                                    <Typography variant="body2">
                                        {formatDateTime(action.getTime())}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default LabelingTaskActionHistory
