import { UseQueryResult } from 'react-query/types/react/types'
import { useQuery } from 'react-query'
import { getReviewResults, ReviewResultResponse } from '../../../utils/api'

/**
 * Hook that returns a microtask
 */
export default function useGetReviewResults<TError = unknown>(
    taskId: number
): UseQueryResult<ReviewResultResponse, TError> {
    return useQuery(['getReviewSummaries', taskId], getReviewResults, {
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: 0,
    })
}
