import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { YesNoSetting } from 'config'
import _ from 'lodash'
import { PlotDatum, SelectionRange, Shape } from 'plotly.js'
import { FeatureDefinition, MorphometricFeatureGroup, MORPHOMETRIC_FEATURES } from 'utils/constants'

/** Defines the specific subset of Plotly's PlotDatum that we currently use
 * We store the CELL_ID in `customdata` as a unique identifier and use that to lookup
 * other data as needed
 */
export type DeepcellPlotDatum = Pick<PlotDatum, 'x' | 'y' | 'customdata'> & {
  data?: Partial<PlotDatum['data']>
}
export type Cells = DeepcellPlotDatum[] | undefined
export type PaginationParams = { page?: number; cellsPerPage?: number }

// Feature groups used for non-morphometric features
const GROUP_NONE = ''
export const GROUP_RUN_INFORMATION = 'Run Information'
const GROUP_AI_FEATURES = 'AI Features'

export interface CellDataField {
  label: string // human readable label to display
  attribute: keyof CellInfo | '' | string // unique string corresponding to the data field to use (or '' for None)
  group: string
  isContinuous: boolean
}

export interface LabelColor {
  name: string
  color: string
  isHidden?: boolean
}

export type PlotSelectionState = {
  points?: DeepcellPlotDatum[]
  range?: SelectionRange
  lassoPoints?: SelectionRange
  selections?: Partial<Shape>[]
}
export type PinnedCellGroup = {
  id: number
  active?: boolean
  name: string
  cells: PlotSelectionState
  pagination?: PaginationParams
  isHighlighted?: boolean
  isInSelectedGroup?: boolean
}

export type MergedCellGroup = Pick<PinnedCellGroup, 'id' | 'cells'>

export enum ActiveCellVisualizationsTab {
  CellGroup = 'CELL_GROUP',
  Compare = 'COMPARE',
}

export type CellImagesFilter = {
  /**
   * Boolean flag to turn images on and off
   */
  displayImages: boolean
  /**
   * Desired image size in screen pixels
   */
  imageSize: number

  /**
   * Adjusts distance between sampled points.
   *
   * 1 = prevent any overlap of the bounding boxes
   *
   * Set to a lower value to have denser images, with potential overlap.
   * Set to a higher value to have less dense images.
   */
  spacingAdjust: number
}

export type ZoomThreshold = {
  /**
   * Maximum pixel size in plot coordinates that this zoom threshold should be used for
   */
  pixelWidthInPlotCoords: number

  /**
   * Points to load images when the current pixel size in the plot is less than pixelWidthInPlotCoords
   */
  points?: DeepcellPlotDatum[]
}

export const CELL_DATA_FIELD_NONE: CellDataField = {
  label: 'None',
  attribute: '',
  group: GROUP_NONE,
  isContinuous: false,
}

function featureDefinitionToCellDataField(
  feature: FeatureDefinition,
  group: string
): CellDataField {
  return {
    label: feature.label ? feature.label : _.startCase(feature.field.toLowerCase()),
    attribute: feature.field,
    group,
    isContinuous: true,
  }
}

// @TODO Remove this once we're happy with the new sort order
export const legacyDataFields: CellDataField[] = [
  {
    label: 'Prediction',
    attribute: 'LABEL_NAME',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  {
    label: 'Cluster',
    attribute: 'leiden',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  {
    label: 'Instrument',
    attribute: 'INSTRUMENT_NAME',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  {
    label: 'Run',
    attribute: 'RUN_ID',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  {
    label: 'Sample ID',
    attribute: 'SAMPLE_ID',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  {
    label: 'Sample Type',
    attribute: 'SAMPLE_TYPE',
    group: 'Non-morphometric',
    isContinuous: false,
  },
  ...MORPHOMETRIC_FEATURES.map((feature) =>
    featureDefinitionToCellDataField(feature, 'Morphometrics')
  ),
]

/** Returns the data fields to make available for selection based on feature flags */
export function getDataFields(showOnlyFeaturedMorphometrics: YesNoSetting): CellDataField[] {
  let result: CellDataField[] = [
    { label: 'Run ID', attribute: 'RUN_ID', group: GROUP_RUN_INFORMATION, isContinuous: false },
    {
      label: 'Ground Truth Label',
      attribute: 'GROUND_TRUTH_LABEL',
      group: GROUP_RUN_INFORMATION,
      isContinuous: false,
    },
    {
      label: 'Sample ID',
      attribute: 'SAMPLE_ID',
      group: GROUP_RUN_INFORMATION,
      isContinuous: false,
    },
    {
      label: 'Sample Type',
      attribute: 'SAMPLE_TYPE',
      group: GROUP_RUN_INFORMATION,
      isContinuous: false,
    },
    {
      label: 'Instrument',
      attribute: 'INSTRUMENT_NAME',
      group: GROUP_RUN_INFORMATION,
      isContinuous: false,
    },
    {
      label: 'Prediction',
      attribute: 'LABEL_NAME',
      group: GROUP_AI_FEATURES,
      isContinuous: false,
    },
    {
      label: 'Cluster',
      attribute: 'leiden',
      group: GROUP_AI_FEATURES,
      isContinuous: false,
    },
  ]

  const topFeatures = MORPHOMETRIC_FEATURES.filter((feature) => feature.groupPriority !== undefined)
  const visibleFeatures =
    showOnlyFeaturedMorphometrics === 'yes' ? topFeatures : MORPHOMETRIC_FEATURES

  const featureCategoryOrder = [
    MorphometricFeatureGroup.CUSTOM,
    MorphometricFeatureGroup.CELL_SHAPE_FEATURES,
    MorphometricFeatureGroup.SHAPE,
    MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES,
    MorphometricFeatureGroup.TEXTURE_FEATURES,
    MorphometricFeatureGroup.FOCUS,
    MorphometricFeatureGroup.OTHER,
  ]
  featureCategoryOrder.forEach((featureCategory) => {
    const features = _.orderBy(
      visibleFeatures.filter((feature) => feature.group === featureCategory),
      'featurePriority'
    )

    result = [
      ...result,
      ...features.map((feature) =>
        featureDefinitionToCellDataField(feature, _.startCase(feature.group.toLowerCase()))
      ),
    ]
  })
  return result
}
