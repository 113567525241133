import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import firebase from 'firebase'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { CellResponse } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'
import { SAMPLE_DISTRIBUTION } from 'utils/constants'
import { CellImageAction } from '../shared/CellImageControl'
import SearchDetailsView, { ActiveTab, SearchDetailsAction } from '../shared/SearchDetailsView'
import CellBrowsingSampleDistribution from './CellBrowsingSampleDistribution'
import CellBrowsingSortBy from './CellBrowsingSortBy'
import CellDetailsPanel from './CellDetailsPanel'
import CellSearchFilters, { SearchFilters } from './CellSearchFilters'
import { prefetchPages } from './cellSearchHelpers'
import CellSearchOptions, { SearchDispatchAction } from './CellSearchOptions'
import useCellBrowsingQueryParams from './useCellBrowsingQueryParams'

const PAGES_TO_PREFETCH = 2
const PREFETCH_DELAY = 500

Moment.locale('en')
momentLocalizer()

const StyledSearchFilters = styled(CellSearchFilters)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))

const CellImagesContainer = styled('div')({
  flex: 3,
  height: 'calc(100% - 150px)',
  overflow: 'auto',
})

// Used a pattern from here: https://www.sumologic.com/blog/react-hook-typescript/
// that seemed cleaner than others
export type CellBrowsingAction =
  | SearchDetailsAction
  // Handle actions for field changes from children (search filters and search options)
  | SearchDispatchAction
  | CellImageAction
  // search-related actions
  | { type: 'search'; searchFilters: SearchFilters }

function CellBrowsingPage(): JSX.Element {
  const analytics = firebase.analytics()
  const [cellCount, setCellCount] = useState<number>()
  const [hoverCellId, setHoverCellId] = useState<CellId>()

  const { query, setQuery, search, displayOptions, queryClient, searchingAcrossCorpus } =
    useCellBrowsingQueryParams()

  // @TODO Remove old runId conversion when it's no longer used
  if (query.runId) {
    const { runId } = query
    if (query.runIds.length === 0) {
      setQuery({ ...search, runIds: [runId], runId: undefined })
      query.runIds = [runId]
    } else {
      setQuery({ ...search, runId: undefined })
    }
    query.runId = undefined
  }

  /**
   * Handles state updates by updating the state of the URL query
   */
  function handleQueryUpdate(action: CellBrowsingAction) {
    switch (action.type) {
      case 'showSortFields':
        setQuery({ ...search, showSortFields: action.checked })
        analytics.logEvent('CellBrowsingPage/showSortFields')
        break

      case 'sortOrderKey':
        if (action.value === SAMPLE_DISTRIBUTION) {
          setQuery({
            ...search,
            sortOrderKey: action.value,
            cellsPerPage: undefined,
            page: 0,
            selectedCellId: undefined,
            activeTab: 'search',
          })
        } else {
          // when sort order changes, keep the existing counts but jump to first result page
          setQuery({ ...search, sortOrderKey: action.value, page: 0 })
        }
        break

      case 'page':
        if (search.sortOrderKey === SAMPLE_DISTRIBUTION) {
          setQuery({ ...search, page: action.value, cellsPerPage: undefined })
        } else {
          setQuery({ ...search, page: action.value })
        }
        break

      case 'cellsPerPage': {
        const currentOffset = search.cellsPerPage * search.page
        const newCellsPerPage = action.value
        setQuery({
          ...search,
          page: Math.floor(currentOffset / newCellsPerPage),
          cellsPerPage: newCellsPerPage,
        })
        break
      }

      case 'search':
        // Update search and reset the count
        setCellCount(undefined)
        setQuery({ ...search, ...action.searchFilters, page: 0 })
        break

      case 'activeTab':
        setQuery({ ...search, activeTab: action.tab })
        break

      /** Handle cell image actions */
      case 'mouseEnterCell':
        if (!search.selectedCellId) setHoverCellId(action.cellId)
        break

      case 'mouseLeaveCell':
        setHoverCellId(undefined)
        break

      case 'clickCell':
        if (search.selectedCellId && messagesEqual(search.selectedCellId, action.cellId)) {
          setQuery({
            ...search,
            selectedCellId: undefined,
          })
        } else {
          setQuery({
            ...search,
            selectedCellId: action.cellId,
            activeTab: ActiveTab.DETAILS,
          })
        }
        break

      case 'toggleCenterCrop':
        setQuery({
          ...search,
          centerCrop: !search.centerCrop,
        })
        analytics.logEvent('CellBrowsingPage/toggleCenterCrop', search)
        break

      case 'toggleSharpen':
        setQuery({
          ...search,
          sharpen: !search.sharpen,
        })
        analytics.logEvent('CellBrowsingPage/toggleSharpen', search)
        break

      default:
        throw Error('Unhandled action')
    }
  }

  useEffect(() => {
    // if (cellCount !== undefined) {
    if (search.sortOrderKey !== SAMPLE_DISTRIBUTION && !searchingAcrossCorpus) {
      setTimeout(async () => {
        return prefetchPages(queryClient, search, PAGES_TO_PREFETCH, displayOptions)
      }, PREFETCH_DELAY)
    }

    // }
  }, [search, queryClient, displayOptions, searchingAcrossCorpus])

  const { activeTab } = search

  const [activeCellState, setActiveCellState] = useState<CellResponse | undefined>()

  const useCellBrowsingHotkeys = (key: string, action: CellBrowsingAction) =>
    useHotkeys(key, () => handleQueryUpdate(action), {}, [search, handleQueryUpdate])

  useCellBrowsingHotkeys('/', { type: 'activeTab', tab: ActiveTab.SEARCH })
  useCellBrowsingHotkeys('d', { type: 'activeTab', tab: ActiveTab.DETAILS })
  useCellBrowsingHotkeys('z', { type: 'toggleCenterCrop' })
  useCellBrowsingHotkeys('x', { type: 'toggleSharpen' })

  return (
    <SearchDetailsView
      searchTab={
        <StyledSearchFilters
          defaultSearchFilters={search}
          handleSearch={(searchFilters: SearchFilters) =>
            handleQueryUpdate({
              type: 'search',
              searchFilters,
            })
          }
          onError={(errors) => console.error(errors)}
        />
      }
      detailsTab={<CellDetailsPanel cell={activeCellState?.cell} />}
      handleTabChange={(tab) => handleQueryUpdate({ type: 'activeTab', tab })}
      activeTab={activeTab}
      searchDetailsFlex={1}
      contentFlex={4.5}
    >
      {searchingAcrossCorpus ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          Please narrow your search down by including a search criteria
        </Typography>
      ) : (
        <>
          <CellSearchOptions
            searchOptions={search}
            displayOptions={displayOptions}
            handleSearchOptionAction={handleQueryUpdate}
            cellCount={cellCount}
          />

          <CellImagesContainer>
            {search.sortOrderKey !== SAMPLE_DISTRIBUTION ? (
              <CellBrowsingSortBy
                searchOptions={search}
                handleQueryUpdate={handleQueryUpdate}
                hoverCellId={hoverCellId}
                setActiveCellState={setActiveCellState}
                setCellCount={setCellCount}
                cellCount={cellCount}
              />
            ) : null}
            {search.sortOrderKey === SAMPLE_DISTRIBUTION && search.runIds.length === 0 ? (
              <Typography sx={{ textAlign: 'center', mt: 2 }}>
                Please search with a Run Id
              </Typography>
            ) : null}
            {search.sortOrderKey === SAMPLE_DISTRIBUTION && search.runIds.length !== 0 ? (
              <CellBrowsingSampleDistribution
                searchOptions={search}
                handleQueryUpdate={handleQueryUpdate}
                hoverCellId={hoverCellId}
                setActiveCellState={setActiveCellState}
                setCellCount={setCellCount}
                cellCount={cellCount}
              />
            ) : null}
          </CellImagesContainer>
        </>
      )}
    </SearchDetailsView>
  )
}

export default CellBrowsingPage
