import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { DeepcellAutocomplete } from 'components/shared'
import _ from 'lodash'
import Moment from 'moment'
import { FocusEvent, useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useLabelingTasksSlice from 'redux/slices/hooks/useLabelingTasksSlice'
import { LabelingTaskWithActions, updateLabelingTask } from 'utils/api'
import OkCancelDialogBody, { useBatchUpdate } from '../../../shared/OkCancelDialogBody'

Moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
}

function SetAssigneePopover(props: Props): JSX.Element {
    const { handleClose, tasks } = props

    const {
        labeling: { availableAssignees },
    } = useLabelingTasksSlice()

    //  @TODO Improve this when we have real users
    // The backend doesn't yet have a real representation for a user object, with APIs to list users.
    // Nor ways to indicate show only users who are relevant assignees for labeling (instead of everyone)
    const sortedAvailableAssignees = availableAssignees ? [...availableAssignees].sort() : []

    const assignees = tasks.map((task) => task.getAssignee())
    const uniqueAssignees = _.uniq(assignees)
    const initialAssignee =
        uniqueAssignees.length === 1 && uniqueAssignees[0] ? uniqueAssignees[0] : ''

    const [assignee, setAssignee] = useState<string | null>(initialAssignee)
    const [inputValue, setInputValue] = useState<string>(initialAssignee)
    const [error, setError] = useState<string>()

    const [updatingTasks, handleBatchUpdate] = useBatchUpdate(
        tasks,
        (task: LabelingTask) => {
            return task.setAssignee(inputValue || '')
        },
        updateLabelingTask,
        handleClose,
        setError
    )

    return (
        <OkCancelDialogBody pending={updatingTasks} handleButtonClick={handleBatchUpdate}>
            <DeepcellAutocomplete
                label="Assignee (email)"
                value={assignee}
                inputValue={inputValue}
                options={sortedAvailableAssignees}
                getOptionLabel={(option) => option}
                freeSolo
                multiple={false}
                inputProps={{
                    error: Boolean(error),
                    autoFocus: true,
                    onFocus: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        event.target.select()
                    },
                    helperText: error,
                }}
                onInputChange={(_event, value) => {
                    setInputValue(value)
                    setError('')
                }}
                onChange={(_event, value) => {
                    setAssignee(value)
                    setError('')
                }}
            />
        </OkCancelDialogBody>
    )
}

export default SetAssigneePopover
