import { useTheme } from '@mui/material'
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { ITooltipParams } from 'ag-grid-community/dist/lib/rendering/tooltipComponent'
import _ from 'lodash'
import React from 'react'
import { LabelingTaskWithActions } from 'utils/api'

export const useRunIdAgGridColumn = (
    tasks?: LabelingTaskWithActions[]
): {
    runIdCellStyle(params: CellClassParams): React.CSSProperties
    runIdTooltip(params: ITooltipParams): string
} => {
    const theme = useTheme()
    const allRunIds = tasks?.map((task) => task.labelingTask.getRunId())
    const dupeRunIdSet = new Set<string | undefined>(
        _(allRunIds)
            .groupBy()
            .pickBy((x) => x.length > 1)
            .keys()
            .value()
    )

    function runIdIsDupe(task: LabelingTaskWithActions): boolean {
        const runId = task.labelingTask.getRunId()
        return dupeRunIdSet.has(runId)
    }

    function runIdCellStyle(params: CellClassParams) {
        return {
            backgroundColor: runIdIsDupe(params.data) ? theme.palette.warning.light : 'none',
        } as React.CSSProperties
    }

    function runIdTooltip(params: ITooltipParams): string {
        return runIdIsDupe(params.data) ? 'More than one task with a Run Id' : ''
    }

    return { runIdCellStyle, runIdTooltip } as const
}

export default useRunIdAgGridColumn
