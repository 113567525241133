import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import Typography from '@mui/material/Typography'
import { CellImageAction } from 'components/shared/CellImageControl'
import ContentLoading from 'components/shared/ContentLoading'
import { useEffect } from 'react'
import { CellResponse } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'
import CellImages from './CellImages'
import { QueryState, useSearchMetricsCells } from './cellSearchHelpers'
import { MIN_CELL_IMAGES } from './CellSearchOptions'

interface Props {
    searchOptions: QueryState
    handleQueryUpdate: (action: CellImageAction) => void
    hoverCellId: CellId | undefined
    setActiveCellState: (value: CellResponse | undefined) => void
    setCellCount: (value: number) => void
    cellCount: number | undefined
}

const CellBrowsingSampleDistribution = (props: Props): JSX.Element => {
    const {
        searchOptions,
        handleQueryUpdate,
        hoverCellId,
        setActiveCellState,
        setCellCount,
        cellCount,
    } = props

    const { result: cellDataMetrics, error, isLoading } = useSearchMetricsCells(searchOptions)

    const { selectedCellId } = searchOptions

    const detailCellId = selectedCellId || hoverCellId

    const activeCell: CellResponse | undefined = cellDataMetrics?.cells.find((cell) =>
        messagesEqual(cell.cell.getCellId(), detailCellId)
    )

    useEffect(() => {
        if (error || cellDataMetrics === undefined || cellDataMetrics.cells.length === 0) {
            setCellCount(0)
        } else setCellCount(cellDataMetrics?.cells.length)
    })

    useEffect(() => {
        setActiveCellState(activeCell)
    }, [setActiveCellState, activeCell])

    return (
        <div data-testid="cellBrowsingSampleDistribution">
            {isLoading ? <ContentLoading /> : null}
            {!isLoading && error ? (
                <Typography sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>
            ) : null}
            {!isLoading && !error && cellCount === 0 ? (
                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                    No cell images found for this Run Id
                </Typography>
            ) : null}
            {!isLoading &&
            cellDataMetrics &&
            cellCount !== undefined &&
            cellCount > 0 &&
            cellCount <= MIN_CELL_IMAGES ? (
                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                    Run Id doesn&apos;t contain required minimum ({MIN_CELL_IMAGES}) cell images.
                </Typography>
            ) : null}
            {!isLoading &&
            cellDataMetrics &&
            cellCount !== undefined &&
            cellCount > MIN_CELL_IMAGES ? (
                <CellImages cellData={cellDataMetrics} handleQueryUpdate={handleQueryUpdate} />
            ) : null}
        </div>
    )
}

export default CellBrowsingSampleDistribution
