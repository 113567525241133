import { Box } from '@mui/material'
import React from 'react'

/** TabPanel component from sample code https://mui.com/material-ui/react-tabs/ */
interface Props {
    index: string | number
    value: string | number
    overflow?: React.CSSProperties['overflow']
    position?: string
}

const TabPanel = ({
    children,
    value,
    index,
    overflow,
    position = 'relative',
}: React.PropsWithChildren<Props>): JSX.Element => (
    <Box
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        sx={{ overflowY: overflow ?? 'auto' }}
    >
        {value === index && <Box sx={{ position }}>{children}</Box>}
    </Box>
)

export default TabPanel
