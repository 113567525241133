import { CellInfo } from 'components/cell-visualizations/tsv/types'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useMemo } from 'react'
import { getNewUniqueName, getRandomId } from 'utils/helpers'
import { cellVisualizationsSlice, imageCountDefault } from '../CellVisualizationsSlice'
import { PaginationParams, PinnedCellGroup, PlotSelectionState } from '../types'
import useEventsManager from './useEventsManager'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
  const disableCurrentSelection = useFlagCondition('disableCurrentSelection')
  const demoEnabled = useFlagCondition('demoEnabled')

  const sliceMethodsAndData = useSliceWrapper('cellVisualizations', cellVisualizationsSlice)
  const {
    setPinnedGroupValue,
    setSelectedCells,
    setSelectedImagePagination,
    setShowCellGroups,
    setPinnedCells,
    cellVisualizations: {
      cellsData,
      fileName,
      pinnedCells,
      mergedPinnedCells,
      cellIdMap,
      groupByOption,
      legendLabelColors,
      pagination: statePagination,
    },
  } = sliceMethodsAndData

  // groupByOption is undefined in some tests so we need this failsafe
  const { attribute } = groupByOption ?? {}

  const hiddenAttributes = (legendLabelColors ?? []).flatMap((llc) =>
    llc.isHidden ? llc.name : []
  )
  const eventsManager = useEventsManager()

  const visibleCellsData = useMemo(
    () =>
      hiddenAttributes.length
        ? cellsData?.filter(
            (cd) => !hiddenAttributes.includes(cd[attribute as keyof CellInfo] ?? '')
          )
        : cellsData,
    [cellsData, attribute, hiddenAttributes]
  )

  const mergedPinnedCellGroups =
    pinnedCells?.reduce((acc, pinnedCellGroup) => {
      const existingPinnedCellGroupIndex = acc.findIndex((x) => x.id === pinnedCellGroup.id)
      if (existingPinnedCellGroupIndex > -1) {
        return acc.map((accPinnedCellGroup, i) => {
          if (i === existingPinnedCellGroupIndex) {
            const points = mergedPinnedCells?.find((x) => x.id === accPinnedCellGroup.id)?.cells
              .points
            return {
              ...accPinnedCellGroup,
              cells: {
                ...accPinnedCellGroup.cells,
                points,
              },
            }
          }
          return accPinnedCellGroup
        })
      }
      return [...acc, pinnedCellGroup]
    }, [] as PinnedCellGroup[]) ?? []

  const extraMethods = {
    clearSelectedPoints: () => {
      setSelectedCells({})

      setSelectedImagePagination({
        page: 0,
        cellsPerPage: imageCountDefault,
      })
    },
    setPinnedGroupHighlighted: ({
      pinnedGroupId,
      isHighlighted = true,
    }: {
      pinnedGroupId: number
      isHighlighted?: boolean
    }) => {
      setPinnedGroupValue({
        pinnedGroupId,
        key: 'isHighlighted',
        value: isHighlighted,
      })
    },
    setPointsAsSelected: (currentSelection?: PlotSelectionState) => {
      if (currentSelection) {
        /*
                 there's a bug?/feature? in plotly where if the layout.shapes
                 and/or layout.annotations are updated then the onSelected gets
                 triggered with e.points and e.selections as empty arrays
                */
        const { points, selections, lassoPoints, range } = currentSelection
        if (points && points.length > 0 && selections && selections.length > 0) {
          // don't include points that have been filtered
          const visiblePoints = points.filter((p) => !hiddenAttributes.includes(p.data?.name ?? ''))

          setShowCellGroups(true)
          if (lassoPoints) {
            eventsManager.sendLassoEvent(fileName as string, visiblePoints.length)
          } else if (range) {
            eventsManager.sendRectangleEvent(fileName as string, visiblePoints.length)
          }

          const currentSelectionState = {
            ...currentSelection,
            // @TODO Figure out why we need to make a copy of the points.  It doesn't work otherwise?
            points: visiblePoints?.map((point) => ({
              x: point.x,
              y: point.y,
              customdata: point.customdata,
            })),
            selections: selections?.map((selection) => ({
              type: selection.type,
              x0: selection.x0,
              y0: selection.y0,
              x1: selection.x1,
              y1: selection.y1,
              path: selection.path,
            })),
          }

          setSelectedCells(currentSelectionState)

          if (disableCurrentSelection && !demoEnabled) {
            const newPinnedCells = [
              // Place the new selection first, so that the user is more likely to see it
              // @TODO Scroll to the new selection in the future
              {
                // default is 1000 random ids, which should be enough...
                id: getRandomId({ exclude: pinnedCells?.map((x) => x.id) }),
                name: getNewUniqueName(pinnedCells),
                cells: currentSelectionState,
                active: true,
                pagination: statePagination,
              } as PinnedCellGroup,
              ...(pinnedCells ?? []),
            ]
            setPinnedCells({ pinnedCells: newPinnedCells, eventsManager })
            extraMethods.clearSelectedPoints()
          }
        }
      } else {
        extraMethods.clearSelectedPoints()
      }
    },

    setPinnedGroupPagination: ({
      pinnedGroupId,
      pagination,
    }: {
      pinnedGroupId: number
      pagination: PaginationParams
    }) => {
      setPinnedGroupValue({
        pinnedGroupId,
        key: 'pagination',
        value: pagination,
      })
    },

    updatePinnedName: ({ pinnedGroupId, name }: { pinnedGroupId: number; name: string }) =>
      setPinnedGroupValue({ pinnedGroupId, key: 'name', value: name }),
    setPinnedGroupActive: ({
      pinnedGroupId,
      active,
    }: {
      pinnedGroupId: number
      active?: boolean
    }) =>
      setPinnedGroupValue({
        pinnedGroupId,
        key: 'active',
        value: Boolean(active),
      }),

    getCellInfoByCellId: (cellId: string): CellInfo => {
      return cellIdMap[cellId] ?? {}
    },
  }

  return {
    mergedPinnedCellGroups,
    visibleCellsData,
    ...sliceMethodsAndData,
    ...extraMethods,
  } as const
}

export const useCellVisualizationsSlice = (): ReturnType<typeof useGetMethodsAndData> =>
  useGetMethodsAndData()

export type UseCellVisualizationsSlice = ReturnType<typeof useCellVisualizationsSlice>
