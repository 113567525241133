import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import CellImagePagination from 'components/cell-visualizations/cellSelectionDrawer/CellImagePagination'
import PlotCellImages from 'components/cell-visualizations/cellSelectionDrawer/PlotCellImages'

import { EditTextPopover } from 'components/shared'
import { useEffect, useRef, useState } from 'react'
import { imageCountDefault, PaginationParams, PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { getRandomId, getSliceFromPagination } from 'utils/helpers'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

const getNewUniqueName = (listOfNames?: { name: string }[]) => {
  if (!listOfNames || !listOfNames.length) {
    return 'New Selection 1'
  }

  const namePrefix = 'New Selection '
  let numberSuffix = 2
  for (let i = 2; listOfNames.some((x) => x.name === `${namePrefix}${i}`); i += 1) {
    numberSuffix = i + 1
  }

  return `${namePrefix}${numberSuffix}`
}

export const CurrentSelectedCells = (): JSX.Element => {
  const cardHeaderRef = useRef<HTMLDivElement>(null)

  const { cellVisualizations, clearSelectedPoints, setPinnedCells, setSelectedImagePagination } =
    useCellVisualizationsSlice()
  const currentSelectionState = cellVisualizations?.currentSelectionState
  const { points: selectedCells } = currentSelectionState ?? { points: [] }
  const pinnedCells = cellVisualizations?.pinnedCells
  const slice = getSliceFromPagination({
    count: selectedCells?.length ?? 0,
    page: cellVisualizations?.pagination?.page ?? 0,
    itemsPerPage: cellVisualizations?.pagination?.cellsPerPage ?? imageCountDefault,
  })
  const endIndex = slice.endIndex ?? imageCountDefault
  const startIndex = slice.startIndex ?? 0
  const truncatedCells = selectedCells ? selectedCells.slice(startIndex, endIndex) : []

  const [textInputanchorEl, setTextInputAnchorEl] = useState<HTMLElement | null>(null)

  const [selectionName, setSelectionName] = useState(getNewUniqueName(pinnedCells))
  const eventsManager = useEventsManager()

  useEffect(() => {
    if (!selectedCells?.length) {
      setSelectionName(getNewUniqueName(pinnedCells))
    }
  }, [pinnedCells, selectedCells?.length])

  useEffect(() => {
    if (cardHeaderRef.current && selectedCells) {
      cardHeaderRef.current.scrollIntoView()
    }
  }, [cardHeaderRef, selectedCells])

  const handleSave = () => {
    if (!currentSelectionState) return

    const newPinnedCells = [
      // Place the new selection first, so that the user is more likely to see it
      // @TODO Scroll to the new selection in the future
      {
        // default is 1000 random ids, which should be enough...
        id: getRandomId({ exclude: pinnedCells?.map((x) => x.id) }),
        name: selectionName,
        cells: currentSelectionState,
        active: true,
        pagination: cellVisualizations.pagination,
      } as PinnedCellGroup,
      ...(pinnedCells ?? []),
    ]
    setPinnedCells({ pinnedCells: newPinnedCells, eventsManager })
    setSelectionName(getNewUniqueName(newPinnedCells))
    clearSelectedPoints()
  }

  const handleCheckClick = (val?: string) => {
    if (val) {
      setSelectionName(val)
    }
  }

  const handlePaginationChange = (pagination: PaginationParams) => {
    setSelectedImagePagination(pagination)
  }

  return (
    <>
      <Card elevation={0}>
        <CardHeader
          ref={cardHeaderRef}
          title={<Typography variant="h5">Current Selections</Typography>}
          sx={{ my: '8px', py: '0' }}
        />
        <CardContent sx={{ my: '8px', pt: '0', pb: '0 !important' }}>
          {selectedCells && selectedCells.length > 0 ? (
            <Paper variant="outlined" sx={{ p: '6px' }}>
              <PlotCellImages cells={truncatedCells} />
              <Stack direction="row" justifyContent="space-between">
                <Tooltip title="Save Selection">
                  <IconButton data-testid="newSelectedImagesPinIconButton">
                    <SaveOutlinedIcon fontSize="large" color="primary" onClick={handleSave} />
                  </IconButton>
                </Tooltip>
                <CellImagePagination
                  count={selectedCells.length}
                  onPaginationChange={handlePaginationChange}
                />
              </Stack>
            </Paper>
          ) : (
            <Box
              sx={{
                justifyContent: 'space-around',
                flexDirection: 'column',
                display: 'flex',
                textAlign: 'center',
                height: '15rem',
              }}
            >
              <Typography color="lightgray">No Selection</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <EditTextPopover
        anchorEl={textInputanchorEl}
        textBeingEdited={selectionName}
        onSave={handleCheckClick}
        onClose={() => setTextInputAnchorEl(null)}
      />
    </>
  )
}

export default CurrentSelectedCells
