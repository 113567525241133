import * as ROUTES from 'constants/routes'
import { LABELING_REVIEW_SUMMARY_EDIT } from 'constants/routes'
import { Microtask, OracleType } from '@deepcell/cell_data_api_proto'
import { Box, Button, styled, Typography } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import firebase from 'firebase'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    FullScreen as ReactFullScreen,
    FullScreenProps,
    useFullScreenHandle,
} from 'react-full-screen'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import useLabelingMicrotaskSlice from 'redux/slices/hooks/useLabelingMicrotaskSlice'
import { NoContentReturned, ProcessingIncomplete } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import ContentLoading from '../../shared/ContentLoading'
import useGetLabelingTask from '../tasks/useGetLabelingTask'
import LabelingMicrotaskPage from './LabelingMicrotaskPage'
import ReviewMicrotaskPage from './ReviewMicrotaskPage'
import { useGetMicrotask } from './useGetMicrotask'
import { prefetchMicrotask } from './utils'

const INCLUDE_FULLSCREEN_MODE = true

const FullScreen = ReactFullScreen as (
    props: React.PropsWithChildren<FullScreenProps>
) => JSX.Element

const REFETCH_INTERVAL = 5000
const PREFETCH_WAIT_MS = 200

const FullScreenMessage = styled('div')(({ theme }) => ({
    textAlign: 'center',
    justifyContent: 'middle',
    width: '100%',
    height: '100%',
    minHeight: '600px',
    paddingTop: theme.spacing(6),
}))

const FullScreenParent = styled(FullScreen)(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(1),
}))

const ExitFullScreenButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
}))

const FullWidthCenterText = styled('div')({
    width: '100%',
    textAlign: 'center',
})

function MicrotaskPage(): JSX.Element {
    const { permissions } = useAuthTokens()

    const analytics = firebase.analytics()
    analytics.logEvent('labeling/labeling_microtask/show')

    const handle = useFullScreenHandle()

    const history = useHistory()
    const queryClient = useQueryClient()

    const {
        labelingMicrotask: { displayOptions, progress },
        setDisplayOptions,
        setProgress,
    } = useLabelingMicrotaskSlice()

    const { taskId } = useParams<{ taskId: string }>()
    const taskIdInt = parseInt(taskId, 10)

    const microtaskRef = useRef<Microtask>()
    const [microtaskIndex, setMicrotaskIndex] = useState<number>()

    // @TODO Rework all of this when we start doing prefetch
    const { data, error, refetch } = useGetMicrotask(taskIdInt, microtaskIndex)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttledRefetch = useCallback(throttle(refetch, REFETCH_INTERVAL), [refetch])

    let resultReady = false
    let isBatchDone = false
    if (error) {
        if (error instanceof ProcessingIncomplete) {
            setTimeout(throttledRefetch, REFETCH_INTERVAL)
        } else if (error instanceof NoContentReturned) {
            isBatchDone = true
        } else {
            console.error('Unexpected error in MicrotaskPage', error)
        }
    } else if (data) {
        if (
            microtaskRef.current &&
            microtaskRef.current?.getOracleType() !== data.getOracleType()
        ) {
            isBatchDone = true
        } else {
            microtaskRef.current = data
            resultReady = true
        }
    }

    const { data: labelingTask, refetch: refetchLabelingTask } = useGetLabelingTask(taskIdInt)

    useEffect(() => {
        if (progress === undefined) {
            setProgress(labelingTask?.getCurrentBatchProgress() || 0)
        }
    }, [labelingTask, progress, setProgress])

    function handleGoToNextMicrotask() {
        const currentMicrotask = microtaskRef.current
        if (microtaskIndex !== undefined) {
            setMicrotaskIndex(microtaskIndex + 1)
        } else if (currentMicrotask) {
            const currentMicrotaskIndex = currentMicrotask.getMicrotaskIndex()
            if (currentMicrotaskIndex !== undefined) {
                setMicrotaskIndex(currentMicrotaskIndex + 1)
            }
        }
        refetchLabelingTask()
    }

    const toggleDisplayOption = (key: keyof typeof displayOptions) => {
        setDisplayOptions({ ...displayOptions, [key]: !displayOptions[key] })
        document.getElementById('submit-button')?.focus()
    }

    useHotkeys('z', () => toggleDisplayOption('centerCrop'), {}, [displayOptions])
    useHotkeys('s', () => toggleDisplayOption('sharpen'), {}, [displayOptions])

    useEffect(() => {
        if (microtaskRef.current) {
            const curIndex = microtaskRef.current.getMicrotaskIndex()
            if (curIndex !== undefined) {
                setTimeout(() => {
                    prefetchMicrotask(
                        queryClient,
                        {
                            taskId: taskIdInt,
                            microtaskIndex: curIndex + 1,
                        },
                        displayOptions
                    )
                }, PREFETCH_WAIT_MS)
            }
        }
    }, [taskIdInt, data, displayOptions, queryClient])

    let microtaskContent: JSX.Element | JSX.Element[] | undefined

    if (INCLUDE_FULLSCREEN_MODE && !handle.active) {
        // @TODO Adding an onClick handler on a div is not kosher for accessibility
        // Fix this at some point
        microtaskContent = (
            <FullScreenMessage>
                <Typography variant="h3">
                    Please enable full screen mode to start the task.
                </Typography>
                <DeepcellPrimaryButton contained onClick={handle.enter} fullWidth>
                    <Typography variant="h3">Enter full screen mode</Typography>
                </DeepcellPrimaryButton>
            </FullScreenMessage>
        )
    } else if (resultReady) {
        const oracleType = microtaskRef.current?.getOracleType()
        if (oracleType === OracleType.ORACLE_TYPE_LABELING) {
            microtaskContent = (
                <LabelingMicrotaskPage
                    microtask={microtaskRef.current}
                    handleGoToNextMicrotask={handleGoToNextMicrotask}
                    labelingTask={labelingTask}
                />
            )
        } else if (oracleType === OracleType.ORACLE_TYPE_REVIEW) {
            microtaskContent = (
                <ReviewMicrotaskPage
                    microtask={microtaskRef.current}
                    handleGoToNextMicrotask={handleGoToNextMicrotask}
                    labelingTask={labelingTask}
                />
            )
        }
    } else if (isBatchDone) {
        if (microtaskRef.current?.getOracleType() === OracleType.ORACLE_TYPE_LABELING) {
            microtaskContent = (
                <FullWidthCenterText>
                    <Typography variant="body1">The labeling task is done.</Typography>
                    <Typography variant="subtitle1">
                        Return to the{' '}
                        <RouterLink
                            to={
                                `${ROUTES.LABELING_TASKS}?activeTab=search&statusCategory=incomplete` +
                                `&selectedTaskIds=${taskId}`
                            }
                        >
                            tasks page
                        </RouterLink>
                    </Typography>
                </FullWidthCenterText>
            )
        } else if (microtaskRef.current?.getOracleType() === OracleType.ORACLE_TYPE_REVIEW) {
            if (permissions.has('labeling:tasks:approve')) {
                // Done review task.  Go to the review summary
                history.push({
                    pathname: LABELING_REVIEW_SUMMARY_EDIT.replace(':taskId', String(taskId)),
                })
            } else {
                microtaskContent = (
                    <FullWidthCenterText>
                        <Typography variant="body1">The review task is done.</Typography>
                        <Typography variant="subtitle1">
                            Return to the{' '}
                            <RouterLink
                                to={
                                    `${ROUTES.LABELING_TASKS}?activeTab=search&statusCategory=incomplete` +
                                    `&selectedTaskIds=${taskId}`
                                }
                            >
                                tasks page
                            </RouterLink>
                            and assign it someone who can approve or reject the labels.
                        </Typography>
                    </FullWidthCenterText>
                )
            }
        }
    } else {
        microtaskContent = (
            <FullWidthCenterText>
                <Typography variant="body1">Preparing next microtask. Please wait!</Typography>
                <Typography variant="subtitle1">
                    This should take no more than half a minute.
                </Typography>
                <ContentLoading />
            </FullWidthCenterText>
        )
    }

    const WrappedMicrotaskContent = () => (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>{microtaskContent}</Box>
    )

    if (INCLUDE_FULLSCREEN_MODE) {
        return (
            <FullScreenParent handle={handle}>
                <WrappedMicrotaskContent />
                {handle.active && (
                    <ExitFullScreenButton variant="outlined" color="primary" onClick={handle.exit}>
                        Exit full screen mode
                    </ExitFullScreenButton>
                )}
            </FullScreenParent>
        )
    }

    return <WrappedMicrotaskContent />
}

export default MicrotaskPage
