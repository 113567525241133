import { LabelingTask, LabelingTaskStatus } from '@deepcell/cell_data_api_proto'
import { Box } from '@mui/material'
import { GridApi, GridReadyEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community'
import { AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react'
import ProgressBarCellRenderer from 'components/shared/ProgressBarCellRenderer'
import firebase from 'firebase'
import { useEffect, useRef, useState } from 'react'
import useLabelingTasksSlice from 'redux/slices/hooks/useLabelingTasksSlice'
import { LabelingTaskWithActions } from 'utils/api'
import useColumnStateManager from 'utils/useColumnStateManager'
import useSelection from 'utils/useSelection'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import ActionCellRenderer from '../ActionCellRenderer'
import { labelingTaskValueGetter } from '../helpers'
import useLabelingTaskQueryParams from '../useLabelingTaskQueryParams'
import useLabelingTaskTableColumns from './useLabelingTasksTableColumns'

interface LabelingTasksTableProps {
    tasks: LabelingTaskWithActions[]
    gridApi: GridApi | null
    onGridApiChange(gridApi: GridApi): void
    selectionManager: ReturnType<typeof useSelection<number, LabelingTaskWithActions>>
}

export const LabelingTasksTable = ({
    tasks,
    gridApi: gridApiFromParent,
    onGridApiChange,
    selectionManager,
}: LabelingTasksTableProps): JSX.Element => {
    const { user } = useAuthWrapper(useAuth0)
    const {
        labeling: { labelingTaskFilter },
    } = useLabelingTasksSlice()

    /* This component stores state in the query, except for caching cell counts */
    const { query, setQuery } = useLabelingTaskQueryParams()
    const { scrollToSelected, columnState } = query

    /* The filtering functions called from AgGrid don't cause a rerender so we need to use a ref here */
    const queryRef = useRef(query)
    useEffect(() => {
        queryRef.current = {
            ...query,
        }
    }, [query])

    const [gridApi, setGridApi] = useState<GridApi | null>(gridApiFromParent)
    const { initColumnState, bindColumnStateHandlers } = useColumnStateManager(query, setQuery)

    // Used to force re-renders when task data is updated inside the grid from update actions
    // but React does not detect that the protobuf objects have internally changed
    // @TODO Find a more elegant way to handle this
    const [forceRerender, setForceRerender] = useState<boolean>(false)
    const rerender = () => {
        setForceRerender(!forceRerender)
    }

    const { columnDefs, Popover } = useLabelingTaskTableColumns({
        tasks,
        gridApi,
        onTaskDataChanged: rerender,
    })

    /* Log a google analytics event using a standard event type
        https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484
    */
    const analytics = firebase.analytics()
    analytics.logEvent('list_tasks', query)

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api)
        selectionManager.setGridApi(params.api)
        onGridApiChange(params.api)

        initColumnState(params.columnApi, columnState)
        selectionManager.initSelection()

        if (scrollToSelected) {
            selectionManager.scrollToSelected()
        }
    }

    /*
      statusExists has to be a function instead of a variable because
      its value is stale by the time hasExternalFilter is called
    */
    const statusExists = () =>
        Object.keys(LabelingTaskStatus).some(
            (_, i) =>
                Object.prototype.hasOwnProperty.call(Object.values(LabelingTaskStatus), i) &&
                Object.values(LabelingTaskStatus)[i] === queryRef.current.status
        )

    function hasExternalFilter(): boolean {
        const { assignedToMe, projectName } = queryRef.current
        return statusExists() || assignedToMe || Boolean(projectName)
    }

    function runFilter(node: RowNode): boolean {
        const task = node.data.labelingTask as LabelingTask
        const { status, assignedToMe, projectName } = queryRef.current

        if (statusExists() && task.getStatus() !== status) {
            return false
        }

        if (assignedToMe && task.getAssignee() !== user?.email) {
            return false
        }

        if (Boolean(projectName) && task.getProjectName() !== projectName) {
            return false
        }

        return true
    }

    return (
        <>
            <Popover />
            <Box
                sx={{ width: '100%', height: '95%' }}
                className="ag-theme-balham ag-theme-deepcell"
                data-testid="labeling-task-table"
            >
                <AgGridReact
                    rowData={tasks}
                    columnDefs={columnDefs}
                    rowSelection="multiple"
                    quickFilterText={labelingTaskFilter?.keywordSearch ?? ''}
                    defaultColDef={{
                        valueGetter: labelingTaskValueGetter,
                        resizable: true,
                    }}
                    rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                    onGridReady={onGridReady}
                    onSelectionChanged={(event: SelectionChangedEvent) => {
                        selectionManager.onSelectionChanged(event)
                    }}
                    isExternalFilterPresent={hasExternalFilter}
                    doesExternalFilterPass={runFilter}
                    suppressDragLeaveHidesColumns
                    frameworkComponents={{
                        actionCellRenderer: ActionCellRenderer,
                        progressCellRenderer: ProgressBarCellRenderer,
                    }}
                    {...bindColumnStateHandlers}
                />
            </Box>
        </>
    )
}

export default LabelingTasksTable
