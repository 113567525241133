import React, { FunctionComponent } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import { LabelingTaskWithActions } from '../../../utils/api'

interface Props extends ICellRendererParams {
    data: LabelingTaskWithActions
    handleClick: (
        event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>,
        labelingTaskWithActions: LabelingTaskWithActions
    ) => void
}

const ActionCellRenderer: FunctionComponent<Props> = ({ data, handleClick }: Props) => {
    return (
        <div>
            {data.availableActions.length > 0 && (
                <DeepcellPrimaryButton
                    small
                    onClick={(
                        event:
                            | React.MouseEvent<HTMLAnchorElement>
                            | React.MouseEvent<HTMLButtonElement>
                    ) => {
                        handleClick(event, data)
                    }}
                    style={{
                        fontSize: '10px',
                        padding: '0px',
                    }}
                >
                    Actions
                </DeepcellPrimaryButton>
            )}
        </div>
    )
}

export default ActionCellRenderer
