import { LABELING_MICROTASK, LABELING_REVIEW_SUMMARY_EDIT } from 'constants/routes'
import { LabelingTaskActionType } from '@deepcell/cell_data_api_proto'
import { GridApi } from 'ag-grid-community'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import defaultPopoverProps from 'components/shared/popover'
import { useFlags } from 'components/shared/useFlags'
import Moment from 'moment'
import { useHistory } from 'react-router-dom'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { LabelingTaskWithActions } from 'utils/api'
import { LabelingTaskActionTypeValue } from 'utils/proto-utils'
import TaskActionPopover from './dialogs/TaskActionPopover'

Moment.locale('en')
momentLocalizer()

interface LabelingTaskActionPopoverProps {
    gridApi: GridApi | null
    handleTaskDataChanged(): void
    popoverTask?: LabelingTaskWithActions
    anchorEl?: Element | null
    handleClose(): void
}

export const LabelingTaskActionPopover = ({
    gridApi,
    handleTaskDataChanged,
    popoverTask,
    anchorEl,
    handleClose,
}: LabelingTaskActionPopoverProps): JSX.Element => {
    const history = useHistory()
    const { labelingOraclesEnabled } = useFlags()

    // Handle action popovers
    const actionPopoverOpen = Boolean(anchorEl)
    const actionPopoverId = actionPopoverOpen ? 'action-popover' : undefined

    function handlePopoverClose() {
        handleClose()
    }

    function handleTaskAction(
        action?: LabelingTaskActionTypeValue,
        updatedTaskWithActions?: LabelingTaskWithActions
    ) {
        if (!updatedTaskWithActions) return
        handleClose()

        const updatedTaskId = updatedTaskWithActions.labelingTask.getTaskId()
        const itemsToUpdate: LabelingTaskWithActions[] = []
        gridApi?.forEachNodeAfterFilterAndSort((rowNode, _index) => {
            const { data } = rowNode
            if (!data) return

            if (data.labelingTask.getTaskId() === updatedTaskId) {
                data.labelingTask = updatedTaskWithActions.labelingTask
                data.availableActions = updatedTaskWithActions.availableActions
                itemsToUpdate.push(data)
            }
        })
        gridApi?.applyTransaction({ update: itemsToUpdate })

        handleTaskDataChanged()

        if (labelingOraclesEnabled === 'yes' && updatedTaskId) {
            if (
                action === LabelingTaskActionType.ACTION_TYPE_START_REVIEW ||
                action === LabelingTaskActionType.ACTION_TYPE_START_LABELING
            ) {
                history.push({
                    pathname: LABELING_MICROTASK.replace(':taskId', String(updatedTaskId)),
                })
            }

            if (action === LabelingTaskActionType.ACTION_TYPE_VIEW_REVIEW) {
                history.push({
                    pathname: LABELING_REVIEW_SUMMARY_EDIT.replace(
                        ':taskId',
                        String(updatedTaskId)
                    ),
                })
            }
        }
    }

    return (
        <DeepcellPopover
            {...defaultPopoverProps}
            id={actionPopoverId}
            open={actionPopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
        >
            {popoverTask && (
                <TaskActionPopover taskWithActions={popoverTask} handleClose={handleTaskAction} />
            )}
        </DeepcellPopover>
    )
}
export default LabelingTaskActionPopover
