import React, { ReactElement } from 'react'
import LinearProgressWithLabel from './LinearProgressWithLabel'

interface Props {
    value: number
}

const ProgressBarCellRenderer: React.FC<Props> = ({ value }: Props): ReactElement => (
    <LinearProgressWithLabel value={value} />
)

export default ProgressBarCellRenderer
