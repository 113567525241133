import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { GridReadyEvent, GridApi } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { DeepcellPrimaryButton, DeepcellSecondaryButton } from 'components/shared'
import React, { useState } from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { Run, RunQualityMetric, updateRun } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import { getQCMetricsColumnDefs } from './metadata'

interface IQCMetricsGrid {
    run: Run
    onEdit: (runs: Run[]) => void
}
interface IRowData extends Omit<RunQualityMetric, 'total_image_count'> {
    run_percent: string
}

const runBuckets = ['0-25%', '26-50%', '51-75%', '76-100%']

const QCMetricsGrid = (props: IQCMetricsGrid): JSX.Element => {
    const { permissions } = useAuthTokens()
    const { displayNotification } = useNotificationSlice()
    const [gridApi, setGridApi] = useState<GridApi | null>(null)
    const { run, onEdit } = props
    const [loading, setLoading] = useState<Record<'approve' | 'disapprove' | 'save', boolean>>({
        approve: false,
        disapprove: false,
        save: false,
    })

    const {
        run_quality_metrics: runQualityMetrics,
        run_quality_approved: runQualityApproved,
        run_quality_approved_email: runQualityApprovedBy,
        run_quality_score_email: runQualityScorer,
    } = run

    const getAgGridRenderedRowData = (): IRowData[] | undefined => {
        const renderedNodes = gridApi?.getRenderedNodes()
        return renderedNodes?.map((item): IRowData => item.data)
    }

    const getRunRowData = (rowData: Record<number, RunQualityMetric>) => {
        const runRowData: IRowData[] = []
        for (let i = 1; i <= 4; i += 1) {
            runRowData.push({
                run_percent: runBuckets[i - 1],
                cell_debris: rowData[i]?.cell_debris ?? 0,
                blebbing_image: rowData[i]?.blebbing_image ?? 0,
                chip_blemish_covering_image: rowData[i]?.chip_blemish_covering_image ?? 0,
                contamination_covering_image: rowData[i]?.contamination_covering_image ?? 0,
                cutoff_image: rowData[i]?.cutoff_image ?? 0,
            })
        }
        return runRowData
    }

    const generateQualityMetricsPayload = (rowData: IRowData[]) => {
        const payload: Record<number, RunQualityMetric> = {}

        for (let i = 0; i <= 3; i += 1) {
            payload[i + 1] = {
                total_image_count: 200,
                cutoff_image: Number(rowData[i].cutoff_image),
                contamination_covering_image: Number(rowData[i].contamination_covering_image),
                chip_blemish_covering_image: Number(rowData[i].chip_blemish_covering_image),
                blebbing_image: Number(rowData[i].blebbing_image),
                cell_debris: Number(rowData[i].cell_debris),
            }
        }
        return payload
    }

    const defaultRowData = (): IRowData[] => {
        const noMetricsRowData = []
        for (let i = 0; i < 4; i += 1) {
            noMetricsRowData.push({
                run_percent: runBuckets[i],
                cell_debris: 0,
                blebbing_image: 0,
                chip_blemish_covering_image: 0,
                contamination_covering_image: 0,
                cutoff_image: 0,
            })
        }
        return noMetricsRowData
    }

    const handleMetricsDataSubmission = async () => {
        const updatedRowData: IRowData[] | undefined = getAgGridRenderedRowData()

        if (updatedRowData !== undefined) {
            const runMetricsPayload = generateQualityMetricsPayload(updatedRowData)
            const params = {
                run_id: run.run_id,
                run_quality_metrics: runMetricsPayload,
            }

            try {
                setLoading({ ...loading, save: true })
                const res = await updateRun(params)
                const successMessage = 'Run Quality Review submitted successfully'
                displayNotification({ message: successMessage, type: 'success' })
                onEdit([res])
            } catch (err) {
                const errMessage =
                    (err as Error).message ||
                    'An unexpected error occurred when trying to update metrics data'
                displayNotification({ message: errMessage, type: 'error' })
            } finally {
                setLoading({ ...loading, save: false })
            }
        }
    }

    const handleApproveDisapproveAction = async (runQualityStatus: boolean) => {
        const confirmation = window.confirm(
            `Are you sure you want to ${runQualityStatus ? 'Approve' : 'Disapprove'}?`
        )

        const statusParams = {
            run_id: run.run_id,
            run_quality_approved: runQualityStatus,
        }

        if (confirmation) {
            const updatedRowData: IRowData[] | undefined = getAgGridRenderedRowData()
            if (runQualityStatus && updatedRowData !== undefined) {
                const runMetricsPayload = generateQualityMetricsPayload(updatedRowData)
                const metricsParams = {
                    run_id: run.run_id,
                    run_quality_metrics: runMetricsPayload,
                }
                try {
                    setLoading({ ...loading, approve: true })
                    await updateRun(metricsParams)
                    const res = await updateRun(statusParams)
                    const successMessage = 'Run Quality Review approved successfully'
                    displayNotification({ message: successMessage, type: 'success' })
                    onEdit([res])
                } catch (err) {
                    const errMessage =
                        (err as Error).message ||
                        'An unexpected error occurred when trying to update metrics data'
                    displayNotification({ message: errMessage, type: 'error' })
                } finally {
                    setLoading({ ...loading, approve: false })
                }
            } else {
                try {
                    setLoading({ ...loading, disapprove: true })
                    const res = await updateRun(statusParams)
                    const successMessage = 'Run Quality Review disapproved successfully'
                    displayNotification({ message: successMessage, type: 'success' })
                    onEdit([res])
                } catch (err) {
                    const errMessage =
                        (err as Error).message ||
                        'An unexpected error occurred when trying to update metrics data'
                    displayNotification({ message: errMessage, type: 'error' })
                } finally {
                    setLoading({ ...loading, disapprove: false })
                }
            }
        }
    }

    let rowData: IRowData[] = []
    if (runQualityMetrics !== undefined) {
        rowData = getRunRowData(runQualityMetrics as unknown as Record<number, RunQualityMetric>)
    } else {
        rowData = defaultRowData()
    }

    return (
        <>
            <div
                style={{ width: '100%' }}
                className="ag-theme-balham ag-theme-deepcell"
                data-testid="qc-metric-grid"
            >
                <AgGridReact
                    domLayout="autoHeight"
                    rowData={rowData}
                    defaultColDef={{
                        autoHeight: true,
                        suppressMenu: true,
                    }}
                    columnDefs={getQCMetricsColumnDefs(run.run_id, runBuckets)}
                    editType="fullRow"
                    onGridReady={(params: GridReadyEvent) => setGridApi(params.api)}
                />
            </div>
            <Divider />
            {permissions.has('qc:approve') && permissions.has('qc:edit') && (
                <>
                    <Stack direction="row" spacing={1}>
                        <DeepcellPrimaryButton
                            onClick={() => handleApproveDisapproveAction(true)}
                            style={{ width: '150px' }}
                            contained
                            disabled={loading.approve || loading.disapprove}
                            endIcon={
                                loading.approve && <CircularProgress color="secondary" size={16} />
                            }
                        >
                            Approve
                        </DeepcellPrimaryButton>
                        <DeepcellSecondaryButton
                            onClick={() => handleApproveDisapproveAction(false)}
                            style={{ width: '150px' }}
                            contained
                            disabled={loading.approve || loading.disapprove}
                            endIcon={
                                loading.disapprove && (
                                    <CircularProgress color="secondary" size={16} />
                                )
                            }
                        >
                            Disapprove
                        </DeepcellSecondaryButton>
                    </Stack>
                    {runQualityApproved !== undefined &&
                        runQualityApprovedBy !== undefined &&
                        runQualityApprovedBy.length > 0 && (
                            <span>
                                Run quality was previously{' '}
                                {runQualityApproved ? 'approved' : 'disapproved'} by{' '}
                                <u>{runQualityApprovedBy}</u>
                            </span>
                        )}
                    {runQualityScorer !== undefined && runQualityScorer.length > 0 && (
                        <span>
                            Manual run quality was performed by <u>{runQualityScorer}</u>
                        </span>
                    )}
                </>
            )}
            {permissions.has('qc:edit') && !permissions.has('qc:approve') && (
                <DeepcellPrimaryButton
                    onClick={handleMetricsDataSubmission}
                    style={{ width: '150px' }}
                    contained
                    disabled={loading.save}
                    endIcon={loading.save && <CircularProgress color="secondary" size={16} />}
                >
                    Save
                </DeepcellPrimaryButton>
            )}
        </>
    )
}
export default QCMetricsGrid
