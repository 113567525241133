import { MutableRefObject, useMemo, useRef } from 'react'
import { GridApi, SelectionChangedEvent } from 'ag-grid-community'

export type SelectionAction<TIndex> = {
    type: 'selection'
    selectedIds: TIndex[]
}

class SelectionManager<TIndex, TData> {
    selectedSet: Set<TIndex>

    getIdFunc: (data: TData) => TIndex

    handleUpdate: (action: SelectionAction<TIndex>) => void

    gridApi: GridApi | null

    clearedRef: MutableRefObject<boolean | null>

    constructor(
        selectedIds: TIndex[],
        getIdFunc: (data: TData) => TIndex,
        handleUpdate: (action: SelectionAction<TIndex>) => void,
        gridApi: GridApi | null,
        clearedRef: MutableRefObject<boolean | null>
    ) {
        this.selectedSet = new Set(selectedIds)
        this.getIdFunc = getIdFunc
        this.handleUpdate = handleUpdate
        this.gridApi = gridApi
        this.clearedRef = clearedRef
    }

    setGridApi(gridApi: GridApi) {
        this.gridApi = gridApi
    }

    initSelection() {
        this.gridApi?.forEachNode((node, _index) => {
            if (this.selectedSet.has(this.getIdFunc(node.data))) {
                node.setSelected(true)
            } else {
                node.setSelected(false)
            }
        })
    }

    scrollToSelected() {
        if (!this.gridApi) {
            return
        }
        let firstSelectedIndex: number | undefined

        this.gridApi.forEachNodeAfterFilterAndSort((node, index) => {
            if (this.selectedSet.has(this.getIdFunc(node.data))) {
                if (firstSelectedIndex === undefined) {
                    firstSelectedIndex = index
                } else {
                    firstSelectedIndex = Math.min(firstSelectedIndex, index)
                }
            }
        })
        if (firstSelectedIndex) {
            this.gridApi.ensureIndexVisible(firstSelectedIndex, 'middle')
        }
    }

    resetSelection(): void {
        this.clearedRef.current = true
        this.gridApi?.deselectAll()
    }

    onSelectionChanged(event: SelectionChangedEvent): void {
        // Don't trigger a selection update if the selection change was an automatically
        // triggered reset to nothing selected
        if (this.clearedRef.current) {
            this.clearedRef.current = false
            return
        }
        const nodes = event.api.getSelectedNodes()
        const ids = nodes.map((value) => this.getIdFunc(value.data as TData))
        this.handleUpdate({ type: 'selection', selectedIds: ids })
    }
}

/** Messy (but less messy than before) hook that handles selection state and handling changes for an Ag-Grid Table
 *
 * See LabelingTaskPage and RunPage for example usage of the selectionManager
 *
 * @TODO Think more about how this mixes in with other common parts (query parameter, search filter, and tab state)
 * I tried to do that refactor and it's a little bit too much to attempt.  Leaving it for future work...
 *
 * e.g. this hook could deal with the common handleUpdate logic, instead of having a function passed in -- but that
 * requires properly handling search + selection logic together
 * */
const useSelection = <TIndex, TData>(
    selectedIds: TIndex[],
    getIdFunc: (data: TData) => TIndex,
    handleUpdate: (action: SelectionAction<TIndex>) => void,
    gridApi: GridApi | null
): SelectionManager<TIndex, TData> => {
    const clearedRef = useRef<boolean | null>(null)

    return useMemo(
        () =>
            new SelectionManager(
                selectedIds,
                getIdFunc,
                handleUpdate,
                gridApi,
                clearedRef
            ),
        [selectedIds, getIdFunc, handleUpdate, gridApi, clearedRef]
    )
}

export default useSelection
