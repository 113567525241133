import SellIcon from '@mui/icons-material/Sell'
import { ReactElement } from 'react'

const StatusCellComponent = (data: number): ReactElement | null => {
    if (data === undefined) return null
    const iconColor = {
        Good: '#14D7275E',
        Fair: '#FDB807BF',
        Poor: '#D7143780',
    }
    const cellValue = data * 100
    let status: string
    if (cellValue >= 0 && cellValue <= 20) status = 'Good'
    else if (cellValue >= 21 && cellValue <= 50) status = 'Fair'
    else status = 'Poor'
    return (
        <span style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <SellIcon
                sx={{ color: iconColor[status as keyof typeof iconColor] }}
                fontSize="small"
            />{' '}
            {status}
        </span>
    )
}
export default StatusCellComponent
