import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { ComponentType, MouseEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface LinkListItemProps {
    url: string
    label: string
    iconEl: ComponentType
}

export const LinkListItem = ({
    url,
    label,
    iconEl,
}: LinkListItemProps): JSX.Element => {
    const MyIcon = iconEl
    return (
        <ListItem button component="a" href={url} target="_blank">
            <ListItemIcon>
                <MyIcon />
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    )
}

interface RouterLinkListItemProps {
    to: string
    label: string
    iconEl: ComponentType
}

export const RouterLinkListItem = ({
    to,
    label,
    iconEl,
}: RouterLinkListItemProps): JSX.Element => {
    const MyIcon = iconEl
    return (
        <ListItem>
            <ListItemIcon>
                <MyIcon />
            </ListItemIcon>
            <ListItemText>
                <RouterLink
                    to={to}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    {label}
                </RouterLink>
            </ListItemText>
        </ListItem>
    )
}

interface ButtonListItemProps {
    onClick: (
        event: MouseEvent<HTMLElement> | MouseEvent<HTMLAnchorElement>
    ) => void
    label: string
    iconEl: ComponentType
}

export const ButtonListItem = ({
    onClick,
    label,
    iconEl,
}: ButtonListItemProps): JSX.Element => {
    const MyIcon = iconEl
    return (
        <ListItem button onClick={onClick}>
            <ListItemIcon>
                <MyIcon />
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    )
}
