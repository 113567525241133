import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/system'
import React, { FunctionComponent } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import TabPanel from './TabPanel'

const Root = styled('div')({
    height: '100%',
    maxHeight: '100%',
})

const SearchDetailsContent = styled('div')({
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
})

const SearchDetailsLeftPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',

    '& .MuiTab-root': {
        minWidth: '80px',
    },
}))

export enum ActiveTab {
    SEARCH = 'search',
    DETAILS = 'details',
}

export type SearchDetailsQueryState = {
    activeTab: ActiveTab
}

export type SearchDetailsAction = {
    type: 'activeTab'
    tab: ActiveTab | undefined
}

interface Props {
    activeTab: ActiveTab
    handleTabChange: (at: ActiveTab) => void
    searchTab: React.ReactNode
    detailsTab: React.ReactNode
    children?: React.ReactNode
    searchDetailsFlex?: number // default 1
    contentFlex?: number // default 4
}

/**
 * A view with a tabbed search + details panel on the left and content panel on the right
 *
 * @TODO This is workable for now, but not the most compact.  May want to add support for collapsing the panel.
 * Or tweak layout.
 */
const SearchDetailsView: FunctionComponent<Props> = ({
    searchTab,
    detailsTab,
    children,
    searchDetailsFlex,
    contentFlex,
    activeTab,
    handleTabChange,
}: Props) => {
    const searchDetailsFlexValue = searchDetailsFlex === undefined ? 1 : searchDetailsFlex
    const contentFlexValue = contentFlex === undefined ? 4 : contentFlex

    useHotkeys('/', () => handleTabChange(ActiveTab.SEARCH), {}, [])
    useHotkeys('d', () => handleTabChange(ActiveTab.DETAILS), {}, [])

    return (
        <Root>
            <SearchDetailsContent>
                <SearchDetailsLeftPanel style={{ flex: searchDetailsFlexValue }}>
                    <Tabs
                        value={activeTab}
                        onChange={(_event: React.ChangeEvent<unknown>, value: ActiveTab) =>
                            handleTabChange(value)
                        }
                    >
                        <Tab label="Search (/)" value={ActiveTab.SEARCH} />
                        <Tab label="Details (D)" value={ActiveTab.DETAILS} />
                    </Tabs>
                    {/* Need to keep this tab's overflow visible because of the date picker
                        @TODO: find a way to not have to do this
                    */}
                    <TabPanel value={activeTab} index={ActiveTab.SEARCH} overflow="visible">
                        {searchTab}
                    </TabPanel>
                    <TabPanel value={activeTab} index={ActiveTab.DETAILS}>
                        {detailsTab}
                    </TabPanel>
                </SearchDetailsLeftPanel>
                <div style={{ flex: contentFlexValue }}>{children}</div>
            </SearchDetailsContent>
        </Root>
    )
}

export default SearchDetailsView
