import { DEFAULT_REMOTE_CONFIG, RemoteConfigSettings } from 'config'
import firebase from 'firebase'
import { useEffect, useState } from 'react'

// Loosely based on https://medium.com/javascript-in-plain-english/create-feature-flags-for-your-react-app-with-firebase-remote-config-92d17c5e518d
// If additional logic around versions in the flags is useful, go look up that article again
//
// Currently, this provides access to config settings with a defined type (RemoteConfigSettings),
// ignoring all other settings that may exist on Firebase which are not supported by this app yet
export const useFlags = (): RemoteConfigSettings => {
    const [flags, setFlags] = useState(DEFAULT_REMOTE_CONFIG)

    useEffect(() => {
        const remoteConfig = firebase.remoteConfig()

        remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG
        remoteConfig
            .fetchAndActivate()
            .then((_activated) => {
                return remoteConfig.getAll()
            })
            .then((remoteFlags) => {
                const newFlags = Object.keys(remoteFlags).reduce((acc, key) => {
                    return { ...acc, [key]: remoteFlags[key].asString() }
                }, {} as RemoteConfigSettings)
                setFlags(newFlags)
            })
            .catch((error) => console.error(error))
    }, [])

    return flags
}

export default useFlags
