import * as ROUTES from 'constants/routes'
import { AddCircle, BarChart, GetApp, Label, Search } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Run } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import { LinkListItem, RouterLinkListItem } from '../shared/CustomListItems'
import { useFlags } from '../shared/useFlags'

interface RunDetailsActionsProps {
    runs: Run[]
    runIdString: string | undefined
    handleGeneratePredictions: VoidFunction
    handleCreateLabelingTasks: VoidFunction
    onDownload: VoidFunction
}

export const CLASS_DISTRIBUTION_URL =
    'https://app.mode.com/deepcell/reports/7b023439388c?param_run_id_param='
export const LABEL_SUMMARY_URL =
    'https://app.mode.com/deepcell/reports/3565fff6cd73?param_run_id_param='
export const OOF_SCORE_URL = 'https://app.mode.com/deepcell/reports/313d3d2fda59?param_RunId='

export const RunDetailsActions = ({
    runs,
    runIdString,
    handleGeneratePredictions,
    handleCreateLabelingTasks,
    onDownload,
}: RunDetailsActionsProps): JSX.Element => {
    const { permissions } = useAuthTokens()
    const { labelingEnabled, modelsV3ApiEnabled } = useFlags()
    const GET_EMBEDDINGS_URL =
        modelsV3ApiEnabled === 'yes'
            ? 'https://app.mode.com/deepcell/reports/f140de791fe1?param_run_id_param='
            : 'https://app.mode.com/deepcell/reports/aa1634064be5?param_run_id_param='
    return (
        <div>
            <Typography sx={{ display: 'block', mt: 2 }} variant="h6">
                With the {runs?.length && runs.length > 1 ? runs.length : ''} selected run
                {runs?.length && runs.length > 1 ? 's' : ''}:
            </Typography>
            <List>
                {/* @TODO Make this work quickly for multiple runs */}
                {runs?.length === 1 && (
                    <RouterLinkListItem
                        to={`${ROUTES.CELL_BROWSING}?sortOrderKey=RUN_ID_ASC&runIds=${runIdString}`}
                        label="Browse cells"
                        iconEl={Search}
                    />
                )}
                {permissions.has('models:predictions:create') && (
                    <ListItem
                        button
                        component="a"
                        onClick={(_event: React.MouseEvent) => handleGeneratePredictions()}
                    >
                        <ListItemIcon>
                            <AddCircle />
                        </ListItemIcon>
                        <ListItemText primary="Generate predictions" />
                    </ListItem>
                )}
                {labelingEnabled === 'yes' && (
                    <ListItem
                        button
                        component="a"
                        onClick={(_event: React.MouseEvent) => handleCreateLabelingTasks()}
                    >
                        <ListItemIcon>
                            <Label />
                        </ListItemIcon>
                        <ListItemText primary="Create labeling tasks" />
                    </ListItem>
                )}
                <LinkListItem
                    url={CLASS_DISTRIBUTION_URL + runIdString}
                    label="See class distributions"
                    iconEl={BarChart}
                />
                <LinkListItem
                    url={LABEL_SUMMARY_URL + runIdString}
                    label="See label distributions"
                    iconEl={BarChart}
                />
                {runs?.length === 1 && (
                    <LinkListItem
                        url={OOF_SCORE_URL + runIdString}
                        label="See OOF distribution"
                        iconEl={BarChart}
                    />
                )}
                <LinkListItem
                    url={GET_EMBEDDINGS_URL + runIdString}
                    label="Download embeddings"
                    iconEl={GetApp}
                />
                <ListItem button component="a" onClick={(_event: React.MouseEvent) => onDownload()}>
                    <ListItemIcon>
                        <GetApp />
                    </ListItemIcon>
                    <ListItemText primary="Download run data (CSV)" />
                </ListItem>
            </List>
        </div>
    )
}

export default RunDetailsActions
