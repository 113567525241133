import { Breadcrumbs, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import useAxonTitle from 'components/shared/useAxonTitle'
import { Helmet } from 'react-helmet'
import { Route, Link as RouterLink } from 'react-router-dom'

const NavTopBarBreadcrumb = styled(Breadcrumbs)({
  color: 'white !important',
})

const breadcrumbNameMap: { [key: string]: string } = {
  '/runs': 'Runs',
  '/cell-browsing': 'Cell Browsing',
  '/labeling': 'Labeling',
  '/labeling/tasks': 'Tasks',
  '/cell-visualizations': 'Cell Visualization',
}

interface NavigationTopBarTitleProps {
  appTitle: string
}

export const NavigationTopBarTitle = ({ appTitle }: NavigationTopBarTitleProps): JSX.Element => {
  const title = useAxonTitle('Deepcell Cloud')
  return (
    <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split('/').filter((x) => x)

          // @TODO At some point make links work in the breadcrumbs
          // Code is from this example: https://material-ui.com/components/breadcrumbs/
          return (
            <NavTopBarBreadcrumb aria-label="breadcrumb">
              <Typography variant="h6">
                {appTitle}
                <Helmet>
                  {/* Page titles help with tracking in google analytics */}
                  <title>
                    {title}
                    {pathnames.length > 0
                      ? ` - ${breadcrumbNameMap[`/${pathnames[pathnames.length - 1]}`]}`
                      : ''}
                  </title>
                </Helmet>
              </Typography>
              {pathnames.map((_value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`

                return (
                  <RouterLink key={to} to={to}>
                    <Typography
                      variant="h6"
                      key={to}
                      sx={{
                        color: 'white',
                        textDecoration: 'underline',
                      }}
                    >
                      {breadcrumbNameMap[to]}
                    </Typography>
                  </RouterLink>
                )
              })}
            </NavTopBarBreadcrumb>
          )
        }}
      </Route>
    </Typography>
  )
}

export default NavigationTopBarTitle
