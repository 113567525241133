import { Box, Stack, Typography } from '@mui/material'
import HideIcon from 'components/shared/icons/Hide'
import ShowIcon from 'components/shared/icons/Show'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useFlagCondition from 'components/shared/useFlagCondition'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { HIDDEN_COLOR } from '../shared'

const colorBoxSize = 40

export interface ILegendItemProps {
  label: string
  bgColor: string
  onClick?: (e: React.MouseEvent) => void
  dataLabel: string
}

const LegendItem = ({
  label,
  bgColor,
  dataLabel,
  onClick,
  ...rest
}: ILegendItemProps): JSX.Element => {
  const eventsManager = useEventsManager()

  const showFilter = useFlagCondition('enableFiltering', true, false)
  const {
    setLegendLabelColors,
    cellVisualizations: { legendLabelColors },
  } = useCellVisualizationsSlice()

  const currentLegendLabelColor = legendLabelColors.find((llc) => `${llc.name}` === `${label}`)
  const { isHidden } = currentLegendLabelColor ?? {}

  const onClickHide = () => {
    if (isHidden) {
      eventsManager.sendFilterShowEvent(label)
    } else {
      eventsManager.sendFilterHideEvent(label)
    }

    setLegendLabelColors(
      legendLabelColors.map((llc) => {
        if (`${llc.name}` === `${label}`) {
          return { ...llc, isHidden: !llc.isHidden }
        }
        return llc
      })
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ opacity: isHidden ? 0.4 : undefined }}
    >
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            minWidth: colorBoxSize,
            minHeight: colorBoxSize,
            width: colorBoxSize,
            height: colorBoxSize,
            backgroundColor: `${isHidden ? HIDDEN_COLOR : bgColor} !important`,
            cursor: onClick ? 'pointer' : '',
            borderRadius: 1,
          }}
          data-testid="legend-item"
          data-label={dataLabel}
          onClick={onClick}
          {...rest}
        />
        <Typography alignSelf="center">{label}</Typography>
      </Stack>
      {showFilter ? (
        <>
          {isHidden ? (
            <ShowIcon fontSize="large" onClick={onClickHide} sx={{ cursor: 'pointer' }} />
          ) : (
            <HideIcon fontSize="large" onClick={onClickHide} sx={{ cursor: 'pointer' }} />
          )}
        </>
      ) : null}
    </Stack>
  )
}
export default LegendItem
