import { rest, RestRequest } from 'msw'
import MiniSearch, { SearchResult } from 'minisearch'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import _ from 'lodash'
import { API_URL, GetRunsParams, Run, WellConfiguration } from '../../utils/api'
import wellConfigurationData from '../../mocks/json/well-configuration.json'
import wellCountData from '../../mocks/json/well-count.json'
import wellMetricData from '../../mocks/json/well-metric.json'
import cellCountData from '../../mocks/json/cell_count.json'

Moment.locale('en')
momentLocalizer()

/** Define mock run API handlers for use in unit testing and local development */

const PROJECT_CODES = ['THO', 'ABBV', 'ABBV2', 'ZUR', 'SVS', 'IRIS', 'CLA']

const USER_EMAILS = [
    'jill@deepcell.com',
    'joe@deepcell.com',
    'jack@deepcell.com',
    'janet@deepcell.com',
]

const INSTRUMENTS = ['Libra', 'Scorpio']

export const RUN_DATA: Run[] = []
const NUM_FAKE_RUNS = 1000

/** Create a large number of fake rus to return for run search */
for (let i = 0; i < NUM_FAKE_RUNS; i += 1) {
    RUN_DATA.push({
        id: i,
        organization_id: 'org_deepcell',
        user_email: USER_EMAILS[i % USER_EMAILS.length],
        instrument_name: INSTRUMENTS[i % INSTRUMENTS.length],
        run_id: `IR${i}`,
        description: `Run ${i} description`,
        project_code: PROJECT_CODES[i % PROJECT_CODES.length],
        total_cell_count: (i * 100003) % 20000,
        start_time: Moment('2020-01-01').add(i, 'hour').toISOString(),
        stop_time: Moment('2020-01-01').add(i, 'hour').add(i, 'second').toISOString(),
        well_sorting_configurations: wellConfigurationData as WellConfiguration[],
        well_metrics: wellMetricData,
        well_counts: wellCountData,
        cell_counts: cellCountData,
        stopped: true,
        run_quality_metrics: {
            1: {
                total_image_count: 200,
                cutoff_image: Math.random() * 200,
                contamination_covering_image: Math.random() * 200,
                chip_blemish_covering_image: Math.random() * 200,
                blebbing_image: Math.random() * 200,
                cell_debris: Math.random() * 200,
            },
            2: {
                total_image_count: 200,
                cutoff_image: Math.random() * 200,
                contamination_covering_image: Math.random() * 200,
                chip_blemish_covering_image: Math.random() * 200,
                blebbing_image: Math.random() * 200,
                cell_debris: Math.random() * 200,
            },
            3: {
                total_image_count: 200,
                cutoff_image: Math.random() * 200,
                contamination_covering_image: Math.random() * 200,
                chip_blemish_covering_image: Math.random() * 200,
                blebbing_image: Math.random() * 200,
                cell_debris: Math.random() * 200,
            },
            4: {
                total_image_count: 200,
                cutoff_image: Math.random() * 200,
                contamination_covering_image: Math.random() * 200,
                chip_blemish_covering_image: Math.random() * 200,
                blebbing_image: Math.random() * 200,
                cell_debris: Math.random() * 200,
            },
        },
    })
}

/** Use MiniSearch to build a full-text search index on the fake runs */
const miniSearch = new MiniSearch({
    fields: ['user_email', 'instrument_name', 'run_id', 'description', 'project_code'],
    searchOptions: {
        prefix: true,
    },
})
miniSearch.addAll(RUN_DATA)

/** Define a mock API handler for GET /v1/runs:search that simulates most of the search behaviour */
const RUN_API_HANDLERS = [
    rest.get(`${API_URL}/v1/runs:search`, (req: RestRequest<GetRunsParams>, res, ctx) => {
        // Parameters use underscores instead of camelCase.  Defaulting all values to null, in case they're not passed in
        // with the request body (since all search parameters are optional)
        const params = req.url.searchParams
        const q = params.get('q')
        const limitParam = params.get('limit')
        const limit = limitParam ? parseInt(limitParam, 10) : null
        const offsetParam = params.get('offset')
        const offset = offsetParam ? parseInt(offsetParam, 10) : 0
        const runIds = params.has('run_ids') ? params.get('run_ids')?.split(',') : null
        const projectCode = params.get('project_code')
        const userEmail = params.get('user_email')
        const startTimeMin = params.get('start_time_min')
        const startTimeMax = params.get('start_time_max')
        const sampleTypes = params.has('sample_types')
            ? params.get('sample_types')?.split(',')
            : null
        const orderBy = params.get('order_by')

        const filters: ((run: Run | SearchResult) => boolean)[] = []

        if (runIds) filters.push((run: Run | SearchResult) => runIds.includes(run.run_id))
        if (projectCode) filters.push((run: Run | SearchResult) => run.project_code === projectCode)
        if (userEmail) filters.push((run: Run | SearchResult) => run.user_email === userEmail)
        if (startTimeMin) filters.push((run: Run | SearchResult) => run.start_time > startTimeMin)
        if (startTimeMax) filters.push((run: Run | SearchResult) => run.start_time < startTimeMax)
        if (sampleTypes)
            filters.push((run: Run | SearchResult) =>
                run.sample_type ? run.sample_type in sampleTypes : false
            )

        function filterClause(run: Run | SearchResult): boolean {
            for (let i = 0; i < filters.length; i += 1) {
                if (!filters[i](run)) return false
            }
            return true
        }

        let rawResults
        if (q) {
            rawResults = miniSearch
                .search(q, {
                    filter: filterClause,
                    combineWith: 'AND',
                })
                .map((searchResultItem: SearchResult) => RUN_DATA[searchResultItem.id])
        } else {
            rawResults = RUN_DATA.filter(filterClause)
        }

        if (orderBy) {
            const fieldDirections = orderBy.split(',').map((fd) => fd.split(':'))
            const fields = fieldDirections.map((fd) => fd[0])
            const directions = fieldDirections.map((fd) => fd[1]) as ('asc' | 'desc')[]
            rawResults = _.orderBy(rawResults, fields, directions)
        }
        const start = offset || 0
        const end = limit ? Math.min(start + limit, rawResults.length) : rawResults.length
        const resultRuns = rawResults.slice(start, end)

        return res(ctx.json(resultRuns))
    }),
]

export default RUN_API_HANDLERS
