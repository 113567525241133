import { styled } from '@mui/material'
import { DeepcellAutocomplete } from 'components/shared'
import _ from 'lodash'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import { PinnedCellGroup } from 'redux/slices/types'
import useDataCategory, { CELL_GROUPS_CATEGORY_KEY } from './useDataCategory'

const StyledDeepcellAutocomplete = styled(DeepcellAutocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
        color: theme.palette.primary.main,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
    },
}))

export const DataTypeCompareSelect = (): JSX.Element => {
    const {
        mergedPinnedCellGroups,
        setGroupCompareSelectedGroups,
        setGroupCompare,
        cellVisualizations: { groupCompare, cellsData },
    } = useCellVisualizationsSlice()

    const { getDataCategoryAttribute } = useDataCategory()

    const { selectedDataCategory, selectedDataFields } = groupCompare
    const cellInfoKey = getDataCategoryAttribute(selectedDataCategory)

    const selectedGroups = mergedPinnedCellGroups.filter((x) => x.isInSelectedGroup)

    // Get all the unique values for the selected data category.  Convert them to String to ensure they display
    // and compare consistently (e.g. in case of BigInt cluster values)
    const dataFields = _.uniq(
        (cellsData ?? []).flatMap((cd) => String(cd[cellInfoKey]) ?? [])
    ).sort()
    const eventsManager = useEventManager()

    const autocompleteProps: React.ComponentProps<typeof DeepcellAutocomplete> = {
        multiple: true,
        filterSelectedOptions: true,
        disableClearable: true,
        id: 'select-data-type',
        label: `Choose ${selectedDataCategory} to compare`,
    }

    return (
        <>
            {selectedDataCategory === CELL_GROUPS_CATEGORY_KEY ? (
                <StyledDeepcellAutocomplete
                    {...autocompleteProps}
                    options={mergedPinnedCellGroups}
                    getOptionLabel={(opt) => (opt as PinnedCellGroup).name}
                    value={selectedGroups}
                    onChange={(__, opt) =>
                        setGroupCompareSelectedGroups(
                            (opt as typeof mergedPinnedCellGroups).map((x) => x.id)
                        )
                    }
                />
            ) : (
                <StyledDeepcellAutocomplete
                    options={dataFields}
                    value={selectedDataFields[selectedDataCategory] ?? []}
                    onChange={(__, opt) =>
                        setGroupCompare({
                            groupCompare: {
                                ...groupCompare,
                                selectedDataFields: {
                                    ...selectedDataFields,
                                    [selectedDataCategory]: opt as typeof dataFields,
                                },
                            },
                            eventsManager,
                        })
                    }
                    {...autocompleteProps}
                />
            )}
        </>
    )
}

export default DataTypeCompareSelect
