import { usePapaParse } from 'react-papaparse'

const useReadCSV = (): ((_: string) => Promise<unknown>) => {
    const { readString } = usePapaParse()

    const readCSV = async (csvData: string) => {
        return new Promise((resolve) => {
            readString(csvData, {
                worker: true,
                header: true,
                complete: (results) => {
                    resolve(results.data)
                },
            })
        })
    }
    return readCSV
}

export default useReadCSV
