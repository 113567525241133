import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { ValueGetterParams } from 'ag-grid-community'
import _ from 'lodash'
import Moment from 'moment'
import { useQuery } from 'react-query'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import {
    convertErrorToString,
    FindLabelingTasksResponse,
    getLabelingTasks,
    LabelingTaskWithActions,
} from 'utils/api'
import {
    CellClassEncoderDecoder,
    LabelingTaskActionTypeEncoderDecoder,
    LabelingTaskActionTypeValue,
    LabelingTaskStatusEncoderDecoder,
} from 'utils/proto-utils'

Moment.locale('en')
momentLocalizer()

/**
 * Hook that runs a cell search
 */
export const useLabelingTaskApiData = (): {
    result: FindLabelingTasksResponse | undefined
    error: string | undefined
} => {
    return convertErrorToString(
        useQuery(['getLabelingTasks', {}], getLabelingTasks, {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        })
    )
}

export const labelingTaskValueGetter = (params: ValueGetterParams): string | number | undefined => {
    const { field } = params.colDef
    const taskWithActions: LabelingTaskWithActions = params.data
    const task: LabelingTask = taskWithActions.labelingTask
    const priority = task.getPriority()
    const dueDate = task.getDueDate()

    switch (field) {
        case 'availableActions':
            return taskWithActions.availableActions
                ?.map((x: LabelingTaskActionTypeValue) =>
                    LabelingTaskActionTypeEncoderDecoder.convertToString(x)
                )
                .join(',')
        case 'assignee':
            return task.getAssignee()?.split('@')[0]
        case 'cell_count':
            return task.getCellCount()
        case 'run_id':
            return task.getRunId() || 'N/A'
        case 'task_id':
            return task.getTaskId()
        case 'priority':
            return priority !== undefined ? `P${priority}` : ''
        case 'due_date':
            if (dueDate === 0) return ''
            return Moment(task.getDueDate()).format('YYYY-MM-DD')
        case 'status':
            return _.startCase(
                _.toLower(LabelingTaskStatusEncoderDecoder.convertToString(task.getStatus()))
            )
        case 'cell_classes_to_label':
            return task
                .getCellClassesToLabelList()
                .map((x: number) => CellClassEncoderDecoder.convertToString(x))
                .join(', ')
        case 'labeling_instructions':
            return task.getLabelingInstructions() || ''
        case 'labeling_notes':
            return task.getLabelingNotes()
        case 'run_description':
            return task.getRunDescription()
        case 'project_name':
            return task.getProjectName()
        case 'experiment_name':
            return task.getExperimentName()
        case 'instrument_name':
            return task.getInstrumentName()
        case 'created_by':
            return task.getCreatedBy() || ''
        case 'created_time':
            return Moment(task.getCreatedTime()).format('YYYY-MM-DD HH:mm:ss') || ''
        case 'current_batch_progress':
            return (task.getCurrentBatchProgress() || 0) * 100
        default:
            throw Error(`Unhandled task field ${field}`)
    }
}
