import BarChartIcon from '@mui/icons-material/BarChart'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import { Stack, useTheme } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import useFlags from 'components/shared/useFlags'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import SessionOptions from '../sessionSelect/SessionOptions'

import { DatasetSelection } from './DatasetSelection'
import ToolButtons from './ToolButtons'

export const cellVisualizationsHeaderId = 'cellVisualizationsHeaderId'
export const dataVizCellGroupsButtonId = 'dataVizCellGroupsButtonId'
export const dataVizCompareGroupsButtonId = 'dataVizCompareGroupsButtonId'

const CellVisualizationsHeader = (): JSX.Element => {
    const theme = useTheme()

    const {
        setShowCellGroups,
        setShowCompare,
        cellVisualizations: { showCellGroups, showCompare },
    } = useCellVisualizationsSlice()

    const { cellVisualizationsCompareEnabled, demoEnabled, saveSessionsV2 } = useFlags()
    const showCompareButton = cellVisualizationsCompareEnabled === 'yes'
    const showDataset = demoEnabled === 'yes'
    const isSaveV2 = saveSessionsV2 === 'yes'
    const showSessions = demoEnabled === 'no' && !isSaveV2

    return (
        <Stack
            id="cellVisualizationsHeaderId"
            sx={{
                justifyContent: 'space-between',
                boxShadow: '0 3px 5px rgb(0 0 0 / 12%)',
                height: '48px',
                mb: '5px',
                position: 'relative',
                left: '-8px',
                px: 1,
                width: 'calc(100% + 16px)',
            }}
            direction="row"
            justifyContent="space-between"
            boxShadow="0 3px 5px rgb(0 0 0 / 12%)"
            height="48px"
            marginBottom="5px"
        >
            <Stack direction="row" spacing={2}>
                {showDataset && <DatasetSelection />}
                {showSessions && <SessionOptions />}
            </Stack>
            <ToolButtons />
            <Stack direction="row" spacing={2} mb=".3rem">
                <DeepcellPrimaryButton
                    id={dataVizCellGroupsButtonId}
                    sx={{ background: showCellGroups ? theme.palette.success.dark : undefined }}
                    {...(showCellGroups ? { contained: true } : { outlined: true })}
                    onClick={() => setShowCellGroups(!showCellGroups)}
                    startIcon={<ScatterPlotIcon />}
                >
                    Cell Groups
                </DeepcellPrimaryButton>
                {showCompareButton ? (
                    <DeepcellPrimaryButton
                        id={dataVizCompareGroupsButtonId}
                        sx={{ background: showCompare ? theme.palette.success.dark : undefined }}
                        {...(showCompare ? { contained: true } : { outlined: true })}
                        onClick={() => setShowCompare(!showCompare)}
                        startIcon={<BarChartIcon />}
                    >
                        Compare
                    </DeepcellPrimaryButton>
                ) : null}
            </Stack>
        </Stack>
    )
}

export default CellVisualizationsHeader
