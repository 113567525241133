import {
    DialogContentText,
    SelectChangeEvent,
    Stack,
    Checkbox,
    FormControlLabel,
} from '@mui/material'
import { DeepcellDialog, DeepcellPrimarySelect, DeepcellTextField } from 'components/shared'
import { useFlags } from 'components/shared/useFlags'
import { useState } from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { ClientError, Run, generatePredictions, generatePredictionsV3 } from 'utils/api'

interface Props {
    isOpen: boolean
    handleClose: VoidFunction
    runs: Run[]
}

function GeneratePredictionsDlg(props: Props): JSX.Element {
    const { validModelList, modelsV3ApiEnabled } = useFlags()
    // @TODO: figure out why '[]' isn't getting automatically added in tests
    const validModels = JSON.parse(validModelList ?? '[]')

    const { isOpen, handleClose, runs } = props
    const [modelName, setModelName] = useState<string>(validModels[0])
    const [modelType, setModelType] = useState<string>('Embedding')
    const [showV3Models, setShowV3Models] = useState(false)
    const { displayNotification } = useNotificationSlice()

    async function handleGeneratePredictions() {
        const runIds: string[] = runs.map((run: Run): string => {
            return String(run.run_id)
        })
        try {
            displayNotification({
                message: `Queueing prediction run for: ${modelName}:${modelType} (${runIds.join(
                    ', '
                )})`,
                showProgress: true,
            })
            const resp = showV3Models
                ? await generatePredictionsV3(modelName, runIds)
                : await generatePredictions(modelName, modelType.toLowerCase(), runIds)
            const theDate = new Date(
                new Date(resp.execution_date.replace(/\..*$/, 'Z')).valueOf() + 1000
            ) // add 1 second to compensate for nanoseconds
            const baseDate = theDate
                .toISOString()
                .replace(/\..*$/, '')
                .replace(/T/, '+')
                .replace(/:/g, '%3A')
            const message = `The prediction run has been queued. Run Id: ${resp.run_id}. View the status in the <a target="_blank" href="${resp.dashboard_url}&base_date=${baseDate}&num_runs=1">AirFlow Dashboard</a>`
            displayNotification({ message, type: 'success' })
        } catch (err) {
            console.error(err)
            let errMessage = 'An unkown error occurred'
            if (err instanceof ClientError) {
                errMessage = err.message
            } else {
                errMessage = `${err}`
            }
            displayNotification({ message: errMessage, type: 'error', timeout: 60000 })
        } finally {
            handleClose()
        }
    }

    function handleModelNameChange(event: SelectChangeEvent<unknown>) {
        setModelName(String(event.target.value))
    }

    function handleModelTypeChange(event: SelectChangeEvent<unknown>) {
        setModelType(String(event.target.value))
    }

    return (
        <>
            <DeepcellDialog
                open={isOpen}
                handleCancel={() => handleClose()}
                handleConfirm={() => handleGeneratePredictions()}
                titleLabel="Generate Model Predictions"
                okLabel="Generate"
                fullWidth
                maxWidth="sm"
                role="dialog"
                id="generate-predictions-dlg"
                hideBackdrop
                aria-labelledby="generate-predictions-dlg-title"
            >
                <Stack spacing={2}>
                    <DialogContentText>
                        This will generate predictions for the selected runs.
                    </DialogContentText>
                    {modelsV3ApiEnabled === 'yes' && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={showV3Models}
                                    onChange={() => setShowV3Models(!showV3Models)}
                                />
                            }
                            label="Use Iris Models (experimental)"
                        />
                    )}
                    {!showV3Models && (
                        <>
                            <DeepcellPrimarySelect
                                autoFocus
                                label="Model Name"
                                items={validModels}
                                value={modelName}
                                onChange={handleModelNameChange}
                                defaultValue=""
                            />
                            <DeepcellPrimarySelect
                                label="Model Type"
                                items={['Embedding', 'Classification']}
                                value={modelType}
                                onChange={handleModelTypeChange}
                            />
                        </>
                    )}
                    {showV3Models && (
                        <DeepcellTextField
                            label="Model Name"
                            value={modelName}
                            defaultValue=""
                            onChange={(event) => setModelName(String(event.target.value))}
                        />
                    )}
                </Stack>
            </DeepcellDialog>
        </>
    )
}

export default GeneratePredictionsDlg
