import { CellInfo } from 'components/cell-visualizations/tsv/types'

export enum MorphometricFeatureGroup {
  CELL_SHAPE_FEATURES = 'CELL_SHAPE_FEATURES',
  SHAPE = 'SHAPE',
  PIXEL_INTENSITY_FEATURES = 'PIXEL_INTENSITY_FEATURES',
  TEXTURE_FEATURES = 'TEXTURE_FEATURES',
  FOCUS = 'FOCUS',
  OTHER = 'OTHER',
  CUSTOM = 'CUSTOM',
}

export type FeatureDefinition = {
  field: keyof CellInfo
  label?: string
  group: MorphometricFeatureGroup
  groupPriority?: number // feature priority when displayed in group
}

// Keep this list in sync with types.ts
// eslint-disable-next-line import/prefer-default-export
export const MORPHOMETRIC_FEATURES: FeatureDefinition[] = [
  // Most important features comes first
  // Do not change the order of this initial section of feature names
  { field: 'AREA', label: 'Cell Area', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'PERIMETER', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  {
    field: 'MAX_FERET',
    label: 'Cell Diameter (μm)',
    group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES,
    groupPriority: 1,
  },
  { field: 'MIN_FERET', label: 'Cell Min Diameter (μm)', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'MAX_RADIUS', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'MIN_RADIUS', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'RADIUS', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'LONG_AXIS', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },
  { field: 'SHORT_AXIS', group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES },

  {
    field: 'ECCENTRICITY',
    label: 'Ellipse Elongation',
    group: MorphometricFeatureGroup.CELL_SHAPE_FEATURES,
    groupPriority: 1,
  },
  { field: 'ELLIPSE_VARIANCE', group: MorphometricFeatureGroup.SHAPE },
  { field: 'CIRCULARITY', group: MorphometricFeatureGroup.SHAPE },
  { field: 'ROUNDNESS', group: MorphometricFeatureGroup.SHAPE },
  { field: 'SOLIDITY', group: MorphometricFeatureGroup.SHAPE },

  { field: 'MEAN', label: 'Mean', group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES, groupPriority: 1 },
  {
    field: 'STANDARD_DEVIATION',
    label: 'Standard Deviation',
    group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES,
    groupPriority: 2,
  },
  { field: 'POSITIVE_FRACTION', group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES },
  { field: 'NEGATIVE_FRACTION', group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES },
  { field: 'PERCENTILE_75', group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES },
  { field: 'PERCENTILE_25', group: MorphometricFeatureGroup.PIXEL_INTENSITY_FEATURES },

  { field: 'SMALL_WHITE_BLOB_INTEGRAL', label: 'Small set of connected bright pixels, integral', group: MorphometricFeatureGroup.TEXTURE_FEATURES, groupPriority: 1 },
  { field: 'SMALL_BLACK_BLOB_INTEGRAL', label: 'Small set of connected dark pixels, integral', group: MorphometricFeatureGroup.TEXTURE_FEATURES, groupPriority: 2 },
  { field: 'LARGE_WHITE_BLOB_INTEGRAL', label: 'Large set of connected bright pixels, integral', group: MorphometricFeatureGroup.TEXTURE_FEATURES, groupPriority: 3 },
  { field: 'LARGE_BLACK_BLOB_INTEGRAL', label: 'Large set of connected dark pixels, integral', group: MorphometricFeatureGroup.TEXTURE_FEATURES, groupPriority: 4 },
  { field: 'LARGE_BLACK_BLOBS', group: MorphometricFeatureGroup.TEXTURE_FEATURES },
  { field: 'LARGE_WHITE_BLOBS', group: MorphometricFeatureGroup.TEXTURE_FEATURES },
  { field: 'SMALL_BLACK_BLOBS', group: MorphometricFeatureGroup.TEXTURE_FEATURES },
  { field: 'SMALL_WHITE_BLOBS', group: MorphometricFeatureGroup.TEXTURE_FEATURES },

  { field: 'RING_WIDTH', group: MorphometricFeatureGroup.FOCUS },
  { field: 'RING_INTENSITY', group: MorphometricFeatureGroup.FOCUS },

  // Less important features (you can change the order here)
  { field: 'CENTROID_X', group: MorphometricFeatureGroup.OTHER },
  { field: 'CENTROID_Y', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_CENTER_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_PERIPHERY_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_11', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_12', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_13', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_14', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_15', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_16', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_17', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_18', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_19', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_20', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_21', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_22', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_23', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_24', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_25', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_26', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_27', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_28', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_29', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_30', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_31', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_32', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_33', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_34', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_35', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R2_36', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_11', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_12', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_13', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_14', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_15', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_16', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_17', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_18', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_19', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_20', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_21', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_22', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_23', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_24', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_25', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_26', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_27', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_28', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_29', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_30', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_31', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_32', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_33', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_34', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_35', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R3_36', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_11', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_12', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_13', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_14', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_15', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_16', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_17', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_18', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_19', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_20', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_21', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_22', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_23', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_24', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_25', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_26', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_27', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_28', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_29', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_30', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_31', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_32', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_33', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_34', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_35', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_ROR_R4_36', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R2_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R3_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_01', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_02', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_03', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_04', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_05', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_06', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_07', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_08', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_09', group: MorphometricFeatureGroup.OTHER },
  { field: 'LBP_UNIFORM_R4_10', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU01', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU02', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU03', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU04', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU05', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU06', group: MorphometricFeatureGroup.OTHER },
  { field: 'HU07', group: MorphometricFeatureGroup.OTHER },
]

export const SAMPLE_DISTRIBUTION = 'SAMPLE_DISTRIBUTION'
