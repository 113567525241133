import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { LabelingTaskStatusValue } from 'utils/proto-utils'

export enum ActiveTab {
    SEARCH = 'search',
    DETAILS = 'details',
}

export interface LabelingTaskFilter {
    keywordSearch: string
    projectName: string
    status: LabelingTaskStatusValue | -1
    assignedToMe: boolean
}

export const defaultLabelingTaskFilter: LabelingTaskFilter = {
    keywordSearch: '',
    projectName: '',
    status: -1,
    assignedToMe: false,
}

export interface LabelingTaskState {
    availableAssignees: string[]
    projects: string[]
    labelingTaskFilter?: LabelingTaskFilter
    selectedTaskIds?: number[]
    activeTab?: ActiveTab
}

const labelingTaskSliceInitialState: LabelingTaskState = {
    availableAssignees: [],
    projects: [],
}

export const labelingTaskSlice = createSlice({
    name: 'labeling',
    initialState: labelingTaskSliceInitialState,
    reducers: {
        reset: (state) => {
            state.availableAssignees = labelingTaskSliceInitialState.availableAssignees
            state.projects = labelingTaskSliceInitialState.projects
            state.labelingTaskFilter = labelingTaskSliceInitialState.labelingTaskFilter
            state.selectedTaskIds = labelingTaskSliceInitialState.selectedTaskIds
            state.activeTab = labelingTaskSliceInitialState.activeTab
        },
        setAvailableAssignees: (state, action: PayloadAction<string[]>) => {
            state.availableAssignees = action.payload
        },
        setProjects: (state, action: PayloadAction<string[]>) => {
            state.projects = action.payload
        },
        setActiveTab: (state, action: PayloadAction<ActiveTab>) => {
            state.activeTab = action.payload
        },
        setSelectedTaskIds: (state, action: PayloadAction<number[]>) => {
            state.selectedTaskIds = action.payload
        },
        setLabelingTaskFilter: (state, action: PayloadAction<LabelingTaskFilter | undefined>) => {
            state.labelingTaskFilter = action.payload
        },
    },
})

export const LabelingTaskActions = labelingTaskSlice.actions
export const LabelingTaskReducer = labelingTaskSlice.reducer

export const labelingTasksFromState = (state: RootState): LabelingTaskState => state.labeling
