import { LabelingOracleResult } from '@deepcell/cell_data_api_proto'
import { CellClass, CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { AccordionDetails, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import ContentLoading from 'components/shared/ContentLoading'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import firebase from 'firebase'
import Moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useLabelingMicrotaskSlice from 'redux/slices/hooks/useLabelingMicrotaskSlice'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { ConflictError, submitMicrotaskResult } from 'utils/api'
import {
    base64StrToMessage,
    CellClassEncoderDecoder,
    CellClassValue,
    messageToBase64Str,
} from 'utils/proto-utils'
import MicrotaskCellDisplayOptions from './MicrotaskCellDisplayOptions'
import MicrotaskCellGrid from './MicrotaskCellGrid'
import {
    LeftPanel,
    MicrotaskAccordion,
    MicrotaskAccordionDetails,
    MicrotaskAccordionHeader,
    MicrotaskAccordionHeaderTaskHeader,
    MicrotaskFiller,
    RightPanel,
} from './sharedComponents'
import { MicrotaskPageProps } from './sharedTypes'
import TaskInfoAccordion from './TaskInfoAccordion'

Moment.locale('en')
momentLocalizer()

const RECOMMENDED_MAX_EXCEPTIONS = 10

const HotkeyWrapper = (props: { hotkey: string; onKey(): void }) => {
    const { hotkey, onKey } = props
    useHotkeys(hotkey, onKey, {})
    return <></>
}

function LabelingMicrotaskPage(props: MicrotaskPageProps): JSX.Element {
    const {
        reset,
        setPrimaryCellClass,
        setSelectedCellIds,
        labelingMicrotask: { primaryCellClass, selectedCellIds, progress: progressRaw },
    } = useLabelingMicrotaskSlice()
    const analytics = firebase.analytics()
    analytics.logEvent('labeling/labeling_microtask/show')

    const mutation = useMutation(submitMicrotaskResult)

    const { displayNotification } = useNotificationSlice()

    const { taskId } = useParams<{ taskId: string }>()
    const { microtask, handleGoToNextMicrotask, labelingTask } = props
    const [doBlurInput, setBlurInput] = useState<boolean>(false)
    const inputToBlurRef = useRef<HTMLInputElement>()
    const [submitPending, setSubmitPending] = useState<boolean>(false)
    let progress = labelingTask ? labelingTask.getCurrentBatchProgress() : progressRaw
    progress = progress ?? 0
    progress *= 100

    // Handle removing focus from an input after clicking (to make sure hotkeys continue to work)
    useEffect(() => {
        if (doBlurInput && inputToBlurRef.current) {
            inputToBlurRef.current.blur()
            setBlurInput(false)
        }
    }, [doBlurInput, inputToBlurRef])

    const cellItems = microtask?.getCellItemsList() ?? []
    const cellClasses = microtask?.getCellClassesList()
    const requireExceptions = microtask?.getRequireExceptions()

    let enablePickExceptions: boolean
    const canSubmitRef = useRef<boolean>(false)

    if (requireExceptions) {
        enablePickExceptions =
            primaryCellClass !== CellClass.CLASS_NOT_DEFINED && primaryCellClass !== -1
        canSubmitRef.current = enablePickExceptions
    } else {
        enablePickExceptions =
            primaryCellClass !== CellClass.CLASS_NOT_DEFINED && primaryCellClass !== -1
        canSubmitRef.current = primaryCellClass === -1 || enablePickExceptions
    }

    async function handleSubmitHelper() {
        if (submitPending) {
            return
        }

        setSubmitPending(true)
        const currentIndex = microtask?.getMicrotaskIndex()

        if (!canSubmitRef?.current || currentIndex === undefined) return

        const resultProto = new LabelingOracleResult()
        const chosenCellClass = primaryCellClass

        if (
            microtask?.getRequireExceptions() ||
            (chosenCellClass !== CellClass.CLASS_NOT_DEFINED && chosenCellClass !== -1)
        ) {
            resultProto.setPrimaryCellClass(primaryCellClass)

            const exceptions = selectedCellIds.map((cellIdStr) =>
                base64StrToMessage(cellIdStr, CellId)
            )

            resultProto.setExceptionsList(exceptions)
        }

        const params = {
            labelingTaskId: parseInt(taskId, 10),
            microtaskIndex: currentIndex,
            resultProto: messageToBase64Str(resultProto),
        }
        reset()
        handleGoToNextMicrotask()

        let errorMessage = ''
        try {
            await mutation.mutateAsync(params)
        } catch (err) {
            if (err instanceof ConflictError) {
                errorMessage =
                    `The microtask you submitted was already done on task ${taskId}.` +
                    'This should not happen -- please refresh the page.'
            } else {
                errorMessage = `${err}`
            }
        } finally {
            if (errorMessage) {
                displayNotification({ message: errorMessage, type: 'error' })
            }
            setSubmitPending(false)
        }
    }
    // Wrap the helper function.
    // This is a callback for useHotKeys, which doesn't handle handlers that return promises
    function handleSubmit() {
        handleSubmitHelper()
    }

    // Setup hotkeys for labeling with left hand on keyboard, right hand on mouse
    // Support up to 13 classes with the left hand, but this is most highly optimized for 3 classes
    // @TODO If we have any right-handed labelers...
    const CLASS_HOTKEYS = '12345QWERT'
    const hotkeyClassMap: { [key: string]: CellClassValue } = {}

    useHotkeys('x', () => {
        setPrimaryCellClass(-1)
    })
    useHotkeys(
        'space',
        () => {
            if (canSubmitRef.current) handleSubmit()
        },
        {},
        [handleSubmit]
    )

    function classHotkeyHandler(hotkey: string): () => void {
        return () => {
            const cellClass = hotkeyClassMap[hotkey]
            setPrimaryCellClass(cellClass)

            analytics.logEvent('labeling/labeling_microtask/set_class', {
                batch_id: microtask?.getBatchId(),
                microtask_index: microtask?.getMicrotaskIndex(),
                cell_class: cellClass,
            })
        }
    }

    const HotKeyWrappers = () => (
        <>
            {cellClasses?.map((cellClass, index) => {
                const hotkey = CLASS_HOTKEYS.charAt(index)
                hotkeyClassMap[hotkey] = cellClass
                return (
                    <HotkeyWrapper
                        key={hotkey}
                        hotkey={hotkey}
                        onKey={classHotkeyHandler(hotkey)}
                    />
                )
            })}
        </>
    )

    function getClassHotkeyLabel(index: number): string {
        if (index < CLASS_HOTKEYS.length) {
            return `(${CLASS_HOTKEYS[index]})`
        }
        return ''
    }

    return (
        <>
            <HotKeyWrappers />
            <LeftPanel>
                <MicrotaskAccordionHeaderTaskHeader>
                    <Typography variant="h5">Label Cells</Typography>
                    {requireExceptions && <Typography variant="h6">Exceptions Required</Typography>}
                </MicrotaskAccordionHeaderTaskHeader>
                <MicrotaskAccordion expanded>
                    <MicrotaskAccordionHeader>
                        <Typography variant="h6">1) What label class are most cells?</Typography>
                    </MicrotaskAccordionHeader>
                    <AccordionDetails>
                        <RadioGroup
                            aria-label="primary-class"
                            name="primary-class"
                            value={String(primaryCellClass)}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                                value: string
                            ) => {
                                const newCellClass = parseInt(value, 10) as CellClassValue

                                setPrimaryCellClass(newCellClass)

                                const notOneLabelClassSelected = newCellClass < 0
                                const labelClassWasAlreadySelected = primaryCellClass !== 100001

                                if (labelClassWasAlreadySelected || notOneLabelClassSelected) {
                                    setSelectedCellIds([])
                                }

                                if (event.target) {
                                    inputToBlurRef.current = event.target
                                    setBlurInput(true)
                                }
                            }}
                        >
                            {cellClasses &&
                                cellClasses.map((cellClass, index) => (
                                    <FormControlLabel
                                        key={cellClass}
                                        value={String(cellClass)}
                                        control={<Radio />}
                                        label={`${CellClassEncoderDecoder.convertToString(
                                            cellClass
                                        )}${getClassHotkeyLabel(index)}`}
                                    />
                                ))}
                            {!requireExceptions && (
                                <div>
                                    <FormControlLabel
                                        key={-1}
                                        value={String(-1)}
                                        control={<Radio />}
                                        label="Not one label class (X)"
                                    />
                                </div>
                            )}
                        </RadioGroup>
                    </AccordionDetails>
                </MicrotaskAccordion>
                <MicrotaskAccordion expanded disabled={!enablePickExceptions}>
                    <MicrotaskAccordionHeader>
                        <Typography variant="h6">2) Pick exceptions</Typography>
                    </MicrotaskAccordionHeader>

                    <MicrotaskAccordionDetails>
                        {!enablePickExceptions && 'Choose a label class before picking exceptions.'}
                        {enablePickExceptions &&
                            `Click on cells that are not ${CellClassEncoderDecoder.convertToString(
                                primaryCellClass
                            )}`}
                        {selectedCellIds.length > 0 && (
                            <p>{selectedCellIds.length} cells picked.</p>
                        )}
                        {enablePickExceptions &&
                            !requireExceptions &&
                            selectedCellIds.length > RECOMMENDED_MAX_EXCEPTIONS &&
                            'You may stop picking exceptions and choose "Not one label class"'}
                    </MicrotaskAccordionDetails>
                </MicrotaskAccordion>

                <MicrotaskFiller />

                <DeepcellPrimaryButton
                    id="submit-button"
                    contained
                    disabled={!canSubmitRef.current}
                    onClick={handleSubmit}
                >
                    Submit (Space Bar)
                </DeepcellPrimaryButton>
            </LeftPanel>

            <MicrotaskCellGrid cellItems={cellItems} />

            <RightPanel id="labelingMicrotaskPageRightPanel">
                <TaskInfoAccordion title={`Label ${labelingTask?.getRunId()}`} value={progress}>
                    <>
                        {labelingTask?.getLabelingInstructions() && (
                            <Typography variant="body1">
                                {labelingTask?.getLabelingInstructions()}
                            </Typography>
                        )}
                    </>
                </TaskInfoAccordion>
                <MicrotaskFiller />
                <MicrotaskCellDisplayOptions />
            </RightPanel>
            <Dialog
                open={submitPending}
                container={document.getElementById('labelingMicrotaskPageRightPanel')}
            >
                <DialogTitle>Submitting...</DialogTitle>
                <DialogContent>
                    <ContentLoading />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default LabelingMicrotaskPage
