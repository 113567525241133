import { DeepcellAutocomplete } from 'components/shared'
import { useEffect, useState } from 'react'
import { CELL_DATA_FIELD_NONE } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useDataFieldsInData from '../useDataFieldsInDataset'

export const ColorByDropdown = (): JSX.Element => {
  const [open, setOpen] = useState(false)

  const {
    setShowColorBy,
    setGroupByDataField,
    cellVisualizations: { groupByOption, showColorBy },
  } = useCellVisualizationsSlice()

  useEffect(() => {
    if (showColorBy !== undefined) {
      if (!showColorBy) {
        setOpen(false)
      } else if (groupByOption.attribute === CELL_DATA_FIELD_NONE.attribute) {
        setOpen(true)
        document.getElementById('color-by')?.focus()
      }
      setShowColorBy(undefined)
    }
  }, [groupByOption.attribute, setShowColorBy, showColorBy])

  const eventsManager = useEventsManager()
  const { dataFields } = useDataFieldsInData()

  const dataFieldsWithNone = [CELL_DATA_FIELD_NONE, ...dataFields]

  const byOptionAttribute = (attribute: string) =>
    dataFieldsWithNone.find((x) => x.attribute === attribute) ?? CELL_DATA_FIELD_NONE

  return (
    <DeepcellAutocomplete
      sx={{ width: '100%' }}
      disableClearable
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      label="Color By"
      id="color-by"
      options={dataFieldsWithNone.map((x) => x.attribute) ?? []}
      getOptionLabel={(attribute) => byOptionAttribute(attribute).label}
      groupBy={(attribute) => byOptionAttribute(attribute).group}
      value={groupByOption.attribute}
      onChange={(_, attribute) =>
        setGroupByDataField({
          dataField: byOptionAttribute(attribute),
          eventsManager,
        })
      }
      multiple={false}
      blurOnSelect
      disabled={dataFields.length === 0}
    />
  )
}

export default ColorByDropdown
