import {
    FormControl,
    FormControlProps,
    InputLabelProps,
    MenuItem,
    MenuItemProps,
    Select,
    SelectProps,
    styled,
} from '@mui/material'
import React from 'react'
import DeepcellLabel from './DeepcellLabel'
import PrimaryDropdownIcon from './icons/PrimaryDropdownIcon'

export interface SimpleSelectItem {
    key?: MenuItemProps['key']
    value?: string | number
    output?: MenuItemProps['children']
}

const StyledSelect = styled(Select)(({ theme }) => ({
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '14px',
    textAlign: 'left',
    borderRadius: '4px',
    '& .MuiSelect-icon': {
        color: theme.palette.info.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}))

export interface DeepcellSelectProps extends SelectProps {
    label?: string
    items: (SimpleSelectItem | string | number | JSX.Element)[]
    inputLabelProps?: InputLabelProps
    formControlProps?: FormControlProps
}

export const DeepcellPrimarySelect = ({
    label,
    items,
    formControlProps,
    ...rest
}: DeepcellSelectProps): JSX.Element => {
    return (
        <FormControl fullWidth size={rest.size ?? 'small'} {...formControlProps}>
            {label ? <DeepcellLabel label={label} /> : null}
            <StyledSelect
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: '50vh',
                            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
                            borderRadius: '4px',
                            border: '1px solid #5F55D1',
                            '& .MuiMenuItem-root': {
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#172623',
                            },
                            '& .MuiMenu-list': {
                                padding: '0px',
                            },
                        },
                    },
                }}
                IconComponent={PrimaryDropdownIcon}
                {...rest}
            >
                {items.map((i) => {
                    let item: SimpleSelectItem
                    if (React.isValidElement(i)) return i
                    if (typeof i === 'string' || typeof i === 'number') {
                        item = { key: i, value: i, output: i }
                    } else {
                        item = i
                    }
                    const { key, value, output } = item
                    return (
                        <MenuItem key={key ?? value ?? -1} value={value ?? key ?? undefined}>
                            {output ?? value ?? key ?? ''}
                        </MenuItem>
                    )
                })}
            </StyledSelect>
        </FormControl>
    )
}

export default DeepcellPrimarySelect
