// Based on https://medium.com/javascript-in-plain-english/google-analytics-with-react-router-and-hooks-16d403ddc528
// Modified to use firebase analytics

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'

export default function useTracking(): void {
    const { listen } = useHistory()

    useEffect(() => {
        return listen((location) => {
            const analytics = firebase.analytics()
            analytics.setCurrentScreen(location.pathname)
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: location.pathname,
            })
        })
    }, [listen])
}
