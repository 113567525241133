import firebase from 'firebase'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import { CellDataField, CellImagesFilter, PinnedCellGroup } from 'redux/slices'
import { datadogRum } from '@datadog/browser-rum'

/* 
This module abstracts away the tools we're using for analytics from the rest of the codebase.
This way it becomes very easy to change providers, add new providers, etc.
Simply edit these functions to send events to other providers, e.g., Sentry and DataDog.

TODO: Make sure all the logic for event sending (currently spread around the app) is moved here, creating a separate function for each event
TODO: Once the todo above is completed, make sure to move this file somewhere more shared

Log a google analytics event using a standard event type
    https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484
*/

const ANALYTICS = firebase.analytics() // Singleton

/**
 * Generic event logging call that sends an event to each analytics provider
 *
 * @TODO if the list of providers grows or we need more complex logic,
 * we may want to consider something like Segment.com where we can make a single API call
 * and have Segment handle compliance and sending events to multiple providers
 */
function logEvent(name: string, params?: { [key: string]: unknown }) {
  ANALYTICS.logEvent(name, params)
  datadogRum.addAction(name, params)
}

const events = {
  logEvent,

  setUserEmail(email: string): void {
    ANALYTICS.setUserId(email)
    ANALYTICS.setUserProperties({ email })
    datadogRum.setUser({
      email,
    })
  },

  setUserProperty(key: string, value: string): void {
    const properties: firebase.analytics.CustomParams = {}
    properties[key] = value
    ANALYTICS.setUserProperties(properties)

    datadogRum.setUserProperty(key, value)
  },

  sendDataUploadEvent: (file: File, status: boolean): void => {
    logEvent('new-dataset', {
      filename: file.name,
      size: file.size,
      status,
    })
  },

  sendResetAxisEvent: (filename: string | undefined): void => {
    logEvent('reset-axis', {
      filename,
    })
  },

  sendZoomEvent: (filename: string | undefined, stepInOrOut: 'in' | 'out'): void => {
    logEvent('zoom', {
      filename,
      'step-in-or-out': stepInOrOut,
    })
  },
  sendPanEvent: (filename: string | undefined): void => {
    logEvent('pan', {
      filename,
    })
  },

  sendSaveEvent: (projectCode?: string): void => {
    logEvent('save', {
      projectCode,
    })
  },

  sendExportEvent: (filename: string | undefined): void => {
    logEvent('export', {
      filename,
    })
  },

  sendImportEvent: (file: File, status: boolean): void => {
    logEvent('import', {
      filename: file.name,
      size: file.size,
      status,
    })
  },

  sendLassoEvent: (filename: string, numberOfCells: number): void => {
    logEvent('lasso', {
      filename,
      'number-of-cells': numberOfCells,
    })
  },

  sendRectangleEvent: (filename: string, numberOfCells: number): void => {
    logEvent('rectangle', {
      filename,
      'number-of-cells': numberOfCells,
    })
  },

  sendCustomColorEvent: (numberOfColorsPicked: number): void => {
    logEvent('custom-color', {
      'number-of-colors-picked': numberOfColorsPicked,
    })
  },

  sendPinEvent: (numberOfCells: number, name: string): void => {
    logEvent('pin', {
      'number-of-cells': numberOfCells,
      name,
    })
  },

  sendDeletePinEvent: (name: string): void => {
    logEvent('delete-pin', { name })
  },

  sendPaginationPinEvent: (numberOfCellsPage: number, pageNum: number, name: string): void => {
    logEvent('pagination-pin', {
      'page-num': pageNum,
      'number-of-cells-page': numberOfCellsPage,
      name,
    })
  },

  sendColorByEvent: (option: CellDataField): void => {
    logEvent('color-by', {
      value: option.label,
      'selected-morphometric': option.group === 'Morphometrics',
    })
  },

  sendDisplayImagesByEvent: (cellImagesFilter: CellImagesFilter): void => {
    const { imageSize, displayImages, spacingAdjust } = cellImagesFilter
    if (displayImages) {
      logEvent('display-images', {
        'image-size': imageSize,
        'image-spacing': spacingAdjust,
      })
    }
  },

  sendPinnedCellsEvent: (pinnedCellGroup: PinnedCellGroup): void => {
    logEvent('pinned-cell-group', {
      name: pinnedCellGroup.name,
      numberOfCells: pinnedCellGroup.cells.points?.length,
    })
  },

  sendMergedPinnedCellsEvent: (pinnedCellGroups: PinnedCellGroup[]): void => {
    pinnedCellGroups.forEach((pinnedCellGroup) => {
      logEvent('merged-pinned-cell-group', {
        name: pinnedCellGroup.name,
        numberOfCells: pinnedCellGroup.cells.points?.length,
      })
    })
  },

  sendCompareByEvent: (
    dataCategory: string,
    selectedComparisionOptions: CellDataField[],
    dataFieldsToCompare: { [key: string]: string[] }
  ): void => {
    const selectedComparisionCategories = selectedComparisionOptions.map((option) => {
      return option.label
    })
    logEvent('compare-by', {
      'data-category': dataCategory,
      'selected-comparision-categories': JSON.stringify(selectedComparisionCategories),
      'selected-comparision-data-fields': JSON.stringify(dataFieldsToCompare),
    })
  },

  sendContactUsByEvent: (time: number): void => {
    logEvent('contact-us', {
      'contacted-at-time': time,
    })
  },

  sendContactedUsSubmittedEvent: (location: string): void => {
    logEvent('contact-us-submitted', {
      location,
    })
  },

  sendGuideWalkthroughEvent: (skipped?: boolean, titleWhenSkipped?: string): void => {
    if (skipped) {
      logEvent('guide-walkthrough', {
        'title-when-skipped': titleWhenSkipped,
        status: 'incomplete',
      })
    } else {
      logEvent('guide-walkthrough', {
        status: 'complete',
      })
    }
  },

  // demo dataset selections
  sendDemoDatasetEvent: (datasetName: string): void => {
    logEvent('demo-dataset', {
      name: datasetName,
    })
  },

  // session list selection
  sendSessionSelectionEvent: (sessionId: number): void => {
    logEvent('session-selection', { sessionId })
  },

  sendFilterHideAllEvent: () => {
    logEvent('filter-hide-all')
  },
  sendFilterShowAllEvent: () => {
    logEvent('filter-show-all')
  },
  sendFilterHideEvent: (name: string) => {
    logEvent('filter-show-all', { name })
  },
  sendFilterShowEvent: (name: string) => {
    logEvent('filter-show-all', { name })
  },
}

const useEventManager = (): typeof events => {
  const { user, isAuthenticated } = useAuthWrapper(useAuth0)
  if (isAuthenticated && user && user.email) {
    events.setUserEmail(user.email)
  }

  return events
}

export default useEventManager
