import { Box, Card, CardContent, Dialog, SelectChangeEvent, Stack, useTheme } from '@mui/material'
import useSessionApi from 'components/cell-visualizations/sessionSelect/useSessionApi'
import { DeepcellPrimaryButton, DeepcellPrimarySelect } from 'components/shared'
import { useState } from 'react'
import { Run } from 'utils/api'

const models = ['Projection Model', 'Morphometrics Model'] as const
type Model = typeof models[number]
type SelectedModels = { [x in Model]: string }
type ModelItems = { [x in Model]: string[] }

const modelItems: ModelItems = {
    'Projection Model': ['hfm_projection:v1'],
    'Morphometrics Model': ['', 'morphometrics:v2'],
}

interface StartAnalysisDialogProps {
    isOpen: boolean
    handleClose?(): void
    runs: Run[]
}

export const StartAnalysisDlg = (props: StartAnalysisDialogProps): JSX.Element => {
    const { handleClose, isOpen, runs } = props

    const theme = useTheme()

    const { createSessionFromRuns } = useSessionApi()

    const [selectedModels, setSelectedModels] = useState<SelectedModels>({
        'Projection Model': modelItems['Projection Model'][0],
        'Morphometrics Model': modelItems['Morphometrics Model'][0],
    })

    const onStartAnalysis = (): void => {
        createSessionFromRuns({
            run_ids: runs.map((x) => `${x.run_id}`),
            morphometric_model: selectedModels['Morphometrics Model'],
            projection_model: selectedModels['Projection Model'],
        })
    }

    const handleChange = (model: Model) => (event: SelectChangeEvent<unknown>) =>
        setSelectedModels((curr) => ({ ...curr, [model]: event.target.value }))

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <Card sx={{ px: 2, width: 500 }}>
                <CardContent>
                    <Stack spacing={2}>
                        <Box alignSelf="center" component="h1" sx={{ mt: 1, mb: -2 }}>
                            Start Analysis
                        </Box>
                        <Box
                            alignSelf="center"
                            component="p"
                            sx={{ color: theme.palette.info.light }}
                        >
                            {`${runs.length} Run${runs.length === 1 ? '' : 's'} selected`}
                        </Box>
                        {models.map((model) => (
                            <DeepcellPrimarySelect
                                key={model}
                                items={modelItems[model]}
                                value={selectedModels[model]}
                                onChange={handleChange(model)}
                                label={model}
                            />
                        ))}
                        <DeepcellPrimaryButton
                            contained
                            sx={{ width: 200, alignSelf: 'center', mt: 3 }}
                            onClick={onStartAnalysis}
                        >
                            Start Analysis
                        </DeepcellPrimaryButton>
                    </Stack>
                </CardContent>
            </Card>
        </Dialog>
    )
}

export default StartAnalysisDlg
