/* eslint-disable @typescript-eslint/lines-between-class-members */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CELL_GROUPS_CATEGORY_KEY } from 'components/cell-visualizations/Compare/useDataCategory'
import {
  CATEGORICAL_COLOR_PALETTE,
  CELL_IMAGE_NORMAL_SIZE,
  CELL_IMAGE_NORMAL_SPACING,
} from 'components/cell-visualizations/shared'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { DatasetItem, datasetItems } from '../types/DatasetItem'
import {
  ActiveCellVisualizationsTab,
  CellDataField,
  CellImagesFilter,
  CELL_DATA_FIELD_NONE,
  LabelColor,
  MergedCellGroup,
  PaginationParams,
  PinnedCellGroup,
  PlotSelectionState,
  ZoomThreshold,
} from './types'

export const imageCountDefault = 10

export interface CellVisualizationsState {
  /**
   * Indicates the version of this state. Used for matching stateVersion to logic in the backend
   * @important Whenever CellVisualizationsState is changed, please increment stateVersion
   */
  // Keep this in sync with the saveVersionConfig function in useSessionApi.ts
  // That function omits specific fields from this state that should not be persisted when saving
  stateVersion: 1
  pinnedCells?: PinnedCellGroup[]
  mergedPinnedCells?: MergedCellGroup[]
  currentSelectionState?: PlotSelectionState
  pagination?: PaginationParams
  groupByOption: CellDataField
  cellsData?: CellInfo[]
  cellIdMap: Record<string, CellInfo>
  cellImagesFilter: CellImagesFilter
  legendLabels: string[]
  legendLabelColors: LabelColor[]
  fileName?: string
  groupCompare: {
    selectedFeatures: CellDataField[]
    selectedDataCategory: string // Datafield.label value
    selectedDataFields: { [key: string]: string[] } // key is a DataField.label value,
  }
  activeTab: ActiveCellVisualizationsTab
  zoomThresholds?: ZoomThreshold[]
  dataRevision: number
  showCellGroups?: boolean
  showCompare?: boolean
  showColorBy?: boolean
  selectedDataset: DatasetItem
  name?: string
  projectCode?: string
  isDirty: boolean
  plotImgSrc?: string
}

export const cellVisualizationsInitialState: CellVisualizationsState = {
  stateVersion: 1,
  isDirty: false,
  pinnedCells: [],
  currentSelectionState: {},
  pagination: { page: 0, cellsPerPage: imageCountDefault },
  groupByOption: CELL_DATA_FIELD_NONE,
  cellImagesFilter: {
    displayImages: false,
    imageSize: CELL_IMAGE_NORMAL_SIZE,
    spacingAdjust: CELL_IMAGE_NORMAL_SPACING,
  },
  legendLabels: [],
  legendLabelColors: [],
  groupCompare: {
    selectedFeatures: [],
    selectedDataCategory: CELL_GROUPS_CATEGORY_KEY,
    selectedDataFields: {},
  },
  activeTab: ActiveCellVisualizationsTab.CellGroup,
  dataRevision: 1,
  selectedDataset: datasetItems[0],
  mergedPinnedCells: undefined,
  cellsData: undefined,
  fileName: undefined,
  zoomThresholds: undefined,
  showCellGroups: undefined,
  showCompare: undefined,
  showColorBy: undefined,
  name: undefined,
  projectCode: undefined,
  cellIdMap: {},
}
const getNewLabels = (state: CellVisualizationsState): string[] => {
  const { attribute } = state.groupByOption
  if (!state.cellsData || !attribute) {
    return []
  }
  const labels = new Set<string>()
  state.cellsData.forEach((cell) => {
    const value = cell[attribute as keyof CellInfo]
    if (value === null || value === undefined) return
    labels.add(value as string)
  })
  return [...labels].sort()
}

const setInitialColors = (state: CellVisualizationsState): LabelColor[] => {
  const labels = state.legendLabels
  // Make sure to keep this in sync with MainGraphContent.tsx
  // @TODO Clean this up so the color assignment logic is in one place
  const initialLegendLabelsObj = labels.map((item: string, index: number) => ({
    name: item,
    color: CATEGORICAL_COLOR_PALETTE[index % CATEGORICAL_COLOR_PALETTE.length],
    isHidden: false,
  }))

  return initialLegendLabelsObj
}

export const cellVisualizationsSlice = createSlice({
  name: 'cellVisualizations',
  initialState: cellVisualizationsInitialState,
  reducers: {
    // TODO: it'd be nice to make all these reducer functions generic
    setPlotImgSrc: (state, action: PayloadAction<string>) => {
      state.plotImgSrc = action.payload
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setProjectCode: (state, action: PayloadAction<string>) => {
      state.projectCode = action.payload
    },
    setSelectedDataset(state, action: PayloadAction<DatasetItem>) {
      state.selectedDataset = action.payload
    },
    setShowCellGroups(state, action: PayloadAction<boolean>) {
      state.showCellGroups = action.payload
    },
    setShowCompare(state, action: PayloadAction<boolean>) {
      state.showCompare = action.payload
    },
    setShowColorBy(state, action: PayloadAction<boolean | undefined>) {
      state.showColorBy = action.payload
    },
    setGroupCompare(
      state,
      action: PayloadAction<{
        groupCompare: CellVisualizationsState['groupCompare']
        eventsManager: {
          sendCompareByEvent(
            dataCategory: string,
            selectedComparisionOptions: CellDataField[],
            dataFieldsToCompare: { [key: string]: string[] }
          ): void
        }
      }>
    ) {
      const { groupCompare, eventsManager } = action.payload
      state.groupCompare = groupCompare
      state.isDirty = true
      eventsManager.sendCompareByEvent(
        groupCompare.selectedDataCategory,
        groupCompare.selectedFeatures,
        groupCompare.selectedDataFields
      )
    },
    setGroupCompareSelectedGroups(state, action: PayloadAction<number[]>) {
      state.pinnedCells = state.pinnedCells?.map((x) => ({
        ...x,
        isInSelectedGroup: action.payload.includes(x.id),
      }))
      state.isDirty = true
    },
    incrementDataRevision(state) {
      state.dataRevision += 1
    },
    setZoomThresholds(state, action: PayloadAction<ZoomThreshold[]>) {
      state.zoomThresholds = action.payload
    },
    setFileName(state, action: PayloadAction<string>) {
      state.fileName = action.payload
    },
    setCellImagesFilter: (
      state,
      action: PayloadAction<{
        cellImagesFilter: CellImagesFilter
        eventsManager: { sendDisplayImagesByEvent(_: CellImagesFilter): void }
      }>
    ) => {
      const { cellImagesFilter, eventsManager } = action.payload
      eventsManager.sendDisplayImagesByEvent(cellImagesFilter)
      state.cellImagesFilter = cellImagesFilter
    },
    setStore(state, action: PayloadAction<Partial<CellVisualizationsState>>) {
      /*
              Using "state = action.payload" throws an error
              so we have to set the state props individually
            */
      const {
        pinnedCells,
        groupByOption,
        cellsData,
        currentSelectionState,
        pagination,
        legendLabels,
        legendLabelColors,
        cellImagesFilter,
        groupCompare,
        mergedPinnedCells,
        fileName,
        activeTab,
        zoomThresholds,
        showCellGroups,
        showCompare,
        selectedDataset,
        name,
        projectCode,
        plotImgSrc,
      } = action.payload

      state.isDirty = false
      state.pinnedCells = pinnedCells
      state.currentSelectionState = currentSelectionState
      state.groupByOption = groupByOption ?? cellVisualizationsInitialState.groupByOption
      state.cellsData = cellsData
      state.legendLabels = legendLabels ?? cellVisualizationsInitialState.legendLabels
      state.legendLabelColors =
        legendLabelColors ?? cellVisualizationsInitialState.legendLabelColors
      state.pagination = pagination
      state.cellImagesFilter = cellImagesFilter ?? cellVisualizationsInitialState.cellImagesFilter
      state.groupCompare = groupCompare ?? cellVisualizationsInitialState.groupCompare
      state.mergedPinnedCells = mergedPinnedCells
      state.fileName = fileName
      state.activeTab = activeTab ?? cellVisualizationsInitialState.activeTab
      state.zoomThresholds = zoomThresholds ?? cellVisualizationsInitialState.zoomThresholds
      state.showCellGroups = showCellGroups
      state.showCompare = showCompare
      state.selectedDataset = selectedDataset ?? cellVisualizationsInitialState.selectedDataset
      state.name = name
      state.projectCode = projectCode
      state.plotImgSrc = plotImgSrc
    },
    setPinnedGroupValue: (
      state,
      action: PayloadAction<{
        pinnedGroupId: number
        key: keyof PinnedCellGroup
        value: boolean | string | PaginationParams
      }>
    ) => {
      const { pinnedGroupId, key, value } = action.payload
      state.pinnedCells = state.pinnedCells?.map((pinnedCell) =>
        pinnedCell.id === pinnedGroupId
          ? {
              ...pinnedCell,
              [key]: value,
            }
          : pinnedCell
      )
    },
    setCellsData(state, action: PayloadAction<CellInfo[] | undefined>) {
      state.isDirty = false
      state.cellsData = action.payload
      state.legendLabels = getNewLabels(state)
      state.legendLabelColors = setInitialColors(state)

      // Update the cellIdMap
      const nextCellIdMap: Record<string, CellInfo> = {}
      action.payload?.forEach((cell) => {
        nextCellIdMap[cell.CELL_ID] = cell
      })
      state.cellIdMap = nextCellIdMap
    },
    setGroupByDataField(
      state,
      action: PayloadAction<{
        dataField: CellDataField
        eventsManager: { sendColorByEvent(_: CellDataField): void }
      }>
    ) {
      const { dataField, eventsManager } = action.payload
      eventsManager.sendColorByEvent(dataField)
      state.groupByOption = dataField ?? CELL_DATA_FIELD_NONE
      state.legendLabels = getNewLabels(state)
      state.legendLabelColors = setInitialColors(state)
      state.isDirty = true
    },
    setPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: PinnedCellGroup[]
        eventsManager: { sendPinnedCellsEvent(_: PinnedCellGroup): void }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      const recentlyAddedPin = pinnedCells.at(0)
      if (recentlyAddedPin) eventsManager.sendPinnedCellsEvent(recentlyAddedPin)
      state.pinnedCells = pinnedCells
      state.isDirty = true
    },
    setMergedPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: MergedCellGroup[]
        eventsManager: {
          sendMergedPinnedCellsEvent(_: Pick<PinnedCellGroup, 'id' | 'cells'>[]): void
        }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      eventsManager.sendMergedPinnedCellsEvent(pinnedCells)
      state.mergedPinnedCells = pinnedCells
      state.isDirty = true
    },
    addPinnedCellGroup: (state, action: PayloadAction<PinnedCellGroup>) => {
      state.pinnedCells = state.pinnedCells
        ? [...state.pinnedCells, action.payload]
        : [action.payload]
      state.isDirty = true
    },
    deletePinnedGroup: (
      state,
      action: PayloadAction<{
        targetPinnedCellId: number
        eventsManager: { sendDeletePinEvent(_: string): void }
      }>
    ) => {
      const { targetPinnedCellId, eventsManager } = action.payload
      const deletedPin = state.pinnedCells?.filter((x) => x.id === targetPinnedCellId)
      if (deletedPin && deletedPin.length > 0) eventsManager.sendDeletePinEvent(deletedPin[0].name)
      else eventsManager.sendDeletePinEvent(`pinnedCellId ${targetPinnedCellId}`)
      state.pinnedCells = state.pinnedCells?.filter((x) => x.id !== targetPinnedCellId)
      state.isDirty = true
    },
    setSelectedCells: (state, action: PayloadAction<PlotSelectionState>) => {
      state.currentSelectionState = action.payload
      state.activeTab = ActiveCellVisualizationsTab.CellGroup
    },
    setSelectedImagePagination: (state, action: PayloadAction<PaginationParams>) => {
      state.pagination = action.payload
    },
    setLegendLabels: (state, action: PayloadAction<string[]>) => {
      state.legendLabels = action.payload
    },
    setLegendLabelColors: (state, action: PayloadAction<LabelColor[]>) => {
      state.legendLabelColors = action.payload
    },
    setActiveTab: (state, action: PayloadAction<ActiveCellVisualizationsTab>) => {
      state.activeTab = action.payload
    },
  },
})

export const CellVisualizationsActions = cellVisualizationsSlice.actions
export const CellVisualizationsReducer = cellVisualizationsSlice.reducer
