import { Search } from '@mui/icons-material'
import { debounce, InputAdornment } from '@mui/material'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import DeepcellTextField from 'components/shared/DeepcellTextField'
import Moment from 'moment'
import React, { useCallback, useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useRunSearchFilterSlice from 'redux/slices/hooks/useRunSearchFilterSlice'
import { RunSearchFilterState } from 'redux/slices/RunSearchFilterSlice'
import { AdvancedRunSearchFilterPopover } from './AdvancedRunSearchFilterPopover/AdvancedRunSearchFilterPopover'

Moment.locale('en')
momentLocalizer()

// @TODO After doing this another time or two, refactor and pull out common search panel functionality into a component

interface RunSearchFiltersProps {
    className?: string
    handleCancel?: (clear?: boolean) => void
    handleSearch: (searchFilters?: RunSearchFilterState) => void
}

export function RunSearchFilter({
    className,
    handleCancel: handleCancelProp,
    handleSearch: handleSearchProp,
}: RunSearchFiltersProps): JSX.Element {
    const { update, runSearchFilter } = useRunSearchFilterSlice()

    // Helps typing feel interactive while delaying more expensive search updates
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const debounceHandleSearch = useCallback(debounce(handleSearchProp, 250), [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRunSearchFilter: RunSearchFilterState = {
            ...runSearchFilter,
            keyword: e.target.value,
        }
        update(newRunSearchFilter)
        debounceHandleSearch(newRunSearchFilter)
    }

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [openPopper, setOpenPopper] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpenPopper(!openPopper)
    }

    const handleSearch = () => {
        handleSearchProp()
        setOpenPopper(false)
    }

    const handleCancel = (clear = true) => {
        if (handleCancelProp) handleCancelProp(clear)
        setOpenPopper(false)
    }

    return (
        <div className={className}>
            <DeepcellTextField
                value={runSearchFilter.keyword ?? ''}
                margin="dense"
                label="Search Runs"
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                    paddingRight: '0px !important'
                }}}
                InputProps={{
                    // @ts-ignore Typescript doesn't handle data-testid properties properly
                    'data-testid': 'search-keyword',
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <DeepcellPrimaryButton
                            contained
                            onClick={handleClick}
                            style={{ width: '20rem' }}
                        >
                            {runSearchFilter.advancedSearchEnabled ? 'Refine' : 'Advanced'} Search
                        </DeepcellPrimaryButton>
                    ),
                }}
                onChange={handleChange}
            />
            <AdvancedRunSearchFilterPopover
                open={openPopper}
                anchorEl={anchorEl}
                handleCancel={handleCancel}
                handleSearch={handleSearch}
            />
        </div>
    )
}

export default RunSearchFilter
