import { styled } from '@mui/material'
import useFlagCondition from 'components/shared/useFlagCondition'
import useFlags from 'components/shared/useFlags'
import { PlotRelayoutEvent, Shape } from 'plotly.js'
import { useEffect, useState } from 'react'
import Plot, { Figure } from 'react-plotly.js'
import { useWindowSize } from 'react-use'
import { PlotSelectionState } from 'redux/slices'
import { useNotificationSlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { useDebounce } from 'use-debounce'
import useElementById from 'utils/useElementById'
import useFooterHeight from 'utils/useFooterHeight'
import { cellVisualizationsHeaderId } from '../header'
import { usePlotlyModebar } from '../header/ToolButtons'
import { PlotRange } from '../tsv/types'
import usePlotLayoutImages from './plotLayoutImages/usePlotLayoutImages'
import { UseCellsDataOutput } from './useCellsData'
import { useCustomAnnotation } from './useCustomAnnotation'
import usePinnedCellsSelectionInfo from './usePinnedCellsSelectionInfo'

const StyledPlot = styled(Plot)({
  '& * .imagelayer image': {
    clipPath: 'circle()',
  },
  '& * .modebar': {
    visibility: 'hidden',
  },
})

const Root = styled('div')({
  height: 'calc(100% - 155px)',
  width: '100%',
  overflow: 'hidden',
})

// Don't do any heavy compute operations (e.g. pre-computing images to show)
// until after waiting this many milliseconds
const DEBOUNCE_DELAY = 500

const ScatterPlot = (props: UseCellsDataOutput): JSX.Element => {
  const [selectionNotificationDisplayed, setSelectionNotificationDisplayed] = useState(false)
  const disableCurrentSelection = useFlagCondition('disableCurrentSelection')
  const demoEnabled = useFlagCondition('demoEnabled')

  const { plotData, flattenedPlotData } = props
  const footerHeight = useFooterHeight()
  const { rect: headerRect } = useElementById(cellVisualizationsHeaderId)
  const headerBottom = headerRect?.bottom ?? 0
  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const { triggerModebarButton } = usePlotlyModebar()

  const plotHeight = windowHeight - headerBottom - footerHeight - 16

  const [debouncedWindowWidth] = useDebounce(windowWidth, DEBOUNCE_DELAY)

  const { CustomAnnotation, setAnnotationData } = useCustomAnnotation()

  const {
    cellVisualizations: { fileName, dataRevision, selectedDataset, currentSelectionState },
    setPointsAsSelected,
  } = useCellVisualizationsSlice()
  const { selections: currentSelections } = currentSelectionState ?? {}
  const { cellVisualizationsImagesEnabled } = useFlags()
  const enableImages = cellVisualizationsImagesEnabled === 'yes'

  const eventsManager = useEventsManager()
  const { shapes, shapeAnnotations } = usePinnedCellsSelectionInfo()

  // Store axis range locally, based on Plotly's current state
  const [range, setRange] = useState<PlotRange>()

  const onRelayout = (e: PlotRelayoutEvent & { selections?: Partial<Shape>[] }) => {
    const {
      'xaxis.range[0]': relayoutX0,
      'xaxis.range[1]': relayoutX1,
      'yaxis.range[0]': relayoutY0,
      'yaxis.range[1]': relayoutY1,
      'xaxis.autorange': xAutorange,
      'yaxis.autorange': yAutorange,
      dragmode,
      selections,
    } = e

    const userIsClickingSelectionOption = Boolean(dragmode)
    const userIsMakingSelection = Boolean(selections && selections[0])
    const userResetedAxis = Boolean(xAutorange && yAutorange)

    if (userResetedAxis) {
      eventsManager.sendResetAxisEvent(fileName)
    }

    const userIsSelecting = userIsMakingSelection || userIsClickingSelectionOption

    // New layout is user simply making a selection, so don't update range
    if (userIsSelecting) return

    const newRangeDetected = Boolean(relayoutX0)

    if (newRangeDetected) {
      if (range) {
        const { x1, x2 } = range
        let zoomEvent: 'in' | 'out' | undefined
        if (x1 && x2 && relayoutX0 && relayoutX1) {
          if (x1 <= relayoutX0 && relayoutX1 <= x2) {
            zoomEvent = 'in'
          } else if (relayoutX0 <= x1 && x2 <= relayoutX1) {
            zoomEvent = 'out'
          }
        }
        if (zoomEvent) {
          eventsManager.sendZoomEvent(fileName || selectedDataset.label, zoomEvent)
          triggerModebarButton('Pan')
        } else {
          // pan
          eventsManager.sendPanEvent(fileName || selectedDataset.label)
        }
      }
      // New layout includes a new range (the user zoomed, panned, etc...)
      setRange({
        x1: relayoutX0,
        x2: relayoutX1,
        y1: relayoutY0,
        y2: relayoutY1,
      })

      // If the user zooms, close the annotation
      setAnnotationData()
    }
  }

  function onUpdate(figure: Readonly<Figure>, _graphDiv: Readonly<HTMLElement>): void {
    /** This is necessary to setRange initially, once we have data and chose the initial axes ranges.
     * After this, we depedn on the relayout handler above to continue updating the range
     *
     * @TODO Figure out if there's also an updated viewable range in this event -- I couldn't find it.
     * If so, we could make some of transitions more smoothly in the middle of a zoom or pan interaction
     * (for example to resize images more frequently to keep them the same size in screen coordinate space)
     *
     * onRelayout only fires at the end of a zoom or pan. */
    const xAxisRange = figure?.layout?.xaxis?.range
    const yAxisRange = figure?.layout?.yaxis?.range
    if (xAxisRange && yAxisRange && figure.data?.length > 0 && range === undefined) {
      setRange({
        x1: xAxisRange[0],
        x2: xAxisRange[1],
        y1: yAxisRange[0],
        y2: yAxisRange[1],
      })
    }
  }

  const images = usePlotLayoutImages({
    plotData: flattenedPlotData,
    range,
    plotPixelWidth: debouncedWindowWidth,
    enabled: enableImages,
  })

  useEffect(() => {
    setRange(undefined)
  }, [dataRevision])

  const currentSelectionShape: Partial<Shape> | undefined =
    currentSelections && currentSelections[0]
      ? {
          line: { color: undefined, width: 1, dash: 'dot' },
          xref: 'x',
          yref: 'y',
          ...currentSelections[0],
        }
      : undefined

  const { displayNotification } = useNotificationSlice()

  const onSelected = (e?: PlotSelectionState | undefined) => {
    setPointsAsSelected(e)

    if (!selectionNotificationDisplayed && disableCurrentSelection && !demoEnabled) {
      displayNotification({
        message: 'Your selection has been saved as a cell group',
        type: 'success',
        showIcon: false,
        origin: { vertical: 'bottom', horizontal: 'center' },
        timeout: 5000,
      })
      setSelectionNotificationDisplayed(true)
    }
  }

  return (
    <Root id="scatter-plot">
      <StyledPlot
        data={[...plotData]}
        style={{
          width: '100%',
          height: `${plotHeight}px`,
          position: 'relative',
          top: '-5px',
        }}
        layout={{
          margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
          shapes: currentSelectionShape ? [...shapes, currentSelectionShape] : shapes,
          xaxis: {
            visible: false,
            range: [range?.x1, range?.x2],
          },
          yaxis: {
            visible: false,
            scaleanchor: 'x',
            scaleratio: 1,
            range: [range?.y1, range?.y2],
          },
          showlegend: false,
          clickmode: 'event',
          annotations: [...shapeAnnotations],
          uirevision: dataRevision,
          images: enableImages ? images : undefined,
          // this is only default dragmode. This gets changed internally because plotly is a bad boy and doesn't play by React's rules
          dragmode: 'pan',
        }}
        onRelayout={onRelayout}
        onSelected={onSelected}
        onClick={setAnnotationData}
        onUpdate={onUpdate}
        useResizeHandler
        config={{
          responsive: true,
          displaylogo: false,
          displayModeBar: true,
          scrollZoom: true,
          modeBarButtonsToRemove: ['zoom2d', 'resetScale2d'],
        }}
      />
      <CustomAnnotation />
    </Root>
  )
}

export default ScatterPlot
