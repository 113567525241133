import LinkIcon from '@mui/icons-material/Link'
import { styled } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'

const Icon = styled(LinkIcon)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main,
}))

const HyperlinkCellComponent = (props: ICellRendererParams): JSX.Element => {
    const { value } = props

    const handleIconClick = () => {
        window.open(value, '_blank')
    }

    return <Icon onClick={handleIconClick} />
}

export default HyperlinkCellComponent
