import { Box, styled, TablePagination, TablePaginationProps } from '@mui/material'

const zero = '0 !important'
const Wrapper = styled(Box)(() => ({
    '& .MuiTablePagination-displayedRows': {
        margin: zero,
        padding: zero,
    },
    '& .MuiTablePagination-actions': {
        margin: '.2rem !important',
        '*': { padding: '.1rem !important' },
    },
    '& [class$="MuiTablePagination-select"]': {
        margin: '0 .5rem 0 0 !important',
        '& *': {
            paddingLeft: zero,
        },
    },
    '& *': {
        border: zero,
    },
    '& .MuiTablePagination-selectLabel': {
        alignSelf: 'start',
        lineHeight: 1,
    },
    '& .MuiTablePagination-toolbar': {
        minHeight: '30px',
    },
}))

export const CompactTablePagination = (props: TablePaginationProps): JSX.Element => (
    <Wrapper>
        <TablePagination {...props} />
    </Wrapper>
)

export default CompactTablePagination
