import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { useQuery } from 'react-query'
import { DeepcellPlotDatum } from 'redux/slices/types'
import { getCellImageDataURLs } from 'utils/api'
import { getCellImageInfoFromCellId } from '../../tsv/utils'
import DataURLCache from './DataURLCache'

const dataURLCache = new DataURLCache()

export type PointQueryData = {
    point: DeepcellPlotDatum
    dataURL?: string
}

export const byPointCoords = (pqd: PointQueryData): string => `${pqd.point.x}${pqd.point.y}`

interface UsePlotLayoutImageQueryProps {
    points: DeepcellPlotDatum[]
    enabled?: boolean
}

/**
 * Loads and returns images for a list of points in DataURL form.
 *
 * For images that are not ready yet, this function will return an undefined dataURL
 */
export const usePlotLayoutImageQuery = ({
    points,
    enabled,
}: UsePlotLayoutImageQueryProps): PointQueryData[] => {
    const cellIdsToFetch: CellId[] = []
    const cellIds: (CellId | undefined)[] = points.map((point) => {
        const CELL_ID: string = point.customdata as string
        const cellImageInfo = getCellImageInfoFromCellId(CELL_ID ?? '')
        const { cellId } = cellImageInfo
        return cellId
    })

    // Start constructing point query data result using cached values, where possible
    // And where there is no cached value, fetch an image for that CellId
    const pointQueryData: PointQueryData[] = points.map((point: DeepcellPlotDatum, i: number) => {
        const cellId = cellIds[i]

        if (cellId === undefined) {
            return { point }
        }

        if (!dataURLCache.isCached(cellId)) {
            cellIdsToFetch.push(cellId)
        }
        const dataURL = dataURLCache.getDataURL(cellId)

        return {
            dataURL,
            point,
        }
    })

    /// Fetch DataURLs for images that we didn't have a URL for yet
    const result = useQuery(['getCellImageDataURLs', cellIdsToFetch], getCellImageDataURLs, {
        enabled,
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: 0,
        staleTime: 0,
    })

    // Update the items in the result that didn't have URLs yet
    if (result?.data) {
        // DataURLs that match the cellIdsToFetch
        const fetchedDataURLs = result?.data
        let fetchedCellIndex = 0

        pointQueryData.forEach((item: PointQueryData, i: number) => {
            if (item.dataURL === undefined) {
                const cellId = cellIdsToFetch[fetchedCellIndex]
                const itemCellId = cellIds[i]
                if (cellId === itemCellId) {
                    // We tried to fetch this CellId and got a result!
                    const dataURL = fetchedDataURLs[fetchedCellIndex]
                    // eslint-disable-next-line no-param-reassign
                    item.dataURL = dataURL
                    fetchedCellIndex += 1 // move to the next fetched cell
                    dataURLCache.cacheDataURL(cellId, dataURL) // cache the result!
                } else {
                    // Try to fetch from the cache -- perhaps another fetch happened in parallel
                    // And the data for this CellId was filled in by the other fetch

                    // eslint-disable-next-line no-param-reassign
                    item.dataURL = dataURLCache.getDataURL(cellId)
                }
            }
        })
    }
    return pointQueryData
}

export default usePlotLayoutImageQuery
