import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'

const CellDetailsItemWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

interface Props {
    title: React.ReactNode
    children: React.ReactNode
}

const CellDetailsItem = ({ title, children }: Props): JSX.Element => (
    <CellDetailsItemWrapper>
        <Typography variant="caption">{title}</Typography>
        <br />
        {children}
    </CellDetailsItemWrapper>
)

export default CellDetailsItem
