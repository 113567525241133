import { FormControl, MenuItem, Select, styled } from '@mui/material'
import React from 'react'
import DeepcellLabel from './DeepcellLabel'
import { DeepcellSelectProps, SimpleSelectItem } from './DeepcellPrimarySelect'
import SecondaryDropdownIcon from './icons/SecondaryDropdownIcon'

const StyledSelect = styled(Select)(({ theme }) => ({
    background: '#EEEFFF',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    fontWeight: '500',
    height: '30px',
    fontSize: '10px',
    minWidth: '90px',
    padding: '9px 8px',
    '& .MuiSelect-icon': {
        color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}))

export const DeepcellSecondarySelect = ({
    label,
    items,
    formControlProps,
    ...rest
}: DeepcellSelectProps): JSX.Element => {
    return (
        <FormControl fullWidth size={rest.size ?? 'small'} {...formControlProps}>
            {label ? <DeepcellLabel label={label} /> : null}
            <StyledSelect
                MenuProps={{
                    PaperProps: {
                        sx: {
                            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
                            borderRadius: '5px',
                            bgcolor: '#EEEFFF',
                            '& .MuiMenuItem-root': {
                                fontSize: '10px',
                                minHeight: '30px !important',
                                fontWeight: '500',
                                padding: '9px 8px',
                            },
                            '& .MuiMenu-list': {
                                padding: '0px',
                            },
                        },
                    },
                }}
                IconComponent={SecondaryDropdownIcon}
                {...rest}
            >
                {items.map((i) => {
                    let item: SimpleSelectItem
                    if (React.isValidElement(i)) return i
                    if (typeof i === 'string' || typeof i === 'number') {
                        item = { key: i, value: i, output: i }
                    } else {
                        item = i
                    }
                    const { key, value, output } = item
                    return (
                        <MenuItem key={key ?? value ?? -1} value={value ?? key ?? undefined}>
                            {output ?? value ?? key ?? ''}
                        </MenuItem>
                    )
                })}
            </StyledSelect>
        </FormControl>
    )
}

export default DeepcellSecondarySelect
