import { AgGridReact } from 'ag-grid-react'
import { ReactElement } from 'react'
import { Run } from '../../utils/api'
import { getCellTypeColumnDefs } from './metadata'

interface IWellResultsGridProps {
    run: Run
}

const CellTypeGrid = ({ run }: IWellResultsGridProps): ReactElement => {
    const rowData = run.cell_counts ?? []
    return (
        <div
            style={{ width: '100%' }}
            className="ag-theme-balham ag-theme-deepcell"
            data-testid="cell-type-grid"
        >
            {/* @TODO: Need to find way for making the extra blank row or find a way to set fixed height. */}
            <AgGridReact
                domLayout="autoHeight"
                rowData={rowData}
                columnDefs={getCellTypeColumnDefs(run.run_id)}
            />
        </div>
    )
}

export default CellTypeGrid
