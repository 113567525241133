import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { CellClass } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { DialogContentText, styled, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { InstructionsText } from 'components/shared/sharedStyles'
import Moment from 'moment'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { createLabelingTask, Run } from 'utils/api'
import { CellClassEncoderDecoder, CellClassValue } from 'utils/proto-utils'
import usePrevious from 'utils/usePrevious'
import DeepcellAutocomplete from 'components/shared/DeepcellAutocomplete'
import DeepcellDialog from 'components/shared/DeepcellDialog'

Moment.locale('en')
momentLocalizer()

const TARGET_CELL_CLASS_KEY = -1

const LabelingFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    minWidth: '500px',
    marginTop: theme.spacing(2),
}))

interface Props {
    isOpen: boolean
    // Returns created labeling tasks, if tasks are created
    handleClose: (labelingTasks: LabelingTask[]) => void
    runs: Run[]
}

function CreateLabelingTaskFromRunsDialog(props: Props): JSX.Element {
    const { isOpen, handleClose, runs } = props
    const [savingTasks, setSavingTasks] = useState<boolean>(false)

    // Setup data for selecting target cell class(es) as a class to label
    const targetCellClassSet = useMemo(
        () => new Set(runs.flatMap((run) => run.target_cell_classes || [])),
        [runs]
    )

    const hasManyTargetClasses = targetCellClassSet.size > 1
    const targetCellClassNames = [...targetCellClassSet].map((cellClass) =>
        CellClassEncoderDecoder.convertToString(cellClass)
    )

    const [cellClassesToLabel, setCellClassesToLabel] = useState<
        (CellClassValue | typeof TARGET_CELL_CLASS_KEY)[]
    >([])
    const cellClassOptions = [...Object.values(CellClass)] as (
        | CellClassValue
        | typeof TARGET_CELL_CLASS_KEY
    )[]
    if (targetCellClassSet.size > 0) {
        cellClassOptions.unshift(TARGET_CELL_CLASS_KEY)
    }

    const [labelingInstructions, setLabelingInstructions] = useState<string>('')

    // Reset the dialog each time it's opened by tracking when we transition from closed to open
    // From https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
    const prevIsOpen = usePrevious(isOpen)

    useEffect(() => {
        // If it is now open, but was previously not open
        if (isOpen && !prevIsOpen) {
            setLabelingInstructions('')
            setSavingTasks(false)

            // Amy chose this order so that if we switch back to "Target, JUNK, OOF" some day,
            // the order won't change as much
            const defaultClasses = [CellClass.CLASS_JUNK, CellClass.CLASS_OOF]
            const initialClassesToLabel: (CellClassValue | -1)[] =
                targetCellClassSet.size > 0
                    ? [TARGET_CELL_CLASS_KEY, ...defaultClasses]
                    : defaultClasses
            setCellClassesToLabel(initialClassesToLabel)
        }
    }, [isOpen, prevIsOpen, targetCellClassSet])

    const mutation = useMutation(createLabelingTask)

    async function handleCreateTasks(): Promise<void> {
        const labelingTask = new LabelingTask()

        setSavingTasks(true)
        labelingTask.setLabelingInstructions(labelingInstructions)

        const promises = runs.map((run) => {
            // Replace target cell class with run-specific class(es)
            const taskCellClasses = [...cellClassesToLabel]
            const targetCellClassIndex = taskCellClasses.indexOf(TARGET_CELL_CLASS_KEY)
            if (targetCellClassIndex !== -1) {
                taskCellClasses.splice(
                    targetCellClassIndex,
                    1,
                    ...((run.target_cell_classes || []) as CellClassValue[])
                )
            }
            const taskCopy = labelingTask.clone()
            taskCopy.setCellClassesToLabelList(taskCellClasses)

            const params = {
                labelingTask: taskCopy,
                runId: run.run_id,
            }
            // @TODO Handle errors better
            return mutation.mutateAsync(params)
        })

        const result = await Promise.all(promises)
        setSavingTasks(false)

        handleClose(result)
    }

    return (
        <DeepcellDialog
            open={isOpen}
            hideBackdrop
            aria-labelledby="form-dialog-title"
            titleLabel={`Create Labeling Task${runs.length > 1 ? 's' : ''}`}
            handleConfirm={() => handleCreateTasks()}
            handleCancel={() => handleClose([])}
            loadingButton
            loadingButtonState={savingTasks}
            description="This will create one labeling task per run, with run data copied to the task (project, experiment, run description, and instrument). Add these settings for each task:"
            okLabel={`Create Task${runs.length > 1 ? 's' : ''}`}
        >
            <LabelingFormControl margin="dense">
                <InputLabel shrink htmlFor="labeling-instructions">
                    Labeling Instructions
                </InputLabel>

                <Typography variant="body1">
                    <InstructionsText
                        name="labeling-instructions"
                        id="labeling-instructions"
                        minRows={3}
                        value={labelingInstructions}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                            setLabelingInstructions(event.target.value)
                        }
                    />
                </Typography>
            </LabelingFormControl>
            <LabelingFormControl margin="dense">
                <DeepcellAutocomplete
                    label="Cell Classes to Label"
                    multiple
                    freeSolo={false}
                    options={cellClassOptions}
                    getOptionLabel={(option) =>
                        option === TARGET_CELL_CLASS_KEY
                            ? 'Target Cell Class(es)'
                            : CellClassEncoderDecoder.convertToString(option)
                    }
                    value={cellClassesToLabel}
                    filterSelectedOptions
                    onChange={(_event, value) => setCellClassesToLabel(value)}
                />

                {targetCellClassNames && cellClassesToLabel.indexOf(TARGET_CELL_CLASS_KEY) !== -1 && (
                    <DialogContentText>
                        Each task will use the target cell class of the run as an expected cell
                        class. The target cell class
                        {hasManyTargetClasses ? 'es are ' : ' is '}
                        {targetCellClassNames.join(', ')}.
                    </DialogContentText>
                )}
            </LabelingFormControl>
        </DeepcellDialog>
    )
}

export default CreateLabelingTaskFromRunsDialog
