export const HOME = '/'
export const CELL_BROWSING = '/cell-browsing'
export const CELL_VISUALIZATIONS = '/cell-visualizations'
export const CELL_VISUALIZATIONS_SESSION = `${CELL_VISUALIZATIONS}/:sessionId`
export const CELL_VISUALIZATIONS_SESSION_VERSION = `${CELL_VISUALIZATIONS_SESSION}/versions/:versionId`
export const RUNS = '/runs'
export const LABELING_TASKS = '/labeling/tasks'
export const LABELING_MICROTASK = '/labeling/tasks/:taskId/microtask'
export const LABELING_REVIEW_SUMMARY_EDIT = '/labeling/tasks/:taskId/reviews'
export const LABELING_REVIEW_SUMMARY_VIEW = '/labeling/tasks/:taskId/reviews/:oracleIndex'
export const SAMPLES = '/samples'
export const LOGIN = '/login'

export const ALL_CELL_VISUALIZATION_ROUTES = [
    CELL_VISUALIZATIONS,
    CELL_VISUALIZATIONS_SESSION,
    CELL_VISUALIZATIONS_SESSION_VERSION,
]
export const HEALTH_STATUS = '/health'
export const DEMO_REGISTER = '/register'
export const DEMO_DATASETS = '/datasets'
