import { Box, Typography } from '@mui/material'
import { TopNavMenu } from 'components/navigationTopBar'
import useAxonTitle from 'components/shared/useAxonTitle'
import useFlags from 'components/shared/useFlags'
import { isExternalDemo } from 'config'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { NavTopBarRightSideButtons } from './NavTopBarRightSideButtons'
import TopBarBase from './TopBarBase'

const ToolbarBox = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
)

export const CellVisualizationNavTopBar = (): JSX.Element => {
  const { demoEnabled, saveSessionsV2 } = useFlags()
  const isSaveV2 = saveSessionsV2 === 'yes'
  const showSessions = demoEnabled === 'no' && !isSaveV2
  const { sessionId } = useCellVisualizationUrlParams()
  const suiteTitle = useAxonTitle('Deepcell Software Suite')
  const cloudTitle = useAxonTitle('Deepcell Cloud')

  let mainText = `${cloudTitle} - Cell Visualization`
  if (isExternalDemo) {
    mainText = suiteTitle
  } else if (!sessionId && showSessions) {
    mainText = 'Saved Sessions'
  }

  const {
    cellVisualizations: { name },
  } = useCellVisualizationsSlice()

  return (
    <TopBarBase toolbarProps={{ sx: { justifyContent: 'space-between' } }}>
      <ToolbarBox>
        {!isExternalDemo && <TopNavMenu />}
        <img
          alt=""
          src="/images/Deepcell-Icon-White.png"
          width="15px"
          style={{ marginRight: '15px' }}
        />
        <Typography variant="h6" noWrap width="100%">
          {mainText}
        </Typography>
      </ToolbarBox>
      {sessionId && showSessions ? (
        <Typography variant="h6" noWrap>
          {name ?? 'Untitled Session'}
        </Typography>
      ) : null}
      <NavTopBarRightSideButtons />
    </TopBarBase>
  )
}

export default CellVisualizationNavTopBar
