import useLabelingTaskQueryParams from 'components/labeling/tasks/useLabelingTaskQueryParams'
import { useEffect } from 'react'
import { labelingTaskSlice } from '../LabelingTaskSlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => useSliceWrapper('labeling', labelingTaskSlice)

export const useLabelingTasksSlice = (): ReturnType<typeof useGetMethodsAndData> => {
    const { query, setQuery } = useLabelingTaskQueryParams()
    const methodsAndData = useGetMethodsAndData()

    const {
        labeling: { activeTab, labelingTaskFilter, selectedTaskIds },
        setLabelingTaskFilter,
        setActiveTab,
        setSelectedTaskIds,
    } = methodsAndData

    /* ********************************* */
    /* ********************************* */
    /* ********************************* */
    // if there's no data in the store when the page loads, get it from the URL
    useEffect(() => {
        if (!labelingTaskFilter) {
            setLabelingTaskFilter({
                assignedToMe: query.assignedToMe,
                keywordSearch: query.keywordSearch,
                projectName: query.projectName,
                status: query.status,
            })
        }
        if (!activeTab) {
            setActiveTab(query.activeTab)
        }
        if (!selectedTaskIds) {
            setSelectedTaskIds(query.selectedTaskIds)
        }
    }, [
        activeTab,
        labelingTaskFilter,
        query,
        selectedTaskIds,
        setActiveTab,
        setLabelingTaskFilter,
        setSelectedTaskIds,
    ])

    /* ********************************* */
    /* ********************************* */
    /* ********************************* */
    // if the store gets updated, promote that change to the url

    useEffect(() => {
        if (labelingTaskFilter) {
            setQuery({ ...query, ...labelingTaskFilter, selectedTaskIds: [] })
        }
    }, [labelingTaskFilter, query, setQuery])

    useEffect(() => {
        if (activeTab) {
            setQuery({ ...query, activeTab })
        }
    }, [activeTab, query, setQuery])

    useEffect(() => {
        if (selectedTaskIds) {
            setQuery({ ...query, selectedTaskIds })
        }
    }, [query, selectedTaskIds, setQuery])

    /* ********************************* */
    /* ********************************* */
    /* ********************************* */

    return methodsAndData
}

export default useLabelingTasksSlice
