import { PlotRange } from 'components/cell-visualizations/tsv/types'
import { getDataWidth } from 'components/cell-visualizations/tsv/utils'
import _ from 'lodash'
import RBush, { BBox } from 'rbush'
import { useEffect, useMemo, useState } from 'react'
import { DeepcellPlotDatum, ZoomThreshold } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DeepcellPlotData } from '../useCellsData'
import { getPlotDatum, getVisiblePointsFromPoints } from './getVisiblePoints'

// Parameters for setting up zoom levels

// Each zoom level has a pixel ratio that's ZOOM_THRESHOLD_RATIO of the previous level
const ZOOM_THRESHOLD_RATIO = 1 / Math.sqrt(2)

// We'll precompute this many zoom levels
const NUM_THRESHOLDS = 14

// Minimum plot pixel width to compute thresholds for
const MIN_PLOT_WIDTH_IN_PIXELS = 200

/** This function precomputes the images to show at each zoom threshold.
 *
 * Each zoom threshold is for a specific pixel ratio.  And we keep an array
 * e.g. The array could be for pixel ratios of 1, 1 / sqrt(2), 0.5, ...
 *
 * When we display images, we use the zoom threshold that has a pixel ratio that is
 * greater than the current pixel ratio but as close as possible.
 *
 * @TODO consider if it might help to not make this algorithm depend on plotPixelWidth
 */
function precomputeZoomThresholds(
    allPoints: DeepcellPlotDatum[],
    imageSize: number, // Desired image size in screen pixels
    spacingAdjust: number // Scaling factor to adjust spacing.  Higher means more spacing. 1 means lay out images so they are imageSize pixels apart.
): ZoomThreshold[] {
    // Get maximum pixel size to consider = (plot width in UMAP coordinates) / (screen width in pixels) 
    const dataWidth = getDataWidth(allPoints)
    const maxPixelWidthInPlotCoords = dataWidth / MIN_PLOT_WIDTH_IN_PIXELS

    // Compute thresholds!
    const prevSampledPoints = new RBush<BBox>()
    const thresholds = [...Array(NUM_THRESHOLDS)]
        .map(
            (__, i) =>
                ({ pixelWidthInPlotCoords: maxPixelWidthInPlotCoords * ZOOM_THRESHOLD_RATIO ** i } as ZoomThreshold)
        )
        .reduce((prevZoomThresholds: ZoomThreshold[], curZoomThreshold: ZoomThreshold) => {
            let points: typeof allPoints = []
            const { pixelWidthInPlotCoords } = curZoomThreshold

            // Minimum distance between points to include, in UMAP coordinate space
            const minDist = pixelWidthInPlotCoords * imageSize * spacingAdjust

            // Add all previous points
            if (prevZoomThresholds.length > 0) {
                const lastPoints = prevZoomThresholds[prevZoomThresholds.length - 1].points
                if (lastPoints !== undefined) {
                    points = [...lastPoints]
                }
            }

            // Go through points in random order
            // Note that we go through points that have already been added at the moment
            // But they'll fail the hasOverlap check pretty quickly
            const shuffledPoints = _.shuffle(allPoints)

            shuffledPoints.forEach((p) => {
                const px = p.x as number
                const py = p.y as number

                // If there's bad data, it breaks RBush's collission algorithm
                // and RBush.collides starts returning false all the time...so let's filter this out
                if (Number.isNaN(px) || Number.isNaN(py)) return

                // Check if any of the previously sampled points is within minDist
                // of point p in any direction.  If so, we have an overlap!
                const pBoundingBox = {
                    minX: px - minDist,
                    maxX: px + minDist,
                    minY: py - minDist,
                    maxY: py + minDist,
                }
                const hasOverlap = prevSampledPoints.collides(pBoundingBox)

                // If we don't have an overlap, show the point!
                if (!hasOverlap) {
                    points.push(p)
                    prevSampledPoints.insert({
                        minX: px,
                        minY: py,
                        maxX: px,
                        maxY: py,
                    })
                }
            })

            return [...prevZoomThresholds, { ...curZoomThreshold, points } as ZoomThreshold]
        }, [] as ZoomThreshold[])

    return thresholds
}

export const useZoomThresholdSampleImages = (
    enabled: boolean,
    plotData?: DeepcellPlotData,
    range?: PlotRange,
    plotPixelWidth?: number
): DeepcellPlotDatum[] => {
    const {
        setZoomThresholds,
        cellVisualizations: {
            zoomThresholds,
            cellImagesFilter: { imageSize, spacingAdjust },
        },
    } = useCellVisualizationsSlice()

    const allPoints = useMemo(() => getPlotDatum(plotData), [plotData])

    // Keep a local copy to tell when we should recompute zoomThresholds
    const [prevImageSize, setPrevImageSize] = useState(imageSize)
    const [prevSpacingAdjust, setPrevSpacingAdjust] = useState(spacingAdjust)

    // Points to show images for
    const [points, setPoints] = useState<DeepcellPlotDatum[]>([])

    // Precompute zoomThresholds, if needed
    // This only needs to happen once and is independent of the current plot pixel width
    useEffect(() => {
        // Only compute zoomThresholds if they're not already computed
        // Or we just changed parameters
        const paramsUnchanged =
            prevImageSize === imageSize &&
            spacingAdjust === prevSpacingAdjust

        const zoomThresholdsExist = Boolean(zoomThresholds && zoomThresholds[0].pixelWidthInPlotCoords)

        if (
            !plotData ||
            !enabled ||
            (zoomThresholdsExist && paramsUnchanged)
        )
            return

        // Compute the zoom thresholds!
        const thresholds = precomputeZoomThresholds(
            allPoints,
            imageSize,
            spacingAdjust
        )

        // Update state
        setZoomThresholds(thresholds)
        setPrevImageSize(imageSize)
        setPrevSpacingAdjust(spacingAdjust)
    }, [
        enabled,
        allPoints,
        plotData,
        zoomThresholds,
        prevImageSize,
        imageSize,
        setZoomThresholds,
        spacingAdjust,
        prevSpacingAdjust,
    ])

    // Pick points to show, if we have zoom thresholds available
    // This layout depends on the currently visible plot pixel width
    useEffect(() => {
        if (
            !enabled ||
            !zoomThresholds?.length ||
            plotPixelWidth === undefined ||
            range?.x2 === undefined ||
            range?.x1 === undefined
        )
            return

        const pixelWidthInPlotCoords = (((range.x2 as number) - range.x1) as number) / plotPixelWidth

        // Find the zoom threshold to use (the one with a pixel ratio just larger than current pixel ratio)
        // Note that if you zoom out really far, the current pixel ratio may be larger than the pixel ratio
        // for zoomThresholds[0], so just use zoomThresholds[0]
        let zoomThreshold = zoomThresholds[0]
        zoomThresholds.forEach((threshold: ZoomThreshold) => {
            if (threshold.pixelWidthInPlotCoords > pixelWidthInPlotCoords) {
                zoomThreshold = threshold
            }
        })
        if (!zoomThreshold.points) {
            return
        }

        const visiblePoints = getVisiblePointsFromPoints(zoomThreshold.points, range)
        setPoints(visiblePoints)
    }, [enabled, plotPixelWidth, range, zoomThresholds])

    return points
}

export default useZoomThresholdSampleImages
