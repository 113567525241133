import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import OkCancelDialogBody, { useBatchUpdate } from 'components/shared/OkCancelDialogBody'
import { InstructionsText } from 'components/shared/sharedStyles'
import _ from 'lodash'
import Moment from 'moment'
import { ChangeEvent, FocusEvent, useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { LabelingTaskWithActions, updateLabelingTask } from 'utils/api'

Moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
}

// @TODO Make a shared component for the popovers
// Most of this is duplicated from EditInstructionsPopover
function EditNotesPopover(props: Props): JSX.Element {
    const { handleClose, tasks } = props

    const notes = tasks.map((task) => task.getLabelingNotes())
    const uniqueNotes = _.uniq(notes)
    const initialNotes =
        uniqueNotes.length === 1 && uniqueNotes[0] !== undefined ? uniqueNotes[0] : ''

    const [labelingNotes, setLabelingNotes] = useState<string>(initialNotes)

    const [updatingTasks, handleBatchUpdate] = useBatchUpdate(
        tasks,
        (task: LabelingTask) => task.setLabelingNotes(labelingNotes),
        updateLabelingTask,
        handleClose
    )

    return (
        <OkCancelDialogBody pending={updatingTasks} handleButtonClick={handleBatchUpdate}>
            <InputLabel shrink htmlFor="labeling-notes">
                Labeling Notes
            </InputLabel>

            <Typography variant="body1">
                <InstructionsText
                    autoFocus
                    onFocus={(event: FocusEvent<HTMLTextAreaElement>) => {
                        event.target.selectionStart = event.target.value.length // eslint-disable-line
                        event.target.selectionEnd = event.target.value.length // eslint-disable-line
                    }}
                    name="labeling-notes"
                    id="labeling-notes"
                    minRows={3}
                    value={labelingNotes}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        setLabelingNotes(event.target.value)
                    }
                />
            </Typography>
        </OkCancelDialogBody>
    )
}

export default EditNotesPopover
