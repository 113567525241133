import { Box, Typography } from '@mui/material'

interface SessionStaticInfoProps {
    label: string
    info: string
}
export const SessionStaticInfo = ({ info, label }: SessionStaticInfoProps): JSX.Element => {
    return (
        <Typography
            sx={{
                backgroundColor: (t) => t.palette.info.light,
                px: 1,
                py: '4px',
                borderRadius: 1,
                display: 'flex',
                width: '100%',
            }}
            component="div"
        >
            <Box sx={{ minWidth: 100 }}>{label}</Box>
            <Box>{info}</Box>
        </Typography>
    )
}

export default SessionStaticInfo
