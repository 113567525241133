import * as ROUTES from 'constants/routes'
import { Box } from '@mui/material'
import CellVisualizationFooter from 'components/cell-visualizations/CellVisualizationFooter'
import { useRouteMatch } from 'react-router-dom'

export const deepcellFooterId = 'deepcellFooterId'

export const DeepcellFooter = (): JSX.Element => {
    const isCellViz = useRouteMatch([
        ROUTES.CELL_VISUALIZATIONS_SESSION,
        ROUTES.CELL_VISUALIZATIONS_SESSION_VERSION,
    ])

    let footer = <></>

    if (isCellViz) {
        footer = <CellVisualizationFooter />
    }

    return (
        <Box
            id={deepcellFooterId}
            sx={{ top: 'auto', bottom: 0, height: '64px', position: 'fixed' }}
        >
            {footer}
        </Box>
    )
}

export default DeepcellFooter
