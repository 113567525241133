import { Box, styled } from '@mui/material'
import 'react-widgets/dist/css/react-widgets.css'
import * as api from '../../utils/api'
import { messagesEqual } from '../../utils/proto-utils'
import CellImageControl, { CellImageAction } from '../shared/CellImageControl'
import { IMAGE_SIZE } from './cellSearchHelpers'
import { getDisplayChipLabel } from './sort-orders'
import useCellBrowsingQueryParams from './useCellBrowsingQueryParams'

const CellImagesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    lineHeight: 0,
    gap: '1px',
    flexWrap: 'wrap',
})

interface CellImagesProps {
    cellData: api.FindCellsResponse
    handleQueryUpdate: (action: CellImageAction) => void
}

function CellImages({ cellData, handleQueryUpdate }: CellImagesProps): JSX.Element {
    const { search, displayOptions } = useCellBrowsingQueryParams()
    const { sortOrderKey, showSortFields, selectedCellId } = search
    return (
        <CellImagesContainer>
            {cellData.cells.map((cell: api.CellResponse, i) => {
                const cellId = cell.cell.getCellId()
                const highlightCell = messagesEqual(cellId, selectedCellId)
                const key = `${cellId?.getTime()}_${cellId?.getNumber()}_${cellId?.getInstrumentId()}`
                if (cellId) {
                    return (
                        <CellImageControl
                            key={key || i.toString()}
                            width={IMAGE_SIZE}
                            chipLabel={
                                showSortFields
                                    ? getDisplayChipLabel(
                                          cell.cell,
                                          i > 0 ? cellData.cells[i - 1].cell : undefined,
                                          sortOrderKey
                                      )
                                    : undefined
                            }
                            cellId={cellId}
                            onCellImageAction={handleQueryUpdate}
                            highlighted={highlightCell}
                            displayOptions={displayOptions}
                        />
                    )
                }
                return null
            })}
        </CellImagesContainer>
    )
}

export default CellImages
