import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { CellClass } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { TextField } from '@mui/material'
import { DeepcellAutocomplete } from 'components/shared'
import OkCancelDialogBody, { useBatchUpdate } from 'components/shared/OkCancelDialogBody'
import _ from 'lodash'
import Moment from 'moment'
import { useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { LabelingTaskWithActions, updateLabelingTask } from 'utils/api'
import { CellClassEncoderDecoder, CellClassValue } from 'utils/proto-utils'

Moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
}

function getSharedCellClassList(tasks: LabelingTask[]) {
    const currentCellClassesLists = tasks.map((task) => task.getCellClassesToLabelList())
    const uniqueLists = _.uniqBy(currentCellClassesLists, (cellClasses: CellClassValue[]) =>
        cellClasses.sort().join('_')
    )
    return uniqueLists.length === 1 && uniqueLists[0] !== undefined ? uniqueLists[0] : []
}

function SetCellClassToLabelPopover(props: Props): JSX.Element {
    const { handleClose, tasks } = props

    const [cellClassesToLabel, setCellClassesToLabel] = useState<CellClassValue[]>(
        getSharedCellClassList(tasks)
    )

    const cellClassOptions = [...Object.values(CellClass)] as CellClassValue[]

    const [updatingTasks, handleBatchUpdate] = useBatchUpdate(
        tasks,
        (task: LabelingTask) => task.setCellClassesToLabelList(cellClassesToLabel),
        updateLabelingTask,
        handleClose
    )

    return (
        <OkCancelDialogBody pending={updatingTasks} handleButtonClick={handleBatchUpdate}>
            <DeepcellAutocomplete
                multiple
                freeSolo={false} // need to explicitly declare freeSolo as false so options don't infer strings as inputs
                label="Cell Classes to Label"
                options={cellClassOptions}
                getOptionLabel={(option: CellClassValue) => {
                    return CellClassEncoderDecoder.convertToString(option)
                }}
                value={cellClassesToLabel}
                filterSelectedOptions
                onChange={(_event, value) => setCellClassesToLabel(value)}
                renderInput={(params) => <TextField {...params} variant="outlined" label="" />}
            />
        </OkCancelDialogBody>
    )
}

export default SetCellClassToLabelPopover
