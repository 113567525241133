import { Box, styled } from '@mui/material'
import {
    GridApi,
    GridReadyEvent,
    IDatasource,
    IGetRowsParams,
    SelectionChangedEvent,
} from 'ag-grid-community'
import ContentLoading from 'components/shared/ContentLoading'
import firebase from 'firebase'
import Moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import 'react-widgets/dist/css/react-widgets.css'
import { DateOperator, RunSearchFilterInitialState, RunSearchFilterState } from 'redux/slices'
import { useRunSearchFilterSlice, useRunsSlice } from 'redux/slices/hooks'
import { getRuns, GetRunsParams } from 'utils/api'
import useColumnStateManager from 'utils/useColumnStateManager'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import { sortModelItemToOrderBy } from './metadata'
import RunDetailsPanel from './RunDetailsPanel'
import RunGrid from './RunGrid'
import { RunSearchFilter } from './RunSearchFilter'
import useRunQueryParams from './useRunQueryParams'

export const RUN_DATA_CACHE_BLOCK_SIZE = 100

const SearchFilter = styled(RunSearchFilter)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
})

const runSearchFilterStateToParams = (state: RunSearchFilterState) => {
    let startTimeMax: Date | undefined
    let startTimeMin: Date | undefined
    if (Number(state.runDateOperator)) {
        if (`${state.runDateOperator}` === `${DateOperator.On}`) {
            startTimeMax = state.runDateStart ?? undefined
        } else {
            startTimeMax = state.runDateEnd ?? undefined
        }

        startTimeMin =
            `${state.runDateOperator}` === `${DateOperator.Before}`
                ? undefined
                : state.runDateStart ?? undefined
    } else {
        startTimeMin = state.runDateStart ?? undefined
    }
    return {
        q: state.keyword || undefined,
        run_ids: state.runIds ? state.runIds?.split(/\s+/) : undefined,
        user_email: state.operator || undefined,
        start_time_max: startTimeMax ? Moment(`${startTimeMax}T23:59`).toISOString() : undefined,
        start_time_min: startTimeMin ? Moment(startTimeMin).toISOString() : undefined,
        stopped: state.status ? !!+state.status : undefined,
    }
}

export const updateURLParams = (oldQuery: RunSearchFilterState): RunSearchFilterState => {
    let updatedQuery: RunSearchFilterState
    if (`${oldQuery.runDateOperator}` === `${DateOperator.After}`)
        updatedQuery = { ...oldQuery, runDateEnd: undefined }
    else if (`${oldQuery.runDateOperator}` === `${DateOperator.Before}`)
        updatedQuery = { ...oldQuery, runDateStart: undefined }
    else updatedQuery = oldQuery
    return updatedQuery
}

export function RunsPage(): JSX.Element {
    const { isLoading } = useAuthWrapper(useAuth0)

    const queryClient = useQueryClient()
    const { runSearchFilter, update } = useRunSearchFilterSlice()
    const { appendNew } = useRunsSlice()

    const [gridApi, setGridApi] = useState<GridApi | null>(null)
    const { query, setQuery, search } = useRunQueryParams()
    // array of run.run_id
    const [selectedRunIds, setSelectedRunIds] = useState<string[]>(
        search.runIds?.split(/\s+/) ?? []
    )

    // TODO: This ↓ hook and function and useEffect is the only way I got it to work. Maybe because a rerender is needed? No clue
    const [refetch, setRefetch] = useState<{ keepSelection: boolean }>()
    const deselectAndRefetch = () => {
        setRefetch({ keepSelection: false })
    }

    useEffect(() => {
        if (refetch && gridApi) {
            setRefetch(undefined)
            if (typeof refetch === 'object') {
                if (!refetch.keepSelection) gridApi.deselectAll()
            }
            setTimeout(() => gridApi.onFilterChanged(), 100)
        }
    }, [gridApi, refetch])

    const apiParams = useRef<GetRunsParams>(runSearchFilterStateToParams(search))

    useEffect(() => {
        if (runSearchFilter === RunSearchFilterInitialState) {
            // get all values from the url query that match a key in the store
            const seededState = Object.keys(RunSearchFilterInitialState).reduce((acc, k) => {
                const key = k as keyof RunSearchFilterState
                let queryVal = search[key]
                if (queryVal !== undefined) {
                    if (key === 'runIds' && typeof queryVal === 'string') {
                        // TODO: when loading the page from a new tab with runIds in the url, for some reason it sticks commas between them
                        queryVal = queryVal.replace(/,/g, ' ')
                    }
                    return { ...acc, [key]: queryVal }
                }
                return acc
            }, {} as RunSearchFilterState)
            update({
                ...seededState,
                advancedSearchEnabled: Object.keys(seededState).length > 0,
            })
        }
    }, [runSearchFilter, search, update])

    const { initColumnState, bindColumnStateHandlers } = useColumnStateManager(search, setQuery)

    /* Log a google analytics event using a standard event type
        https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484
    */
    const analytics = firebase.analytics()
    analytics.logEvent('list_runs', search)

    const serverDataSource: IDatasource = {
        getRows: async (getRowParams: IGetRowsParams) => {
            const limit = getRowParams.endRow - getRowParams.startRow
            // generate 'order_by' API string from the current getRowParams.sortModels:
            const orderBy = sortModelItemToOrderBy(getRowParams.sortModel) || 'run_id:desc'
            const params = {
                ...apiParams.current,
                offset: getRowParams.startRow,
                limit,
                order_by: orderBy,
            } as GetRunsParams
            try {
                const resultRuns = await queryClient.fetchQuery(['getRuns', params], getRuns, {
                    staleTime: 0,
                })
                // Convert from result count to the index of the lastRow by subtracting one
                let lastRow = -1
                if (resultRuns.length < limit) {
                    lastRow = getRowParams.startRow + resultRuns.length
                }

                appendNew(resultRuns)
                getRowParams.successCallback(resultRuns, lastRow)
            } catch (err) {
                console.error(err)
                getRowParams.failCallback()
            }
        },
    }

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api)

        // Reload column state
        initColumnState(params.columnApi, search.columnState)
        // Connect datasource
        params.api.setDatasource(serverDataSource)

        // Initialize selection
        // TODO: Find a way to select the given ids from the url after all the changes made on the nodes and remove the timeout
        if (selectedRunIds.length > 0) {
            window.setTimeout(() => {
                params.api.forEachNode((node) => {
                    const isSelected =
                        selectedRunIds.findIndex((id) => id === node?.data?.run_id) > -1
                    node.setSelected(isSelected)
                })
            }, 1000)
        }
    }

    function handleUpdate(searchFilters?: RunSearchFilterState) {
        let newApiParams: GetRunsParams
        let storeToUse: RunSearchFilterState

        if (searchFilters) {
            storeToUse = searchFilters
            // main keyword search was updated
            if (searchFilters.advancedSearchEnabled) {
                // ...while advanced options were enabled at some point
                newApiParams = {
                    ...apiParams.current,
                    q: searchFilters.keyword,
                }
            } else {
                newApiParams = { q: searchFilters.keyword }
            }
        } else {
            // advanced search button was clicked
            storeToUse = {
                ...runSearchFilter,
                advancedSearchEnabled: true,
            }

            newApiParams = runSearchFilterStateToParams(storeToUse)
        }

        apiParams.current = newApiParams

        const newQuery: RunSearchFilterState = {
            ...query,
            ...Object.keys(storeToUse).reduce((acc, k) => {
                const key = k as keyof typeof storeToUse
                const val = storeToUse[key]
                if (key === 'runDateOperator') {
                    return { ...acc, [key]: val ? `${val}` : undefined }
                }
                return { ...acc, [key]: val }
            }, {}),
        }

        setQuery(updateURLParams(newQuery))

        // if there are runIds in the search then we want to keep the selection
        setRefetch({ keepSelection: !!storeToUse.runIds })
    }

    const handleAdvancedSearchCancel = (clear = true) => {
        if (clear) {
            apiParams.current = {}
            deselectAndRefetch()
        }
    }

    function onRowSelected(event: SelectionChangedEvent) {
        const runIds = event.api.getSelectedNodes().map((n) => n.data.run_id)
        setSelectedRunIds(runIds)
        setQuery({ ...query, runIds: runIds.toString() })
    }

    function onDownload() {
        gridApi?.exportDataAsCsv({ onlySelected: true })
    }

    return isLoading ? (
        <ContentLoading />
    ) : (
        <>
            <SearchFilter handleCancel={handleAdvancedSearchCancel} handleSearch={handleUpdate} />
            <Box sx={{ display: 'flex', height: '100%' }}>
                <SplitterLayout
                    percentage={false}
                    primaryIndex={0}
                    primaryMinSize={400}
                    secondaryMinSize={200}
                >
                    <RunGrid
                        gridApi={gridApi}
                        onSelectionChanged={onRowSelected}
                        onGridReady={onGridReady}
                        {...bindColumnStateHandlers}
                    />

                    {selectedRunIds.length ? (
                        <Box sx={{ ml: 2, overflow: 'auto', height: 'calc(100% - 110px)' }}>
                            <RunDetailsPanel
                                onDownload={onDownload}
                                selectedRunIds={selectedRunIds}
                            />
                        </Box>
                    ) : null}
                </SplitterLayout>
            </Box>
        </>
    )
}

export default RunsPage
