/* eslint-disable react/jsx-pascal-case */
import { CallMerge } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import PencilIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  IconButton,
  IconButtonProps,
  SvgIcon,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { SimpleSelectItem } from 'components/shared'
import { Font } from 'plotly.js'
import React, { ReactElement } from 'react'

// Using the Vega 20 color palette for now to support up to 20 colors
//
// See these links for other resources that Ryan suggested
// https://github.com/scverse/scanpy/blob/master/scanpy/plotting/palettes.py
// https://graphicdesign.stackexchange.com/questions/3682/where-can-i-find-a-large-palette-set-of-contrasting-colors-for-coloring-many-d
// http://godsnotwheregodsnot.blogspot.com/2012/09/color-distribution-methodology.html
export const CATEGORICAL_COLOR_PALETTE = [
  // Start with category10 scheme, so the first 10 colours are hopefully pairwise distinguishable enough
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',

  '#aec7e8',
  '#ffbb78',
  '#98df8a',
  '#ff9896',
  '#c5b0d5',
  '#c49c94',
  '#f7b6d2',
  '#c7c7c7',
  '#dbdb8d',
  '#9edae5',
]

// This is the color to show when an attribute is marked as hidden
export const HIDDEN_COLOR = '#eee'

// The image size and spacing parameters control how the layout algorithm in useZoomThresholdSampleImages.ts works

// Normal image size in pixels on screen
export const CELL_IMAGE_NORMAL_SIZE = 100

// Normal spacing factor
export const CELL_IMAGE_NORMAL_SPACING = 1

// Image sizes in pixels on screen
export const CELL_IMAGE_SIZES: SimpleSelectItem[] = [
  { output: 'Small', value: CELL_IMAGE_NORMAL_SIZE / 1.5 },
  { output: 'Normal', value: CELL_IMAGE_NORMAL_SIZE },
  { output: 'Large', value: CELL_IMAGE_NORMAL_SIZE * 1.5 },
]

// Spacing factor
//
// Layout algorithm will try to keep images at least this far apart:
//     spacing * image size
export const CELL_IMAGE_SPACING_OPTIONS: SimpleSelectItem[] = [
  { output: 'Low', value: CELL_IMAGE_NORMAL_SPACING / 1.5 },
  { output: 'Normal', value: CELL_IMAGE_NORMAL_SPACING },
  { output: 'High', value: CELL_IMAGE_NORMAL_SPACING * 1.5 },
]

export const LabelFont: Font = { size: 14, family: 'Arial Black', color: 'black' }

export const CellVisualizationsSectionHeader = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{
        fontWeight: 'bold',
        lineHeight: '24px',
        fontSize: '16px',
        textDecoration: 'underline',
      }}
    >
      {children}
    </Typography>
  )
}

export const CellVisualizationsSubSectionHeader = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{
        fontWeight: 'bold',
        lineHeight: '20px',
        fontSize: '14px',
      }}
    >
      {children}
    </Typography>
  )
}

const DefaultIconStyleProps = (color: string): SxProps => ({
  cursor: 'pointer',
  width: '1.2rem',
  color,
})

interface IconProps {
  onClick?: IconButtonProps['onClick']
  tooltip?: string
  iconButtonProps?: IconButtonProps
  iconStyleProps?: SxProps
}

const IconButtonWrapper = ({
  Icon,
  onClick,
  tooltip,
  iconButtonProps,
  iconStyleProps,
}: IconProps & { Icon: ReactElement<typeof SvgIcon> }) => {
  const theme = useTheme()
  const {
    grey,
    primary: { main },
  } = theme.palette
  return (
    <Tooltip title={tooltip || ''} enterDelay={1000}>
      <span>
        <IconButton
          {...iconButtonProps}
          onClick={onClick ?? iconButtonProps?.onClick}
          disabled={iconButtonProps?.disabled}
        >
          <Icon.type
            {...Icon.props}
            sx={
              iconStyleProps ?? DefaultIconStyleProps(iconButtonProps?.disabled ? grey[500] : main)
            }
          />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export const DeleteIconButton = (props: IconProps): JSX.Element => (
  <IconButtonWrapper {...props} Icon={<DeleteIcon />} />
)

export const ChangeIconButton = (props: IconProps): JSX.Element => (
  <IconButtonWrapper {...props} Icon={<PencilIcon />} />
)

export const CloseIconButton = (props: IconProps): JSX.Element => (
  <IconButtonWrapper {...props} Icon={<CancelIcon />} />
)

export const CheckIconButton = (props: IconProps): JSX.Element => (
  <IconButtonWrapper {...props} Icon={<CheckIcon />} />
)

export const MergeIconButton = (props: IconProps): JSX.Element => (
  <IconButtonWrapper {...props} Icon={<CallMerge />} />
)
