import {
    ColDef,
    ICellRendererParams,
    SortModelItem,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import * as ROUTES from '../../constants/routes'
import HyperlinkCellComponent from './HyperlinkCellComponent'
import ProgressCellComponent from './ProgressCellComponent'
import StatusCellComponent from './StatusCellComponent'

Moment.locale('en')
momentLocalizer()

const headerTemplate =
    '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>'

/**
 * Aggregate the sum of a column
 * And it will return a number that will be equivalent to sum of all rows for a particular column.
 * @function aggSumFromString
 * @param field string.
 * @param api ag-grid API.
 */

export const enum RunsHeaderName {
    RunId = 'Run Id',
    RunType = 'Run Type',
    RunDescription = 'Run Description',
    Project = 'Project',
    StartTime = 'Start Time',
    Status = 'Status',
    Operator = 'Operator',
    TotalCellCount = 'Total Cell Count',
    ExperimentCode = 'Experiment Code',
    QualityReview = 'Quality Review',
}

function aggSumFromString(params: ValueFormatterParams): number {
    const {
        colDef: { field },
        api,
    } = params
    if (!api) return 0
    let total = 0
    api?.forEachNode((node) => {
        total += parseInt(node.data[field || ''], 10)
    })
    return total
}

export const columnDefs: ColDef[] = [
    {
        headerName: RunsHeaderName.RunId,
        field: 'run_id',
        checkboxSelection: true,
        pinned: 'left',
        width: 190,
        sort: 'desc',
        sortable: true,
    },
    {
        headerName: RunsHeaderName.RunType,
        field: 'well_sorting_configurations',
        width: 160,
        sortable: true,
        valueFormatter: (params) => (params.value?.length > 0 ? 'Sorting' : 'Imaging'),
    },
    {
        headerName: RunsHeaderName.QualityReview,
        field: 'run_quality_score',
        width: 160,
        sortable: true,
        cellRendererFramework: (params: ICellRendererParams) =>
            StatusCellComponent(params.data?.run_quality_score),
    },
    {
        headerName: RunsHeaderName.RunDescription,
        field: 'description',
        width: 300,
        sortable: true,
        wrapText: true,
        autoHeight: true,
    },
    {
        headerName: RunsHeaderName.Project,
        field: 'project_code',
        width: 200,
        sortable: true,
    },
    {
        headerName: RunsHeaderName.ExperimentCode,
        field: 'experiment_code',
        width: 200,
        sortable: true,
    },
    {
        headerName: RunsHeaderName.StartTime,
        field: 'start_time',
        width: 160,
        sort: 'desc',
        sortable: true,
        valueFormatter: (params) => Moment(params.value).format('YYYY-MM-DD HH:mm:ss') || '',
    },
    {
        headerName: RunsHeaderName.Status,
        field: 'stopped',
        width: 160,
        sortable: true,
        valueFormatter: (params) => ((params.value as boolean) ? 'Complete' : 'In Progress'),
    },
    {
        headerName: RunsHeaderName.Operator,
        field: 'user_email',
        width: 160,
        sortable: true,
    },
    {
        headerName: RunsHeaderName.TotalCellCount,
        field: 'total_cell_count',
        width: 160,
        sortable: true,
        type: 'numericColumn',
    },
]

export const detailColumnDefs: ColDef[] = [
    {
        headerName: 'Run Id',
        field: 'run_id',
        width: 190,
        sort: 'desc',
        sortable: true,
    },
    {
        headerName: 'Sample Id',
        field: 'run_id',
        width: 190,
        sort: 'desc',
        sortable: true,
    },
    {
        headerName: 'Project',
        field: 'project_code',
        width: 200,
        sortable: true,
    },
    {
        headerName: 'Experiment Code',
        field: 'experiment_code',
        width: 200,
        sortable: true,
    },
    {
        headerName: 'Run Description',
        field: 'description',
        width: 200,
        sortable: true,
    },
    {
        headerName: 'Run Notes',
        field: 'experiment_code',
        width: 200,
        sortable: true,
    },
    {
        headerName: 'Target cell classes',
        field: 'experiment_code',
        width: 200,
        sortable: true,
    },
]

export const getSortByWellColumnDefs = (hasRunStopped?: boolean): ColDef[] => [
    {
        headerName: 'Well',
        field: 'well',
        pinned: 'left',
        minWidth: 50,
        autoHeight: true,
    },
    {
        headerName: 'Cell Types',
        field: 'cell_types',
        resizable: true,
        minWidth: 80,
        autoHeight: true,
    },
    {
        headerName: 'Threshold',
        field: 'threshold',
        resizable: true,
        minWidth: 80,
        autoHeight: true,
    },
    {
        headerName: 'Stop Count',
        field: 'stop_count',
        resizable: true,
        minWidth: 85,
        headerComponentParams: {
            template: headerTemplate,
        },
        autoHeight: true,
    },
    {
        headerName: 'Sorted cells count',
        field: 'count',
        minWidth: 90,
        resizable: true,
        cellRendererFramework: ProgressCellComponent,
        valueFormatter: ({ value }) => `${value ?? 0}`,
        headerComponentParams: {
            template: headerTemplate,
        },
        autoHeight: true,
    },
    {
        headerName: 'Yield',
        field: 'true_positive_rate',
        minWidth: 90,
        cellRendererFramework: ProgressCellComponent,
        valueFormatter: ({ value }) => `${(value ?? 0).toFixed(1)}%`,
        cellRendererParams: {
            showFullBar: true,
        },
        hide: !hasRunStopped,
        resizable: true,
        autoHeight: true,
    },
    {
        headerName: 'Purity',
        field: 'purity',
        minWidth: 80,
        cellRendererFramework: ProgressCellComponent,
        valueFormatter: ({ value }) => `${(value ?? 0).toFixed(1)}%`,
        cellRendererParams: {
            showFullBar: true,
        },
        hide: !hasRunStopped,
        resizable: true,
        autoHeight: true,
    },
]

export const getQCMetricsColumnDefs = (runId: string, quadrants: string[]): ColDef[] => [
    {
        headerName: 'Run %',
        field: 'run_percent',
        pinned: 'left',
        width: 80,
        cellStyle: { 'font-weight': 'bold' },
    },
    {
        headerName: 'Browse Cells',
        pinned: 'left',
        width: 100,
        headerComponentParams: {
            template: headerTemplate,
        },
        valueGetter: (params: ValueGetterParams) => {
            const page = quadrants.findIndex((quadrant) => quadrant === params.data.run_percent)
            return `${ROUTES.CELL_BROWSING}?activeTab=search&centerCrop=0&page=${page}&runIds=${runId}&sharpen=0&showSortFields=1&sortOrderKey=SAMPLE_DISTRIBUTION`
        },
        cellRendererFramework: HyperlinkCellComponent,
        cellStyle: { textAlign: 'center' },
    },
    {
        headerName: 'Partial/ Cut off Images',
        field: 'cutoff_image',
        width: 120,
        headerComponentParams: {
            template: headerTemplate,
        },
        editable: true,
    },
    {
        headerName: 'Contamination Covering Images',
        field: 'contamination_covering_image',
        width: 150,
        headerComponentParams: {
            template: headerTemplate,
        },
        editable: true,
    },
    {
        headerName: 'Chip Covering Blemish Images',
        field: 'chip_blemish_covering_image',
        width: 150,
        headerComponentParams: {
            template: headerTemplate,
        },
        editable: true,
    },
    {
        headerName: 'Blebbing Images',
        field: 'blebbing_image',
        width: 100,
        headerComponentParams: {
            template: headerTemplate,
        },
        editable: true,
    },
    {
        headerName: 'Cell Debris Images',
        field: 'cell_debris',
        minWidth: 120,
        flex: 1,
        headerComponentParams: {
            template: headerTemplate,
        },
        editable: true,
    },
]

export const getCellTypeColumnDefs = (runId: string): ColDef[] => [
    {
        headerName: 'Cell Type',
        field: 'cell_type',
        width: 180,
    },
    {
        headerName: 'Sorted cells',
        field: 'count',
        flex: 1,
        cellRendererFramework: ProgressCellComponent,
        cellRendererParams: {
            calculateTotal: aggSumFromString,
        },
    },
    {
        headerName: '% in Sample',
        field: 'count',
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => {
            const total = aggSumFromString(params)
            return `${Math.round((parseInt(params.value, 10) * 100) / total)}%`
        },
        cellStyle: { textAlign: 'center' },
    },
    {
        headerName: 'Browse Details',
        field: 'cell_type',
        width: 120,
        valueGetter: (params: ValueGetterParams) =>
            `${
                ROUTES.CELL_BROWSING
            }?sortOrderKey=RUN_ID_ASC&runIds=${runId}&predictedClasses=${String(
                params.data.cell_type
            ).replace('CellType.', '')}`,
        cellRendererFramework: HyperlinkCellComponent,
        cellStyle: { textAlign: 'center' },
    },
]

/**
 * Transform SortModelItem to a API "order_by" string param.
 * For example [ { colId: 'project_code', sort: 'asc' }, { colId: 'start_time', sort: 'desc' } ] will result in:
 * “order_by=project_code:asc,start_time:desc”.
 * And it will sort ascending by project code first, then descending by start time.
 * @function sortModelItemToOrderBy
 * @param sorts Array<SortModelItem> | The SortModelItem to transform.
 */
export function sortModelItemToOrderBy(sorts: Array<SortModelItem>): string | null {
    try {
        // try to reduce each sortModelItem to a plain string that will be use in
        // the API call as a "order_by" param, in the form : “order_by=sorts[0].colId:sorts[0].sort,sorts[1].colId:sorts[1].sort”
        return sorts.map((s) => `${s.colId}:${s.sort}`).join(',')
    } catch {
        return null
    }
}
