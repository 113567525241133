import EditTextPopover from 'components/shared/EditTextPopover'
import { useState } from 'react'
import { PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'

interface PinnedImagesPopoverProps {
    anchorEl: HTMLElement | null
    pinnedCell: PinnedCellGroup
    onClose: () => void
}
export const EditPinnedNamePopover = ({
    anchorEl,
    pinnedCell,
    onClose,
}: PinnedImagesPopoverProps): JSX.Element => {
    const {
        updatePinnedName,
        cellVisualizations: { pinnedCells },
    } = useCellVisualizationsSlice()
    const [error, setError] = useState('')
    const { id, name } = pinnedCell

    const handleClose = () => {
        setError('')
        onClose()
    }

    const handleNameChangeSave = (newName?: string) => {
        if (pinnedCells?.some((x) => x.name === newName)) {
            setError('Name already exists')
        } else {
            updatePinnedName({ pinnedGroupId: id, name: newName ?? '' })
            handleClose()
        }
    }

    return (
        <EditTextPopover
            anchorEl={anchorEl}
            textBeingEdited={name}
            onSave={handleNameChangeSave}
            onClose={handleClose}
            textFieldProps={{ error: !!error, helperText: error }}
            closeOnSave={false}
        />
    )
}

export default EditPinnedNamePopover
