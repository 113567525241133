/* eslint-disable no-await-in-loop */
import JSZip from 'jszip'
import useReadCSV from 'utils/useReadCSV'
import { CellInfo } from './types'

export type ArrayOfObjects = Record<string, string>[]
export type AllZipData = {
    cellsJson: ArrayOfObjects
    embeddingsJson: ArrayOfObjects
    projectionsJson: ArrayOfObjects
    labelsJson: ArrayOfObjects
}

export const TSV_COLUMNS = [
    'CELL_ID',
    'RUN_ID',
    'UMAP_0',
    'UMAP_1',
]

export const CSV_COLUMNS = [
    'cell_id_hash',
    'run_id',
    'proj_0',
    'proj_1',
]

const renameKeys = (obj: Record<string, string>) => {
    const newObj = {} as CellInfo
    const oldToNewKey: { [key: string]: keyof CellInfo } = {
        cell_id_hash: 'CELL_ID',
        prediction: 'LABEL_NAME',
        // '': 'LABEL_ID',
        instrument: 'INSTRUMENT_NAME',
        run_id: 'RUN_ID',
        sample_id: 'SAMPLE_ID',
        // '': 'SAMPLE_TYPE',
        proj_0: 'UMAP_0',
        proj_1: 'UMAP_1',
    }

    Object.entries(oldToNewKey).forEach(([oldKey, newKey]) => {
        newObj[newKey] = obj[oldKey]
    })

    return newObj
}

export const validateCellInfo = (data: CellInfo): string => {
    let error = ''

    const missingColumn = TSV_COLUMNS.find((column) => !data[column as keyof typeof data])
    if (missingColumn) {
        error = `Column ${missingColumn} is mandatory but it's missing in the uploaded file`
    }

    return error
}

export const validateZipContent = (data: AllZipData): string => {
    const { cellsJson, embeddingsJson, labelsJson, projectionsJson } = data

    // Check if all files are defined
    if (
        !(cellsJson.length && embeddingsJson.length && labelsJson.length && projectionsJson.length)
    ) {
        return 'Uploaded files does not follow the expected format'
    }

    // Check if lengths are matching each other
    if (
        !(cellsJson.length === embeddingsJson.length && cellsJson.length === projectionsJson.length)
    ) {
        return 'Number of records in files do not match'
    }

    const item = { ...cellsJson[0], ...embeddingsJson[0], ...projectionsJson[0] }
    const missingColumn = CSV_COLUMNS.find((column) => !item[column as keyof typeof item])
    if (missingColumn) {
        return `Column ${missingColumn} is mandatory but it's missing in the uploaded file. Please add to one of the files inside the .zip`
    }

    return ''
}

const useTsvFileLogic = () => {
    const readCSV = useReadCSV()

    const parseString = async (content: string): Promise<ArrayOfObjects> => {
        const data = (await readCSV(content)) as ArrayOfObjects // convert string to list of objects
        return data.slice(0, data.length - 1) // remove last array item
    }

    return {
        tsvRawDataToCellInfo: async (rawData: string) => {
            const data = (await readCSV(rawData)) as CellInfo[]

            const error = validateCellInfo(data[0])

            return error || data
        },
        zipRawDataToCellInfo: async (rawData: ArrayBuffer) => {
            const newZip = new JSZip()
            const unzipped = await newZip.loadAsync(rawData)

            // Variables to hold parsed data
            let cellsJson: ArrayOfObjects = []
            let embeddingsJson: ArrayOfObjects = []
            let projectionsJson: ArrayOfObjects = []
            let labelsJson: ArrayOfObjects = []

            const fileNames = Object.keys(unzipped.files).filter((n) => !n.startsWith('_'))

            // can't use fileNames.forEach here because they'll run in parallel and the awaits get out of sync
            for (let i = 0; i < fileNames.length; i += 1) {
                const name = fileNames[i]
                const content = await unzipped.files[name].async('string')
                const parsedContent = await parseString(content)
                if (name.startsWith('umap_using_predictions_report-embeddingsbyprediction')) {
                    cellsJson = parsedContent
                } else if (name.startsWith('umap_using_predictions_report-query')) {
                    labelsJson = parsedContent
                } else if (name.startsWith('umap_using_predictions_report-python_input')) {
                    if (content.startsWith('proj_0')) {
                        projectionsJson = parsedContent
                    } else {
                        embeddingsJson = parsedContent
                    }
                }
            }

            const error = validateZipContent({
                cellsJson,
                embeddingsJson,
                labelsJson,
                projectionsJson,
            })

            if (error) return error

            const finalData = []
            for (let i = 0; i < cellsJson.length; i += 1) {
                const data = { ...cellsJson[i], ...embeddingsJson[i], ...projectionsJson[i] }
                finalData.push(renameKeys(data))
            }
            return finalData
        },
    }
}

export const useTsvFile = (): ReturnType<typeof useTsvFileLogic> => {
    return useTsvFileLogic()
}

export default useTsvFile
