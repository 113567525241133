import { GridSizeChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ReactElement, useEffect, useState } from 'react'
import { Run, WellConfiguration, WellCount, WellMetric } from '../../utils/api'
import { getSortByWellColumnDefs } from './metadata'

interface IWellResultsGridProps {
    run: Run
}

type Well = WellConfiguration & WellCount & WellMetric

const WellResultsGrid = ({ run }: IWellResultsGridProps): ReactElement => {
    const [wells, setWells] = useState<Well[]>([])

    useEffect(() => {
        const configurations = run.well_sorting_configurations ?? []
        const counts = run.well_counts ?? []
        const metrics = run.well_metrics ?? []

        const countByWellId = counts.reduce((obj: Record<number, WellCount>, item: WellCount) => {
            // eslint-disable-next-line no-param-reassign
            obj[item.well] = item
            return obj
        }, {})

        const metricsByWellId = metrics.reduce(
            (obj: Record<number, WellMetric>, item: WellMetric) => {
                // eslint-disable-next-line no-param-reassign
                obj[item.well] = item
                return obj
            },
            {}
        )

        const data = configurations.map((config: WellConfiguration) => ({
            ...config,
            ...(countByWellId[config.well] ?? {}),
            ...(metricsByWellId[config.well] ?? {}),
        }))

        setWells(data)
    }, [run])

    const onGridSizeChange = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit()
    }

    return (
        <div
            style={{ width: '100%' }}
            className="ag-theme-balham ag-theme-deepcell"
            data-testid="well-result-grid"
        >
            {/* @TODO: Need to find way for making the extra blank row or find a way to set fixed height. */}
            <AgGridReact
                domLayout="autoHeight"
                rowData={wells}
                columnDefs={getSortByWellColumnDefs(run?.stopped)}
                onGridSizeChanged={onGridSizeChange}
            />
        </div>
    )
}

export default WellResultsGrid
