import CheckIcon from '@mui/icons-material/Check'
import { Box, Stack, styled } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { CATEGORICAL_COLOR_PALETTE } from 'components/cell-visualizations/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import useFlags from 'components/shared/useFlags'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import Plot from 'react-plotly.js'
import { useWindowSize } from 'react-use'
import { LabelColor } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useElementById from 'utils/useElementById'
import useFooterHeight from 'utils/useFooterHeight'
import { useCellsData } from '../plot/useCellsData'
import LegendItem from './LegendItem'

interface StyledToggleButtonProps {
  bgcolor: string
}

const StyledToggleButton = styled(ToggleButton)(({ bgcolor }: StyledToggleButtonProps) => ({
  borderRadius: 0,
  '&.Mui-disabled': {
    border: 0,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
    backgroundColor: bgcolor,
  },
  width: '100%',
  height: 50,
  backgroundColor: bgcolor,
  cursor: 'pointer',
}))

const Legend = (): JSX.Element => {
  const { cellVisualizations, setLegendLabelColors } = useCellVisualizationsSlice()
  const { groupByOption, legendLabelColors } = cellVisualizations
  const [colorChangeCount, setColorChangeCount] = useState(0)
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null)
  const [legendLabelsObj, setLegendLabelsObj] = useState<LabelColor[]>([])
  const [selectedColor, setSelectedColor] = useState<string>('')
  const [preSelectedColors, setPreSelectedColors] = useState<string[]>()
  const eventsManager = useEventsManager()
  const { cellVisualizationsColorPickerEnabled } = useFlags()
  const footerHeight = useFooterHeight()
  const { rect } = useElementById('color-by')
  const { height: windowHeight } = useWindowSize()
  const innerHeight = windowHeight - (rect?.bottom ?? 0) - footerHeight - 50

  const extractSelectedColorList = useCallback(() => {
    const newSelectedColorList: string[] = []
    legendLabelColors.forEach((item) => {
      newSelectedColorList.push(item.color)
    })
    return newSelectedColorList
  }, [legendLabelColors])

  useEffect(() => {
    setLegendLabelsObj(legendLabelColors)
    setPreSelectedColors(extractSelectedColorList())
  }, [legendLabelColors, extractSelectedColorList])

  const handleColorChange = (_event: React.MouseEvent, newColor: string | null) => {
    if (newColor !== null) {
      setSelectedColor(newColor)
    }
  }

  const handleOnClick = (e: React.MouseEvent) => {
    setShowColorPicker(true)
    setSelectedColor('')
    setPopoverAnchor(e.currentTarget as HTMLElement)
  }

  const onPopoverClose = () => {
    if (selectedColor) {
      if (popoverAnchor !== null) {
        popoverAnchor.style.backgroundColor = selectedColor
      }
      eventsManager.sendCustomColorEvent(colorChangeCount + 1)
      setColorChangeCount((prev) => {
        return prev + 1
      })

      const newLegendLabelColorsArray = [...legendLabelColors]
      const label = popoverAnchor?.getAttribute('data-label')
      const itemIdx = newLegendLabelColorsArray.findIndex((item) => item.name === label)
      const mutableObj = { ...newLegendLabelColorsArray[itemIdx] }
      mutableObj.color = selectedColor
      newLegendLabelColorsArray[itemIdx] = mutableObj

      setLegendLabelColors(newLegendLabelColorsArray)
      setShowColorPicker(false)
    } else {
      setShowColorPicker(false)
    }
  }

  const legendOutput = groupByOption.isContinuous
    ? _.sortBy(_.uniqBy(legendLabelsObj, 'color'), 'name')
    : legendLabelsObj

  const { plotData } = useCellsData({ showColorScale: true })

  return (
    <Box>
      <Stack spacing={2} sx={{ height: innerHeight, overflow: 'auto' }}>
        {groupByOption.isContinuous ? (
          <Plot
            data={[...plotData.map((x) => ({ ...x, marker: { ...x.marker, opacity: 0 } }))]}
            layout={{
              showlegend: false,
              width: 150,
              height: innerHeight,
              margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
              xaxis: { visible: false, showgrid: false, showline: false },
              yaxis: { visible: false, showgrid: false, showline: false },
              clickmode: 'none',
            }}
            config={{
              staticPlot: true,
              displayModeBar: false,
              scrollZoom: false,
              autosizable: false,
              displaylogo: false,
              editable: false,
            }}
          />
        ) : (
          legendOutput.map((item: LabelColor) => (
            <LegendItem
              key={item.name}
              bgColor={item.color}
              label={`${item.name}`} // name is sometimes a bigint
              onClick={cellVisualizationsColorPickerEnabled === 'yes' ? handleOnClick : undefined}
              dataLabel={item.name}
            />
          ))
        )}
      </Stack>
      <DeepcellPopover
        open={showColorPicker}
        onClose={() => onPopoverClose()}
        anchorEl={popoverAnchor}
        data-testid="legend-color-picker"
      >
        <ToggleButtonGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 52px)',
            border: '1px solid black',
          }}
          value={selectedColor}
          exclusive
          onChange={handleColorChange}
          aria-label="color-picker"
        >
          {CATEGORICAL_COLOR_PALETTE.map((item: string) => (
            <StyledToggleButton
              key={item}
              style={{
                border: `${item === selectedColor ? '2px solid white' : 'none'}`,
              }}
              bgcolor={item}
              value={item}
              aria-label={item}
              disabled={preSelectedColors?.includes(item)}
            >
              {item === selectedColor ? <CheckIcon sx={{ color: 'white' }} /> : null}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      </DeepcellPopover>
    </Box>
  )
}

export default Legend
