import React from 'react'

/**
 * Simply wraps the children in a div and exposes the ref to them.
 */
export const RefWrapper = React.forwardRef(
    ({ children }: React.PropsWithChildren<unknown>, ref: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <div style={{ display: 'inline-block' }} ref={ref}>
                {children}
            </div>
        )
    }
)

export default RefWrapper
