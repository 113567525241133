import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import CompactTablePagination from 'components/shared/CompactTablePagination'
import { ChangeEvent, useState } from 'react'
import { imageCountDefault, PaginationParams } from 'redux/slices'

interface CellImagePaginationProps {
    count: number
    onPaginationChange: (s: PaginationParams) => void
    defaultPage?: number
    defaultItemsPerPage?: number
}

const CellsPerPageIcon = () => (
    <Tooltip title="Cells per page" placement="top-start">
        <InfoOutlinedIcon color="info" sx={{ height: '.8rem', cursor: 'pointer' }} />
    </Tooltip>
)

export const CellImagePagination = ({
    count,
    onPaginationChange,
    defaultPage,
    defaultItemsPerPage,
}: CellImagePaginationProps): JSX.Element => {
    const [page, setPage] = useState(defaultPage ?? 0)
    const [cellsPerPage, setItemsPerPage] = useState(defaultItemsPerPage ?? imageCountDefault)

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
        onPaginationChange({ page: newPage, cellsPerPage })
    }

    const handleChangeItemsPerPage = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(+value)
        setPage(0)
        onPaginationChange({ page: 0, cellsPerPage: +value })
    }

    return (
        <CompactTablePagination
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={cellsPerPage}
            onRowsPerPageChange={handleChangeItemsPerPage}
            labelRowsPerPage={<CellsPerPageIcon />}
        />
    )
}

export default CellImagePagination
