/*
@TODO: When the selection box is really small on one axis, the width or height shoot up to 100%
 */

/**
 * The small distance a mouse can move and still be considered a click.
 * Without this the user would need to press the mouse down and
 * up without moving the mouse a single pixel
 */
export const CLICK_DELTA = 5

/**
 * A single point on a 2D space
 */
interface Point {
    x: number
    y: number
}

/**
 * A rendered box in 2D space
 */
export interface BoxElement {
    top?: number
    left?: number
    width?: number
    height?: number
}

/**
 * A 2D box with a unique id associated with it to differentiate it from other boxes
 */
export interface UniqueBoxElement extends BoxElement {
    id: string
}

/**
 * A rendered box with a fixed starting point
 */
export interface SelectionBoxCoordinates extends BoxElement {
    startPoint?: Point
}
/**
 * Checks if a user simply clicked vs clicked and dragged
 */
export const isClick = (startPoint: Point, endPoint: Point): boolean => {
    const deltaX = Math.abs(startPoint.x - endPoint.x)
    const deltaY = Math.abs(startPoint.y - endPoint.y)

    return deltaX < CLICK_DELTA && deltaY < CLICK_DELTA
}

/**
 * Given a two points in 2D space, return a box with those points as its diagonals
 */
export const getBox = (startPoint: Point, diagonalPoint: Point): BoxElement => {
    const width = Math.abs(startPoint.x - diagonalPoint.x)
    const height = Math.abs(startPoint.y - diagonalPoint.y)
    return {
        top: Math.min(startPoint.y, diagonalPoint.y),
        left: Math.min(startPoint.x, diagonalPoint.x),
        width: width < 3 ? 3 : width,
        height: height < 3 ? 3 : height,
    } as BoxElement
}

/**
 * Check if two boxes in 2D space overlap in any way
 */
export const boxesCollide = (box1: BoxElement, box2: BoxElement): boolean => {
    if (
        box1.left === undefined ||
        box2.left === undefined ||
        box1.top === undefined ||
        box2.top === undefined ||
        box1.width === undefined ||
        box2.width === undefined ||
        box1.height === undefined ||
        box2.height === undefined
    ) {
        return false
    }

    return (
        box1.left <= box2?.left + box2.width &&
        box1.left + box1.width >= box2.left &&
        box1.top <= box2.top + box2.height &&
        box1.top + box1.height >= box2.top
    )
}

/**
 * Given an array of 2D boxes with unique ids and a single floating box over them, return the
 * boxes that are overlapped by the floating box
 */
export const getHoveredOverElements = (
    elementBoxes: UniqueBoxElement[],
    selectionBox: BoxElement
): UniqueBoxElement[] => elementBoxes.filter((element) => boxesCollide(selectionBox, element))
