import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Subset of the values for Plotly's dragmode/modebar that we want to support
// these strings are the datatitles for plotly's modebar <a> elements
export const modebarDragActions = ['Pan', 'Box Select', 'Lasso Select'] as const
export type ModebarDragAction = typeof modebarDragActions[number]

export const modebarEventActions = [
    'Download plot as a png',
    'Autoscale',
    'Zoom out',
    'Zoom in',
] as const
export type ModebarEventAction = typeof modebarEventActions[number]

export type ModebarAction = ModebarEventAction | ModebarDragAction | undefined

interface PlotlyState {
    dragMode: ModebarDragAction
}

const plotlySliceInitialState: PlotlyState = {
    dragMode: 'Pan',
}

export const plotlySlice = createSlice({
    name: 'plotly',
    initialState: plotlySliceInitialState,
    reducers: {
        setDragMode: (state, action: PayloadAction<ModebarDragAction>) => {
            state.dragMode = action.payload
        },
    },
})

export const PlotlyActions = plotlySlice.actions
export const PlotlyReducer = plotlySlice.reducer
