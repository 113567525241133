import { LabelingTask } from '@deepcell/cell_data_api_proto'
import DeepcellDatePicker from 'components/shared/DeepcellDatePicker'
import OkCancelDialogBody, { useBatchUpdate } from 'components/shared/OkCancelDialogBody'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { LabelingTaskWithActions, updateLabelingTask } from 'utils/api'
import { roundDueDate } from 'utils/proto-utils'

moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
}

function SetAssigneePopover(props: Props): JSX.Element {
    const { handleClose, tasks } = props

    // get the first due date
    const initialDueDate = _.min(tasks.map((task: LabelingTask) => roundDueDate(task)))

    const [dueDate, setDueDate] = useState<Date | undefined>(initialDueDate)

    const [updatingTasks, handleBatchUpdate] = useBatchUpdate(
        tasks,
        (task: LabelingTask) => {
            const timestamp = moment(dueDate).valueOf()
            task.setDueDate(timestamp)
        },
        updateLabelingTask,
        handleClose
    )

    return (
        <OkCancelDialogBody
            data-testid="dueDatePopoverOkCancelDialogBody"
            pending={updatingTasks}
            handleButtonClick={handleBatchUpdate}
        >
            <DeepcellDatePicker
                label="Due Date"
                value={dueDate as Date}
                onChange={(date) => date && setDueDate(date)}
                autoFocus
            />
        </OkCancelDialogBody>
    )
}

export default SetAssigneePopover
