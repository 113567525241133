import { LabelingTaskStatus } from '@deepcell/cell_data_api_proto'
import { Chip, Divider, SelectChangeEvent, Stack, styled } from '@mui/material'
import { DeepcellPrimaryButton, DeepcellPrimarySelect, DeepcellTextField } from 'components/shared/'
import { ChangeEvent, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { defaultLabelingTaskFilter, LabelingTaskFilter } from 'redux/slices'
import useLabelingTasksSlice from 'redux/slices/hooks/useLabelingTasksSlice'
import DeepcellModal from '../../shared/DeepcellModal'

const SHORTCUT_HINTS = (
    <>
        <p>
            <Chip label="Enter" /> - runs search, if you are in one of the search fields
        </p>
        <p>
            <Chip label="Ctrl + f" /> - quick selects the Keyword Search field
        </p>
    </>
)

const FormRoot = styled('form')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
}))

const statusOptions = [
    {
        output: 'New',
        key: LabelingTaskStatus.TASK_STATUS_NEW,
    },
    {
        output: 'Preparing',
        key: LabelingTaskStatus.TASK_STATUS_PREPARING,
    },
    {
        output: 'Ready',
        key: LabelingTaskStatus.TASK_STATUS_READY,
    },
    {
        output: 'In Progress',
        key: LabelingTaskStatus.TASK_STATUS_IN_PROGRESS,
    },
    {
        output: 'In Review',
        key: LabelingTaskStatus.TASK_STATUS_IN_REVIEW,
    },
    {
        output: 'Review complete',
        key: LabelingTaskStatus.TASK_STATUS_DONE_REVIEW,
    },
    {
        output: 'Approved',
        key: LabelingTaskStatus.TASK_STATUS_DONE,
    },
    {
        output: 'Deleted',
        key: LabelingTaskStatus.TASK_STATUS_DELETED,
    },
]

function LabelingTasksFilters(): JSX.Element {
    const {
        labeling: { projects, labelingTaskFilter: labelingTaskFilterRaw },
        setLabelingTaskFilter,
    } = useLabelingTasksSlice()
    const labelingTaskFilter = labelingTaskFilterRaw ?? defaultLabelingTaskFilter
    const keywordSearchRef = useRef<HTMLInputElement>(null)

    useHotkeys(
        'control+f, ctrl+f',
        (e) => {
            e.preventDefault()
            keywordSearchRef.current?.focus()
        },
        {},
        []
    )

    const handleChange =
        (key: keyof LabelingTaskFilter) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
            const val = e.target.value
            const newLabelingTaskFilter = {
                ...labelingTaskFilter,
                [key]: key === 'assignedToMe' ? val === 'true' : val,
            } as LabelingTaskFilter
            setLabelingTaskFilter(newLabelingTaskFilter)
        }

    return (
        <FormRoot>
            <Stack spacing={2}>
                <DeepcellTextField
                    inputRef={keywordSearchRef}
                    label="Keyword Search (All Fields)"
                    value={labelingTaskFilter.keywordSearch}
                    data-testid="keywordSearch"
                    onFocus={(e) => e.currentTarget.select()}
                    onChange={handleChange('keywordSearch')}
                />
                <DeepcellPrimarySelect
                    label="Project"
                    items={[{ value: '', output: <em>Any</em> }, ...projects]}
                    value={labelingTaskFilter.projectName}
                    onChange={handleChange('projectName')}
                />
                <DeepcellPrimarySelect
                    label="Statuses"
                    data-testid="statuses"
                    items={[{ value: -1, output: <em>All</em> }, ...statusOptions]}
                    value={labelingTaskFilter.status}
                    onChange={handleChange('status')}
                />
                <DeepcellPrimarySelect
                    label="Assigned To"
                    data-testid="assigned-to"
                    items={[
                        { key: 'false', output: 'Assigned to anyone' },
                        { key: 'true', output: 'Assigned to me' },
                    ]}
                    value={labelingTaskFilter.assignedToMe}
                    onChange={handleChange('assignedToMe')}
                />
                <Divider />

                <DeepcellModal
                    title="Shortcuts"
                    content={SHORTCUT_HINTS}
                    modalTriggeringButton={
                        <DeepcellPrimaryButton small>Show keyboard shortcuts</DeepcellPrimaryButton>
                    }
                />
            </Stack>
        </FormRoot>
    )
}

export default LabelingTasksFilters
