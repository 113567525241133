import { AgGridReact } from 'ag-grid-react'
import { ReactElement } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { Run, RunQualityMetric, RunQualityMetrics } from 'utils/api'
import StatusCellComponent from './StatusCellComponent'

interface IQCStatusGrid {
    run: Run
}

const TOTAL_IMAGE_COUNT = 800

const getCellImageQuality = (qualityMetrics: RunQualityMetrics) => {
    const values = Object.values(qualityMetrics)
    const reduceValuesToCellImageQuality = values.reduce(
        (previousValue: number, currentValue: RunQualityMetric) =>
            previousValue + currentValue.blebbing_image,
        0
    )
    return reduceValuesToCellImageQuality / TOTAL_IMAGE_COUNT
}

const getSampleQuality = (qualityMetrics: RunQualityMetrics) => {
    const values = Object.values(qualityMetrics)
    const reduceValuesToSampleQuality = values.reduce(
        (previousValue: number, currentValue: RunQualityMetric) =>
            previousValue + (currentValue.contamination_covering_image + currentValue.cell_debris),
        0
    )
    return reduceValuesToSampleQuality / TOTAL_IMAGE_COUNT
}

const getSystemQuality = (qualityMetrics: RunQualityMetrics) => {
    const values = Object.values(qualityMetrics)
    const reduceValuesToSystemQuality = values.reduce(
        (previousValue: number, currentValue: RunQualityMetric) =>
            previousValue + (currentValue.cutoff_image + currentValue.chip_blemish_covering_image),
        0
    )
    return reduceValuesToSystemQuality / TOTAL_IMAGE_COUNT
}

export default function QCStatusGrid(props: IQCStatusGrid): ReactElement {
    const { run } = props
    const { run_quality_metrics: runQualityMetrics, run_quality_score: runQualityScore } = run

    if (runQualityMetrics === undefined) return <></>

    const OverallRunQuality = runQualityScore ?? 0
    const CellImageQuality = getCellImageQuality(runQualityMetrics) ?? 0
    const SampleQuality = getSampleQuality(runQualityMetrics) ?? 0
    const SystemQuality = getSystemQuality(runQualityMetrics) ?? 0

    return (
        <div
            style={{ width: '100%' }}
            className="ag-theme-balham ag-theme-deepcell"
            data-testid="qc-status-grid"
        >
            <AgGridReact
                domLayout="autoHeight"
                defaultColDef={{
                    autoHeight: true,
                    suppressMenu: true,
                }}
                columnDefs={[
                    {
                        headerName: 'Overall Run Quality',
                        field: 'label',
                        pinned: 'left',
                        lockPinned: true,
                        flex: 1,
                        cellClass: 'lock-pinned',
                    },
                    {
                        headerName: '',
                        field: 'values',
                        headerComponentFramework: () => StatusCellComponent(OverallRunQuality),
                        flex: 3,
                        cellRendererFramework: (params: ICellRendererParams) =>
                            StatusCellComponent(params.value),
                    },
                ]}
                rowData={[
                    { label: 'Cell Image Quality', values: CellImageQuality },
                    { label: 'Sample Quality', values: SampleQuality },
                    { label: 'System Quality', values: SystemQuality },
                ]}
            />
        </div>
    )
}
