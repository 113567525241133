import { Card, Stack, Typography } from '@mui/material'
import PlotCellImage from 'components/cell-visualizations/PlotCellImage'
import useSessionApi from 'components/cell-visualizations/sessionSelect/useSessionApi'
import { DeepcellDialog, DeepcellTextField } from 'components/shared'
import moment from 'moment'
import { useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import {
  ClassifierSuccessOutput,
  TrainClassifierSuccessDialog,
} from './TrainClassifierSuccessDialog'

export interface TrainClassifierDialogProps {
  open?: boolean
  onTrainClassifier?(): void
  onClose?(): void
}
export const TrainClassifierDialog = (props: TrainClassifierDialogProps): JSX.Element => {
  const { open, onTrainClassifier, onClose } = props

  const { saveVersionConfig } = useSessionApi()

  const {
    getCellInfoByCellId,
    mergedPinnedCellGroups,
    cellVisualizations: { projectCode, name },
  } = useCellVisualizationsSlice()
  const pinnedCellInfo = mergedPinnedCellGroups?.map((pc) => ({
    name: pc.name,
    cellId: getCellInfoByCellId(`${pc.cells.points ? pc.cells.points[0].customdata : ''}`).CELL_ID,
  }))

  const [classifierName, setClassifierName] = useState('')
  const [error, setError] = useState('')
  const [successOutput, setSuccessOutput] = useState<ClassifierSuccessOutput>()

  const handleClose = () => {
    setError('')
    setClassifierName('')
    if (onClose) onClose()
  }

  const handleTrainClassifierClick = async () => {
    if (!classifierName) {
      setError('Classifier Name is required')
      return
    }

    await saveVersionConfig({ name: classifierName, projectCode })

    if (onTrainClassifier) onTrainClassifier()
    if (onClose) onClose()
    setSuccessOutput({
      classifierName,
      sessionName: name,
      trainedDate: moment().format('MMM Do YYYY, h:mm:ss a'),
    })
  }

  const onClassifierChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setError('')
    setClassifierName(e.target.value)
  }

  return (
    <>
      <DeepcellDialog
        handleCancel={handleClose}
        handleConfirm={handleTrainClassifierClick}
        open={!!open}
        okLabel="Train Classifier"
        titleLabel="Train Cell Group Classifier"
      >
        <Stack spacing={2} sx={{ width: 400, px: 1 }}>
          <Typography align="center">
            Please confirm you would like to train a classifier to sort cells on instrument using
            cell groups
          </Typography>
          <DeepcellTextField
            label="Classifier Name"
            value={classifierName}
            onChange={onClassifierChange}
            error={!!error}
            helperText={error}
          />
          <Card sx={{ p: 2 }}>
            <Typography mb={1}>Cell Groups</Typography>
            <Stack spacing={1} maxHeight={300} sx={{ overflowY: 'auto' }}>
              {pinnedCellInfo?.map((pci) => (
                <Stack
                  direction="row"
                  p={1}
                  spacing={2}
                  borderRadius={1}
                  sx={{ bgcolor: (t) => t.palette.info.light }}
                >
                  <PlotCellImage
                    cellId={pci.cellId ?? ''}
                    imgProps={{ width: 40, height: 40, style: { borderRadius: 8 } }}
                  />
                  <Typography>{pci.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Card>
          <Typography align="center" fontSize="12px" color={(t) => t.palette.text.secondary}>
            Note: Once you publish classifiers it creates a version.You can access versions from top
            navigation.
          </Typography>
        </Stack>
      </DeepcellDialog>
      <TrainClassifierSuccessDialog
        open={!!successOutput}
        onClose={() => setSuccessOutput(undefined)}
        output={successOutput}
      />
    </>
  )
}

export default TrainClassifierDialog
