import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Typography,
} from '@mui/material'
import React from 'react'
import DeepcellPrimaryButton from './DeepcellPrimaryButton'

export interface DeepcellDialogProps extends DialogProps {
    titleLabel?: string
    okLabel?: string
    cancelLabel?: string
    showCancelButton?: boolean
    description?: string
    loadingButtonState?: boolean
    id?: string
    loadingButton?: boolean
    handleCancel?: () => void
    handleConfirm?: () => void
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        border: 'none',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(2),
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '25px',
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        paddingBottom: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export const DeepcellDialog = ({
    titleLabel,
    cancelLabel,
    showCancelButton = true,
    okLabel,
    children,
    loadingButtonState,
    loadingButton,
    description,
    handleCancel,
    handleConfirm,
    ...props
}: DeepcellDialogProps): JSX.Element => {
    return (
        <StyledDialog onClose={handleCancel} {...props}>
            {titleLabel && <DialogTitle id="alert-dialog-title">{titleLabel}</DialogTitle>}
            <DialogContent>
                {description && (
                    <DialogContentText id="alert-dialog-description">
                        <Typography component="span">{description}</Typography>
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions>
                {showCancelButton ? (
                    <DeepcellPrimaryButton outlined onClick={handleCancel}>
                        {cancelLabel ?? 'Cancel'}
                    </DeepcellPrimaryButton>
                ) : null}

                {loadingButton ? (
                    <LoadingButton
                        loading={loadingButtonState}
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                    >
                        {okLabel ?? 'Ok'}
                    </LoadingButton>
                ) : (
                    <DeepcellPrimaryButton contained onClick={handleConfirm}>
                        {okLabel ?? 'Ok'}
                    </DeepcellPrimaryButton>
                )}
            </DialogActions>
        </StyledDialog>
    )
}

export default DeepcellDialog
