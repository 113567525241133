import { useTheme } from '@mui/material'
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { ITooltipParams } from 'ag-grid-community/dist/lib/rendering/tooltipComponent'
import Moment from 'moment'
import React from 'react'
import { LabelingTaskWithActions } from 'utils/api'

export const useDueDateAgGridColumn = (): {
    dueDateCellStyle(params: CellClassParams): React.CSSProperties
    dueDateTooltip(params: ITooltipParams): string
} => {
    const theme = useTheme()

    const PAST_DUE = 'past'
    const ALMOST_DUE = 'almost'

    function almostDue(task: LabelingTaskWithActions): string {
        const dueDate = task.labelingTask.getDueDate()
        if (dueDate && Moment(dueDate).isBefore()) {
            return PAST_DUE
        }
        if (dueDate && Moment(dueDate).subtract(1, 'week').isBefore()) {
            return ALMOST_DUE
        }
        return ''
    }

    function dueDateCellStyle(params: CellClassParams) {
        const almostDueCategory = almostDue(params.data)
        let color = 'none'
        if (almostDueCategory === PAST_DUE) {
            color = theme.palette.error.light
        } else if (almostDueCategory === ALMOST_DUE) {
            color = theme.palette.warning.light
        }
        return {
            backgroundColor: color,
        } as React.CSSProperties
    }

    function dueDateTooltip(params: ITooltipParams) {
        const almostDueCategory = almostDue(params.data)
        if (almostDueCategory === PAST_DUE) return 'Task is past due date'
        if (almostDueCategory === ALMOST_DUE) return 'Task is almost due'
        return ''
    }

    return { dueDateCellStyle, dueDateTooltip } as const
}

export default useDueDateAgGridColumn
