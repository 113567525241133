import useFlags from 'components/shared/useFlags'
import { startCase } from 'lodash'
import { useMemo } from 'react'
import { CellDataField, GROUP_RUN_INFORMATION, getDataFields, legacyDataFields } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { MorphometricFeatureGroup } from 'utils/constants'
import { CellInfo } from './tsv/types'

const isCategoryField = /^CATEGORY_[0-9]_.*$/

const toLowerStart = (s: string) => startCase(s.toLowerCase())

/**
 * Filters list of available data fields present in the dataset.
 * Try to use this hook for any purpose where you need a
 *
 * @TODO Currently this is based on a hard-coded schema from getDataFields()
 * Change this to depend on a schema defined in the session later
 *
 * @returns Data fields in the dataset
 * {
 *      dataFields: DataField[] // all data fields
 *      quantitativeDataFields: DataField[] // all data fields that are quantitative (i.e. continuous real variables)
 *      categoricalDataFields: DataField[] // all data fields that are categorical (i.e. a list of items)
 * }
 */
const useDataFieldsInDataset = (): {
  dataFields: CellDataField[]
  quantitativeDataFields: CellDataField[]
  categoricalDataFields: CellDataField[]
} => {
  const {
    cellVisualizations: { cellsData },
  } = useCellVisualizationsSlice()

  const {
    cellVisualizationsShowNewMorphometricsOrder,
    cellVisualizationsShowOnlyFeaturedMorphometrics,
  } = useFlags()

  const dataFields: CellDataField[] = useMemo(() => {
    const allDataFields =
      cellVisualizationsShowNewMorphometricsOrder === 'yes'
        ? getDataFields(cellVisualizationsShowOnlyFeaturedMorphometrics)
        : legacyDataFields

    const customFields = cellsData
      ? Object.keys(cellsData[0]).flatMap((cd) => {
          const field = cd as keyof CellInfo
          const fieldOutput = field.replace(/CATEGORY_._/, '').replace('_', ' ')
          if (isCategoryField.test(field)) {
            return {
              isContinuous: false,
              label: toLowerStart(fieldOutput),
              attribute: field,
              group: toLowerStart(MorphometricFeatureGroup.CUSTOM),
            } as CellDataField
          }
          return []
        })
      : []

    const allCellDataFields = allDataFields.flatMap((option: CellDataField) => {
      const output: CellDataField[] = []
      if (cellsData && cellsData.length >= 0) {
        // If cellsData is defined, only keep features that are in the data
        const firstCell = cellsData[0]
        const attribute = option.attribute as keyof CellInfo

        if (attribute in firstCell) {
          const value = firstCell[attribute]
          if (value !== undefined && value !== '') {
            output.push(option)
          }
        }
      }
      return output
    })

    const lastRunInfoIndex = allCellDataFields.reduce(
      (acc, cdf, i) => (cdf.group === GROUP_RUN_INFORMATION ? i : acc),
      -1
    )
    if (lastRunInfoIndex > -1) {
      allCellDataFields.splice(lastRunInfoIndex + 1, 0, ...customFields)
    } else {
      // if Run Information isn't in there, just put it at the beginning
      allCellDataFields.splice(0, 0, ...customFields)
    }

    return allCellDataFields
  }, [
    cellVisualizationsShowNewMorphometricsOrder,
    cellVisualizationsShowOnlyFeaturedMorphometrics,
    cellsData,
  ])

  return {
    dataFields,
    quantitativeDataFields: dataFields.filter((option) => option.isContinuous),
    categoricalDataFields: dataFields.filter((option) => !option.isContinuous),
  }
}

export default useDataFieldsInDataset
