import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DeepcellDialog, { DeepcellDialogProps } from './DeepcellDialog'

interface RouterPromptProps {
    when: boolean
    dialogProps?: Omit<DeepcellDialogProps, 'open'>
}

export const RouterPrompt = (props: RouterPromptProps): JSX.Element => {
    const { when, dialogProps } = props

    const history = useHistory()

    const [showPrompt, setShowPrompt] = useState(false)
    const [currentPath, setCurrentPath] = useState('')

    const unblockRef = useRef<ReturnType<typeof history.block>>()

    function handleShowModal() {
        setShowPrompt(true)
    }

    function onCancel() {
        setShowPrompt(false)
    }

    useEffect(() => {
        // history.block expects no return value but es-lint expects every function to return a value, so it's necessary to disable es-lint here
        // eslint-disable-next-line consistent-return
        unblockRef.current = history.block((location) => {
            if (when) {
                setCurrentPath(location.pathname)
                handleShowModal()
                return false
            }
        })

        return () => unblockRef.current && unblockRef.current()
    }, [history, when])

    function handleConfirm() {
        if (unblockRef && unblockRef.current) {
            unblockRef.current()
        }
        setShowPrompt(false)
        history.push(currentPath)
    }

    return (
        <DeepcellDialog
            open={showPrompt}
            onClose={onCancel}
            handleCancel={onCancel}
            handleConfirm={handleConfirm}
            {...dialogProps}
        />
    )
}

export default RouterPrompt
