import { Datum } from 'plotly.js'
import { DeepcellPlotDatum } from 'redux/slices/types'
import { PlotRange } from '../../tsv/types'
import { DeepcellPlotData } from '../useCellsData'

export interface PlotDataRange {
    plotData?: DeepcellPlotData
    range?: PlotRange
}

export const coordsAreInRange = (x: Datum, y: Datum, range: PlotRange): boolean => {
    const rangeX1 = range?.x1 ?? -Infinity
    const rangeX2 = range?.x2 ?? Infinity
    const rangeY1 = range?.y1 ?? -Infinity
    const rangeY2 = range?.y2 ?? Infinity

    if (!x || !y) return false

    return x >= rangeX1 && x <= rangeX2 && y >= rangeY1 && y <= rangeY2
}

export const getPlotDatum = (plotData?: DeepcellPlotData): DeepcellPlotDatum[] => {
    if (!plotData) return []

    // @TODO We're using the assumption here that customdata holds a CELL_ID
    // This assumption is currently scattered in several places in the codebase with this comment
    // Refactor and abstract this away better
    const { x: xRaw, y: yRaw, customdata: customDataRaw } = plotData
    const xData = xRaw as number[]
    const yData = yRaw as number[]
    const customData = customDataRaw as string[]

    return xData.map((x, i) => ({
        x,
        y: yData[i],
        customdata: customData[i],
    }))
}

export const getVisiblePointsFromPoints = (
    points: DeepcellPlotDatum[],
    range: PlotRange
): DeepcellPlotDatum[] =>
    points.flatMap((d: DeepcellPlotDatum) => {
        const { x, y } = d
        if (x && y)
            if (coordsAreInRange(x, y, range)) {
                return d as DeepcellPlotDatum
            }

        return []
    })

export const getVisiblePoints = ({ plotData, range }: PlotDataRange): DeepcellPlotDatum[] =>
    getVisiblePointsFromPoints(getPlotDatum(plotData), range)

export default getVisiblePoints
