import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import OkCancelDialogBody, { useBatchUpdate } from 'components/shared/OkCancelDialogBody'
import { InstructionsText } from 'components/shared/sharedStyles'
import _ from 'lodash'
import Moment from 'moment'
import { ChangeEvent, FocusEvent, useState } from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { LabelingTaskWithActions, updateLabelingTask } from 'utils/api'

Moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
}

function EditInstructionsPopover(props: Props): JSX.Element {
    const { handleClose, tasks } = props

    const instructions = tasks.map((task) => task.getLabelingInstructions())
    const uniqueInstructions = _.uniq(instructions)
    const initialInstructions =
        uniqueInstructions.length === 1 && uniqueInstructions[0] !== undefined
            ? uniqueInstructions[0]
            : ''

    const [labelingInstructions, setLabelingInstructions] = useState<string>(initialInstructions)

    const [updatingTasks, handleBatchUpdate] = useBatchUpdate(
        tasks,
        (task: LabelingTask) => task.setLabelingInstructions(labelingInstructions),
        updateLabelingTask,
        handleClose
    )

    return (
        <OkCancelDialogBody pending={updatingTasks} handleButtonClick={handleBatchUpdate}>
            <InputLabel shrink htmlFor="labeling-instructions">
                Labeling Instructions
            </InputLabel>

            <Typography variant="body1">
                <InstructionsText
                    autoFocus
                    onFocus={(event: FocusEvent<HTMLTextAreaElement>) => {
                        event.target.selectionStart = event.target.value.length // eslint-disable-line
                        event.target.selectionEnd = event.target.value.length // eslint-disable-line
                    }}
                    name="labeling-instructions"
                    id="labeling-instructions"
                    minRows={3}
                    value={labelingInstructions}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        setLabelingInstructions(event.target.value)
                    }
                />
            </Typography>
        </OkCancelDialogBody>
    )
}

export default EditInstructionsPopover
