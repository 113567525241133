import { useEffect, useRef } from 'react'

/**
 * Returns the previous value of a variable from the last render.
 * You may use this to check if a variable's value changed, for example.
 * 
 * @param value The variable to get the previous value for 
 * @returns Previous value of the variable (while storing current value for the next render)
 */
export default function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
