import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Card, Chip, styled } from '@mui/material'
import React from 'react'
import { QueryClient } from 'react-query'
import { CellImageFilters } from 'utils/api'
import useCellImage, { prefetchCellImage } from 'utils/useCellImage'

const CENTER_CROP_FILTER: CellImageFilters = {
    centerCropSize: 110,
}

const SHARPEN_FILTER: CellImageFilters = {
    unsharpAmount: 2,
    unsharpRadius: 2,
}

export interface CellDisplayOptions {
    centerCrop?: boolean
    sharpen?: boolean
}

const CardRoot = styled(Card)({
    display: 'inline-flex',
    position: 'relative',
    overflow: 'visible',
    borderRadius: 0,
    boxShadow: 'none',
    flexWrap: 'wrap',
    cursor: 'pointer',
})

const CellImageChip = styled(Chip)({
    maxWidth: 'inherit',
    position: 'absolute',
    left: '2px',
    top: '2px',
    padding: '0px',
    opacity: 0.75,
    border: '1px solid',
    zIndex: 10,

    '& .MuiChip-label': {
        overflow: 'visible',
    },
})

export type CellImageAction =
    | { type: 'mouseEnterCell'; cellId: CellId }
    | { type: 'mouseLeaveCell'; cellId: CellId }
    | { type: 'clickCell'; cellId: CellId }

interface Props {
    key?: string
    cellId?: CellId
    width: number // desired image display size in pixels (assuming square)
    chipLabel?: string
    displayOptions?: CellDisplayOptions
    highlighted?: boolean
    onCellImageAction?: (action: CellImageAction) => void
}

function getFilters(displayOptions?: CellDisplayOptions): CellImageFilters {
    let filters: CellImageFilters = {}
    if (displayOptions?.centerCrop) {
        filters = CENTER_CROP_FILTER
    }
    if (displayOptions?.sharpen) {
        filters = { ...filters, ...SHARPEN_FILTER }
    }
    return filters
}

/** Converts to device pixel width for retina displays */
function getWidthForDevice(width: number): number {
    // @TODO This doesn't update if a user drags to a different monitor with a different pixel ratio
    // But this is not a high priority
    return Math.floor(width * window.devicePixelRatio)
}

/*
 * Prefetch image using the display options encoded in CellImageControl
 * Make sure you use the same QueryClient as when making the actual request.
 */
export async function prefetchForImageControl(
    queryClient: QueryClient,
    cellId: CellId,
    width: number,
    displayOptions?: CellDisplayOptions
): Promise<void> {
    await prefetchCellImage(
        queryClient,
        cellId,
        0,
        getWidthForDevice(width),
        getFilters(displayOptions)
    )
}

// This is a hack. Make it so if there's any classname then it's "enabled"
// @TODO: Figure out how to pass custom props to styled component
const HighlightedImage = styled('div')((props) =>
    props.className === 'true'
        ? {
              // Make this subtle, but enough of a difference in shape + color to be preattentive
              // So it's easy to spot highlighted cells
              border: `2px solid ${props.theme.palette.warning.main}`,
              borderRadius: props.theme.spacing(2),
              opacity: 0.7,
              filter: 'sepia(100%)',
              overflow: 'hidden',
          }
        : {}
)

/** A common control for showing a cell image at within a fixed size square */
const CellImageControl = (props: Props): JSX.Element => {
    const { cellId, width, highlighted, displayOptions, chipLabel, onCellImageAction } = props

    const { data: imageDataUrl } = useCellImage({
        cellId,
        frame: 0,
        width: getWidthForDevice(width),
        filters: getFilters(displayOptions),
    })
    const sizeStyle = {
        width: `${width}px`,
        height: `${width}px`,
    }

    if (!imageDataUrl || !cellId) {
        return <CardRoot key={cellId?.toString()} style={sizeStyle} />
    }

    return (
        <CardRoot
            key={cellId.toString()}
            style={sizeStyle}
            {...(onCellImageAction
                ? {
                      onMouseDown: () => onCellImageAction({ type: 'clickCell', cellId }),
                      onMouseEnter: () => onCellImageAction({ type: 'mouseEnterCell', cellId }),
                      onMouseLeave: () => onCellImageAction({ type: 'mouseLeaveCell', cellId }),
                  }
                : {})}
        >
            <HighlightedImage
                data-testid="cellImageControlIMG"
                className={`${highlighted}`}
                style={sizeStyle}
            >
                <img
                    alt=""
                    src={imageDataUrl}
                    style={{ ...sizeStyle, userSelect: 'none' }}
                    draggable={false}
                />
            </HighlightedImage>
            {chipLabel && (
                <CellImageChip
                    size="small"
                    color="primary"
                    icon={<FiberManualRecordIcon color="primary" />}
                    label={chipLabel}
                />
            )}
        </CardRoot>
    )
}

export default CellImageControl
