import * as ROUTES from 'constants/routes'
import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { Divider, List, ListItem, Stack, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { GridApi } from 'ag-grid-community'
import { DeepcellPrimaryButton } from 'components/shared'
import useFlags from 'components/shared/useFlags'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import useRunsSlice from 'redux/slices/hooks/useRunsSlice'
import { getRuns, Run } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import CreateLabelingTaskFromRunsDialog from '../labeling/tasks/dialogs/CreateLabelingTaskFromRunsDialog'
import BasicInfoTable from './BasicInfoTable'
import CellTypeGrid from './CellTypeGrid'
import GeneratePredictionsDlg from './dialogs/GeneratePredictionsDlg'
import StartAnalysisDlg from './StartAnalysisDlg'
import QCMetricsGrid from './QCMetricsGrid'
import QCStatusGrid from './QCStatusGrid'
import RunDetailsActions from './RunDetailsActions'
import WellResultsGrid from './WellResultsGrid'

const BasicInfoHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
})

export const CLASS_DISTRIBUTION_URL =
    'https://app.mode.com/deepcell/reports/7b023439388c?param_run_id_param='
export const LABEL_SUMMARY_URL =
    'https://app.mode.com/deepcell/reports/3565fff6cd73?param_run_id_param='
export const OOF_SCORE_URL = 'https://app.mode.com/deepcell/reports/313d3d2fda59?param_RunId='

interface Props {
    onDownload: () => void
    selectedRunIds?: string[]
}

function RunDetailsPanel(props: Props): JSX.Element {
    const { permissions } = useAuthTokens()

    const [createTasksIsOpen, setCreateTasksIsOpen] = useState(false)
    const [genPredictionsIsOpen, setGenPredictionsIsOpen] = useState(false)
    const [generateAnalysisIsOpen, setGenerateAnalysisIsOpen] = useState(false)
    const { onDownload, selectedRunIds } = props
    const queryClient = useQueryClient()
    const [runs, setRuns] = useState<Run[]>([])
    const [gridApi] = useState<GridApi | null>(null)
    const history = useHistory()
    const { runs: runsFromState } = useRunsSlice()
    const { runsStartAnalysisEnabled, manualQualityReviewEnabled } = useFlags()

    const loadInitialRuns = useCallback(async () => {
        // if every selected run is already stored in state, then just use state, otherwise fetch the latest data
        const selectedRunsInState = runsFromState.filter((r) => selectedRunIds?.includes(r.run_id))
        const allSelectedRunIdsAreInState = selectedRunsInState.length === selectedRunIds?.length

        if (allSelectedRunIdsAreInState) {
            setRuns(selectedRunsInState)
        } else {
            const resultRuns = await queryClient.fetchQuery(
                [
                    'getRuns',
                    {
                        run_ids: selectedRunIds,
                        limit: selectedRunIds?.length || 1,
                    },
                ],
                getRuns,
                {
                    staleTime: 0,
                }
            )
            setRuns(resultRuns)
        }
    }, [queryClient, runsFromState, selectedRunIds])

    useEffect(() => {
        loadInitialRuns()
    }, [loadInitialRuns, gridApi, selectedRunIds])

    const runIdString = selectedRunIds?.join(',')

    function handleCreateLabelingTasks() {
        setCreateTasksIsOpen(true)
    }

    function handleCreateTasksClose(labelingTasks: LabelingTask[]) {
        setCreateTasksIsOpen(false)
        if (labelingTasks.length > 0) {
            const taskIds = labelingTasks.map((task) => `${task.getTaskId()}`)
            history.push({
                pathname: ROUTES.LABELING_TASKS,
                search: `?selectedTaskIds=${taskIds.join('_')}&scrollToSelected=1`,
            })
        }
    }

    function handleGeneratePredictions() {
        setGenPredictionsIsOpen(true)
    }

    function handleGenPredictionsClose() {
        setGenPredictionsIsOpen(false)
    }

    function handleBrowseCells() {
        window.open(
            `${ROUTES.CELL_BROWSING}?sortOrderKey=RUN_ID_ASC&runIds=${runIdString}`,
            '_blank'
        )
    }

    function replaceRun(newRun: Run, rns: Run[]) {
        const index = rns.findIndex((r) => r.run_id === newRun.run_id)
        if (index > -1) rns.splice(index, 1, newRun)
    }

    function updateLocalRuns(editedRuns: Run[]) {
        const updatedRuns = [...runs]
        editedRuns.forEach((r) => replaceRun(r, updatedRuns))
        setRuns(updatedRuns)
    }

    const RunDetailsList = styled(List)({
        borderWidth: 'thin',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderStyle: 'solid',
        paddingTop: 0,
    })

    const RunDetailsListItemHeader = styled(ListItem)({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        height: '53px',
    })

    const RunDetailsListItem = styled(ListItem)({
        padding: '1rem',
    })

    const handleStartAnalysis = () => {
        setGenerateAnalysisIsOpen(true)
    }

    return (
        <>
            <RunDetailsList>
                <RunDetailsListItemHeader>
                    <BasicInfoHeader>
                        <Typography data-testid="basic-header">Run Details</Typography>
                        <Stack direction="row" spacing={1}>
                            {runsStartAnalysisEnabled === 'yes' && (
                                <DeepcellPrimaryButton contained onClick={handleStartAnalysis}>
                                    Start Analysis
                                </DeepcellPrimaryButton>
                            )}
                            <DeepcellPrimaryButton contained onClick={handleBrowseCells}>
                                Browse Cells
                            </DeepcellPrimaryButton>
                        </Stack>
                    </BasicInfoHeader>
                </RunDetailsListItemHeader>
                <Divider />
                <RunDetailsListItem>
                    <div className="ag-theme-balham ag-theme-deepcell" style={{ width: '100%' }}>
                        <BasicInfoTable onEdit={(r) => updateLocalRuns(r)} runs={runs} />
                    </div>
                </RunDetailsListItem>

                {runs.length === 1 &&
                    runs[0].well_sorting_configurations &&
                    runs[0].well_sorting_configurations.length > 0 && (
                        <>
                            <Divider />
                            <RunDetailsListItemHeader>
                                <Typography>Sort Results By Well</Typography>
                            </RunDetailsListItemHeader>
                            <Divider />
                            <RunDetailsListItem>
                                <WellResultsGrid run={runs[0]} />
                            </RunDetailsListItem>
                        </>
                    )}

                {runs.length === 1 && runs[0].cell_counts && (
                    <>
                        <Divider />
                        <RunDetailsListItemHeader>
                            <Typography>Cell Count By Cell Type</Typography>
                        </RunDetailsListItemHeader>
                        <Divider />
                        <RunDetailsListItem>
                            <CellTypeGrid run={runs[0]} />
                        </RunDetailsListItem>
                    </>
                )}
                <Divider />

                {runs.length === 1 &&
                    manualQualityReviewEnabled === 'yes' &&
                    (permissions.has('qc:edit') || permissions.has('deepcell_internal:read')) && (
                        <>
                            <RunDetailsListItemHeader>
                                <Typography>Run Quality Review</Typography>
                            </RunDetailsListItemHeader>

                            <Divider />
                            <RunDetailsListItem>
                                <Stack direction="column" spacing={1} style={{ width: '100%' }}>
                                    {permissions.has('qc:edit') && (
                                        <QCMetricsGrid
                                            run={runs[0]}
                                            onEdit={(r) => updateLocalRuns(r)}
                                        />
                                    )}
                                    {permissions.has('deepcell_internal:read') && (
                                        <QCStatusGrid run={runs[0]} />
                                    )}
                                </Stack>
                            </RunDetailsListItem>
                            <Divider />
                        </>
                    )}

                {permissions.has('deepcell_internal:read') && (
                    <>
                        <RunDetailsListItemHeader>
                            <Typography>Actions</Typography>
                        </RunDetailsListItemHeader>
                        <Divider />
                        <RunDetailsListItem>
                            <RunDetailsActions
                                runs={runs}
                                handleCreateLabelingTasks={handleCreateLabelingTasks}
                                handleGeneratePredictions={handleGeneratePredictions}
                                onDownload={onDownload}
                                runIdString={runIdString}
                            />
                        </RunDetailsListItem>
                    </>
                )}
            </RunDetailsList>

            <CreateLabelingTaskFromRunsDialog
                isOpen={createTasksIsOpen}
                handleClose={handleCreateTasksClose}
                runs={runs}
            />

            <GeneratePredictionsDlg
                isOpen={genPredictionsIsOpen}
                handleClose={handleGenPredictionsClose}
                runs={runs}
            />

            <StartAnalysisDlg
                isOpen={generateAnalysisIsOpen}
                handleClose={() => setGenerateAnalysisIsOpen(false)}
                runs={runs}
            />
        </>
    )
}

export default React.memo(RunDetailsPanel)
