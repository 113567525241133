import { Microtask } from '@deepcell/cell_data_api_proto'
import { useQuery } from 'react-query'
import { UseQueryResult } from 'react-query/types/react/types'
import { getMicrotask, GetMicrotaskParams } from 'utils/api'

/**
 * Hook that returns a microtask
 */
export function useGetMicrotask<TError = unknown>(
    taskId: number,
    microtaskIndex?: number
): UseQueryResult<Microtask, TError> {
    const params: GetMicrotaskParams = { taskId, microtaskIndex }

    return useQuery(['getMicrotask', params], getMicrotask, {
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: 0,
    })
}

export default useGetMicrotask
