import moment, { MomentInput, Duration } from 'moment'

/**
 * Returns a formatted date time string
 *
 * @param dateTime May be a timestamp in milliseconds or a Date
 */
export function formatDateTime(
    dateTime: MomentInput | undefined | null
): string {
    if (dateTime === undefined || dateTime === null) return ''
    return moment(dateTime).format('YYYY-MM-DD HH:mm')
}

/**
 * Returns a formatted date string
 *
 * @param dateTime May be a timestamp in milliseconds or a Date
 */
export function formatDate(dateTime: number | Date | undefined | null): string {
    if (dateTime === undefined || dateTime === null) return ''
    return moment(dateTime).format('YYYY-MM-DD')
}

/**
 * Returns a formatted duration string in HH:MM:SS
 */
export function formatDuration(duration: Duration | undefined | null): string {
    if (duration === undefined || duration === null) return ''

    function formatNum(num: number): string {
        return Math.floor(num).toString().padStart(2, '0')
    }

    const hours = formatNum(duration.hours())
    const minutes = formatNum(duration.minutes())
    const seconds = formatNum(duration.seconds())

    return `${hours}:${minutes}:${seconds}`
}
