import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { useQuery } from 'react-query'
import { UseQueryResult } from 'react-query/types/react/types'
import { getLabelingTask } from '../../../utils/api'

/**
 * Hook that runs a cell search
 */
function useGetLabelingTask<TError = unknown>(
    taskId: number
): UseQueryResult<LabelingTask, TError> {
    // @TODO Figure out refresh properly, or change how we get data for this component
    // Currently, this does not update immediately if you trigger an action on a task.  It should.
    // https://www.freecodecamp.org/news/force-refreshing-a-react-child-component-the-easy-way-6cdbb9e6d99c/
    return useQuery(['getLabelingTask', taskId], getLabelingTask, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    })
}

export default useGetLabelingTask
