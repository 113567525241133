import { ChangeEvent, ReactElement } from 'react'
import { Checkbox } from '@mui/material'
import { RowNode } from 'ag-grid-community'

interface Props {
    value: boolean
    column: string
    node: RowNode
}

function CheckboxCellRenderer({ value, column, node }: Props): ReactElement {
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const { checked } = event.target
        node.setDataValue(column, checked)
    }
    return <Checkbox checked={value} onChange={handleChange} />
}

export default CheckboxCellRenderer
