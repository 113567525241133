import { ActiveTab, SearchDetailsQueryState } from 'components/shared/SearchDetailsView'
import { LabelingTaskFilter } from 'redux/slices'
import {
    BooleanParam,
    DelimitedNumericArrayParam,
    NumberParam,
    QueryParamConfigMap,
    SetQuery,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params'
import { withIgnoreBlanks } from 'utils/custom-query-params'
import { ColumnStateQueryParam, ColumnStateQueryState } from 'utils/useColumnStateManager'

type QueryState = LabelingTaskFilter &
    SearchDetailsQueryState &
    ColumnStateQueryState & {
        selectedTaskIds: number[]
        scrollToSelected: boolean
    }

export const useLabelingTaskQueryParams = (): {
    query: QueryState
    setQuery: SetQuery<QueryParamConfigMap>
} => {
    const [query, setQuery] = useQueryParams({
        keywordSearch: withDefault(withIgnoreBlanks(StringParam), ''),
        status: withDefault(NumberParam, -1),
        assignedToMe: withDefault(withIgnoreBlanks(BooleanParam), false),
        projectName: withDefault(StringParam, ''),
        selectedTaskIds: withDefault(withIgnoreBlanks(DelimitedNumericArrayParam), []),
        activeTab: withDefault(withIgnoreBlanks(StringParam), ActiveTab.SEARCH),
        scrollToSelected: withIgnoreBlanks(BooleanParam), // Scrolls to the selected tasks on load
        ...ColumnStateQueryParam,
    })

    return { query: query as QueryState, setQuery } as const
}

export default useLabelingTaskQueryParams
