import { Microtask } from '@deepcell/cell_data_api_proto'
import ms from 'ms'
import { QueryClient } from 'react-query'
import { getMicrotask, GetMicrotaskParams } from 'utils/api'
import { CellDisplayOptions, prefetchForImageControl } from '../../shared/CellImageControl'
import { IMAGE_SIZE } from './MicrotaskCellGrid'

export function prefetchImages(
    queryClient: QueryClient,
    microtask: Microtask,
    displayOptions?: CellDisplayOptions
): void {
    microtask.getCellItemsList().forEach(async (cellItem) => {
        // Prefetch both the zoomed in and not zoomed in version
        // Some labelers like to toggle quickly between the two to make a judgement
        await prefetchForImageControl(queryClient, cellItem.getCellId(), IMAGE_SIZE, displayOptions)
        await prefetchForImageControl(queryClient, cellItem.getCellId(), IMAGE_SIZE, {
            ...displayOptions,
            centerCrop: !displayOptions?.centerCrop,
        })
    })
}
export async function prefetchMicrotask(
    queryClient: QueryClient,
    params: GetMicrotaskParams,
    displayOptions?: CellDisplayOptions
): Promise<void> {
    const response = await queryClient.fetchQuery(['getMicrotask', params], getMicrotask, {
        staleTime: ms('1 hour'),
    })
    if (response) prefetchImages(queryClient, response, displayOptions)
}
