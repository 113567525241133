import { configureStore } from '@reduxjs/toolkit'
import {
    LabelingMicrotaskReducer,
    LabelingTaskReducer,
    NotificationReducer,
    RunSearchFilterReducer,
    RunsReducer,
    UserReducer,
    CellVisualizationsReducer,
    VisualizationReducer,
    PlotlyReducer,
    WalkthroughReducer,
} from './slices'

export const store = configureStore({
    reducer: {
        runSearchFilter: RunSearchFilterReducer,
        runs: RunsReducer,
        user: UserReducer,
        labelingMicrotask: LabelingMicrotaskReducer,
        cellVisualizations: CellVisualizationsReducer,
        labeling: LabelingTaskReducer,
        notification: NotificationReducer,
        visualization: VisualizationReducer,
        plotly: PlotlyReducer,
        walkthrough: WalkthroughReducer,
    },
    middleware: [],
})

export type RootState = ReturnType<typeof store.getState>
