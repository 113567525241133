import { AccordionDetails, Box, Typography } from '@mui/material'
import React from 'react'
import LinearProgressWithLabel from '../../shared/LinearProgressWithLabel'
import { MicrotaskAccordion, MicrotaskAccordionHeader } from './sharedComponents'

interface Props {
    title: string
    value: number // progress value between 0-100
    children: React.ReactNode
}

function TaskInfoAccordion(props: Props): JSX.Element {
    const { title, value, children } = props
    return (
        <MicrotaskAccordion expanded>
            <MicrotaskAccordionHeader>
                <Typography variant="h6">{title}</Typography>
            </MicrotaskAccordionHeader>
            <AccordionDetails sx={{ flexDirection: 'column' }}>
                {children}
                <Box mt={1}>
                    <Typography variant="body1">Progress:</Typography>
                    <LinearProgressWithLabel value={value} />
                </Box>
            </AccordionDetails>
        </MicrotaskAccordion>
    )
}

export default TaskInfoAccordion
