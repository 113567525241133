import * as ENVIRONMENTS from 'constants/environments'
import firebase from 'firebase'
import FIREBASE_CONFIG_DEV, { AUTH0_CONFIG_CLOUD_DEV } from './dev'

// Set this to true to enable mock APIs for testing
export const ENABLE_MOCK_API = false

// Set this to true to use staging instead of local API for local development
export const ENABLE_STAGING_API_FOR_LOCAL_DEV = true

// @TODO: Need to find the method to fetch license from account of AgGrid itself.
export const AG_GRID_LICENSE_KEY =
  'CompanyName=Deepcell, Inc,LicensedApplication=Deepcell Cloud,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-029302,SupportServicesEnd=15_June_2023_[v2]_MTY4Njc4MzYwMDAwMA==e5617e876ece97b6b97a54d82837a58f'

const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyAHY81w7cAbY3ehAjrdhGZb92mhgAV3vBo',
  authDomain: 'dc-classifier.firebaseapp.com',
  databaseURL: 'https://dc-classifier.firebaseio.com',
  projectId: 'dc-classifier',
  storageBucket: 'dc-classifier.appspot.com',
  messagingSenderId: '1060407151248',
  appId: '1:1060407151248:web:85e72d291ca9de43736615',
  measurementId: 'G-EW3PJ1WBPD',
}

const FIREBASE_CONFIG_STAGING_LOCAL_DEV = {
  apiKey: 'AIzaSyAARxOjl8ejFHJ7zZHohzpugtR9VO8jolg',
  authDomain: 'monocle-staging-a7d13.firebaseapp.com',
  databaseURL: 'https://monocle-staging-a7d13.firebaseio.com',
  projectId: 'monocle-staging-a7d13',
  storageBucket: 'monocle-staging-a7d13.appspot.com',
  messagingSenderId: '158057788799',
  appId: '1:158057788799:web:e14269841f6365aad7964a',
  measurementId: 'G-E5MYH83SE5',
}

const FIREBASE_CONFIG_EXTERNAL_DEMO = {
  apiKey: 'AIzaSyCXHSiwZuWSvJtdsY0MyIpVOc730Zo-eUo',
  authDomain: 'external-demo-37a1c.firebaseapp.com',
  databaseURL: 'https://external-demo-37a1c.firebaseio.com',
  projectId: 'external-demo-37a1c',
  storageBucket: 'external-demo-37a1c.appspot.com',
  messagingSenderId: '782466927598',
  appId: '1:782466927598:web:807853ec175a692c9c1cff',
  measurementId: 'G-JFXYLRXS3F',
}

export const isProduction = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION
export const isStaging = process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING
export const isExternalDemo = process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO
export const isCloudDev =
  process.env.REACT_APP_ENV && !isProduction && !isStaging && !isExternalDemo

function getFirebaseConfig() {
  if (isProduction) return FIREBASE_CONFIG_PROD
  if (isStaging) return FIREBASE_CONFIG_STAGING_LOCAL_DEV
  if (isExternalDemo) return FIREBASE_CONFIG_EXTERNAL_DEMO
  if (isCloudDev) return FIREBASE_CONFIG_DEV
  return FIREBASE_CONFIG_STAGING_LOCAL_DEV
}

const firebaseConfig = getFirebaseConfig()

export type Auth0ConfigParameters = {
  domain: string
  clientId: string
}

// Auth0 Configuration Parameters from the Deepcell Cloud Single Page App in each Auth0 tenant
const AUTH0_CONFIG_PROD: Auth0ConfigParameters = {
  domain: 'deepcell.us.auth0.com',
  clientId: 'JmcXeHMU1MPY9MieOYy51awbEx2CGQu8',
}

const AUTH0_CONFIG_STAGING: Auth0ConfigParameters = {
  domain: 'deepcell-staging.us.auth0.com',
  clientId: 'Z92a6z4SNYWF341lXK2oizHIA1O5skHY',
}

const AUTH0_CONFIG_EXTERNAL_DEMO: Auth0ConfigParameters = {
  domain: 'deepcell-demo.us.auth0.com',
  clientId: 'qZrw7JhmtelSBoCnBVMaw8GXc8adLjB2',
}

let AUTH0_CONFIG_LOCAL_DEV: Auth0ConfigParameters = {
  domain: 'deepcell-dev.us.auth0.com',
  clientId: '0tjOdWXvmIjvZcZYPlto7564TuALG9Yo',
}

if (ENABLE_STAGING_API_FOR_LOCAL_DEV) {
  AUTH0_CONFIG_LOCAL_DEV = AUTH0_CONFIG_STAGING
}

// Switch to this if you want to use a local API.  And also update api.ts
// const AUTH0_CONFIG_LOCAL_DEV: Auth0ConfigParameters = {
//     domain: 'deepcell-dev.us.auth0.com',
//     clientId: '0tjOdWXvmIjvZcZYPlto7564TuALG9Yo',
// }

function getAuth0Config() {
  if (isProduction) return AUTH0_CONFIG_PROD
  if (isStaging) return AUTH0_CONFIG_STAGING
  if (isExternalDemo) return AUTH0_CONFIG_EXTERNAL_DEMO
  if (isCloudDev) return AUTH0_CONFIG_CLOUD_DEV
  return AUTH0_CONFIG_LOCAL_DEV
}

export const auth0Config = getAuth0Config()

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.performance()

export default {
  projectId: firebaseConfig.projectId,
}

// Initialize singleton Firebase feature flag functionality (aka "Remote Config")
const remoteConfig = firebase.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = isProduction ? 3600000 : 10000

export type YesNoSetting = 'yes' | 'no'

export type RemoteConfigSettings = {
  dataset4Enabled: YesNoSetting
  cellBrowsingEnabled: YesNoSetting
  demoEnabled: YesNoSetting
  labelingEnabled: YesNoSetting
  runsEnabled: YesNoSetting
  runsStartAnalysisEnabled: YesNoSetting
  labelingOraclesEnabled: YesNoSetting
  cellVisualizationsColorPickerEnabled: YesNoSetting
  cellVisualizationsCompareEnabled: YesNoSetting
  cellVisualizationsTrainClassifierEnabled: YesNoSetting
  cellVisualizationsContinousColorScale: string
  cellVisualizationsReverseContinuousColorScale: YesNoSetting
  cellVisualizationsImagesEnabled: YesNoSetting
  cellVisualizationsShowNewMorphometricsOrder: YesNoSetting
  cellVisualizationsShowOnlyFeaturedMorphometrics: YesNoSetting
  cellVisualizationUploadV2: YesNoSetting
  cellVisualizationsGuideEnabled: YesNoSetting
  validModelList: string
  modelsV3ApiEnabled: YesNoSetting
  manualQualityReviewEnabled: YesNoSetting
  saveSessionsV2: YesNoSetting
  showSaveAs: YesNoSetting
  allowSessionDelete: YesNoSetting
  showAxonDataSuiteName: YesNoSetting
  enableFiltering: YesNoSetting
  disableCurrentSelection: YesNoSetting
  enableShare: YesNoSetting
}

export const DEFAULT_REMOTE_CONFIG: RemoteConfigSettings = {
  dataset4Enabled: 'yes',
  cellBrowsingEnabled: 'yes',
  demoEnabled: 'no',
  labelingEnabled: 'yes',
  runsEnabled: 'yes',
  runsStartAnalysisEnabled: 'no',
  labelingOraclesEnabled: 'yes',
  cellVisualizationsColorPickerEnabled: 'no',
  cellVisualizationsCompareEnabled: 'no',
  cellVisualizationsTrainClassifierEnabled: 'no',
  cellVisualizationsContinousColorScale: 'YlOrRd',
  cellVisualizationsReverseContinuousColorScale: 'yes',
  cellVisualizationsShowNewMorphometricsOrder: 'no',
  cellVisualizationsShowOnlyFeaturedMorphometrics: 'no',
  cellVisualizationsImagesEnabled: 'no',
  cellVisualizationUploadV2: 'no',
  cellVisualizationsGuideEnabled: 'yes',
  validModelList: '[]',
  modelsV3ApiEnabled: 'no',
  manualQualityReviewEnabled: 'yes',
  saveSessionsV2: 'yes',
  showSaveAs: 'yes',
  allowSessionDelete: 'no',
  showAxonDataSuiteName: 'no',
  enableFiltering: 'yes',
  disableCurrentSelection: 'yes',
  enableShare: 'yes',
}

export const APM_CONFIG = {
  datadog: {
    URL: 'datadoghq.com',
    applicationId: '9b3823e3-d1bd-4d02-9303-85e7aeeeceaa',
    clientToken: 'pub60b4747b326d1dace4b2b8c9ea854c11',
  },
  origins: [
    'localhost',
    'cloud-staging.deepcell.com',
    'cloud.deepcell.com',
    'demo.deepcell.com',
    /^\//,
  ],
  serviceName: 'Deepcell Cloud',
}
