import { PopoverProps } from '@mui/material'

const defaultPopoverProps: Partial<PopoverProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
}

export default defaultPopoverProps
