import { AlertColor, SnackbarOrigin } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export interface NotificationState {
  open?: boolean
  type?: AlertColor
  message?: string
  timeout?: number | null
  closeOnClickaway?: boolean
  showProgress?: boolean
  showIcon?: boolean
  origin?: SnackbarOrigin
}

export const notificationInitialState: NotificationState = {
  open: false,
  type: 'info',
  message: '',
  timeout: null,
  closeOnClickaway: false,
  showProgress: false,
  showIcon: true,
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    displayNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    clearNotification: (state) => ({ ...state, open: false }),
  },
})

export const NotificationActions = NotificationSlice.actions
export const NotificationReducer = NotificationSlice.reducer

export const notificationFromState = (state: RootState): NotificationState => state.notification
