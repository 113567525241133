import { Card, CardContent, PopoverProps, Stack, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import {
    DeepcellMultiInput,
    DeepcellPrimaryButton,
    DeepcellPrimarySelect,
    DeepcellTextField,
} from 'components/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import React, { ChangeEvent } from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import useRunSearchFilterSlice from 'redux/slices/hooks/useRunSearchFilterSlice'
import {
    RunSearchFilterInitialState,
    RunSearchFilterState,
    RunSearchStatus,
} from 'redux/slices/RunSearchFilterSlice'
import { enumToKeys } from 'utils/helpers'
import { RunSearchDateSelector } from './RunSearchDateSelector'

interface AdvancedRunSearchFilterPopoverProps {
    open: PopoverProps['open']
    anchorEl?: PopoverProps['anchorEl']
    handleCancel?: (clear?: boolean) => void
    handleSearch?: () => void
}

const StyledCardContent = styled(CardContent)({
    width: '600px',
    padding: '0px',
})

export const AdvancedRunSearchFilterPopover = ({
    open,
    anchorEl,
    handleCancel: handleCancelProp,
    handleSearch: handleSearchProp,
}: AdvancedRunSearchFilterPopoverProps): JSX.Element => {
    const { runSearchFilter, update } = useRunSearchFilterSlice()

    const handleChange =
        (key: keyof RunSearchFilterState) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
            const newRunSearchFilter = {
                ...runSearchFilter,
                [key]: e.target.value,
            }
            update(newRunSearchFilter)
        }

    const handleCancel = (clear = true) => {
        // Upon cancelling, clear everything but the keyword input
        if (clear) {
            update({
                ...RunSearchFilterInitialState,
                keyword: runSearchFilter.keyword,
            })
        }
        if (handleCancelProp) handleCancelProp(clear)
    }

    const handleSearch = () => {
        update({
            ...runSearchFilter,
            advancedSearchEnabled: true,
        })
        if (handleSearchProp) handleSearchProp()
    }

    const statusKeys = enumToKeys(RunSearchStatus)

    const updateRunIds = (newVal: string) =>
        update({
            ...runSearchFilter,
            runIds: newVal,
        })

    return (
        <DeepcellPopover
            open={open}
            onClose={() => handleCancel(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Card sx={{ border: 'none' }} variant="outlined">
                <StyledCardContent>
                    <Stack spacing={2}>
                        <DeepcellTextField
                            fullWidth
                            label="Contains Keywords"
                            placeholder="Search for keywords in any fields"
                            value={runSearchFilter.keyword}
                            onChange={handleChange('keyword')}
                        />
                        <DeepcellMultiInput
                            value={
                                runSearchFilter.runIds ? runSearchFilter.runIds.split(/\s+/) : []
                            }
                            updateValueState={updateRunIds}
                            label="Run Ids"
                        />
                        <RunSearchDateSelector />
                        {/* TODO: This field can be added back in once the backend supports sample id */}
                        {/* 
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Sample Id"
                                placeholder="Sample Ids separated by commas"
                                value={runSearchFilter.sampleID}
                                onChange={handleChange('sampleID')}
                                InputLabelProps={{ shrink: true }}
                            />
                         */}
                        <DeepcellTextField
                            fullWidth
                            label="Operator"
                            value={runSearchFilter.operator}
                            placeholder="Enter email address"
                            onChange={handleChange('operator')}
                        />
                        <DeepcellPrimarySelect
                            label="Status"
                            onChange={handleChange('status')}
                            items={[
                                { value: '', output: '--' },
                                ...statusKeys.map((s) => ({
                                    key: s,
                                    value: s,
                                    output: RunSearchStatus[+s],
                                })),
                            ]}
                        />
                        <Stack direction="row" spacing={2}>
                            <DeepcellPrimaryButton
                                outlined
                                data-testid="advancedRunSearchCancelButton"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </DeepcellPrimaryButton>
                            <DeepcellPrimaryButton contained onClick={handleSearch}>
                                Search
                            </DeepcellPrimaryButton>
                        </Stack>
                    </Stack>
                </StyledCardContent>
            </Card>
        </DeepcellPopover>
    )
}

export default AdvancedRunSearchFilterPopover
