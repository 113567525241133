import { Box, BoxProps, Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { QueryStatus } from 'react-query'
import { useCellImageUrlFromCellId } from './tsv/utils'

interface PlotCellImageProps {
    cellId: string
    imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    wrapperProps?: BoxProps
    handleStatusChange?(status?: QueryStatus): void
}

const imageSize = 72

export const PlotCellImage = ({
    cellId,
    imgProps,
    wrapperProps,
    handleStatusChange,
}: PlotCellImageProps): JSX.Element => {
    const { imageDataUrl, status } = useCellImageUrlFromCellId(cellId)

    useEffect(() => {
        if (handleStatusChange) handleStatusChange(status)
    }, [handleStatusChange, status])

    return (
        <Box component="span" sx={{ display: 'inline-flex', p: '2px' }} {...wrapperProps}>
            {imageDataUrl ? (
                <img
                    data-testid="plotCellImageImgElement"
                    src={imageDataUrl}
                    width={imgProps?.width ?? imageSize}
                    alt=""
                    {...imgProps}
                />
            ) : (
                <Skeleton
                    data-testid="plotCellImageSkeleton"
                    variant="rectangular"
                    width={imgProps?.width ?? imageSize}
                    height={imgProps?.height ?? imageSize}
                    style={imgProps?.style}
                />
            )}
        </Box>
    )
}

export default PlotCellImage
