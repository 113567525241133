/** @TODO Remove this file once we're fully on Save Session V2 */
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import Box from '@mui/material/Box'
import { saveAs } from 'file-saver'

import useEventsManager from 'redux/slices/hooks/useEventsManager'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SaveIcon from '@mui/icons-material/Save'
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import useFlags from 'components/shared/useFlags'
import { ChangeEvent, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { CellVisualizationsState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { CellInfo } from '../tsv/types'
import useTsvFile from '../tsv/useTsvFile'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { MenuItemFileUpload } from './MenuItemFileUpload'
import useSessionApi from './useSessionApi'

export function SessionOptions(): JSX.Element {
  const { showError } = useNotificationSlice()
  const eventsManager = useEventsManager()

  const { saveVersionConfig, uploadNewBlobToCloud } = useSessionApi()
  const [sessionButtonAnchor, setSessionButtonAnchor] = useState<Element>()
  const sessionButtonOpen = Boolean(sessionButtonAnchor)
  const { tsvRawDataToCellInfo, zipRawDataToCellInfo } = useTsvFile()
  const enableNewUpload = useFlags().cellVisualizationUploadV2 === 'yes'

  const { sessionId } = useCellVisualizationUrlParams()

  const validSessionId = sessionId > 0

  const { setStore, setFileName, setCellsData, incrementDataRevision, cellVisualizations } =
    useCellVisualizationsSlice()
  const { fileName: cellVizFileName, cellsData } = cellVisualizations

  const readFile = (e: ChangeEvent<HTMLInputElement>) => {
    setSessionButtonAnchor(undefined)
    if (!e.target.files) return
    const file = e.target.files[0]
    const reader = new FileReader()

    const isTsv = file.name.match(/\.tsv$/)
    const isZip = file.name.match(/\.zip$/)

    if (isTsv || isZip) {
      if (enableNewUpload) {
        uploadNewBlobToCloud({ dataFromBlob: file, fileName: file.name })
        return
      }

      // TODO: The rest of the logic in this block will be removed once cloud storage is completed

      if (isTsv) {
        reader.readAsText(file)
      } else {
        reader.readAsBinaryString(file)
      }

      reader.addEventListener(
        'load',
        async () => {
          let result: string | CellInfo[]

          if (isTsv) {
            const rawData = reader.result as string
            result = await tsvRawDataToCellInfo(rawData)
          } else {
            const rawData = reader.result as ArrayBuffer
            result = await zipRawDataToCellInfo(rawData)
          }

          if (typeof result === 'string') {
            showError(result)
          } else {
            eventsManager.sendDataUploadEvent(file, true)
            setFileName(file.name)
            setCellsData(result)
          }
        },
        false
      )
      setStore({})
    } else {
      eventsManager.sendDataUploadEvent(file, false)
      const extension = file.name.split('.').slice(-1)[0]
      showError(
        `You've uploaded a .${extension} file, but the supported extensions are .tsv and .zip`
      )
    }

    incrementDataRevision()
    setSessionButtonAnchor(undefined)
  }

  /** Handle JSON serialization of BigInt values */
  function handleBigInt(_key: string, value: unknown) {
    if (typeof value === 'bigint') {
      return value.toString()
    }
    return value
  }

  // @TODO This function is now copied to SaveSessionMenu.tsx
  // Eventually, we should remove this copy once SessionOptions.tsx goes away
  const handleDownload = () => {
    // reset zoomThresholds because it gets way too big and crashes the download
    const cellAnalysisWithVersion = {
      version: '1.0.1',
      ...({
        ...cellVisualizations,
        zoomThresholds: undefined,
      } as CellVisualizationsState),
    }
    const blob = new Blob([JSON.stringify(cellAnalysisWithVersion, handleBigInt, 1)], {
      type: 'application/json',
    })
    eventsManager.sendExportEvent(cellVizFileName)
    saveAs(blob, 'cellanalysis.json')
    setSessionButtonAnchor(undefined)
  }

  const handleFilesAccepted = (files: File[]) => {
    if (files && files[0]) {
      const file = files[0]
      const fileReader = new FileReader()
      fileReader.readAsText(file, 'UTF-8')
      fileReader.onload = (x) => {
        const store = JSON.parse(x.target?.result?.toString() ?? '') as CellVisualizationsState
        if ('cellsData' in store) {
          setStore(store)
          setFileName(file.name)
          eventsManager.sendImportEvent(file, true)
          incrementDataRevision()
        } else {
          showError(`No cell data found in ${file.name}.`)
          eventsManager.sendImportEvent(file, false)
        }
      }
    }
  }
  const handleFiledRejected = (files: FileRejection[]) => {
    eventsManager.sendImportEvent(files[0].file, false)
    showError(`${files[0].errors[0].message}`)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    maxFiles: 1,
    accept: { 'application/JSON': ['.json'] },
    onDropAccepted: handleFilesAccepted,
    onDropRejected: handleFiledRejected,
    onFileDialogOpen: () => setSessionButtonAnchor(undefined),
  })

  const saveButtonClicked = async () => {
    // Make sure to wait for save version to finish successfully before proceeding
    // it may encounter an error
    await saveVersionConfig()
    setSessionButtonAnchor(undefined)
  }

  return (
    <Box>
      <DeepcellPrimaryButton
        size="large"
        outlined
        onClick={(e) => setSessionButtonAnchor(e.currentTarget)}
        sx={{ width: 'fit-content' }}
        endIcon={sessionButtonOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        color="info"
      >
        Sessions
      </DeepcellPrimaryButton>
      <Menu
        anchorEl={sessionButtonAnchor}
        open={sessionButtonOpen}
        onClose={() => setSessionButtonAnchor(undefined)}
      >
        <MenuList>
          <MenuItemFileUpload onUpload={readFile} disabled={Boolean(sessionId)}>
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText>Upload File</ListItemText>
          </MenuItemFileUpload>
          <Divider />
          {enableNewUpload
            ? [
                <MenuItem onClick={saveButtonClicked} disabled={!validSessionId}>
                  <ListItemIcon>
                    <SaveIcon />
                  </ListItemIcon>
                  <ListItemText>Save Session</ListItemText>
                </MenuItem>,
                <MenuItem key={1} disabled={Boolean(!cellsData?.length)} onClick={handleDownload}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText>Download Session</ListItemText>
                </MenuItem>,
              ]
            : [
                <MenuItem key={1} disabled={Boolean(!cellsData?.length)} onClick={handleDownload}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText>Download Session</ListItemText>
                </MenuItem>,
                <MenuItem key={2} {...getRootProps()} onClick={open}>
                  <ListItemIcon>
                    <UploadIcon />
                  </ListItemIcon>
                  <ListItemText>Import Session</ListItemText>
                  <input {...getInputProps()} hidden />
                </MenuItem>,
              ]}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default SessionOptions
