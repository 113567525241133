import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import firebase from 'firebase'
import ms from 'ms'
import { QueryClient, useQuery } from 'react-query'
import 'react-widgets/dist/css/react-widgets.css'
import * as api from '../../utils/api'
import {
    CellsQueryParams,
    convertErrorToString,
    FindCellsResponse,
    getCells,
    getMetricsCells,
} from '../../utils/api'
import { CellDisplayOptions, prefetchForImageControl } from '../shared/CellImageControl'
import { SearchDetailsQueryState } from '../shared/SearchDetailsView'
import { SearchFilters } from './CellSearchFilters'
import { SearchOptions } from './CellSearchOptions'
import { SortOrders } from './sort-orders'

export const IMAGE_SIZE = 100

export interface CellBrowsingState {
    selectedCellId: CellId
}

// State stored in the URL query parameters
export type QueryState = SearchDetailsQueryState &
    SearchFilters &
    SearchOptions &
    CellBrowsingState &
    CellDisplayOptions

export function getFindCellsQueryParams(
    search: QueryState,
    includeEstimatedCount: boolean
): CellsQueryParams {
    const {
        predictedClasses,
        predictedProbabilityGreaterThan,
        sampleType,
        sampleId,
        mixedSampleId,
        before,
        after,
        runIds,
        sortOrderKey,
        page,
        cellsPerPage,
    } = search

    const params = { ...api.DefaultCellsQueryParams }

    if (predictedClasses && predictedClasses.length > 0) {
        params.predicted_classes = predictedClasses
    }

    if (predictedClasses && predictedClasses.length > 0 && predictedProbabilityGreaterThan) {
        params.predicted_probability_greater_than = parseFloat(predictedProbabilityGreaterThan)
    }

    if (sampleType != null && sampleType > -1) {
        params.sample_type = sampleType
    }
    if (sampleId != null && sampleId.trim() !== '') {
        params.sample_id = sampleId
    }
    if (mixedSampleId != null && mixedSampleId.trim() !== '') {
        params.mixed_sample_id = mixedSampleId
    }
    if (before) {
        params.before = before.getTime()
    }
    if (after) {
        params.after = after.getTime()
    }

    if (runIds != null) {
        const trimmedRunIds = runIds.map((r) => r.trim())
        const nonEmptyRunIds = trimmedRunIds.filter((r) => r !== '')
        if (nonEmptyRunIds.length > 0) {
            params.run_ids = nonEmptyRunIds
        }
    }

    if (sortOrderKey) {
        params.order_by = SortOrders[sortOrderKey].orderBy
    }

    params.include_estimated_count = includeEstimatedCount

    params.limit = cellsPerPage
    params.offset = page * cellsPerPage

    return params
}

/**
 * Hook that runs a cell search
 */
export function useSearchCells(
    search: QueryState,
    includeEstimatedCount = false
): {
    result: FindCellsResponse | undefined
    error: string | undefined
    isLoading: boolean
} {
    const params: CellsQueryParams = getFindCellsQueryParams(search, includeEstimatedCount)

    /* Log a google analytics event using a standard event type
        https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484
    */
    const analytics = firebase.analytics()
    analytics.logEvent('search', search)

    const { data, error, isPreviousData, isLoading } = useQuery(['getCells', params], getCells, {
        keepPreviousData: true,
    })
    const { result, error: processedError } = convertErrorToString({ data, error })
    return { result, error: processedError, isLoading: isLoading || isPreviousData }
}
/**
 * Hook that runs a cell search
 */
export function useSearchMetricsCells(search: QueryState): {
    result: FindCellsResponse | undefined
    error: string | undefined
    isLoading: boolean
} {
    // const _params: CellsQueryParams = getFindCellsQueryParams(search, includeEstimatedCount)

    /* Log a google analytics event using a standard event type
        https://support.google.com/firebase/answer/6317498?hl=en&ref_topic=6317484
    */
    const analytics = firebase.analytics()
    analytics.logEvent('search', search)

    const params = {
        run_ids: search.runIds[0],
        quadrant: search.page + 1,
    }

    const { data, error, isPreviousData, isLoading } = useQuery(
        ['getMetricsCells', params],
        getMetricsCells,
        { keepPreviousData: true }
    )
    const { result, error: processedError } = convertErrorToString({ data, error })
    return { result, error: processedError, isLoading: isLoading || isPreviousData }
}

function prefetchImages(
    queryClient: QueryClient,
    cellData: FindCellsResponse,
    displayOptions?: CellDisplayOptions
): void {
    cellData.cells.forEach((cell) => {
        const cellId = cell.cell.getCellId()
        if (cellId) prefetchForImageControl(queryClient, cellId, IMAGE_SIZE, displayOptions)
    })
}

async function prefetchPageAndImages(
    queryClient: QueryClient,
    params: CellsQueryParams,
    displayOptions?: CellDisplayOptions
): Promise<void> {
    const response = await queryClient.fetchQuery(['getCells', params], getCells, {
        staleTime: ms('10 minutes'),
    })
    if (response) prefetchImages(queryClient, response, displayOptions)
}

export async function prefetchPages(
    queryClient: QueryClient,
    search: QueryState,
    howMany: number,
    displayOptions?: CellDisplayOptions
): Promise<void> {
    const params: CellsQueryParams = getFindCellsQueryParams(search, false)

    const promises: Promise<void>[] = []
    for (let i = -howMany; i <= howMany; i += 1) {
        if (i !== 0) {
            const newPage = search.page + i
            const offset = newPage * search.cellsPerPage
            if (offset > 0) {
                promises.push(
                    prefetchPageAndImages(queryClient, { ...params, offset }, displayOptions)
                )
            }
        }
    }
    await Promise.all(promises)
}
