import {
    Autocomplete,
    AutocompleteProps,
    Chip,
    TextFieldProps,
    styled,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import DeepcellTextField from './DeepcellTextField'

interface DeepcellMultiInputProps
    extends Omit<
        Partial<AutocompleteProps<string, true, false, true>>,
        'freeSolo' | 'multiple' | 'options' | 'renderInput'
    > {
    label: string
    inputProps?: TextFieldProps
    shrink?: boolean
    updateValueState: (newVal: string) => void
}

export const DeleteIcon = ({ onDelete }: { onDelete: (event: unknown) => void }): JSX.Element => (
    <svg
        onClick={onDelete}
        style={{ cursor: 'pointer' }}
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.146862 0.646862C0.193297 0.600308 0.248461 0.563373 0.309193 0.538173C0.369924 0.512972 0.435032 0.5 0.500785 0.5C0.566537 0.5 0.631645 0.512972 0.692376 0.538173C0.753108 0.563373 0.808272 0.600308 0.854707 0.646862L3.50013 3.29329L6.14555 0.646862C6.19203 0.600384 6.24721 0.563515 6.30794 0.538362C6.36866 0.513208 6.43375 0.500262 6.49948 0.500262C6.56521 0.500262 6.63029 0.513208 6.69102 0.538362C6.75175 0.563515 6.80692 0.600384 6.8534 0.646862C6.89988 0.693339 6.93675 0.748516 6.9619 0.809243C6.98705 0.869969 7 0.935055 7 1.00078C7 1.06651 6.98705 1.1316 6.9619 1.19233C6.93675 1.25305 6.89988 1.30823 6.8534 1.35471L4.20698 4.00013L6.8534 6.64555C6.89988 6.69203 6.93675 6.74721 6.9619 6.80794C6.98705 6.86866 7 6.93375 7 6.99948C7 7.06521 6.98705 7.13029 6.9619 7.19102C6.93675 7.25175 6.89988 7.30692 6.8534 7.3534C6.80692 7.39988 6.75175 7.43675 6.69102 7.4619C6.63029 7.48705 6.56521 7.5 6.49948 7.5C6.43375 7.5 6.36866 7.48705 6.30794 7.4619C6.24721 7.43675 6.19203 7.39988 6.14555 7.3534L3.50013 4.70698L0.854707 7.3534C0.80823 7.39988 0.753053 7.43675 0.692326 7.4619C0.6316 7.48705 0.566514 7.5 0.500785 7.5C0.435055 7.5 0.369969 7.48705 0.309243 7.4619C0.248516 7.43675 0.193339 7.39988 0.146862 7.3534C0.100384 7.30692 0.0635154 7.25175 0.0383618 7.19102C0.0132082 7.13029 0.000261727 7.06521 0.000261727 6.99948C0.000261727 6.93375 0.0132082 6.86866 0.0383618 6.80794C0.0635154 6.74721 0.100384 6.69203 0.146862 6.64555L2.79329 4.00013L0.146862 1.35471C0.100308 1.30827 0.0633735 1.25311 0.0381725 1.19238C0.0129716 1.13164 0 1.06654 0 1.00078C0 0.935032 0.0129716 0.869924 0.0381725 0.809193C0.0633735 0.748461 0.100308 0.693297 0.146862 0.646862Z"
            fill="black"
        />
    </svg>
)

const StyledChip = styled(Chip)(({ theme }) => ({
    padding: '4px 8px',
    background: '#E7E8FF',
    border: 'none',
    borderRadius: theme.spacing(1),
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&.MuiChip-sizeMedium': {
        margin: '0px !important',
    },
    '& > *': {
        flexShrink: 0,
    },
    '& > span': {
        padding: '0px !important',
    },
}))

const StyledErrorMessage = styled(Typography)({
    color: 'red',
    marginTop: '4px',
})

const formattedValue = (value: string[]) => {
    return value
        .flatMap((x) => x.trim().split(/\s+/))
        .toString()
        .replace(/,/g, ' ')
}

export const DeepcellMultiInput = ({
    label,
    inputProps,
    updateValueState,
    ...props
}: DeepcellMultiInputProps): JSX.Element => {
    const [inputValue, setInputValue] = useState<string>()
    const [valueExists, setValueExists] = useState<boolean>(false)

    const handleBlur = () => {
        if (inputValue && !(props.value as string[]).includes(inputValue)) {
            const newValue = `${formattedValue(props.value as string[])} ${inputValue.replace(
                /,/g,
                ' '
            )}`.trim()
            updateValueState(newValue)
            setValueExists(false)
            setInputValue(undefined)
        }
    }

    return (
        <div>
            <Autocomplete
                size="small"
                multiple
                freeSolo
                options={[]}
                clearOnBlur={!inputValue}
                onInputChange={(_, input) => {
                    if ((props.value as string[]).includes(input)) {
                        setValueExists(true)
                    } else {
                        setValueExists(false)
                    }

                    setInputValue(input)
                }}
                onChange={(_, value) => {
                    const newVal = formattedValue(value)
                    updateValueState(newVal)
                }}
                renderInput={(params) => (
                    <DeepcellTextField
                        label={label}
                        onBlur={handleBlur}
                        {...params}
                        {...inputProps}
                    />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                        const tagProps = getTagProps({ index })
                        return (
                            <div key={tagProps.key} style={{ display: 'flex', gap: '4px' }}>
                                <StyledChip
                                    label={option}
                                    deleteIcon={<DeleteIcon onDelete={tagProps.onDelete} />}
                                    {...tagProps}
                                />
                            </div>
                        )
                    })
                }
                {...props}
            />
            {valueExists ? (
                <StyledErrorMessage>Run Id is already specified.</StyledErrorMessage>
            ) : null}
        </div>
    )
}

export default DeepcellMultiInput
