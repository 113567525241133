import { Box, Checkbox, Container, DialogContentText, Grid, Paper, Stack } from '@mui/material'
import { DeepcellDialog, DeepcellPrimaryButton } from 'components/shared'
import useFlags from 'components/shared/useFlags'
import { useState } from 'react'
import { useMount } from 'react-use'
import { cellVisualizationsInitialState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { SessionMetaData } from 'utils/api'
import { DeleteIconButton } from '../shared'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { SessionStaticInfo } from './SessionStaticInfo'
import useSessionApi from './useSessionApi'

export const CellVisualizationSessionSelection = (): JSX.Element => {
  const { allowSessionDelete } = useFlags()
  const eventsManager = useEventsManager()

  const { getSessions, deleteSession } = useSessionApi()

  const { setStore } = useCellVisualizationsSlice()
  const { updateSession } = useCellVisualizationUrlParams()

  const [sessions, setSessions] = useState<SessionMetaData[]>()
  const [sessionToDelete, setSessionToDelete] = useState<SessionMetaData>()

  useMount(() => {
    const loadSessions = async () => {
      const sessionsFromBackend = await getSessions({
        version_config_fields: ['plotImgSrc', 'name', 'projectCode'],
        limit: 200,
      })
      setSessions(sessionsFromBackend)
    }

    setStore(cellVisualizationsInitialState)
    loadSessions()
  })

  const handleOpenSession = async (sessionId: number) => {
    eventsManager.sendSessionSelectionEvent(sessionId)

    updateSession(sessionId)
  }

  const handleDeleteClick = (id: number) => {
    const s = sessions?.find((x) => x.session_id === id)
    setSessionToDelete(s)
  }

  const handleDelete = async () => {
    if (!sessionToDelete) return
    const successfullyDeleted = await deleteSession({
      id: sessionToDelete.session_id,
      name: sessionToDelete.version_config.name ?? '',
    })

    if (successfullyDeleted) {
      setSessions((curr) => curr?.filter((x) => x.session_id !== sessionToDelete.session_id))
    }

    setSessionToDelete(undefined)
  }

  const imageSize = 140

  return (
    <Box sx={{ overflowY: 'auto', height: 'calc(100% - 48px)', py: 1 }}>
      <Container maxWidth="lg">
        <Stack spacing={2}>
          {sessions &&
            sessions?.map((sessionMetaData) => {
              const {
                author_email: authorEmail,
                updated_at: updatedAt,
                session_id: sessionId,
                version_config: { name, projectCode, plotImgSrc },
              } = sessionMetaData
              return (
                <Stack direction="row" spacing={2} key={sessionId}>
                  <Checkbox />
                  <Paper
                    sx={{
                      justifyContent: 'space-between',
                      p: 2,
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        {plotImgSrc ? (
                          <img alt="" src={plotImgSrc} style={{ maxWidth: imageSize }} />
                        ) : (
                          <Box
                            sx={{
                              height: imageSize,
                              width: imageSize,
                              backgroundColor: (t) => t.palette.info.light,
                              borderRadius: 2,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid container item spacing={1} maxWidth={650}>
                        <Grid item sm={12} md={6}>
                          <SessionStaticInfo label="Name" info={name ?? ''} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <SessionStaticInfo label="Project Code" info={projectCode ?? ''} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <SessionStaticInfo label="Start Time" info={updatedAt.toString()} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <SessionStaticInfo label="User" info={authorEmail ?? ''} />
                        </Grid>
                      </Grid>
                      <Grid item sx={{ display: 'flex' }}>
                        <DeepcellPrimaryButton
                          contained
                          sx={{ width: 190, height: 50, mr: 4 }}
                          onClick={() => handleOpenSession(sessionId)}
                        >
                          Open Session
                        </DeepcellPrimaryButton>
                        {allowSessionDelete === 'yes' ? (
                          <DeleteIconButton
                            data-testid="delete-button"
                            onClick={() => handleDeleteClick(sessionId)}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Paper>
                </Stack>
              )
            })}
        </Stack>
      </Container>
      <DeepcellDialog
        open={!!sessionToDelete}
        handleConfirm={handleDelete}
        handleCancel={() => setSessionToDelete(undefined)}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Delete"
      >
        <DialogContentText>{`Are you sure you want to delete ${
          sessionToDelete?.version_config.name ?? ''
        }?`}</DialogContentText>
      </DeepcellDialog>
    </Box>
  )
}
export default CellVisualizationSessionSelection
