import { LabelingTaskActionType } from '@deepcell/cell_data_api_proto'
import { DialogContent, DialogContentText } from '@mui/material'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import Moment from 'moment'
import { useState } from 'react'
import { useMutation } from 'react-query'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { doActionOnLabelingTask, LabelingTaskWithActions } from 'utils/api'
import {
    LabelingTaskActionTypeEncoderDecoder,
    LabelingTaskActionTypeValue,
} from 'utils/proto-utils'

const HIDDEN_ACTIONS: LabelingTaskActionTypeValue[] = [
    LabelingTaskActionType.ACTION_TYPE_REVIEW_FAIL,
    LabelingTaskActionType.ACTION_TYPE_REVIEW_ACCEPT,
]

Moment.locale('en')
momentLocalizer()

interface Props {
    handleClose: (
        action?: LabelingTaskActionTypeValue,
        updatedTaskWithActions?: LabelingTaskWithActions
    ) => void
    taskWithActions: LabelingTaskWithActions
}

function TaskActionPopover(props: Props): JSX.Element {
    const { handleClose, taskWithActions } = props
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

    const availableActions = taskWithActions?.availableActions.filter((action) => {
        return HIDDEN_ACTIONS.indexOf(action) === -1
    })

    const mutation = useMutation(doActionOnLabelingTask)
    async function handleAction(action: LabelingTaskActionTypeValue): Promise<void> {
        const taskId = taskWithActions?.labelingTask?.getTaskId()
        if (taskId === undefined) return

        const params = {
            labelingTaskId: taskId,
            action,
        }
        // @TODO: Handle errors better
        const result = await mutation.mutateAsync(params)
        handleClose(action, result)
    }

    function handleConfirmDelete(cancelled: boolean) {
        if (cancelled) {
            setConfirmDeleteOpen(false)
            handleClose()
        } else {
            handleAction(LabelingTaskActionType.ACTION_TYPE_DELETE)
        }
    }

    return (
        <div>
            <DialogContent>
                {availableActions &&
                    availableActions.map((action: LabelingTaskActionTypeValue) => {
                        const actionName =
                            LabelingTaskActionTypeEncoderDecoder.convertToString(action)
                        return (
                            <DeepcellPrimaryButton
                                contained
                                key={actionName}
                                sx={{ m: 1 }}
                                size="small"
                                onClick={(_event) =>
                                    action === LabelingTaskActionType.ACTION_TYPE_DELETE
                                        ? setConfirmDeleteOpen(true)
                                        : handleAction(action)
                                }
                            >
                                {actionName}
                            </DeepcellPrimaryButton>
                        )
                    })}
            </DialogContent>
            <DeepcellDialog
                open={confirmDeleteOpen}
                handleConfirm={() => handleConfirmDelete(false)}
                handleCancel={() => handleConfirmDelete(true)}
                okLabel="Yes"
                cancelLabel="No"
                titleLabel="Confirm Delete"
            >
                <DialogContentText>Are you sure you want to delete the task?</DialogContentText>
            </DeepcellDialog>
        </div>
    )
}

export default TaskActionPopover
