import { Box } from '@mui/material'
import { QueryStatus } from 'react-query'
import { DeepcellPlotDatum } from 'redux/slices/types'
import PlotCellImage from '../PlotCellImage'

const PlotCellImageWrapper = (
    props: { cell: DeepcellPlotDatum } & Omit<Parameters<typeof PlotCellImage>[0], 'cellId'>
) => {
    const { cell } = props
    const CELL_ID = cell.customdata as string
    return <PlotCellImage {...props} cellId={CELL_ID ?? ''} />
}

interface PlotCellImagesProps {
    cells?: DeepcellPlotDatum[]
    handleCellStatusUpdate?(cell: DeepcellPlotDatum, status?: QueryStatus): void
}
export const PlotCellImages = ({
    cells,
    handleCellStatusUpdate,
}: PlotCellImagesProps): JSX.Element => {
    const handleCellStatusChange = (cell: DeepcellPlotDatum) => (status?: QueryStatus) => {
        if (handleCellStatusUpdate) handleCellStatusUpdate(cell, status)
    }
    return (
        <Box sx={{ textAlign: 'center' }}>
            {cells?.map((cell, i) => {
                const key = `key${i}`
                return (
                    <PlotCellImageWrapper
                        key={key}
                        cell={cell}
                        handleStatusChange={handleCellStatusChange(cell)}
                        imgProps={{ style: { borderRadius: '10px' } }}
                    />
                )
            })}
        </Box>
    )
}

export default PlotCellImages
