/* eslint-disable no-console */
import { MicrotaskCell } from '@deepcell/cell_data_api_proto'
import { styled } from '@mui/material'
import CellImageControl from 'components/shared/CellImageControl'
import { RefWrapper } from 'components/shared/RefWrapper'
import firebase from 'firebase'
import Moment from 'moment'
import React from 'react'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useLabelingMicrotaskSlice from 'redux/slices/hooks/useLabelingMicrotaskSlice'
import { messageToBase64Str } from 'utils/proto-utils'
import { useBoxSelection } from 'utils/selectionUtils/useBoxSelection'

Moment.locale('en')
momentLocalizer()

export const IMAGE_SIZE = 70

// @TODO Consider refactoring this even more to share the cell grid with cell browsing
// If it makes sense to
const Content = styled('div')({
    flex: 3,
    display: 'grid',
    gridTemplateColumns: `repeat(10, ${IMAGE_SIZE}px);`,
    gridTemplateRows: `repeat(10, ${IMAGE_SIZE}px);`,
    columnGap: '1px',
    rowGap: '1px',
})

interface Props {
    cellItems: Array<MicrotaskCell>
}

function MicrotaskCellGrid(props: Props): JSX.Element {
    const {
        setSelectedCellIds,
        labelingMicrotask: { displayOptions, primaryCellClass, selectedCellIds },
    } = useLabelingMicrotaskSlice()
    const analytics = firebase.analytics()
    analytics.logEvent('labeling/labeling_microtask/show')

    const { cellItems } = props

    const isSelected = (cellItem: MicrotaskCell): boolean => {
        const itemCellIdStr = messageToBase64Str(cellItem.getCellId())
        return selectedCellIds.includes(itemCellIdStr)
    }

    const cellContent = cellItems.map((cellItem) => {
        const ref = React.createRef<HTMLDivElement>()
        const cellId = cellItem.getCellId()

        return {
            component: (
                <RefWrapper ref={ref} key={cellId}>
                    <CellImageControl
                        width={IMAGE_SIZE}
                        cellId={cellId}
                        highlighted={isSelected(cellItem)}
                        displayOptions={displayOptions}
                    />
                </RefWrapper>
            ),
            ref,
            cellId,
        }
    })
    const CellImages = () => <>{cellContent.map((x) => x.component)}</>
    const uniqueCellImageElements = cellContent.map(({ cellId, ref }) => ({
        id: messageToBase64Str(cellId),
        ref,
    }))

    const cellSelectionEnabled = !primaryCellClass || primaryCellClass > -1

    const { selectionAreaProps, SelectionBox } = useBoxSelection({
        elements: uniqueCellImageElements,
        defaultSelectedIds: selectedCellIds,
        enabled: cellSelectionEnabled,
        onSelectionChange: setSelectedCellIds,
    })

    return (
        <Content {...selectionAreaProps}>
            <CellImages />
            <SelectionBox />
        </Content>
    )
}

export default MicrotaskCellGrid
