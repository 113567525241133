import { FormControlLabel, FormGroup, SelectChangeEvent, Stack, Switch } from '@mui/material'
import { CELL_IMAGE_SIZES, CELL_IMAGE_SPACING_OPTIONS } from 'components/cell-visualizations/shared'
import DeepcellLabel from 'components/shared/DeepcellLabel'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import DeepcellPrimaryIconButton from 'components/shared/DeepcellPrimaryIconButton'
import DeepcellSecondarySelect from 'components/shared/DeepcellSecondarySelect'
import HubspotReactForm from 'components/shared/HubspotReactForm'
import ChatIcon from 'components/shared/icons/ChatIcon'
import useFlags from 'components/shared/useFlags'
import { ChangeEvent, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

export const PlotImagesFilter = (): JSX.Element => {
    const {
        setCellImagesFilter,
        cellVisualizations: { cellImagesFilter },
    } = useCellVisualizationsSlice()
    const { imageSize, displayImages, spacingAdjust } = cellImagesFilter
    const { demoEnabled } = useFlags()
    const contactUsVisible = demoEnabled === 'yes'

    const eventsManager = useEventsManager()

    useHotkeys(
        'i',
        () => {
            setCellImagesFilter({
                cellImagesFilter: { ...cellImagesFilter, displayImages: !displayImages },
                eventsManager,
            })
        },
        {},
        [setCellImagesFilter, cellImagesFilter, displayImages]
    )

    const onDisplayImagesChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCellImagesFilter({
            cellImagesFilter: { ...cellImagesFilter, displayImages: e.target.checked },
            eventsManager,
        })
    }

    const onImageSizeChange = (e: SelectChangeEvent<unknown>) => {
        const val = e.target.value as number
        setCellImagesFilter({
            cellImagesFilter: { ...cellImagesFilter, imageSize: val },
            eventsManager,
        })
    }
    const onSpacingAdjustChange = (e: SelectChangeEvent<unknown>) => {
        const val = e.target.value as number
        setCellImagesFilter({
            cellImagesFilter: { ...cellImagesFilter, spacingAdjust: val },
            eventsManager,
        })
    }

    const [showContactUs, setShowContactUs] = useState(false)

    return (
        <>
            <Stack
                id="plot-images-filter"
                direction="row"
                spacing={2}
                data-testid="plot-images-filter"
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch checked={displayImages} onChange={onDisplayImagesChange} />
                        }
                        sx={{ color: 'black' }}
                        label="Display Images (I)"
                        labelPlacement="start"
                    />
                </FormGroup>

                <div style={{ display: 'flex', gap: '8px' }}>
                    <DeepcellLabel label="Image Size" />
                    <DeepcellSecondarySelect
                        items={CELL_IMAGE_SIZES}
                        value={imageSize}
                        onChange={onImageSizeChange}
                        sx={{ width: '8rem' }}
                        formControlProps={{ fullWidth: false }}
                    />
                </div>

                <div style={{ display: 'flex', gap: '8px' }}>
                    <DeepcellLabel label="Image Spacing" />
                    <DeepcellSecondarySelect
                        items={CELL_IMAGE_SPACING_OPTIONS}
                        value={spacingAdjust}
                        onChange={onSpacingAdjustChange}
                        sx={{ width: '8rem' }}
                        formControlProps={{ fullWidth: false }}
                    />
                </div>
                {contactUsVisible && (
                    <DeepcellPrimaryIconButton
                        data-testid="contact-us-button"
                        onClick={() => {
                            eventsManager.sendContactUsByEvent(new Date().getTime())
                            return setShowContactUs(true)
                        }}
                        size="small"
                        outlined
                        sx={{
                            position: 'absolute',
                            bottom: '13px',
                            right: '23px',
                            paddingY: '10px !important',
                            backgroundColor: '#A4EFC0',
                            '&:hover': {
                                boxShadow: '0px 0px 10px #5F55D1',
                                backgroundColor: '#A4EFC0',
                            },
                        }}
                        icon={<ChatIcon />}
                    />
                )}
            </Stack>

            <DeepcellPopover
                open={showContactUs}
                sx={{
                    '& .MuiPopover-paper': {
                        width: '400px',
                        minHeight: '450px',
                        inset: 'unset !important',
                        bottom: '13px !important',
                        right: '16px !important',
                    },
                }}
                onClose={() => setShowContactUs(false)}
            >
                <HubspotReactForm
                    portalId="22481347"
                    formId="078978ad-3b6c-4eec-ba76-73ec4bb2849e"
                    onFormSubmitted={() => {
                        eventsManager.sendContactedUsSubmittedEvent('footer')
                    }}
                />
            </DeepcellPopover>
        </>
    )
}

export default PlotImagesFilter
