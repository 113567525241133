import { CellClass } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import PencilIcon from '@mui/icons-material/Create'
import { styled } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { DeepcellAutocomplete, DeepcellPrimaryButton, DeepcellTextField } from 'components/shared'
import { formatDateTime, formatDuration } from 'components/shared/date-utils'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import OkCancelDialogBody from 'components/shared/OkCancelDialogBody'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { NoContentReturned, Run, updateRun, UpdateRunRequest } from 'utils/api'
import { CellClassEncoderDecoder } from 'utils/proto-utils'

// Displayed when there are multiple runs selected and there is more than one value for a field
const MULTIPLE_VALUES = '<Multiple Values>'

interface BasicInfoTableProps {
    runs: Run[]
    onEdit: (runs: Run[]) => void
}

const ChangeIcon = styled(PencilIcon)({
    cursor: 'pointer',
    width: '1rem',
    height: '0.8rem',
})

/*
 *  Data type to be dysplayed
 */
type BasicDataType = string | boolean | number | null | string[] | number[] | undefined

/** Extracts a field from each run in an array and turns into a comma-separated list */
function joinValues(runs: Run[], field: keyof Run) {
    return runs.map((r) => r[field]).join(', ')
}

/* If all runs have the same value for the property, then returns that value, otherwise returns "<Multiple Values>" */

function mergeDisplayValue(runs: Run[], property: keyof Run): BasicDataType {
    const values = _.uniq(runs.map((run: Run) => run[property]))
    if (values.length === 1) return values[0] as BasicDataType
    return MULTIPLE_VALUES
}

/** Gets cell class strings from an array of cell class numbers */
function getCellClassStrings(cell_classes?: number[]): string[] {
    return (
        cell_classes?.map((cellClass: number) =>
            CellClassEncoderDecoder.convertToString(cellClass)
        ) || []
    )
}

/*
 * Row data type to be displayed in the table.
 */
interface Row {
    name: string
    data: BasicDataType
    field?: string | null
    editable: boolean | undefined
}

interface RowDataType {
    label: string
    values: BasicDataType
    field?: string | null
    editable?: boolean
}

/*
 * We know it’s imaging if the well_sorting_configurations is present
 */
function getRunType(run: Run) {
    return run?.well_sorting_configurations && run?.well_sorting_configurations.length > 0
        ? 'Sorting'
        : 'Imaging'
}

const gridOptions = {
    defaultCsvExportParams: {
        columnKeys: ['label', 'values'],
    },
}

export default function BasicInfoTable(props: BasicInfoTableProps): ReactElement {
    const { displayNotification } = useNotificationSlice()
    const { runs, onEdit } = props
    const [value, setValue] = useState<BasicDataType>('')
    const [selectedField, selectField] = useState<Row | null>(null)
    const [popoverAnchor, setPopoverAnchor] = useState<Element | null>(null)

    const single = runs.length === 1
    let rowData: RowDataType[] = []
    let columnDefs: ColDef[] = []
    const selectedRun = runs.length > 0 ? runs[0] : undefined

    const select = (r: Row) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setValue(single ? r.data : '')
        selectField(r)
        setPopoverAnchor(e.currentTarget)
    }

    function unselect() {
        selectField(null)
    }

    /*
     * Draw the pencil button if the row is editable
     */
    const drawEditPencilButton = (r: RowDataType) => {
        const { editable, label, values, field } = r
        const data: Row = {
            editable,
            name: label,
            data: values,
            field,
        }
        return data.editable ? (
            <DeepcellPrimaryButton
                data-testid={`${field}-edit`}
                small
                onClick={select(data)}
                startIcon={<ChangeIcon fontSize="small" />}
            />
        ) : null
    }

    if (single) {
        const run = runs[0]
        // Time display parts
        const startTime = formatDateTime(run?.start_time)
        const endTime = formatDateTime(run?.stop_time)
        const duration = run?.stop_time
            ? moment.duration(moment(run.stop_time).diff(run.start_time))
            : null
        const durationString = duration ? ` ( ${formatDuration(duration)} duration )` : ''
        // Cell count display parts
        const countPerWell = run?.well_counts?.map((item) => item.count)
        const sortedCellCount = countPerWell ? _.sum(countPerWell) : null
        const sortedCells = sortedCellCount !== null ? ` (${sortedCellCount} sorted)` : ''

        rowData = [
            {
                label: 'Run Id',
                values: run?.run_id,
            },
            {
                label: 'Run Type',
                values: getRunType(run),
            },
            {
                label: 'Time',
                values: `${startTime}${endTime ? ` to ${endTime}` : ''}${durationString}`,
            },
            {
                label: 'Cell Count',
                values: `${run?.total_cell_count}${sortedCells}`,
            },
            {
                label: 'Sample Id',
                values: run?.sample_id || '',
                editable: true,
                field: 'sample_id',
            },
            {
                label: 'Instrument Serial Number',
                values: run?.instrument_serial_number || '',
                editable: false,
                field: 'instrument_serial_number',
            },
            {
                label: 'Chip Barcode',
                values: run?.chip_barcode || '',
                editable: false,
                field: 'chip_barcode',
            },
            {
                label: 'Project Code',
                values: run?.project_code || '',
                editable: true,
                field: 'project_code',
            },
            {
                label: 'Experiment Code',
                values: run?.experiment_code || '',
                editable: true,
                field: 'experiment_code',
            },
            {
                label: 'Model',
                values: run?.model || '',
            },
            {
                label: 'Run Description',
                values: run?.description || '',
                editable: true,
                field: 'description',
            },
            {
                label: 'Run Notes',
                values: run?.run_notes || '',
                editable: true,
                field: 'run_notes',
            },
            {
                label: 'Target Cell Classes',
                values: getCellClassStrings(run?.target_cell_classes),
                editable: true,
                field: 'target_cell_classes',
            },
            {
                label: 'User',
                values: run?.user_email,
            },
        ]

        columnDefs = [
            {
                headerName: 'Label',
                field: 'label',
                pinned: 'left',
                lockPinned: true,
                flex: 2,
                cellClass: 'lock-pinned',
            },
            {
                headerName: 'Values',
                field: 'values',
                flex: 3,
            },
            {
                headerName: '',
                field: 'values',
                flex: 1,
                cellRendererFramework: (params: ICellRendererParams) =>
                    drawEditPencilButton(params.data),
            },
        ]
    } else {
        // Time summary
        const minTime = formatDateTime(
            moment.min.apply(
                null,
                runs.map((run) => moment(run?.start_time))
            )
        )
        const maxTime = formatDateTime(
            moment.max.apply(
                null,
                runs.map((run) => moment(run?.stop_time ? run?.stop_time : run?.start_time))
            )
        )
        // Cell count summary
        const totalCellCount = _.sum(runs.map((run) => run.total_cell_count))
        // Target Cell Classes
        const targetCellClasses = mergeDisplayValue(runs, 'target_cell_classes')
        const targetCellClassString =
            targetCellClasses === MULTIPLE_VALUES
                ? MULTIPLE_VALUES
                : getCellClassStrings(targetCellClasses as number[] | undefined)

        rowData = [
            {
                label: 'Run Ids',
                values: joinValues(runs, 'run_id'),
            },
            {
                label: 'Run Types',
                values: MULTIPLE_VALUES,
            },
            {
                label: 'Time',
                values: `${minTime} to ${maxTime}`,
            },
            {
                label: 'Total Cell Count',
                values: totalCellCount,
            },
            {
                label: 'Sample Id',
                values: mergeDisplayValue(runs, 'sample_id') || '',
                editable: true,
                field: 'sample_id',
            },
            {
                label: 'Instrument Serial Number',
                values: mergeDisplayValue(runs, 'instrument_serial_number'),
                editable: false,
                field: 'instrument_serial_number',
            },
            {
                label: 'Chip Barcode',
                values: mergeDisplayValue(runs, 'chip_barcode'),
                editable: false,
                field: 'chip_barcode',
            },
            {
                label: 'Project Code',
                values: mergeDisplayValue(runs, 'project_code'),
                editable: true,
                field: 'project_code',
            },
            {
                label: 'Experiment Code',
                values: mergeDisplayValue(runs, 'experiment_code'),
                editable: true,
                field: 'experiment_code',
            },
            {
                label: 'Model',
                values: mergeDisplayValue(runs, 'model'),
            },
            {
                label: 'Run Description',
                values: mergeDisplayValue(runs, 'description'),
                editable: false,
                field: 'description',
            },
            {
                label: 'Run Notes',
                values: mergeDisplayValue(runs, 'run_notes'),
                editable: true,
                field: 'run_notes',
            },
            {
                label: 'Target Cell Classes',
                values: targetCellClassString,
                editable: true,
                field: 'target_cell_classes',
            },
            {
                label: 'User',
                values: mergeDisplayValue(runs, 'user_email'),
            },
        ]

        columnDefs = [
            {
                headerName: 'Label',
                field: 'label',
                pinned: 'left',
                lockPinned: true,
                flex: 2,
                cellClass: 'lock-pinned',
            },
            {
                headerName: 'Values',
                field: 'values',
                flex: 3,
            },
            {
                headerName: '',
                field: 'values',
                flex: 1,
                cellRendererFramework: (params: ICellRendererParams) =>
                    drawEditPencilButton(params.data),
            },
        ]
    }

    async function tryUpdateRun(run?: Run) {
        if (!run || !selectedRun || !selectedField) {
            unselect()
            return
        }

        const {field} = selectedField
        if (field) {
            const safeTargetClasses =
                value instanceof Array
                    ? value?.map((v) => CellClassEncoderDecoder.convertFromString(v.toString()))
                    : []
            const safeValue: BasicDataType = field === 'target_cell_classes' ? safeTargetClasses : value
            try {
                const payload: UpdateRunRequest = {run_id: run.run_id, [field]: safeValue }
                const updatedRun = await updateRun(payload)
                onEdit([updatedRun])
            } catch (err) {
                if (err instanceof NoContentReturned) {
                    const editedRuns = single
                        ? [{ ...run, [field]: safeValue }]
                        : runs.map((_run) => ({ ..._run, [field]: safeValue }))
                    onEdit(editedRuns)
                } else {
                    const errMessage =
                        (err as Error).message ||
                        `An unexpected error occurred when trying to Update ${field}`
                    displayNotification({ message: errMessage, type: 'error' })
                }
            }
        }
        unselect()
    }

    async function tryUpdateRuns(run?: Run) {
        if (single) {
            await tryUpdateRun(run)
        } else {
            await Promise.all(runs.map((r) => tryUpdateRun(r)))
        }
    }

    const autoValue = value instanceof Array ? value.map((n) => n.toString()) : []
    const input =
        selectedField?.field === 'target_cell_classes' ? (
            <DeepcellAutocomplete
                label="Target Cell Classes"
                multiple
                id="target-cell-classes"
                options={getCellClassStrings([...Object.values(CellClass)])}
                value={autoValue}
                onChange={(_e, values) => setValue(values)}
                getOptionLabel={(option) => option}
                style={{ width: 300 }}
            />
        ) : (
            <DeepcellTextField
                value={value}
                label={selectedField?.name}
                onChange={(e) => setValue(e.target.value)}
            />
        )

    async function dialogAction(canceled: boolean) {
        if (!canceled) await tryUpdateRuns(selectedRun)
        setPopoverAnchor(null)
    }

    return (
        <>
            <div style={{ height: '427px' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} gridOptions={gridOptions} />
            </div>

            <DeepcellPopover
                className="MuiPopover-paper"
                anchorEl={popoverAnchor}
                open={Boolean(popoverAnchor)}
                onClose={() => setPopoverAnchor(null)}
            >
                <OkCancelDialogBody
                    titleLabel={`Editing ${selectedField?.name}`}
                    pending={false}
                    handleButtonClick={(canceled) => dialogAction(canceled)}
                >
                    {input}
                </OkCancelDialogBody>
            </DeepcellPopover>
        </>
    )
}
