import { styled } from '@mui/material'
import { GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps, ChangeDetectionStrategyType } from 'ag-grid-react'
import React, { useEffect, useMemo } from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import Moment from 'moment'
import useAuthTokens from 'utils/useAuthTokens'
import useFlags from 'components/shared/useFlags'
import { columnDefs, RunsHeaderName } from './metadata'

export const RUN_DATA_CACHE_BLOCK_SIZE = 100

const processCellHandler = (params: ProcessCellForExportParams) => {
    const { valueFormatter } = params.column.getColDef()
    if (valueFormatter) {
        switch (params.column.getColDef().headerName) {
            case RunsHeaderName.RunType:
                return params.value?.length > 0 ? 'Sorting' : 'Imaging'
            case RunsHeaderName.StartTime:
                return Moment(params.value).format('YYYY-MM-DD HH:mm:ss') || ''
            case RunsHeaderName.Status:
                return (params.value as boolean) ? 'Complete' : 'In Progress'
            default:
                return params.value
        }
    }
    return params.value
}

const gridOptions = {
    defaultCsvExportParams: {
        processCellCallback: processCellHandler,
    },
    defaultExcelExportParams: {
        processCellCallback: processCellHandler,
    },
    processCellForClipboard: processCellHandler,
}

const TableContainer = styled('div')({
    width: '100%',
    height: '100%',
})

interface IRunGrid extends AgGridReactProps {
    gridApi?: GridApi | null
}
function RunGrid(props: IRunGrid) {
    const { permissions } = useAuthTokens()
    const { gridApi } = props
    const { manualQualityReviewEnabled } = useFlags()
    const withoutQualityReviewColumn = useMemo(
        () => columnDefs.filter((item) => item.headerName !== RunsHeaderName.QualityReview),
        []
    )
    useEffect(() => {
        if (!permissions.has('deepcell_internal:read')) {
            gridApi?.setColumnDefs([])
            gridApi?.setColumnDefs(withoutQualityReviewColumn)
        }
    }, [gridApi, permissions, withoutQualityReviewColumn])
    return (
        <TableContainer className="ag-theme-balham ag-theme-deepcell" data-testid="runs-table">
            <AgGridReact
                rowSelection="multiple"
                rowModelType="infinite"
                cacheBlockSize={RUN_DATA_CACHE_BLOCK_SIZE}
                columnDefs={
                    manualQualityReviewEnabled === 'yes' ? columnDefs : withoutQualityReviewColumn
                }
                gridOptions={gridOptions}
                rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                suppressDragLeaveHidesColumns
                {...props}
            />
        </TableContainer>
    )
}

export default React.memo(RunGrid)
