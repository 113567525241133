import { Button, styled } from '@mui/material'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

const LinkButton = styled(Button)({
  backgroundColor: 'transparent !important',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const ToggleVisibilityButton = (): JSX.Element => {
  const eventsManager = useEventsManager()

  const {
    cellVisualizations: { legendLabelColors },
    setLegendLabelColors,
  } = useCellVisualizationsSlice()
  const hideText = legendLabelColors.every((llc) => !llc.isHidden) ? 'Hide' : 'Show'

  const onLinkClick = () => {
    if (hideText === 'Hide') {
      eventsManager.sendFilterShowAllEvent()
    } else {
      eventsManager.sendFilterHideAllEvent()
    }

    setLegendLabelColors(
      legendLabelColors.map((llc) => ({ ...llc, isHidden: hideText === 'Hide' }))
    )
  }

  return (
    <LinkButton onClick={onLinkClick} sx={{ minWidth: 'fit-content' }}>
      {hideText} All
    </LinkButton>
  )
}

export default ToggleVisibilityButton
