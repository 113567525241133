import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import useLabelingMicrotaskSlice from 'redux/slices/hooks/useLabelingMicrotaskSlice'
import {
    MicrotaskAccordion,
    MicrotaskAccordionDetails,
    MicrotaskAccordionHeader,
} from './sharedComponents'

function MicrotaskCellDisplayOptions(): JSX.Element {
    const {
        labelingMicrotask: { displayOptions },
        setDisplayOptions,
    } = useLabelingMicrotaskSlice()

    const updateDisplayOptions =
        (key: keyof typeof displayOptions) => (_e: unknown, checked: boolean) => {
            setDisplayOptions({ ...displayOptions, [key]: checked })
        }

    return (
        <MicrotaskAccordion expanded>
            <MicrotaskAccordionHeader>
                <Typography variant="h6">Cell Display Options</Typography>
            </MicrotaskAccordionHeader>

            <MicrotaskAccordionDetails>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={displayOptions.centerCrop}
                            onChange={updateDisplayOptions('centerCrop')}
                        />
                    }
                    label="Enable Zoom (Z)"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={displayOptions.sharpen}
                            onChange={updateDisplayOptions('sharpen')}
                        />
                    }
                    label="Enable Sharpen (S)"
                />
            </MicrotaskAccordionDetails>
        </MicrotaskAccordion>
    )
}

export default MicrotaskCellDisplayOptions
