import * as ROUTES from 'constants/routes'
import { ReviewResult } from '@deepcell/cell_data_api_proto'
import { FindInPage } from '@mui/icons-material'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Typography from '@mui/material/Typography'
import { formatDateTime } from 'components/shared/date-utils'
import { useHistory } from 'react-router-dom'
import useGetReviewResults from './useGetReviewResults'

interface Props {
    taskId: number
}

function LabelingTaskReviewHistory(props: Props): JSX.Element {
    const history = useHistory()
    const { taskId } = props
    const { data } = useGetReviewResults(taskId)
    const reviewResults = data?.reviewResults

    if (!reviewResults || reviewResults.length === 0) {
        return <></>
    }

    function navigateTo(oracleIndex?: number) {
        if (oracleIndex === undefined) {
            return
        }
        history.push({
            pathname: ROUTES.LABELING_REVIEW_SUMMARY_VIEW.replace(
                ':taskId',
                String(taskId)
            ).replace(':oracleIndex', String(oracleIndex)),
        })
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ mt: 2 }} variant="h6">
                Review history:
            </Typography>
            <List>
                {reviewResults?.map((result: ReviewResult) => (
                    <ListItem
                        key={result.getOracleIndex()}
                        button
                        onClick={() => navigateTo(result.getOracleIndex())}
                    >
                        <ListItemIcon>
                            <FindInPage />
                        </ListItemIcon>

                        <ListItemText
                            primary={result.getReviewUser()}
                            secondary={formatDateTime(result.getCreatedTime())}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default LabelingTaskReviewHistory
