import {
    LabelingTask,
    LabelingTaskActionType,
    LabelingTaskActionTypeMap,
    LabelingTaskPriority,
    LabelingTaskPriorityMap,
    LabelingTaskStatus,
    LabelingTaskStatusMap,
} from '@deepcell/cell_data_api_proto'
import {
    Cell,
    CellClass,
    CellClassMap,
    Prediction,
    SampleType,
    SampleTypeMap,
} from '@deepcell/proto_schema_js/deepcell_schema_pb'
import * as jspb from 'google-protobuf'
import { Message } from 'google-protobuf'
import moment from 'moment'

export declare type CellClassValue = CellClassMap[keyof CellClassMap]
export declare type SampleTypeValue = SampleTypeMap[keyof SampleTypeMap]
export declare type LabelingTaskStatusValue = LabelingTaskStatusMap[keyof LabelingTaskStatusMap]
export declare type LabelingTaskPriorityValue =
    LabelingTaskPriorityMap[keyof LabelingTaskPriorityMap]
export declare type LabelingTaskActionTypeValue =
    LabelingTaskActionTypeMap[keyof LabelingTaskActionTypeMap]

type EnumClassType =
    | SampleTypeMap
    | CellClassMap
    | LabelingTaskActionTypeMap
    | LabelingTaskStatusMap
    | LabelingTaskPriorityMap

export class EnumEncoderDecoder {
    prefix: string

    enumClass: EnumClassType

    invertedMap: { [key: number]: string }

    constructor(prefix: string, enumClass: EnumClassType) {
        this.prefix = prefix
        this.enumClass = enumClass
        this.invertedMap = Object.entries(enumClass).reduce(
            (acc, [key, val]) => ({ ...acc, [val]: key }),
            {} as Record<number, string>
        )
    }

    convertFromString(key: string): number {
        const fullKey = `${this.prefix}${key}` as keyof EnumClassType
        if (fullKey in this.enumClass) {
            return this.enumClass[fullKey]
        }
        throw Error(`Unknown key ${fullKey} in ${this.enumClass}`)
    }

    convertToString(value: number | undefined): string {
        if (value === undefined) return ''
        return this.invertedMap[value].substring(this.prefix.length)
    }
}

export const SampleTypeEncoderDecoder = new EnumEncoderDecoder('SAMPLE_TYPE_', SampleType)
export const CellClassEncoderDecoder = new EnumEncoderDecoder('CLASS_', CellClass)
export const LabelingTaskStatusEncoderDecoder = new EnumEncoderDecoder(
    'TASK_STATUS_',
    LabelingTaskStatus
)
export const LabelingTaskPriorityEncoderDecoder = new EnumEncoderDecoder('', LabelingTaskPriority)
export const LabelingTaskActionTypeEncoderDecoder = new EnumEncoderDecoder(
    'ACTION_TYPE_',
    LabelingTaskActionType
)

export const base64StrToMessage = <T extends Message>(
    base64Str: string,
    messageClass: { deserializeBinary(bytes: Uint8Array): T }
): T => {
    const buf = Buffer.from(base64Str, 'base64')
    return messageClass.deserializeBinary(buf)
}

export const messageToBase64Str = (message: jspb.Message): string => {
    const buf = message.serializeBinary()
    const bufStr = buf.reduce((data, byte) => data + String.fromCharCode(byte), '')
    return btoa(bufStr)
}

export const messagesEqual = <T extends jspb.Message>(
    a: T | undefined,
    b: T | undefined
): boolean => {
    return a?.toString() === b?.toString()
}

export function roundDueDate(task: LabelingTask): Date | undefined {
    const dueDate = task.getDueDate()
    if (dueDate === undefined || dueDate === null || dueDate === 0) return undefined

    return moment(task.getDueDate()).endOf('day').toDate()
}

export function getCellPrediction(cell: Cell): Prediction | undefined {
    const predictions = cell.getPredictionsList()
    if (predictions.length === 0) return undefined

    return predictions[0]
}

export function getPredictedClass(prediction: Prediction): number | undefined {
    if (prediction === undefined) return undefined

    if (prediction.hasAverage()) {
        return prediction.getAverage()
    }
    return prediction.getVoting()
}
