import { LabelingTask } from '@deepcell/cell_data_api_proto'
import { ListItem, ListItemIcon, ListItemText, StyledComponentProps } from '@mui/material'
import defaultPopoverProps from 'components/shared/popover'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import React, { ComponentType, FunctionComponent, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { LabelingTaskWithActions } from 'utils/api'

interface PopoverProps {
    tasks: LabelingTask[]
    handleClose: (updatedTasks?: LabelingTaskWithActions[]) => void
}

interface Props {
    handleUpdate: (labelingTasks?: LabelingTaskWithActions[]) => void
    tasks: LabelingTask[]
    popupId: string
    hotkey: string
    label: string
    popoverEl: ComponentType<PopoverProps & StyledComponentProps>
    iconEl: ComponentType
}

const LabelingTaskPopoverListItem: FunctionComponent<Props> = ({
    handleUpdate,
    tasks,
    popupId,
    hotkey,
    label,
    popoverEl,
    iconEl,
}: Props) => {
    // These need to have first letter capitalized to use as tags
    const MyIcon = iconEl
    const MyPopover = popoverEl

    const [popoverAnchor, setPopoverAnchor] = useState<Element | null>(null)

    const inputRef = useRef<HTMLDivElement>(null)

    function open() {
        if (inputRef && inputRef.current) {
            setPopoverAnchor(inputRef.current)
        }
    }

    function handleClose(updatedTasks?: LabelingTaskWithActions[]) {
        if (updatedTasks) {
            handleUpdate(updatedTasks)
        }
        setPopoverAnchor(null)
    }

    useHotkeys(hotkey, open, { keyup: true }, [open, inputRef])

    const handleClick = (event: React.MouseEvent<Element>) => {
        setPopoverAnchor(event.currentTarget)
    }

    return (
        <div>
            <ListItem key={popupId} button onClick={handleClick} ref={inputRef}>
                <ListItemIcon>
                    <MyIcon />
                </ListItemIcon>
                <ListItemText primary={label} />
            </ListItem>
            {tasks !== undefined && (
                <DeepcellPopover
                    {...defaultPopoverProps}
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    onClose={() => setPopoverAnchor(null)}
                >
                    <MyPopover handleClose={handleClose} tasks={tasks} />
                </DeepcellPopover>
            )}
        </div>
    )
}

export default LabelingTaskPopoverListItem
