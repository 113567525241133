import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import Typography from '@mui/material/Typography'
import { CellImageAction } from 'components/shared/CellImageControl'
import ContentLoading from 'components/shared/ContentLoading'
import { useEffect } from 'react'
import { CellResponse } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'
import CellImages from './CellImages'
import { QueryState, useSearchCells } from './cellSearchHelpers'

interface Props {
  searchOptions: QueryState
  handleQueryUpdate: (action: CellImageAction) => void
  hoverCellId: CellId | undefined
  setActiveCellState: (value: CellResponse | undefined) => void
  setCellCount: (value: number) => void
  cellCount: number | undefined
}

const CellBrowsingSortBy = ({
  searchOptions,
  handleQueryUpdate,
  hoverCellId,
  setActiveCellState,
  setCellCount,
  cellCount,
}: Props): JSX.Element => {
  const { result: cellData, error, isLoading } = useSearchCells(searchOptions)

  const { selectedCellId } = searchOptions

  useEffect(() => {
    // Cache the cell count value to avoid refetching
    if (
      cellCount === undefined &&
      cellData?.count !== undefined &&
      cellData?.countIsEstimate !== undefined
    ) {
      setCellCount(cellData.count)
    }
  }, [setCellCount, cellCount, cellData])

  const detailCellId = selectedCellId || hoverCellId

  const activeCell: CellResponse | undefined = cellData?.cells.find((cell) =>
    messagesEqual(cell.cell.getCellId(), detailCellId)
  )

  useEffect(() => {
    setActiveCellState(activeCell)
  }, [setActiveCellState, activeCell])
  return (
    <div data-testid="cellBrowsingSortBy">
      {isLoading ? <ContentLoading /> : null}
      {!isLoading && error ? <Typography>{error}</Typography> : null}
      {!isLoading && !error && cellData?.count === 0 ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>No cells matched your query</Typography>
      ) : null}
      {!isLoading && cellData ? (
        <CellImages cellData={cellData} handleQueryUpdate={handleQueryUpdate} />
      ) : null}
    </div>
  )
}

export default CellBrowsingSortBy
