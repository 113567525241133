import * as ROUTES from 'constants/routes'
import { LabelingTaskActionType, ReviewResult } from '@deepcell/cell_data_api_proto'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Checkbox, FormControlLabel, styled, Typography } from '@mui/material'
import { CellClickedEvent, ValueGetterParams, ValueSetterParams } from 'ag-grid-community'
import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { CellDisplayOptions } from 'components/shared/CellImageControl'
import CheckboxCellRenderer from 'components/shared/CheckboxCellRenderer'
import ContentLoading from 'components/shared/ContentLoading'
import { InstructionsText } from 'components/shared/sharedStyles'
import firebase from 'firebase'
import Moment from 'moment'
import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import { DoActionData, doActionOnLabelingTask } from 'utils/api'
import {
    CellClassEncoderDecoder,
    CellClassValue,
    LabelingTaskActionTypeValue,
} from 'utils/proto-utils'
import useGetReviewResults from '../tasks/useGetReviewResults'
import ExceptionCellGrid from './ExceptionCellGrid'
import {
    LeftPanel,
    MicrotaskAccordion,
    MicrotaskAccordionDetails,
    MicrotaskAccordionHeader,
    MicrotaskAccordionHeaderTaskHeader,
} from './sharedComponents'

Moment.locale('en')
momentLocalizer()

const AgGridReactWithChildren = AgGridReact as unknown as (
    props: React.PropsWithChildren<AgGridReactProps>
) => React.ReactElement

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 60px)',
})

const SummaryTable = styled('div')({
    width: '100%',
    height: '300px',

    '& .MuiCheckbox-root': {
        padding: '0px 15px',
    },
})

const ContentPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    flex: 1,
    maxHeight: '100%',
    overflow: 'scroll',
}))

const FilterControl = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
}))

function ReviewSummaryPage(): JSX.Element {
    const analytics = firebase.analytics()
    analytics.logEvent('labeling/review_summary/show')

    const history = useHistory()

    const { taskId, oracleIndex } = useParams<{
        taskId: string
        oracleIndex: string | undefined
    }>()
    const taskIdInt = parseInt(taskId, 10)
    const oracleIndexInt = oracleIndex ? parseInt(oracleIndex, 10) : -1
    const editable = oracleIndexInt === -1

    // Track a selected class to view cell images
    const [selectedClass, setSelectedClass] = useState<CellClassValue>()

    // Track whether each class is approved
    const approved = useRef<Array<boolean>>([])

    const { data: reviewResultData } = useGetReviewResults(taskIdInt)
    const doActionMutation = useMutation(doActionOnLabelingTask)
    const [additionalNotes, setAdditionalNotes] = useState<string>()
    const [displayOptions, setDisplayOptions] = useState<CellDisplayOptions>({
        centerCrop: false,
    })
    const [savingReview, setSavingReview] = useState<boolean>()

    const toggleCenterCrop = useCallback(() => {
        setDisplayOptions({
            ...displayOptions,
            centerCrop: !displayOptions.centerCrop,
        })
    }, [displayOptions])

    const toggleSharpen = useCallback(() => {
        setDisplayOptions({
            ...displayOptions,
            sharpen: !displayOptions.sharpen,
        })
    }, [displayOptions])

    useHotkeys('z', toggleCenterCrop, [toggleCenterCrop, displayOptions])
    useHotkeys('s', toggleSharpen, [toggleSharpen, displayOptions])

    async function doAction(
        action: LabelingTaskActionTypeValue,
        actionData: DoActionData
    ): Promise<void> {
        const params = {
            labelingTaskId: taskIdInt,
            action,
            actionData,
        }
        // @TODO: Handle errors better
        await doActionMutation.mutateAsync(params)
    }

    if (!reviewResultData) {
        return <ContentLoading />
    }

    // @TODO Make a way to access additional review results
    const reviewSummary =
        oracleIndexInt === -1
            ? reviewResultData.reviewResults.reduce(
                  (prevValue: ReviewResult | null, currValue: ReviewResult) => {
                      if (prevValue === null) {
                          return currValue
                      }
                      const currOracleIndex = currValue?.getOracleIndex() ?? -1
                      const prevOracleIndex = prevValue?.getOracleIndex() ?? -1
                      if (currOracleIndex > prevOracleIndex) {
                          return currValue
                      }
                      return prevValue
                  },
                  null
              )
            : reviewResultData.reviewResults.find(
                  (result) => result.getOracleIndex() === oracleIndexInt
              )

    if (!reviewSummary) {
        return (
            <Typography variant="body1">
                Unable to find review summary for task {taskId} and oracle {oracleIndex}. Please ask
                an engineer.
            </Typography>
        )
    }

    const reviewResults = reviewSummary.getResultItemsList()
    const selectedResult = reviewResults.find((item) => item.getCellClass() === selectedClass)
    const exceptionCellListId = selectedResult?.getExceptionCellListId()
    const reviewedCellListId = selectedResult?.getReviewedCellListId()
    const errorRateIsExact =
        selectedResult?.getReviewedCellCount() === selectedResult?.getLabeledCellCount()
    const selectedClassName = CellClassEncoderDecoder.convertToString(selectedClass)

    function formatRate(rate: number): string {
        return (rate * 100).toFixed(1)
    }

    function formatErrorRate(resultItem: ReviewResult.ReviewResultItem) {
        const errorRateLow = resultItem.getErrorRateCiLow()
        const errorRateHigh = resultItem.getErrorRateCiHigh()

        if (errorRateLow === undefined || errorRateHigh === undefined) {
            return ''
        }
        if (Math.abs(errorRateLow - errorRateHigh) < 1e-6) {
            return `${formatRate(errorRateHigh)}%`
        }
        return `${formatRate(errorRateLow)}-${formatRate(errorRateHigh)}%`
    }

    function reviewResultsValueGetter(params: ValueGetterParams): string | boolean | undefined {
        const { field } = params.colDef
        const reviewResult: ReviewResult.ReviewResultItem = params.data
        const index = reviewResults?.indexOf(reviewResult)

        switch (field) {
            case 'cell_class':
                return CellClassEncoderDecoder.convertToString(reviewResult.getCellClass())
            case 'error_rate':
                return formatErrorRate(reviewResult)

            case 'approve':
                if (index === undefined) return false
                return approved.current[index]
            default:
                throw Error(`Unhandled field ${field}`)
        }
    }

    function reviewResultsValueSetter(params: ValueSetterParams): boolean {
        const { field } = params.colDef
        const reviewResult: ReviewResult.ReviewResultItem = params.data
        const index = reviewResults.indexOf(reviewResult)

        switch (field) {
            case 'approve':
                approved.current[index] = params.newValue
                break
            default:
                throw Error(`Unhandled field ${field}`)
        }
        return true
    }

    function handleCellClicked(event: CellClickedEvent) {
        const reviewResult: ReviewResult.ReviewResultItem = event.data
        setSelectedClass(reviewResult.getCellClass())
    }

    async function handleSubmit() {
        setSavingReview(true)
        let allApproved = true
        let linkToTaskStatus = 'incomplete'
        const rejectedClasses: CellClassValue[] = []
        reviewResults.forEach((result: ReviewResult.ReviewResultItem, index: number) => {
            if (!approved.current[index]) {
                allApproved = false
                rejectedClasses.push(result.getCellClass())
            }
        })
        if (!allApproved) {
            const actionData: DoActionData = {
                rejected_cell_classes: rejectedClasses,
            }
            if (additionalNotes) {
                actionData.note_to_add = additionalNotes
            }
            console.error(actionData)
            await doAction(LabelingTaskActionType.ACTION_TYPE_REVIEW_FAIL, actionData)
        } else {
            const actionData: DoActionData = {}
            if (additionalNotes) {
                actionData.note_to_add = additionalNotes
            }
            await doAction(LabelingTaskActionType.ACTION_TYPE_REVIEW_ACCEPT, actionData)
            linkToTaskStatus = 'all'
        }
        setSavingReview(false)

        history.push({
            pathname: ROUTES.LABELING_TASKS,
            search: `?activeTab=search&statusCategory=${linkToTaskStatus}&selectedTaskIds=${taskId}`,
        })
    }

    const editNoteTextArea = (
        <Typography variant="body1">
            <InstructionsText
                name="labeling-notes"
                id="labeling-notes"
                minRows={3}
                value={additionalNotes}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    setAdditionalNotes(event.target.value)
                }
            />
        </Typography>
    )

    return (
        <Root>
            <LeftPanel sx={{ width: '450px', height: '100%', mr: 'inherit', flex: 'inherit' }}>
                <MicrotaskAccordionHeaderTaskHeader>
                    <Typography variant="h4">Review Summary</Typography>
                </MicrotaskAccordionHeaderTaskHeader>
                <MicrotaskAccordion expanded sx={{ flex: 4 }}>
                    {editable && (
                        <MicrotaskAccordionHeader>
                            <Typography variant="h6">Choose Cell Classes to Approve</Typography>
                        </MicrotaskAccordionHeader>
                    )}

                    <MicrotaskAccordionDetails>
                        <SummaryTable
                            className="ag-theme-balham ag-theme-deepcell"
                            data-testid="review-summary-table"
                        >
                            <AgGridReactWithChildren
                                rowData={reviewResults}
                                defaultColDef={{
                                    valueGetter: reviewResultsValueGetter,
                                    valueSetter: reviewResultsValueSetter,
                                    onCellClicked: handleCellClicked,
                                }}
                                frameworkComponents={{
                                    checkboxCellRenderer: CheckboxCellRenderer,
                                }}
                                rowHeight={35}
                            >
                                <AgGridColumn
                                    headerName="Cell Class"
                                    field="cell_class"
                                    width={150}
                                />
                                <AgGridColumn
                                    headerName="Error Rate"
                                    field="error_rate"
                                    width={120}
                                />
                                <AgGridColumn
                                    headerName={editable ? 'Approve?' : 'Approved'}
                                    field="approve"
                                    width={120}
                                    cellRenderer="checkboxCellRenderer"
                                />
                            </AgGridReactWithChildren>
                        </SummaryTable>
                    </MicrotaskAccordionDetails>
                </MicrotaskAccordion>

                <MicrotaskAccordion expanded sx={{ flex: 1 }}>
                    <MicrotaskAccordionHeader>
                        {editable && (
                            <Typography variant="h6">Add Review Notes to Task:</Typography>
                        )}
                        {!editable && <Typography variant="h6">Review Notes</Typography>}
                    </MicrotaskAccordionHeader>
                    <MicrotaskAccordionDetails>{editNoteTextArea}</MicrotaskAccordionDetails>
                </MicrotaskAccordion>

                {editable && (
                    <LoadingButton
                        variant="contained"
                        loading={savingReview}
                        onClick={handleSubmit}
                        color="primary"
                    >
                        Submit
                    </LoadingButton>
                )}
            </LeftPanel>
            <ContentPanel>
                {selectedClass !== undefined && selectedClass >= 0 ? (
                    <>
                        <MicrotaskAccordionHeaderTaskHeader>
                            <Typography variant="h4">{selectedClassName} Details</Typography>
                            {selectedResult && (
                                <Typography variant="body1">
                                    {errorRateIsExact && (
                                        <>
                                            Exceptions: {selectedResult.getExceptionCount()}/
                                            {selectedResult.getLabeledCellCount()} labeled cells.
                                            <br />
                                            Error rate is {formatErrorRate(selectedResult)}
                                        </>
                                    )}
                                    {!errorRateIsExact && (
                                        <>
                                            Exceptions: {selectedResult.getExceptionCount()}/
                                            {selectedResult.getReviewedCellCount()} reviewed cells
                                            (sampled from {selectedResult.getLabeledCellCount()}{' '}
                                            labeled cells)
                                            <br />
                                            Estimated error rate in all cells labeled as{' '}
                                            {selectedClassName} is {formatErrorRate(selectedResult)}
                                        </>
                                    )}
                                </Typography>
                            )}
                            <FilterControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={displayOptions.centerCrop}
                                            onChange={(
                                                _event: React.ChangeEvent<HTMLInputElement>,
                                                _checked: boolean
                                            ) => toggleCenterCrop()}
                                        />
                                    }
                                    label="Zoom (Z)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={displayOptions.sharpen}
                                            onChange={(
                                                _event: React.ChangeEvent<HTMLInputElement>,
                                                _checked: boolean
                                            ) => toggleSharpen()}
                                        />
                                    }
                                    label="Sharpen (S)"
                                />
                            </FilterControl>
                        </MicrotaskAccordionHeaderTaskHeader>
                        {reviewedCellListId && (
                            <Box sx={{ p: 1, height: '100%' }}>
                                <ExceptionCellGrid
                                    reviewedCellListId={reviewedCellListId}
                                    exceptionCellListId={exceptionCellListId}
                                    displayOptions={displayOptions}
                                />
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <MicrotaskAccordionHeaderTaskHeader>
                            <Typography variant="h4">Cell Class Details</Typography>
                        </MicrotaskAccordionHeaderTaskHeader>
                        <MicrotaskAccordion expanded>
                            <MicrotaskAccordionDetails>
                                <Typography variant="body1">
                                    Click on a cell class to see review details
                                </Typography>
                            </MicrotaskAccordionDetails>
                        </MicrotaskAccordion>
                    </>
                )}
            </ContentPanel>
        </Root>
    )
}

export default ReviewSummaryPage
