import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { useEffect, useRef } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { datasetItems } from 'redux/types'
import { useAppIsReady } from 'utils/useAppIsReady'
import { getSessionData } from './getSessionData'

export const useLoadCellInfoEffect = (): void => {
  const { sessionId, versionId, updateVersion } = useCellVisualizationUrlParams()
  const { setStore, setCellsData } = useCellVisualizationsSlice()
  const loaded = useRef(false)
  const isGettingSession = useRef(false)

  const appIsReady = useAppIsReady()

  const readyToGetData = sessionId && appIsReady && !loaded.current && !isGettingSession.current

  /*
    When calling an async api in a useEffect, remember that the useEffect
    might run again before the api call is resolved. Checking isGettingSession
    prevents the api from getting called twice
    */
  useEffect(() => {
    if (!readyToGetData) return

    const getArrowTable = async () => {
      isGettingSession.current = true
      const { cellsData, sessionData } = await getSessionData({
        sessionId,
        versionId,
      })

      if (cellsData) {
        loaded.current = true
        setCellsData(cellsData)
      }

      if (sessionData) {
        if (sessionData.version_config) {
          const matchingDsi = datasetItems.find((dsi) => dsi.id === `${sessionId}`)
          setStore({ selectedDataset: matchingDsi, ...sessionData.version_config, cellsData })
        }

        if (sessionData.version_id) updateVersion(sessionData.version_id)
      }

      isGettingSession.current = false
    }

    getArrowTable()
  }, [readyToGetData, sessionId, setCellsData, setStore, updateVersion, versionId])


  /**
   * Resetting the loaded and isGettingSession so that the API can be called again especially when the session id has changed
   */
  useEffect(() => {
    isGettingSession.current = false
    loaded.current = false
  }, [sessionId]);
}

export default useLoadCellInfoEffect
