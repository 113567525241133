import ImagesearchRollerRoundedIcon from '@mui/icons-material/ImagesearchRollerRounded'
import { Stack } from '@mui/material'
import { DeepcellDrawer } from 'components/shared/drawer'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { ColorByDropdown } from './ColorByDropdown'
import Legend from './Legend'
import { ToggleVisibilityButton } from './ToggleVisibilityButton'

export const colorByButtonId = 'colorByButtonId'

const RollerIcon = () => <ImagesearchRollerRoundedIcon sx={{ ml: 1 }} />

export const ColorByDrawer = (): JSX.Element => {
  const enableFiltering = useFlagCondition('enableFiltering')
  const {
    setShowColorBy,
    cellVisualizations: { showColorBy, groupByOption },
  } = useCellVisualizationsSlice()

  const showFilter = enableFiltering && !groupByOption.isContinuous && groupByOption.attribute

  return (
    <DeepcellDrawer
      buttonText="Color By"
      floatingButtonProps={{
        id: colorByButtonId,
        startIcon: <RollerIcon />,
      }}
      drawerButtonProps={{
        startIcon: <RollerIcon />,
      }}
      onResolve={(isOpen) => setShowColorBy(isOpen)}
      open={showColorBy}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <ColorByDropdown />
          {showFilter ? <ToggleVisibilityButton /> : null}
        </Stack>
        <Legend />
      </Stack>
    </DeepcellDrawer>
  )
}

export default ColorByDrawer
