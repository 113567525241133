import { Box, styled, TablePagination, TablePaginationProps } from '@mui/material'

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    '& .MuiTablePagination-select': {
        backgroundColor: '#EEEFFF',
        padding: '4px 10px',
        borderRadius: '5px',
        '&:focus': {
            backgroundColor: '#EEEFFF',
        }
    },
    /** Tweak the default layout to make it more compact */
    '& .MuiToolbar-gutters': {
        paddingLeft: '0px !important',
    },
    '& .MuiTablePagination-selectRoot': {
        marginLeft: '0px',
        marginRight: theme.spacing(2),
    },

    '& .MuiTablePagination-caption:nth-of-type(2)': {
        /* Keeps the '50-100 cells of about 2.82M cells' from changing size and shifting buttons until
         * you're on a higher page count */
        minWidth: '210px',
    },

    // Hide actions and depend on the <Pagination> component
    '& .MuiTablePagination-actions': {
        display: 'none',
    },
}))

const zero = '0 !important'
const Wrapper = styled(Box)(() => ({
    '& *': {
        border: zero,
    },
}))

export const DeepcellTablePagination = (props: TablePaginationProps): JSX.Element => (
    <Wrapper>
        <StyledTablePagination {...props} />
    </Wrapper>
)

export default DeepcellTablePagination
