import { Buffer } from 'buffer'
import * as ENVIRONMENTS from 'constants/environments'
import {
  LabelingTask,
  LabelingTaskStatusMap,
  Microtask,
  ReviewResult,
} from '@deepcell/cell_data_api_proto/labeling_task_pb'
import { Cell, CellId, Run as RunProto } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import axios, { AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios'
import { ENABLE_MOCK_API, ENABLE_STAGING_API_FOR_LOCAL_DEV } from 'config'
import { Message } from 'google-protobuf'
import qs from 'qs'
import { QueryFunctionContext } from 'react-query'
import { CellVisualizationsState } from 'redux/slices'
import { DEV_BASE_URL } from '../config/dev'
import { getBatchURLAPIRoute, getCellImagesAPIRoute } from './demoEnvironmentUtils'
import plotlyImageToDataURL from './plotly-image-to-dataURL'
import {
  base64StrToMessage,
  CellClassValue,
  LabelingTaskActionTypeValue,
  messageToBase64Str,
} from './proto-utils'

// @TODO: Figure out why this ↓↓ is needed. Maybe something with the React update? Chrome? react-scripts v5?
// @ts-ignore Overwrite the Buffer with the imported one
window.Buffer = Buffer

export const PROD_BASE_URL = 'https://api-dot-dc-classifier.appspot.com'

const initApiUrl = () => {
  if (process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT) {
    if (ENABLE_STAGING_API_FOR_LOCAL_DEV) {
      // Use this one if you'd like to use the Staging API server for local development
      return 'https://api-staging-dot-dc-classifier.appspot.com'
    }
    // By default, use the local API
    return 'http://localhost:8080'
  }
  if (process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING) {
    return 'https://api-staging-dot-dc-classifier.appspot.com'
  }
  if (process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO) {
    return 'https://api-dot-dc-classifier.appspot.com'
  }
  // prod or unit testing (uses mocked prod URLs via mswjs.io)
  if (
    process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION ||
    process.env.REACT_APP_ENV === undefined
  ) {
    return PROD_BASE_URL
  }
  return DEV_BASE_URL
}

// Initialize the API url
export const API_URL = initApiUrl()

export interface RunProtoQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  run_ids?: string[]
  order_by?: string
  limit?: number
  offset?: number
  include_count?: boolean
}

export interface CellsQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  number?: number
  time?: number
  predicted_classes?: number[]
  predicted_probability_greater_than?: number
  before?: number
  after?: number
  run_ids?: string[]
  order_by?: string
  limit: number
  offset?: number
  include_estimated_count?: boolean
}
export interface CellsMetricsQueryParams {
  run_ids?: string
  include_estimated_count?: boolean
  quadrant: number
  include_image_urls?: boolean
}

export interface CellListCellsQueryParams {
  cell_list_id?: number
  limit?: number
  offset?: number
  include_image_urls?: boolean
}

export interface CellImageFilters {
  centerCropSize?: number
  unsharpAmount?: number
  unsharpRadius?: number
}

export interface GetCellImageParams {
  number?: number
  time?: number
  instrumentId?: number
  frame: number
  width: number
  filters?: CellImageFilters
}

export interface CellIdParam {
  number?: number
  time?: number
  instrumentId?: number
}

export interface SamplesQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  limit: number
}

export const DefaultCellsQueryParams: CellsQueryParams = {
  limit: 500,
}

export const DefaultRunsQueryParams: RunProtoQueryParams = {
  limit: 100000,
  include_count: true,
}

export interface FindCellsResponse {
  cells: CellResponse[]
  count?: number
  countIsEstimate?: boolean
}

export interface CellResponse {
  cell: Cell
  imageUrls: string[]
}

export interface GetCellListCellsResponse {
  cells: CellResponse[]
}

export interface FindRunsResponse {
  runs: RunProto[]
  count?: number
}

export interface CreateLabelingTaskParams {
  labelingTask: LabelingTask
  runId?: string
  cellIds?: CellId[]
}

export interface GetLabelingTasksQueryParams {
  statuses?: LabelingTaskStatusMap[keyof LabelingTaskStatusMap]
}

export interface LabelingTaskWithActions {
  labelingTask: LabelingTask
  availableActions: LabelingTaskActionTypeValue[]
}

export interface FindLabelingTasksResponse {
  labelingTasks: LabelingTaskWithActions[]
}

export interface DoActionData {
  rejected_cell_classes?: CellClassValue[]
  note_to_add?: string
}

export interface DoActionOnLabelingTaskParams {
  labelingTaskId: number
  action: LabelingTaskActionTypeValue
  actionData?: DoActionData
}

export interface ReviewResultResponse {
  reviewResults: ReviewResult[]
}

interface GetCellImageUrlsResponse {
  image_urls: string[]
}

/** Standard way to encode a message in API responses */
interface APIMessage {
  proto: string
}

/** Run JSON response format */
export interface GetRunsParams {
  q?: string
  limit?: number
  offset?: number
  run_ids?: string[]
  project_code?: string
  user_email?: string
  start_time_max?: string // ISO8601 Date Time
  start_time_min?: string // ISO8601 Date Time
  stopped?: boolean
  sample_types?: string[]
  order_by?: string
}

export interface Run {
  camera_model?: string
  camera_serial?: string
  camera_settings?: Record<string, unknown>
  cell_counts?: CellCount[]
  cell_detect_window?: string
  cell_image_size?: string
  chip_barcode?: string
  classification_counts?: Record<string, unknown>
  counters?: Record<string, unknown>
  description?: string
  experiment_code?: string
  exposure_time?: number
  frame_rate?: number
  id: number
  image_size?: string
  inference_type?: string
  instrument_name?: string
  instrument_serial_number?: string
  last_delay_time?: number
  metrics?: Record<string, unknown>
  model?: string
  monitoring_info?: Record<string, unknown>
  organization_id: string
  periodic_flush?: Record<string, unknown>
  positive_rate?: number
  project_code?: string
  random_percent?: number
  run_id: string
  run_notes?: string
  run_type?: string
  sample_id?: string
  sample_type?: string
  start_time: string // ISO8601 Date Time
  status?: string
  stop_on_errors?: boolean
  stop_time?: string // ISO8601 Date Time
  stopped?: boolean
  stopped_by_metric?: string
  target_cell_classes?: number[]
  total_cell_count: number
  update_time?: number
  user_email: string
  valve_pattern?: Record<string, unknown>
  versions?: Record<string, unknown>
  well_counts?: WellCount[]
  well_metrics?: WellMetric[]
  well_sorting_configurations?: WellConfiguration[]
  run_quality_score?: number
  run_quality_approved?: boolean
  run_quality_approved_email?: string
  run_quality_score_email?: string
  run_quality_metrics: RunQualityMetrics
}

export interface RunQualityMetrics {
  1: RunQualityMetric
  2: RunQualityMetric
  3: RunQualityMetric
  4: RunQualityMetric
}

export interface RunQualityMetric {
  total_image_count: number
  cutoff_image: number
  contamination_covering_image: number
  chip_blemish_covering_image: number
  blebbing_image: number
  cell_debris: number
}

export interface WellConfiguration {
  well: number
  cell_types: string[]
  threshold: number
  stop_count?: number
  delay_time_delta?: number
}

export interface WellCount {
  well: number
  count: number
}

export interface CellCount {
  cell_type: string
  count: number
}

export interface WellMetric {
  well: number
  true_positive_rate: number
  purity: number
}

export type GetRunsResult = Run[]
export type GetWellConfigurationResult = WellConfiguration[]
export type GetWellCountResult = WellCount[]
export type GetWellMetricResult = WellMetric[]

interface LabelingTaskAPIMessage extends APIMessage {
  available_actions: LabelingTaskActionTypeValue[]
}

export class ClientError extends Error {
  data: unknown

  constructor(message: string, data: unknown) {
    super(message)
    this.data = data
  }
}

let bearerToken = ''
export function updateBearerToken(token: string): void {
  bearerToken = token
}

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export class ProcessingIncomplete extends Error {}

export class NoContentReturned extends Error {}

export class ConflictError extends Error {}

const sendApiRequest = async <ApiResponseType>(
  path: string,
  {
    method = 'GET',
    params = {},
    data = {},
    responseType = 'json',
  }: {
    method?: Method
    params?: unknown
    data?: unknown
    responseType?: ResponseType
  }
): Promise<AxiosResponse<ApiResponseType>> => {
  const options: AxiosRequestConfig = {
    method,
    // Change domain to api-[your username]-dot-dc-classifier.appspot.com to run against your development API.
    url: `${API_URL}/${path}`,
    validateStatus(status) {
      return status >= 200 && status <= 500
    },
    params,
    data,
    responseType,
    paramsSerializer(requestParams) {
      return qs.stringify(requestParams, { arrayFormat: 'comma' })
    },
  }
  const res = await axios(options)

  if (res.status !== 200 && res.status !== 201) {
    console.error('API request failed.', res)

    if (res.status === 202) {
      throw new ProcessingIncomplete()
    }

    if (res.status === 204) {
      throw new NoContentReturned()
    }

    if (res.status === 500) {
      const message =
        res.data && res.data.error ? res.data.error : 'The server may be down.  Ask engineers.'
      throw Error(message)
    }

    if (res.status === 409) {
      throw new ConflictError()
    }

    if (res.status >= 400 && res.status < 500) {
      if (res.data.error) {
        console.error('data=', res.data)
        throw new ClientError(res.data.error, res.data)
      }
    }
    throw Error('Unknown error.  Ask engineers')
  }

  return res
}

function apiMessageToMessage<T extends Message>(
  x: APIMessage,
  messageClass: { deserializeBinary(bytes: Uint8Array): T }
) {
  return base64StrToMessage(x.proto, messageClass)
}

interface ApiCell {
  proto: string
  image_urls: string[]
}

function decodeCells(cells: ApiCell[]) {
  return cells.map((x: ApiCell) => {
    const base64str = x.proto
    const buf = Buffer.from(base64str, 'base64')
    return {
      cell: Cell.deserializeBinary(buf),
      imageUrls: x.image_urls,
    }
  })
}

async function getCellsHelper(params: CellsQueryParams): Promise<FindCellsResponse> {
  interface CellApiResponse {
    cells: ApiCell[]
    count?: number
    count_is_estimate?: boolean
  }
  const res = await sendApiRequest<CellApiResponse>('v1/cells/cells', { params })

  if (res.data.cells.length > params.limit) {
    throw Error('Got more cells than expected')
  }

  const result: FindCellsResponse = {
    cells: decodeCells(res.data.cells),
  }
  if (res.data.count !== undefined) {
    result.count = res.data.count
    result.countIsEstimate = res.data.count_is_estimate
  }

  return result
}
async function getCellsMetricsHelper(params: CellsMetricsQueryParams): Promise<FindCellsResponse> {
  interface CellApiResponse {
    cells: ApiCell[]
    count?: number
    count_is_estimate?: boolean
  }
  const res = await sendApiRequest<CellApiResponse>('v1/cells/quality_review', { params })

  const result: FindCellsResponse = {
    cells: decodeCells(res.data.cells),
  }
  if (res.data.count !== undefined) {
    result.count = res.data.count
    result.countIsEstimate = res.data.count_is_estimate
  }

  return result
}

export async function getCells(
  context: QueryFunctionContext<[string, CellsQueryParams]>
): Promise<FindCellsResponse> {
  const params = context.queryKey[1]
  return getCellsHelper(params)
}

export async function getMetricsCells(
  context: QueryFunctionContext<[string, CellsMetricsQueryParams]>
): Promise<FindCellsResponse> {
  const params = context.queryKey[1]
  return getCellsMetricsHelper(params)
}

export async function getCellListCells(
  context: QueryFunctionContext<[string, CellListCellsQueryParams]>
): Promise<GetCellListCellsResponse> {
  const params = context.queryKey[1]
  interface CellApiResponse {
    cells: ApiCell[]
  }
  const res = await sendApiRequest<CellApiResponse>(`v1/cells/lists/${params.cell_list_id}/cells`, {
    params,
  })

  return {
    cells: decodeCells(res.data.cells),
  }
}

async function getCellImageHelper(params: GetCellImageParams): Promise<string> {
  if (!params.number) return ''

  const cellImagesAPIRoute = getCellImagesAPIRoute(process.env.REACT_APP_ENV)
  const res = await sendApiRequest<Blob>(cellImagesAPIRoute, {
    method: 'GET',
    params: {
      number: params.number,
      time: params.time,
      instrument_id: params.instrumentId,
      frame: params.frame,
      width: params.width,
      center_crop_size: params.filters?.centerCropSize,
      unsharp_amount: params.filters?.unsharpAmount,
      unsharp_radius: params.filters?.unsharpRadius,
    },
    responseType: 'blob',
  })

  return new Promise((resolve) => {
    if (res.data) {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(res.data)
    }
  })
}

export async function getCellImage(
  context: QueryFunctionContext<[string, GetCellImageParams]>
): Promise<string> {
  const params = context.queryKey[1]
  return getCellImageHelper(params)
}

/** Fetches a batch of signed image URLs by CellId.  These URLs expire after 30 minutes */
export async function getCellImageURLs(
  context: QueryFunctionContext<[string, CellId[]]>
): Promise<string[]> {
  const cellIds = context.queryKey[1]

  const batchURLAPIRoute = getBatchURLAPIRoute(process.env.REACT_APP_ENV)
  const res = await sendApiRequest<GetCellImageUrlsResponse>(batchURLAPIRoute, {
    method: 'POST',
    data: {
      cell_ids: cellIds.map((cellId) => {
        return {
          time: cellId.getTime(),
          number: cellId.getNumber(),
          instrument_id: cellId.getInstrumentId(),
        }
      }),
    },
  })
  return res.data.image_urls
}

/** Fetches a batch of images by CellId and returns DataURLs. */
export async function getCellImageDataURLs(
  context: QueryFunctionContext<[string, CellId[]]>
): Promise<string[]> {
  const imageUrls = await getCellImageURLs(context)
  const dataURLs = await Promise.all(imageUrls.map((url) => plotlyImageToDataURL(url)))
  return dataURLs
}

async function getRunProtosHelper(params: RunProtoQueryParams): Promise<FindRunsResponse> {
  interface RunApiResponse {
    runs: APIMessage[]
    count?: number
  }
  const res = await sendApiRequest<RunApiResponse>('v1/runs', { params })

  return {
    runs: res.data.runs.map((x: APIMessage) => apiMessageToMessage(x, RunProto)),
  }
}

export async function getRunProtos(_context: QueryFunctionContext): Promise<FindRunsResponse> {
  return getRunProtosHelper(DefaultRunsQueryParams)
}

export async function getRuns(
  context: QueryFunctionContext<[string, GetRunsParams]>
): Promise<GetRunsResult> {
  const params = context.queryKey[1]
  const res = await sendApiRequest<GetRunsResult>('v1/runs:search', {
    params,
  })
  return res.data
}

/**
 * Represents the body request to update a single run.
 * @see http://api-dot-dc-classifier.uc.r.appspot.com/api/docs/#/Runs/runs-annotate-v1
 */
export interface UpdateRunRequest {
  run_id: string
  project_code?: string
  experiment_code?: string
  run_notes?: string
  description?: string
  target_cell_classes?: number[]
}
/**
 * Call the cell data api to update the given run.
 * @see http://api-dot-dc-classifier.uc.r.appspot.com/api/docs/#/Runs/runs-annotate-v1
 */
export async function updateRun(data: UpdateRunRequest): Promise<Run> {
  const res = await sendApiRequest<Run>(`v1/runs/${data.run_id}:annotate`, {
    method: 'PATCH',
    data,
  })
  return res.data
}

function decodeLabelingTaskAPIMessage(message: LabelingTaskAPIMessage): {
  labelingTask: LabelingTask
  availableActions: LabelingTaskActionTypeValue[]
} {
  return {
    labelingTask: apiMessageToMessage(message, LabelingTask),
    availableActions: message.available_actions,
  }
}

async function getLabelingTasksHelper(
  params: GetLabelingTasksQueryParams
): Promise<FindLabelingTasksResponse> {
  interface LabelingTaskApiResponse {
    labeling_tasks: LabelingTaskAPIMessage[]
  }
  const apiParams = {
    ...params,
    include_available_actions: 1,
    include_progress: 1,
  }
  const res = await sendApiRequest<LabelingTaskApiResponse>('v1/labeling/tasks', {
    params: apiParams,
  })

  return {
    labelingTasks: res.data.labeling_tasks.map(decodeLabelingTaskAPIMessage),
  }
}

export async function getLabelingTasks(
  context: QueryFunctionContext<[string, GetLabelingTasksQueryParams]>
): Promise<FindLabelingTasksResponse> {
  const params = context.queryKey[1]
  return getLabelingTasksHelper(params)
}

export async function getLabelingTask(
  context: QueryFunctionContext<[string, number]>
): Promise<LabelingTask> {
  const taskId = context.queryKey[1]
  const apiParams = {
    include_available_actions: 1,
    include_actions: 1,
    include_progress: 1,
  }

  const res = await sendApiRequest<APIMessage>(`v1/labeling/tasks/${taskId}`, {
    params: apiParams,
  })

  return apiMessageToMessage(res.data, LabelingTask)
}

async function createLabelingTaskHelper(params: CreateLabelingTaskParams): Promise<LabelingTask> {
  const data = {
    labeling_task_proto: messageToBase64Str(params.labelingTask),
    run_id: params.runId,
    cell_ids: params.cellIds,
  }

  const res = await sendApiRequest<APIMessage>('v1/labeling/tasks', {
    method: 'POST',
    data,
  })

  return apiMessageToMessage(res.data, LabelingTask)
}

export async function createLabelingTask(params: CreateLabelingTaskParams): Promise<LabelingTask> {
  return createLabelingTaskHelper(params)
}

export async function updateLabelingTask(
  labelingTask: LabelingTask
): Promise<LabelingTaskWithActions> {
  const data = {
    labeling_task_proto: messageToBase64Str(labelingTask),
  }
  const url = `v1/labeling/tasks/${labelingTask.getTaskId()}`

  await sendApiRequest<LabelingTaskAPIMessage>(url, {
    method: 'PUT',
    data,
  })

  const params = {
    include_available_actions: 1,
    include_actions: 1,
    include_progress: 1,
  }

  const res = await sendApiRequest<LabelingTaskAPIMessage>(url, {
    params,
  })

  return decodeLabelingTaskAPIMessage(res.data)
}

export async function doActionOnLabelingTask(
  params: DoActionOnLabelingTaskParams
): Promise<LabelingTaskWithActions> {
  const path = `v1/labeling/tasks/${params.labelingTaskId}/do_action/${params.action}`
  const res = await sendApiRequest<LabelingTaskAPIMessage>(path, {
    method: 'POST',
    data: params.actionData,
  })

  return decodeLabelingTaskAPIMessage(res.data)
}

export interface GetMicrotaskParams {
  taskId: number
  microtaskIndex?: number
}

export async function getMicrotask(
  context: QueryFunctionContext<[string, GetMicrotaskParams]>
): Promise<Microtask> {
  const { taskId, microtaskIndex } = context.queryKey[1]
  const params = microtaskIndex === undefined ? {} : { microtask_index: microtaskIndex }

  const res = await sendApiRequest<APIMessage>(`v1/labeling/tasks/${taskId}/get_microtask`, {
    params,
  })

  return apiMessageToMessage(res.data, Microtask)
}

export interface SubmitMicrotaskResultParams {
  labelingTaskId: number
  microtaskIndex: number
  resultProto: string
}

export async function submitMicrotaskResult(params: SubmitMicrotaskResultParams): Promise<void> {
  const path = `v1/labeling/tasks/${params.labelingTaskId}/microtask/${params.microtaskIndex}/result`
  await sendApiRequest<LabelingTaskAPIMessage>(path, {
    method: 'POST',
    data: {
      result_proto: params.resultProto,
    },
  })
}

export async function getReviewResults(
  context: QueryFunctionContext<[string, number]>
): Promise<ReviewResultResponse> {
  interface ReviewResultsApiResponse {
    review_results: APIMessage[]
  }

  const taskId = context.queryKey[1]
  const res = await sendApiRequest<ReviewResultsApiResponse>(
    `v1/labeling/tasks/${taskId}/review_results`,
    {}
  )

  return {
    reviewResults: res.data.review_results.map((data) => apiMessageToMessage(data, ReviewResult)),
  }
}

export function convertErrorToString<TData, TError>(response: {
  data: TData
  error?: TError
}): { result: TData; error: string | undefined } {
  let stringError: string | undefined
  const { data, error } = response

  if (error) {
    console.error(error)
    if (error instanceof Error) {
      stringError = error.message
    } else if (typeof error === 'string') {
      stringError = error
    } else {
      stringError = 'Unknown error occurred.'
    }
  }

  return {
    result: data,
    error: stringError,
  }
}

export interface GeneratePredictionsResponse {
  dashboard_url: string
  execution_date: string
  message: string
  run_id: string
}

export async function generatePredictions(
  modelName: string,
  modelType: string,
  runIds: string[]
): Promise<GeneratePredictionsResponse> {
  const data = {
    model_name: modelName,
    model_type: modelType,
    run_ids: runIds,
  }

  const res = await sendApiRequest<GeneratePredictionsResponse>('v1/models/generate_predictions', {
    method: 'POST',
    data,
  })
  return res.data
}

export async function generatePredictionsV3(
  modelName: string,
  runIds: string[]
): Promise<GeneratePredictionsResponse> {
  const data = {
    model_name: modelName,
    run_ids: runIds,
  }

  const res = await sendApiRequest<GeneratePredictionsResponse>(
    'v1/models/v3/generate_predictions',
    { method: 'POST', data }
  )
  return res.data
}

export interface DeleteSessionParams {
  id: number | string
}

export async function deleteSession(params: DeleteSessionParams): Promise<AxiosResponse> {
  const { id } = params
  const res = await sendApiRequest(`v1/cell_visualizations/sessions/${id}`, {
    method: 'DELETE',
  })

  return res
}

export interface SessionPostData {
  session_id: string
  version_id: string
  author_email?: string
  version_config?: Partial<CellVisualizationsState>

  /**
   * Contains an signed, expiring URL that points to the arrow file
   */
  data_url?: string
}

export interface SessionPostResponse {
  data?: SessionPostData
  status?: string
}

export interface SessionSaveResponse {
  status?: string
  version_id?: string
}

export async function getArrowFile(data_url: string): Promise<Response> {
  // TODO: Figure out how to return arrow file from msw so that we can remove this 'if' statement
  if (ENABLE_MOCK_API && process.env.NODE_ENV === 'development') {
    const arr = await fetch('/24.arrow')
    return arr
  }
  const arr = await fetch(data_url)
  return arr
}

export async function getSession(
  sessionId: string | number,
  versionId?: string | number
): Promise<AxiosResponse<SessionPostResponse>> {
  const res = await sendApiRequest<SessionPostResponse>(
    `v1/cell_visualizations/sessions/${sessionId}${versionId ? `/versions/${versionId}` : ''}`,
    {
      method: 'GET',
    }
  )

  return res
}

export type SaveSessionParams = {
  state: Partial<CellVisualizationsState>
  sessionId: number
  versionId: number
  isCopy?: boolean
}
export interface SessionMetaData {
  author_email: string
  created_at: Date | null
  data_url: string
  deleted_at: Date | null
  is_latest: boolean
  session_id: number
  updated_at: Date
  version_config: CellVisualizationsState
  version_id: number
}

export interface SessionMetaDataResponse {
  data: SessionMetaData[]
  status: string
}

type CVSKey = keyof CellVisualizationsState

export interface GetSessionsParams {
  version_config_fields?: CVSKey[]
  offset?: number
  limit?: number
}

export async function getSessions(
  params?: GetSessionsParams
): Promise<AxiosResponse<SessionMetaDataResponse>> {
  const transformedParams = {
    ...params,
    version_config_fields: params?.version_config_fields?.toString(),
  }
  const res = await sendApiRequest<SessionMetaDataResponse>('v1/cell_visualizations/sessions', {
    method: 'GET',
    params: transformedParams,
  })

  return res
}

export async function saveSession(params: SaveSessionParams): Promise<SessionSaveResponse> {
  const { state, sessionId, versionId, isCopy } = params

  /**
   * Another quick and dirty hack to deal with bigints because custom fields default to bigints for some reason
   */
  const updatedState: typeof state = {
    ...state,
    legendLabels: state.legendLabels?.map((x) => `${x}`),
    legendLabelColors: state.legendLabelColors?.map((x) => ({ ...x, name: `${x.name}` })),
  }

  const data = {
    parent_version: versionId,
    version_config: updatedState,
  }

  const res = await sendApiRequest<SessionPostResponse>(
    `v1/cell_visualizations/sessions/${sessionId}${isCopy ? `/versions/${versionId}:copy` : ''}`,
    {
      method: 'POST',
      data,
    }
  )

  return res.data
}

export async function createSessionFromFile(
  dataToUpload: Blob,
  fileName: string
): Promise<AxiosResponse<SessionPostData>> {
  const data = new FormData()
  data.append('file', dataToUpload, fileName)
  const res = await sendApiRequest<SessionPostData>('v1/cell_visualizations/sessions', {
    method: 'POST',
    data,
  })
  return res
}

export interface VisualizationSessionConfig {
  run_ids: string[]
  max_cell_count: number
  projection_model: string
  morphometric_model: string
}

export async function createSessionFromRuns(
  data: VisualizationSessionConfig
): Promise<AxiosResponse<SessionPostData>> {
  const res = await sendApiRequest<SessionPostData>('v1/cell_visualizations/sessions', {
    method: 'POST',
    data,
  })
  return res
}

export interface DemoTokenGetResponse {
  token?: string
}

export async function getDemoToken(): Promise<AxiosResponse<DemoTokenGetResponse>> {
  const res = await sendApiRequest<DemoTokenGetResponse>('v1/auth/demosite', {
    method: 'GET',
  })

  return res
}
