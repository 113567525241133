import { useMemo } from 'react'
import { QueryClient, useQueryClient } from 'react-query'
import 'react-widgets/dist/css/react-widgets.css'
import {
    BooleanParam,
    DecodedValueMap,
    NumberParam,
    QueryParamConfigMap,
    SetQuery,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params'
import {
    CellClassCommaArrayParam,
    CellIdParam,
    SampleTypeParam,
    StringArrayParam,
    TimestampParam,
    withIgnoreBlanks,
} from '../../utils/custom-query-params'
import { CellDisplayOptions } from '../shared/CellImageControl'
import { ActiveTab } from '../shared/SearchDetailsView'
import { QueryState } from './cellSearchHelpers'

export const useCellBrowsingQueryParams = (): {
    query: DecodedValueMap<QueryParamConfigMap>
    setQuery: SetQuery<QueryParamConfigMap>
    search: QueryState
    displayOptions: CellDisplayOptions
    queryClient: QueryClient,
    searchingAcrossCorpus: boolean
} => {

    /* This component stores state in the query, except for caching cell counts */
    const [query, setQuery] = useQueryParams({
        predictedClasses: withIgnoreBlanks(CellClassCommaArrayParam),
        predictedProbabilityGreaterThan: withIgnoreBlanks(StringParam),
        sampleType: withIgnoreBlanks(SampleTypeParam),
        sampleId: withIgnoreBlanks(StringParam),
        mixedSampleId: withIgnoreBlanks(StringParam),
        cellNumber: withIgnoreBlanks(StringParam),
        cellTime: withIgnoreBlanks(StringParam),
        before: withIgnoreBlanks(TimestampParam),
        after: withIgnoreBlanks(TimestampParam),
        // @TODO: Remove old RunId parameter when no longer needed.  It used to search on only one RunId at a time.
        runId: withIgnoreBlanks(StringParam),
        runIds: withDefault(withIgnoreBlanks(StringArrayParam), []),
        sortOrderKey: withDefault(withIgnoreBlanks(StringParam), 'TIME_ASC'),
        showSortFields: withDefault(withIgnoreBlanks(BooleanParam), true),
        cellsPerPage: withDefault(withIgnoreBlanks(NumberParam), 50),
        page: withDefault(withIgnoreBlanks(NumberParam), 0),
        activeTab: withDefault(withIgnoreBlanks(StringParam), ActiveTab.SEARCH),
        selectedCellId: withIgnoreBlanks(CellIdParam),
        sharpen: withDefault(withIgnoreBlanks(BooleanParam), false),
        centerCrop: withDefault(withIgnoreBlanks(BooleanParam), false),
    })
    const search: QueryState = query as QueryState
    const displayOptions: CellDisplayOptions = useMemo(() => {
        return {
            sharpen: search.sharpen,
            centerCrop: search.centerCrop,
        }
    }, [search])

    const queryClient = useQueryClient()
    const filterNotProvided = !query.predictedClasses && !query.predictedProbabilityGreaterThan && !query.sampleType && !query.sampleId 
    && !query.cellNumber && !query.cellTime && !query.runId && !(query.runIds === undefined || query.runIds === null || query.runIds.length === 0 )

    return { query, setQuery, search, displayOptions, queryClient, searchingAcrossCorpus: !filterNotProvided }
}

export default useCellBrowsingQueryParams
