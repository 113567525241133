import { CellClass } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CellDisplayOptions } from 'components/shared/CellImageControl'
import { CellClassValue } from 'utils/proto-utils'

export type PrimaryCellClass = CellClassValue | -1

export interface LabelingMicrotaskState {
    selectedCellIds: string[]
    primaryCellClass: PrimaryCellClass
    displayOptions: CellDisplayOptions
    progress?: number
}

const labelingMicrotaskSliceInitialState: LabelingMicrotaskState = {
    selectedCellIds: [],
    primaryCellClass: CellClass.CLASS_NOT_DEFINED,
    displayOptions: { centerCrop: false, sharpen: true },
}

export const labelingMicrotaskSlice = createSlice({
    name: 'labelingMicrotask',
    initialState: labelingMicrotaskSliceInitialState,
    reducers: {
        reset: (state) => {
            state.selectedCellIds = []
            state.primaryCellClass = CellClass.CLASS_NOT_DEFINED
        },
        setSelectedCellIds: (state, action: PayloadAction<string[]>) => {
            state.selectedCellIds = action.payload
        },
        setPrimaryCellClass: (state, action: PayloadAction<PrimaryCellClass>) => {
            state.primaryCellClass = action.payload
        },
        setDisplayOptions: (state, action: PayloadAction<CellDisplayOptions>) => {
            state.displayOptions = action.payload
        },
        setProgress: (state, action: PayloadAction<number>) => {
            state.progress = action.payload
        },
    },
})

export const LabelingMicrotaskActions = labelingMicrotaskSlice.actions
export const LabelingMicrotaskReducer = labelingMicrotaskSlice.reducer
