import { Cell, CellId, Prediction } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { BarChart } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import * as moment from 'moment'
import {
    CellClassEncoderDecoder,
    getPredictedClass,
    SampleTypeEncoderDecoder,
} from 'utils/proto-utils'
import { LinkListItem } from '../shared/CustomListItems'
import CellDetailsItem from './CellDetailsItem'

interface Props {
    cell?: Cell
}

function CellDetailsPanel(props: Props): JSX.Element {
    const { cell } = props

    if (!cell) {
        return (
            <div>
                <p>Hover over a cell to see details.</p>
                <p>Click on a cell to select it and always show details for that cell.</p>
            </div>
        )
    }

    const cellId = cell.getCellId() || new CellId()
    const predictions = cell.getPredictionsList()

    const cellDetailUrl =
        `https://app.mode.com/deepcell/reports/b122362935cb?param_run_id=${cell.getRunId()}` +
        `&param_cell_id_time=${cell.getCellId()?.getTime()}` +
        `&param_cell_id_number=${cell.getCellId()?.getNumber()}` +
        `&param_cell_id_instrument_id=${cell.getCellId()?.getInstrumentId()}`

    return (
        <div>
            <CellDetailsItem title="Run">{cell.getRunId()}</CellDetailsItem>
            <CellDetailsItem title="Time">
                {`${moment.utc(cellId.getTime()).local().format('YYYY-MM-DD h:mm:ss.SSS A')}`}
            </CellDetailsItem>
            <CellDetailsItem title="Sample">{cell.getSample()?.getSampleId()}</CellDetailsItem>
            <CellDetailsItem title="Mixed Sample">
                {cell.getSample()?.getMixedSampleId()}
            </CellDetailsItem>
            <CellDetailsItem title="Sample Type">
                {SampleTypeEncoderDecoder.convertToString(cell.getSample()?.getSampleType())}
            </CellDetailsItem>
            <CellDetailsItem title="Number of Images">{cell.getNumImages()}</CellDetailsItem>
            {cell?.getNumImages() && (
                <CellDetailsItem title="Image Size">
                    {`${cell?.getImagesList()[0].getBlobWidth()}x${cell
                        ?.getImagesList()[0]
                        .getBlobHeight()}`}
                </CellDetailsItem>
            )}
            <CellDetailsItem title="Cell Id">
                {`${cellId.getTime()} #${cellId.getNumber()} ${cellId.getInstrumentId()}`}
            </CellDetailsItem>
            <CellDetailsItem title="Predictions">
                {predictions
                    .filter((x) => x.getFrame() === -1)
                    .map((p: Prediction) => {
                        const cellClassEnum: number | undefined = getPredictedClass(p)
                        if (cellClassEnum === undefined) return <div />

                        const cellClass = CellClassEncoderDecoder.convertToString(cellClassEnum)
                        const cellClassProbability = p.getProbabilitiesMap().get(cellClassEnum)
                        const model = p.getModel()
                        return (
                            <div key={`${cellClass}_${model}`}>
                                {`${cellClass} (${Number(cellClassProbability).toFixed(2)})`}
                                <br />
                                <Typography variant="caption">{model}</Typography>
                            </div>
                        )
                    })}{' '}
            </CellDetailsItem>
            <LinkListItem url={cellDetailUrl} label="See more data" iconEl={BarChart} />
        </div>
    )
}

export default CellDetailsPanel
