import { KeyboardArrowDown } from '@mui/icons-material'
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material'

export interface DeepcellAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
    label: string
    inputProps?: TextFieldProps
}

export function DeepcellAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>({
    label,
    inputProps,
    ...props
}: DeepcellAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    return (
        <Autocomplete
            {...props}
            options={props.options ?? []}
            renderInput={(params) => <TextField {...params} {...inputProps} label={label} />}
            popupIcon={<KeyboardArrowDown />}
        />
    )
}

export default DeepcellAutocomplete
