// @ts-ignore types are not available for jsfive package
import * as hdf5 from 'jsfive'

function readHdf5(ab: Buffer): Record<string, unknown>[] {
    const f = new hdf5.File(ab.buffer)
    const [totalCells, embeddingDimension]: [number, number] = f.get('X').shape
    const embeddingData: number[] = f.get('X').value
    const projectionData: number[] = f.get('obsm/X_umap').value
    const projectionDimension: number = f.get('obsm/X_umap').shape[1]

    // Create an array of objects, each object represents a cell
    const cellIds: string[] = f.get('obs/_index').value
    const data: Record<string, unknown>[] = []
    for (let i = 0; i < totalCells; i += 1) data[i] = { ID: cellIds[i] }

    // Populate morphometric features
    const featureNames: string[] = f.get('obs').keys
    featureNames.forEach((name) => {
        const featureData: number[] | undefined = f.get(`obs/${name}`).value
        if (featureData) {
            for (let i = 0; i < totalCells; i += 1) data[i][name] = featureData[i]
        } else {
            const featureCode: number[] = f.get(`obs/${name}/codes`).value
            const mapCodeToValue: string[] = f.get(`obs/${name}/categories`).value
            for (let i = 0; i < totalCells; i += 1) data[i][name] = mapCodeToValue[featureCode[i]]
        }
    })

    // Populate embedding data + projection data
    for (let i = 0; i < totalCells; i += 1) {
        // Projection
        data[i].COORDS = projectionData.slice(
            i * projectionDimension,
            (i + 1) * projectionDimension
        )

        // Embedding
        data[i].EMBEDDINGS = embeddingData.slice(
            i * embeddingDimension,
            (i + 1) * embeddingDimension
        )
    }
    return data
}

interface IFilter {
    feature: string
    max?: number
    min?: number
    categories?: string[]
}

class CellData {
    constructor(public data: Record<string, unknown>[]) {}

    filter = ({ feature, max, min, categories }: IFilter): CellData => {
        const filteredData = this.data.filter((value) => {
            if (max !== undefined && (value[feature] as number) > max) return false
            if (min !== undefined && (value[feature] as number) < min) return false
            if (categories?.length && !categories.includes(value[feature] as string)) return false
            return true
        })
        return new CellData(filteredData)
    }
}

export { readHdf5, CellData }
