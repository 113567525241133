import { GridApi, RowNode } from 'ag-grid-community'
import ContentLoading from 'components/shared/ContentLoading'
import SearchDetailsView, { ActiveTab } from 'components/shared/SearchDetailsView'
import _ from 'lodash'
import Moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import useLabelingTasksSlice from 'redux/slices/hooks/useLabelingTasksSlice'
import { LabelingTaskWithActions } from 'utils/api'
import useSelection from 'utils/useSelection'
import { useLabelingTaskApiData } from './helpers'
import LabelingTaskDetailsPanel from './LabelingTaskDetailsPanel'
import LabelingTasksFilters from './LabelingTasksFilters'
import { LabelingTasksTable } from './table/LabelingTasksTable'

Moment.locale('en')
momentLocalizer()

const taskProjects = (task: LabelingTaskWithActions) => task.labelingTask.getProjectName() || []
const taskAssignees = (task: LabelingTaskWithActions) => task.labelingTask.getAssignee() || []

function LabelingTasksPage(): JSX.Element {
    const {
        setSelectedTaskIds,
        setProjects,
        setAvailableAssignees,
        setActiveTab,
        reset,
        labeling: { projects, availableAssignees, activeTab, selectedTaskIds },
    } = useLabelingTasksSlice()

    // reset the filters when mounting
    useMount(reset)

    const { result: taskData } = useLabelingTaskApiData()
    const tasks = useMemo(() => (taskData ? taskData.labelingTasks : []), [taskData])

    useEffect(() => {
        if (tasks) {
            if (!projects.length) {
                const uniqueTaskProjects = _.uniq(tasks.flatMap(taskProjects)).sort()
                if (uniqueTaskProjects?.length) setProjects(uniqueTaskProjects)
            }
            if (!availableAssignees.length) {
                const uniqueTaskAssignees = _.uniq(tasks.flatMap(taskAssignees))
                if (uniqueTaskAssignees?.length) setAvailableAssignees(uniqueTaskAssignees)
            }
        }
    }, [availableAssignees.length, projects.length, setAvailableAssignees, setProjects, tasks])

    const [gridApi, setGridApi] = useState<GridApi | null>(null)

    const selectionManager = useSelection(
        selectedTaskIds ?? [],
        (data: LabelingTaskWithActions) => data.labelingTask.getTaskId() || -1,
        ({ selectedIds }) => {
            setSelectedTaskIds(selectedIds)
            if (activeTab !== ActiveTab.DETAILS) setActiveTab(ActiveTab.DETAILS)
        },
        gridApi
    )

    const selectedTasksSet = selectionManager.selectedSet
    const selectedTasksWithActions = tasks.filter((task: LabelingTaskWithActions) => {
        const taskId = task.labelingTask.getTaskId()
        return taskId !== undefined && selectedTasksSet.has(taskId)
    })
    const selectedTasks = selectedTasksWithActions.map((x) => x.labelingTask)

    // Used to force re-renders when task data is updated inside the grid from update actions
    // but React does not detect that the protobuf objects have internally changed
    // @TODO Find a more elegant way to handle this
    const [forceRerender, setForceRerender] = useState<boolean>(false)
    const rerender = () => {
        setForceRerender(!forceRerender)
    }

    function handleTasksUpdated(updatedTasks: LabelingTaskWithActions[]) {
        if (!gridApi) return

        // Update data for the given tasks
        const updateMap: { [key: string]: LabelingTaskWithActions } = {}
        updatedTasks.forEach((task) => {
            const taskId = task.labelingTask.getTaskId()
            if (taskId) {
                updateMap[String(taskId)] = task
            }
        })
        const itemsToUpdate: LabelingTaskWithActions[] = []
        const updatedNodes: RowNode[] = []
        gridApi?.forEachNodeAfterFilterAndSort((rowNode, _index) => {
            const { data } = rowNode
            if (!data) {
                return
            }
            const taskId = data.labelingTask.getTaskId()
            if (taskId !== undefined && String(taskId) in updateMap) {
                const updated = updateMap[String(taskId)]
                data.labelingTask = updated.labelingTask
                data.availableActions = updated.availableActions
                itemsToUpdate.push(data)
                updatedNodes.push(rowNode)
            }
        })
        gridApi?.applyTransaction({ update: itemsToUpdate })

        // show some feedback
        gridApi?.flashCells({ rowNodes: updatedNodes })
        rerender()
    }

    function onDownload() {
        gridApi?.exportDataAsCsv({ onlySelected: true })
    }

    const searchTab = <LabelingTasksFilters />
    const detailsTab = (
        <LabelingTaskDetailsPanel
            labelingTasks={selectedTasks}
            handleUpdate={handleTasksUpdated}
            onDownload={onDownload}
        />
    )

    return (
        <SearchDetailsView
            activeTab={activeTab ?? ActiveTab.SEARCH}
            handleTabChange={setActiveTab}
            searchTab={searchTab}
            detailsTab={detailsTab}
            contentFlex={4}
            searchDetailsFlex={1}
        >
            {!taskData ? (
                <ContentLoading />
            ) : (
                <LabelingTasksTable
                    tasks={tasks}
                    gridApi={gridApi}
                    onGridApiChange={setGridApi}
                    selectionManager={selectionManager}
                />
            )}
        </SearchDetailsView>
    )
}

export default LabelingTasksPage
