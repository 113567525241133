import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material'

export const LeftPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1.1,
    marginRight: theme.spacing(2),

    '& .MuiTab-root': {
        minWidth: '80px',
    },
}))

export const RightPanel = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(2),
}))

export const MicrotaskAccordion = styled(Accordion)(({ theme }) => ({
    '&.Mui-expanded': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export const MicrotaskAccordionHeader = styled(AccordionSummary)(({ theme }) => ({
    minHeight: '0px !important',
    backgroundColor: theme.palette.grey['300'],

    '& .MuiAccordionSummary-content': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flexDirection: 'column',
    },

    '.disabled &': {
        color: theme.palette.grey['400'],
    },
}))

export const MicrotaskAccordionTaskHeader = styled(AccordionSummary)(() => ({
    textAlign: 'center',
    '& .MuiAccordionSummary-content': {
        flexDirection: 'column',
    },
}))

export const MicrotaskAccordionHeaderTaskHeader = styled(MicrotaskAccordionHeader)({
    ...MicrotaskAccordionTaskHeader,
})

export const MicrotaskAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    flexDirection: 'column',

    '.disabled &': {
        color: theme.palette.grey['400'],
    },
}))

export const MicrotaskFiller = styled('div')(() => ({
    flex: 1,
}))

// /** Explicitly typing the possible keys in the return Record
//  * Otherwise type inference doesn't seem to be smart enough to propagate the class names through
//  * And you don't get autocomplete when using these classes.
//  */
// function sharedStyles(
//     theme: Theme
// ): Record<
//     | 'leftPanel'
//     | 'contentPanel'
//     | 'rightPanel'
//     | 'accordion'
//     | 'header'
//     | 'taskHeader'
//     | 'decisionBody'
//     | 'filler',
//     CSSProperties | CreateCSSProperties
// > {
//     return {
//         // loadingContent: {
//         //     width: '100%',
//         //     textAlign: 'center',
//         // },
//         // leftPanel: {
//         //     padding: theme.spacing(1),
//         //     display: 'flex',
//         //     flexDirection: 'column',
//         //     flex: 1.1,
//         //     marginRight: theme.spacing(2),

//         //     '& .MuiTab-root': {
//         //         minWidth: '80px',
//         //     },
//         // },

//         // contentPanel: {},

//         // rightPanel: {
//         //     padding: theme.spacing(1),
//         //     display: 'flex',
//         //     flexDirection: 'column',
//         //     flex: 1,
//         //     marginLeft: theme.spacing(2),
//         // },
//         // accordion: {
//         //     '&.Mui-expanded': {
//         //         marginTop: theme.spacing(1),
//         //         marginBottom: theme.spacing(1),
//         //     },
//         // },
//         // header: {
//         //     minHeight: '0px !important',
//         //     backgroundColor: theme.palette.grey['300'],

//         //     '& .MuiAccordionSummary-content': {
//         //         marginTop: theme.spacing(1),
//         //         marginBottom: theme.spacing(1),
//         //         flexDirection: 'column',
//         //     },

//         //     '.disabled &': {
//         //         color: theme.palette.grey['400'],
//         //     },
//         // },
//         // taskHeader: {
//         //     textAlign: 'center',
//         //     '& .MuiAccordionSummary-content': {
//         //         flexDirection: 'column',
//         //     },
//         // },
//         // decisionBody: {
//         //     flexDirection: 'column',

//         //     '.disabled &': {
//         //         color: theme.palette.grey['400'],
//         //     },
//         // },
//         filler: {
//             flex: 1,
//         },
//     }
// }

// export default sharedStyles
