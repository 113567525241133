import { MenuItem, MenuItemProps } from '@mui/material'
import { ChangeEvent, useRef } from 'react'

interface MenuItemFileUploadProps extends MenuItemProps {
    onUpload: (e: ChangeEvent<HTMLInputElement>) => void
}

/**
 * A MUI menu item that triggers a file upload when clicked
 */
export const MenuItemFileUpload = ({
    children,
    onUpload,
    ...rest
}: React.PropsWithChildren<MenuItemFileUploadProps>): JSX.Element => {
    const uploadInputRef = useRef<HTMLInputElement>(null)
    return (
        <MenuItem
            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
            {...rest}
        >
            {children}
            <input ref={uploadInputRef} hidden type="file" onChange={onUpload} />
        </MenuItem>
    )
}

export default MenuItemFileUpload
