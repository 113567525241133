import * as React from 'react'
import { Typography, Box, Modal, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { cloneElement, useState } from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

interface DeepcellModalProps {
    modalTriggeringButton: JSX.Element
    title: string
    content: JSX.Element
}

export default function DeepcellModal({
    modalTriggeringButton,
    title,
    content,
}: DeepcellModalProps): JSX.Element {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const button = cloneElement(modalTriggeringButton, { onClick: handleOpen })

    return (
        <div>
            {button}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {content}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}
