import { Card, CardContent } from '@mui/material'
import { Stack } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { useElementById } from 'utils/useElementById'
import useFooterHeight from 'utils/useFooterHeight'
import CompareGroups from '../Compare/CompareGroups'
import CurrentSelectedCells from '../CurrentSelected'
import MergePinned from '../Pinned/MergePinned'
import PinnedGroups from '../Pinned/PinnedGroups'
import { colorByButtonId } from '../colorBy/ColorByDrawer'

const AnimatedCard = animated(Card)
const AnimatedCardContent = animated(CardContent)

const panelWidth = 520
const fullPanelWidth = panelWidth * 2

export const CellSelectionDrawer = (): JSX.Element => {
  const disableCurrentSelection = useFlagCondition('disableCurrentSelection')
  const demoEnabled = useFlagCondition('demoEnabled')
  const showCellSelection = !disableCurrentSelection || demoEnabled

  // !disableCurrentSelection
  const { width: fullWindowWidth } = useWindowSize()

  const { rect: colorByButton } = useElementById(colorByButtonId)
  const colorByButtonTop = colorByButton?.top ?? 0
  const footerHeight = useFooterHeight()

  const {
    cellVisualizations: { showCellGroups, showCompare },
  } = useCellVisualizationsSlice()

  let currentPanelWidth = panelWidth
  if (showCellGroups && showCompare) {
    currentPanelWidth = fullPanelWidth
  } else if (!showCellGroups && !showCompare) {
    currentPanelWidth = 0
  }

  const [cellGroupsSprings, cellGroupsApi] = useSpring(() => ({
    from: { x: fullWindowWidth },
  }))

  const [cGCardContentSprings, cGCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))
  const [compareCardContentSprings, compareCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))

  useEffect(() => {
    cellGroupsApi.start({
      to: {
        // the -8 is to give enough room to show the scrollbar
        x: fullWindowWidth - currentPanelWidth - 8,
      },
    })
  }, [currentPanelWidth, cellGroupsApi, fullWindowWidth])

  useEffect(() => {
    cGCardContentApi.start({
      to: {
        display: showCellGroups ? 'initial' : 'none',
      },
    })
  }, [cGCardContentApi, showCellGroups])

  useEffect(() => {
    compareCardContentApi.start({
      to: {
        display: showCompare ? 'initial' : 'none',
      },
    })
  }, [compareCardContentApi, showCompare])

  const [displayMergeMode, setDisplayMergeMode] = useState(false)

  const panelHeight = `calc(100vh - ${colorByButtonTop}px - ${footerHeight}px - 10px)`

  const [selectedPinnedCellGroupToMerge, setSelectedPinnedCellGroupToMerge] =
    useState<PinnedCellGroup>()

  const onMergeButtonClick = (pcg: PinnedCellGroup) => {
    setSelectedPinnedCellGroupToMerge(pcg)
    setDisplayMergeMode(true)
  }

  const onMergeClose = () => {
    setSelectedPinnedCellGroupToMerge(undefined)
    setDisplayMergeMode(false)
  }

  return (
    <AnimatedCard
      style={cellGroupsSprings}
      sx={{
        position: 'absolute',
        height: panelHeight,
        width: `${fullPanelWidth}px`,
        top: `${colorByButtonTop}px`,
      }}
      elevation={5}
    >
      <Stack direction="row">
        <AnimatedCardContent
          style={cGCardContentSprings}
          sx={{
            width: panelWidth,
            height: panelHeight,
            overflowY: 'auto',
          }}
        >
          {displayMergeMode ? (
            <MergePinned
              onCloseClick={onMergeClose}
              defaultSelected={selectedPinnedCellGroupToMerge}
            />
          ) : (
            <>
              {showCellSelection ? <CurrentSelectedCells /> : null}
              <PinnedGroups onMergeClick={onMergeButtonClick} />
            </>
          )}
        </AnimatedCardContent>
        <AnimatedCardContent
          style={compareCardContentSprings}
          sx={{
            width: panelWidth,
            height: panelHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            borderLeft: `${showCellGroups && showCompare ? '1px solid #EEEFFF' : 'none'}`,
          }}
        >
          <CompareGroups />
        </AnimatedCardContent>
      </Stack>
    </AnimatedCard>
  )
}

export default CellSelectionDrawer
