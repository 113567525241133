import useFlags from 'components/shared/useFlags'
import { PlotData, PlotMarker } from 'plotly.js'
import { useMemo } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CATEGORICAL_COLOR_PALETTE, HIDDEN_COLOR } from '../shared'
import { getMarkerSize } from './getMarkerSize'

// We always define the x, y coordinates and customdata holds CELL_ID in our plots
export type DeepcellPlotData = Partial<PlotData> &
  Required<Pick<PlotData, 'x' | 'y' | 'customdata'>>

type FlattenedPlotData =
  | {
      x: number[]
      y: number[]
      customdata: string[] // Store CELL_ID field to allow lookups later
    }
  | undefined
export interface UseCellsDataProps {
  showColorScale?: boolean
}

export interface UseCellsDataOutput {
  plotData: DeepcellPlotData[]
  flattenedPlotData: FlattenedPlotData
}

export const useCellsData = (props?: UseCellsDataProps): UseCellsDataOutput => {
  const { showColorScale } = props ?? {}
  const {
    cellVisualizations: { cellsData, legendLabelColors, groupByOption },
  } = useCellVisualizationsSlice()
  const markerSize = getMarkerSize(cellsData?.length || 1)

  const { cellVisualizationsContinousColorScale, cellVisualizationsReverseContinuousColorScale } =
    useFlags()

  const plotData = useMemo((): UseCellsDataOutput => {
    if (!cellsData) return { plotData: [], flattenedPlotData: { x: [], y: [], customdata: [] } }

    const data: DeepcellPlotData[] = []
    const flatData: FlattenedPlotData = { x: [], y: [], customdata: [] }

    const { attribute, isContinuous } = groupByOption

    // Uses Partial<PlotData> type as the basePlot does not yet have x, y, or customdata fields
    const basePlot: Partial<PlotData> = {
      type: 'scattergl',
      mode: 'markers',
      name: attribute,
      hoverinfo: 'none',
      opacity: 1,
      // @ts-ignore: This field is supported but not in the type definition for Plotly
      // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-unselected
      // By default, we fade out unselected points to indicate selection.  This makes it harder to make the next selection.
      // Instead, fade out the selected points slightly and make them bigger.
      unselected: {
        marker: {
          opacity: 1,
        },
      },
      // @ts-ignore: This field is supported but not in the type definition for Plotly
      // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-selected
      selected: {
        marker: {
          size: markerSize * 1.7,
          opacity: 0.75,
        },
      },
    }

    let groupId = 0
    let minValue = Infinity
    let maxValue = -Infinity

    const cellDataCount = cellsData.length
    for (let i = 0; i < cellDataCount; i += 1) {
      const cell = cellsData[i]
      if (cell.CELL_ID) {
        const attributeValue = attribute ? cell[attribute as keyof typeof cell] : ''

        if (isContinuous && attributeValue) {
          if (+attributeValue > maxValue) maxValue = +attributeValue
          if (+attributeValue < minValue) minValue = +attributeValue
        }

        const existingPlot = data.find((x) => isContinuous || x.name === attributeValue)
        const { isHidden, color } =
          legendLabelColors.find((item) => item.name === attributeValue) ?? {}

        // @TODO We're using the assumption here that customdata holds a CELL_ID
        // This assumption is currently scattered in several places in the codebase with this comment
        // Refactor and abstract this away better
        flatData.x.push(+cell.UMAP_0)
        flatData.y.push(+cell.UMAP_1)
        flatData.customdata.push(cell.CELL_ID)

        if (existingPlot) {
          ;(existingPlot.x as string[]).push(cell.UMAP_0)
          ;(existingPlot.y as string[]).push(cell.UMAP_1)
          ;(existingPlot.customdata as string[]).push(cell.CELL_ID)
          if (isContinuous && attributeValue) {
            ;(existingPlot.marker?.color as string[]).push(attributeValue)
            const { colorbar } = existingPlot.marker as PlotMarker
            if (colorbar) {
              colorbar.tick0 = minValue
              colorbar.dtick = (maxValue - minValue) / 10
            }
          }
        } else {
          const newData = {
            ...basePlot,
            x: [cell.UMAP_0],
            y: [cell.UMAP_1],
            customdata: [cell.CELL_ID],
            name: isContinuous ? '' : attributeValue,
            marker: isContinuous
              ? {
                  size: markerSize,
                  color: [attributeValue],
                  colorscale: cellVisualizationsContinousColorScale,
                  showscale: Boolean(showColorScale),
                  reversescale: cellVisualizationsReverseContinuousColorScale === 'yes',
                  colorbar: {
                    tick0: minValue,
                    dtick: (maxValue - minValue) / 10,
                  },
                }
              : {
                  color: isHidden ? HIDDEN_COLOR : color ?? CATEGORICAL_COLOR_PALETTE[groupId],
                  size: markerSize,
                  opacity: isHidden ? 0.8 : undefined,
                },
          }

          // We want non-hidden data at the end of the array because that will be rendered on top
          if (isHidden) {
            data.unshift(newData)
          } else {
            data.push(newData)
          }
        }

        // Make sure to keep this in sync with setInitialColors in CellVisualizationsSlice.ts
        // @TODO clean this up so the color assignment logic is in one place
        groupId = (groupId + 1) % CATEGORICAL_COLOR_PALETTE.length // If there are too many groups, colors will repeat
      }
    }

    return { plotData: data, flattenedPlotData: flatData }
  }, [
    cellVisualizationsContinousColorScale,
    cellVisualizationsReverseContinuousColorScale,
    cellsData,
    groupByOption,
    legendLabelColors,
    markerSize,
    showColorScale,
  ])

  return plotData
}

export default useCellsData
