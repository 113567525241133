import { CellClass, SampleType } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { Box, Chip, Divider, Stack } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import {
    DeepcellAutocomplete,
    DeepcellMultiInput,
    DeepcellPrimaryButton,
    DeepcellTextField,
} from 'components/shared'
import DeepcellDateTimePicker from 'components/shared/DeepcellDateTimePicker'
import DeepcellModal from 'components/shared/DeepcellModal'
import DeepcellPrimarySelect from 'components/shared/DeepcellPrimarySelect'
import React, { useReducer } from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import { CellClassEncoderDecoder } from 'utils/proto-utils'
import useAuthTokens from 'utils/useAuthTokens'

const SHORTCUT_HINTS = (
    <>
        <p>
            <Chip label="Left / Right" /> - previous / next page
        </p>
        <p>
            <Chip label="f / b" /> - Jump forward / backward 10 pages
        </p>
        <p>
            <Chip label="control + f / b" /> - Jump forward / backward 100 pages
        </p>
        <p>
            <Chip label="control + shift + f / b" /> - Jump forward / backward 1000 pages\n
        </p>
        <p>
            <Chip label="S" /> - toggle showing the sort fields
        </p>
        <p>
            <Chip label="Z" /> - toggle zooming in to center crop
        </p>
        <p>
            <Chip label="X" /> - toggle image sharpening
        </p>
        <p>
            <Chip label="C" /> - toggle cells shown per page (useful for larger monitors)
        </p>
        <p>
            <Chip label="Enter" /> - runs search, if you are in one of the search fields
        </p>
        <p>
            <Chip label="/" /> - switch to search tab
        </p>
        <p>
            <Chip label="D" /> - switch to details tab
        </p>
    </>
)

interface Props {
    className?: string
    defaultSearchFilters: SearchFilters
    handleSearch: (searchFilters: SearchFilters) => void
    onError: (event: React.SyntheticEvent<HTMLFormElement, Event>) => void
}

export interface SearchFilters {
    predictedClasses?: number[]
    predictedProbabilityGreaterThan?: string
    sampleType?: number
    sampleId?: string
    mixedSampleId?: string
    before?: Date
    after?: Date
    runIds: string[]
}

// Used a pattern from here: https://www.sumologic.com/blog/react-hook-typescript/
// that seemed cleaner than others
export type ChangeSearchFilterAction =
    // actions to update form fields have the same name as the field in the state
    | { type: 'predictedClasses'; values: number[] }
    | { type: 'predictedProbabilityGreaterThan'; value: string }
    | { type: 'sampleType'; value: number }
    | { type: 'mixedSampleId'; value: string }
    | { type: 'sampleId'; value: string }
    | { type: 'runIds'; value: string[] }
    | { type: 'before'; value: Date | undefined }
    | { type: 'after'; value: Date | undefined }

function CellSearchFilters(props: Props): JSX.Element {
    const { permissions } = useAuthTokens()
    const { className, defaultSearchFilters, handleSearch, onError } = props

    /**
     * Handles state updates for the useReducer hook
     */
    function reducer(state: SearchFilters, action: ChangeSearchFilterAction): SearchFilters {
        const result: SearchFilters = { ...state }

        switch (action.type) {
            case 'predictedClasses':
                result.predictedClasses = action.values
                break

            // @TODO: Figure out how to type this properly
            // These are grouped by type so that Typescript infers the type of action.value properly
            // Ideally all of these cases could be one
            case 'predictedProbabilityGreaterThan':
            case 'mixedSampleId':
            case 'sampleId':
                result[action.type] = action.value
                break

            case 'runIds':
                result[action.type] = action.value
                break

            case 'sampleType':
                result[action.type] = action.value
                break

            case 'before':
            case 'after':
                result[action.type] = action.value
                break

            default:
                throw Error('Unhandled action')
        }
        return result
    }

    const [searchFilters, dispatch] = useReducer(reducer, {
        ...defaultSearchFilters,
    })
    const {
        predictedClasses,
        predictedProbabilityGreaterThan,
        sampleType,
        sampleId,
        mixedSampleId,
        runIds,
        before,
        after,
    } = searchFilters

    function submitSearch(newSearchFilters?: Partial<SearchFilters>) {
        handleSearch({
            ...searchFilters,
            ...newSearchFilters,
            sampleType:
                searchFilters.sampleType && searchFilters.sampleType < 0
                    ? undefined
                    : searchFilters.sampleType,
        })
    }

    const updateAndSearch = (newRunIds: string) => {
        const runIdsArray = newRunIds.split(/\s+/)
        dispatch({
            type: 'runIds',
            value: runIdsArray,
        })
        submitSearch({ runIds: runIdsArray })
    }

    return (
        <form
            className={className}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                submitSearch()
            }}
            onErrorCapture={onError}
        >
            <Stack spacing={2}>
                <DeepcellMultiInput
                    label="Run Ids"
                    value={runIds.length && runIds[0] ? runIds : []}
                    updateValueState={updateAndSearch}
                />
                <DeepcellTextField
                    label="Sample Id"
                    value={sampleId}
                    onChange={(event) =>
                        dispatch({
                            type: 'sampleId',
                            value: event.target.value,
                        })
                    }
                />
                <DeepcellTextField
                    label="Mixed Sample Id"
                    value={mixedSampleId}
                    onChange={(event) =>
                        dispatch({
                            type: 'mixedSampleId',
                            value: event.target.value,
                        })
                    }
                />
                {permissions.has('deepcell_internal:read') && (
                    <DeepcellPrimarySelect
                        label="Sample Type"
                        items={[
                            { value: -1, output: <em>Any</em> },
                            ...Object.entries(SampleType).map((e) => ({
                                key: e[0],
                                value: e[1],
                                output: e[0].substring(12),
                            })),
                        ]}
                        value={sampleType}
                        onChange={(event) =>
                            dispatch({
                                type: 'sampleType',
                                value: event.target.value as number,
                            })
                        }
                    />
                )}

                {permissions.has('deepcell_internal:read') && (
                    <FormControl size="small">
                        <Stack spacing={2}>
                            <DeepcellAutocomplete
                                multiple
                                label="Predicted Classes During Run"
                                id="predicted-classes"
                                options={Object.values(CellClass)}
                                getOptionLabel={(option) =>
                                    CellClassEncoderDecoder.convertToString(option)
                                }
                                value={predictedClasses}
                                filterSelectedOptions
                                onChange={(_event, values) =>
                                    dispatch({
                                        type: 'predictedClasses',
                                        values,
                                    })
                                }
                                renderInput={(params) => (
                                    <DeepcellTextField
                                        {...params}
                                        label="Predicted Classes During Run"
                                    />
                                )}
                            />
                            {predictedClasses && predictedClasses.length !== 0 && (
                                <DeepcellTextField
                                    name="predicted-probability-greater-than"
                                    label="Predicted Probability is Above"
                                    placeholder="Enter a probability (e.g. 0.8)"
                                    value={predictedProbabilityGreaterThan}
                                    onChange={(event) =>
                                        dispatch({
                                            type: 'predictedProbabilityGreaterThan',
                                            value: event.target.value,
                                        })
                                    }
                                    type="number"
                                    inputProps={{
                                        step: 0.001,
                                        min: 0,
                                        max: 1,
                                    }}
                                />
                            )}
                        </Stack>
                    </FormControl>
                )}
                <Box>
                    <DeepcellDateTimePicker
                        label="Captured After"
                        value={after as Date}
                        onChange={(date) => dispatch({ type: 'after', value: date })}
                    />
                </Box>
                <Box>
                    <DeepcellDateTimePicker
                        label="Captured Before"
                        value={before as Date}
                        onChange={(date) => dispatch({ type: 'before', value: date })}
                    />
                </Box>
                <Divider />
                <DeepcellPrimaryButton contained type="submit">
                    Search (Enter)
                </DeepcellPrimaryButton>

                <DeepcellModal
                    title="Shortcuts"
                    content={SHORTCUT_HINTS}
                    modalTriggeringButton={
                        <DeepcellPrimaryButton small>Show keyboard shortcuts</DeepcellPrimaryButton>
                    }
                />
            </Stack>
        </form>
    )
}

export default CellSearchFilters
