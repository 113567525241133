import { AppBar, Toolbar } from '@mui/material'
import PlotImagesFilter from './plot/plotLayoutImages/PlotImagesFilter'

export const CellVisualizationFooter = (): JSX.Element => {
    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}
        >
            <Toolbar sx={{ justifyContent: 'center' }}>
                <PlotImagesFilter />
            </Toolbar>
        </AppBar>
    )
}

export default CellVisualizationFooter
