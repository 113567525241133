import { Box } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import {
    getBox,
    getHoveredOverElements,
    SelectionBoxCoordinates,
    UniqueBoxElement,
} from './dimensionalHelpers'

interface UniqueHTMLElements {
    id: string
    ref: React.RefObject<HTMLElement>
}

interface UseBoxSelectionParams {
    elements: UniqueHTMLElements[]
    defaultSelectedIds?: string[]
    enabled?: boolean
    onSelectionChange(ids: string[]): void
}

/**
 * A hook that allows you to click and drag-to-select divs
 */
export const useBoxSelection = ({
    elements,
    defaultSelectedIds,
    enabled = true,
    onSelectionChange,
}: UseBoxSelectionParams): {
    /**
     * Props to be spread over the wrapper div component inside which you want to have selectable items
     */
    selectionAreaProps: { ref: React.RefObject<HTMLDivElement> }
    /**
     * The ids of the elements currently being hovered over
     */
    hoveredIds: string[]
    /**
     * The fixed transparent selection box that will float over the elements
     */
    SelectionBox: () => JSX.Element
} => {
    const [selectionBoxCoordinates, setSelectionBoxCoordinates] = useState<SelectionBoxCoordinates>(
        {}
    )
    const [hoveredIds, setHoveredIds] = useState<string[]>([])
    const [selectedIds, setSelectedIds] = useState<string[]>(
        enabled ? defaultSelectedIds ?? [] : []
    )

    const handleSelectionChange = useCallback(
        (ids: string[]) => {
            if (enabled) {
                const uniqueIds = _.uniq(ids)
                setSelectedIds(uniqueIds)
                onSelectionChange(uniqueIds)
            }
        },
        [enabled, onSelectionChange]
    )

    const selectionAreaRef = useRef<HTMLDivElement>(null)
    const selectionAreaProps = { ref: selectionAreaRef }
    const clickIsInSelectionArea = (e: MouseEvent) =>
        !!(selectionAreaRef.current && selectionAreaRef.current.contains(e.target as Node))

    const [elementBoxes, setElementBoxes] = useState<UniqueBoxElement[]>([])
    const updateElementBoxes = useCallback(() => {
        if (elements && elements.length)
            setElementBoxes(
                elements
                    .map((e) => {
                        const rect = e.ref.current?.getBoundingClientRect()
                        return {
                            top: rect?.top,
                            left: rect?.left,
                            height: rect?.height,
                            width: rect?.width,
                            id: e.id,
                        }
                    })
                    .filter((x) => !!x)
            )
    }, [elements])

    /**
     * Need to use a hook and useEffect here because ref.current is null until
     * the component mounts and useEffect is run after it mounts
     */
    useEffect(() => {
        if (elementBoxes.length === 0 || elementBoxes.every((e) => !e.top)) {
            updateElementBoxes()
        }
    }, [elementBoxes, elementBoxes.length, updateElementBoxes])

    /**
     * Element box locations need to update when the window resizes
     */
    useEffect(() => {
        window.addEventListener('resize', updateElementBoxes)
        return () => window.removeEventListener('resize', updateElementBoxes)
    })

    const onMouseDown = useCallback(
        (e: MouseEvent) =>
            clickIsInSelectionArea(e) &&
            setSelectionBoxCoordinates({ startPoint: { x: e.clientX, y: e.clientY } }),

        []
    )

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            // only create coordinates for the selection box if they clicked in the selectable area
            const { startPoint } = selectionBoxCoordinates
            if (!startPoint || !enabled) return

            // Begin by getting the needed coordinates to render the selectionBox
            const currentPoint = { x: e.clientX, y: e.clientY }
            const renderedSelectionBox = getBox(startPoint, currentPoint)

            setSelectionBoxCoordinates({
                ...selectionBoxCoordinates,
                ...renderedSelectionBox,
            })

            // Then retrieve which elements are being hovered over
            const currentlyHoveredOverIds = getHoveredOverElements(
                elementBoxes,
                renderedSelectionBox
            ).map((x) => x.id)
            setHoveredIds(currentlyHoveredOverIds)
        },
        [elementBoxes, enabled, selectionBoxCoordinates]
    )

    const onMouseUp = useCallback(
        (e: MouseEvent) => {
            const { startPoint } = selectionBoxCoordinates
            setSelectionBoxCoordinates({})
            let elementsToSelect: string[] = []

            if (!enabled) {
                handleSelectionChange([])
                return
            }

            if (startPoint) {
                // user clicked inside the selectable area
                const currentPoint = { x: e.clientX, y: e.clientY }

                if (hoveredIds.length > 0) {
                    /* ************** */
                    // click and drag //
                    /* ************** */
                    const elementsAllAlreadySelected = hoveredIds.every((c) =>
                        selectedIds.includes(c)
                    )

                    if (elementsAllAlreadySelected) {
                        // selected elements are all already selected → deselect just those elements
                        elementsToSelect = selectedIds.filter((x) => !hoveredIds.includes(x))
                    } else {
                        // selected some unselected elements → add to selected elements
                        elementsToSelect = [...selectedIds, ...hoveredIds]
                    }
                } else {
                    /* ************** */
                    // a single click //
                    /* ************** */
                    const clickedElement = getHoveredOverElements(
                        elementBoxes,
                        getBox(startPoint, currentPoint)
                    )[0]

                    if (!clickedElement) {
                        // didn't click on a element → do nothing
                        elementsToSelect = selectedIds
                    } else if (selectedIds.includes(clickedElement.id)) {
                        // clicked on already selected element → deselect element
                        elementsToSelect = selectedIds.filter((x) => x !== clickedElement.id)
                    } else {
                        // clicked on element that wasn't already selected → add to selected elements
                        elementsToSelect = [...selectedIds, clickedElement.id]
                    }
                }
                handleSelectionChange(elementsToSelect)
            }
            setHoveredIds([])
        },
        [
            elementBoxes,
            enabled,
            handleSelectionChange,
            hoveredIds,
            selectedIds,
            selectionBoxCoordinates,
        ]
    )

    useEffect(() => {
        document.addEventListener('mousedown', onMouseDown)
        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('mousemove', onMouseMove)
        return () => {
            document.removeEventListener('mousedown', onMouseDown)
            document.removeEventListener('mouseup', onMouseUp)
            document.removeEventListener('mousemove', onMouseMove)
        }
    }, [onMouseDown, onMouseMove, onMouseUp])

    const SelectionBox = () =>
        enabled ? (
            <Box
                sx={{
                    display:
                        selectionBoxCoordinates.startPoint &&
                        selectionBoxCoordinates.width &&
                        selectionBoxCoordinates.height
                            ? 'initial'
                            : 'none',
                    position: 'fixed',
                    top: selectionBoxCoordinates.top,
                    left: selectionBoxCoordinates.left,
                    width: selectionBoxCoordinates.width,
                    height: selectionBoxCoordinates.height,
                    backgroundColor: 'blue',
                    opacity: 0.25,
                    zIndex: 9999,
                }}
            />
        ) : (
            <></>
        )

    return {
        selectionAreaProps,
        hoveredIds,
        SelectionBox,
    } as const
}

export default useBoxSelection
