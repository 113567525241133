import { MobileStepper, styled } from '@mui/material'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { ReactElement } from 'react'

const ProgressCellMobileStepper = styled(MobileStepper)({
    padding: 0,
    '& .MuiLinearProgress-root': {
        height: 12,
    },
})

function calculateMax(params: ICellRendererParams) {
    const { colDef: { field } = {}, api } = params
    let maxValue = 0
    api.forEachNode((node: RowNode) => {
        const val = parseInt(node.data[field || ''], 10)
        if (maxValue < val) maxValue = val
    })
    return maxValue
}

const ProgressCellComponent = (props: ICellRendererParams): ReactElement => {
    const {
        value,
        colDef: {
            cellRendererParams: { showFullBar = false, calculateTotal = calculateMax } = {},
        } = {},
        valueFormatted,
    } = props

    const maxValue = calculateTotal(props)

    return (
        <ProgressCellMobileStepper
            variant="progress"
            steps={showFullBar ? 100 : maxValue}
            position="static"
            activeStep={value}
            nextButton={<div>{valueFormatted || value}</div>}
            backButton={null}
        />
    )
}

export default ProgressCellComponent
