import { TextareaAutosize, styled } from '@mui/material'

// eslint-disable-next-line import/prefer-default-export
export const InstructionsText = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(2),
}))
