import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import ContentLoading from 'components/shared/ContentLoading'

import ScatterPlot from './ScatterPlot'
import useCellsData from './useCellsData'

const StyledScatterPlot = styled(ScatterPlot)({
  '&': {
    flex: 1,
  },
})

export const MainGraphContent = (): JSX.Element => {
  const cellData = useCellsData()
  const { plotData } = cellData

  const dataExists = Boolean(plotData && plotData.length)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '0.5rem',
      }}
    >
      {dataExists ? <StyledScatterPlot {...cellData} /> : <ContentLoading />}
    </Box>
  )
}

export default MainGraphContent
