import { Box, Pagination, PaginationItem, Stack, styled, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import firebase from 'firebase'
import React, { ChangeEvent } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import 'react-widgets/dist/css/react-widgets.css'
import { SAMPLE_DISTRIBUTION } from 'utils/constants'
import DeepcellTablePagination from 'components/shared/DeepcellTablePagination'
import DeepcellSecondarySelect from 'components/shared/DeepcellSecondarySelect'
import { CellDisplayOptions } from '../shared/CellImageControl'
import { SortOrderKey, SortOrders } from './sort-orders'

const SearchOptionsForm = styled('form')({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
})

const PaginationGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',

  '& .MuiTableCell-root': {
    padding: '0px',
    overflow: 'hidden',
    borderBottom: 0,
  },
})

const CompactPagination = styled(Pagination)(() => ({
  // Hide numerical page displays and depend on the <TablePagination> component
  '& .MuiPagination-ul li:nth-child(3)': {
    display: 'none',
  },

  '& .MuiPagination-ul li:nth-child(4)': {
    display: 'none',
  },
}))

interface Props {
  searchOptions: SearchOptions
  displayOptions: CellDisplayOptions
  handleSearchOptionAction: (action: SearchDispatchAction) => void
  cellCount?: number
}

export interface SearchOptions {
  sortOrderKey: SortOrderKey
  showSortFields?: boolean
  cellsPerPage: number
  page: number
  runIds?: string[]
}

// Used a pattern from here: https://www.sumologic.com/blog/react-hook-typescript/
// that seemed cleaner than others
export type SearchDispatchAction =
  | { type: 'sortOrderKey'; value: SortOrderKey }
  | { type: 'cellsPerPage'; value: number }
  | { type: 'page'; value: number }
  | { type: 'showSortFields'; checked: boolean }
  | { type: 'toggleCenterCrop' }
  | { type: 'toggleSharpen' }

export const MIN_CELL_IMAGES = 50

/** Component that manages cell search options for the parent CellBrowsingPage */
function CellSearchOptions(props: Props): JSX.Element {
  const { searchOptions, displayOptions, handleSearchOptionAction, cellCount } = props

  const { sortOrderKey } = searchOptions

  const analytics = firebase.analytics()

  const maxPage = cellCount
    ? Math.max(0, Math.ceil(cellCount / searchOptions.cellsPerPage) - 1)
    : Infinity

  function pageJumpFunction(jump: number) {
    return () => {
      const { page } = searchOptions
      const newPage = Math.min(Math.max(page + jump, 0), maxPage)
      if (page !== newPage) {
        handleSearchOptionAction({
          type: 'page',
          value: newPage,
        })
        analytics.logEvent('search/page_change', {
          page,
          newPage,
          jump,
        })
      }
    }
  }

  function toggleShowSortFields() {
    handleSearchOptionAction({
      type: 'showSortFields',
      checked: !searchOptions.showSortFields,
    })
  }

  function toggleCellsPerPage() {
    handleSearchOptionAction({
      type: 'cellsPerPage',
      value: searchOptions.cellsPerPage === 50 ? 100 : 50,
    })
  }

  const useCellSearchOptionsHotkeys = (key: string, func: () => void) =>
    useHotkeys(key, func, {}, [searchOptions, props])

  useCellSearchOptionsHotkeys('left', pageJumpFunction(-1))
  useCellSearchOptionsHotkeys('right', pageJumpFunction(1))
  // For Nianzhen's use case to jump to other probabilities
  // Also useful for getting a more diverse sampling of a class in larger result sets
  // @TODO: reconsider supporting this interaction when there are better ways...
  useCellSearchOptionsHotkeys('b', pageJumpFunction(-10))
  useCellSearchOptionsHotkeys('f', pageJumpFunction(10))
  useCellSearchOptionsHotkeys('control+b, ctrl+b', pageJumpFunction(-100))
  useCellSearchOptionsHotkeys('control+f, ctrl+f', pageJumpFunction(100))
  useCellSearchOptionsHotkeys('control+shift+b, ctrl+shift+b', pageJumpFunction(-1000))
  useCellSearchOptionsHotkeys('control+shift+f, ctrl+shift+f', pageJumpFunction(1000))
  useCellSearchOptionsHotkeys('S', toggleShowSortFields)
  useCellSearchOptionsHotkeys('C', toggleCellsPerPage)

  const showPagination = !!(cellCount && cellCount >= MIN_CELL_IMAGES)

  const runBuckets = ['0-25%', '26-50%', '51-75%', '76-100%']

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <SearchOptionsForm data-testid="search-options-form">
        <PaginationGroup>
          {sortOrderKey === SAMPLE_DISTRIBUTION && showPagination && (
            <Typography fontSize="0.875rem">Run at {runBuckets[searchOptions.page]}</Typography>
          )}
          {/* Note the use of two pagination components that cooperate
           * The first one has a really nice page size + cell count display, but is missing support for
           * first / last page.
           *
           * The second one does support that, but doesn't have the features above
           * So let's combine them together and hide the unneeded parts of each using CSS...
           * atm, this the best attempt after reviewing a half dozen popular pagination widgets
           *
           * @TODO: It's probably cleaner to remove the TablePagination and implement some of the behaviour
           * from scratch
           */}
          {sortOrderKey !== SAMPLE_DISTRIBUTION && showPagination && (
            <DeepcellTablePagination
              data-testid="cell-pagination"
              page={searchOptions.page}
              count={-1}
              rowsPerPage={searchOptions.cellsPerPage}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              rowsPerPageOptions={[50, 100]}
              labelRowsPerPage="Cells per page (C):"
              onPageChange={(_event, newPage: number) =>
                handleSearchOptionAction({
                  type: 'page',
                  value: newPage,
                })
              }
              onRowsPerPageChange={(event) =>
                handleSearchOptionAction({
                  type: 'cellsPerPage',
                  value: parseInt(event.target.value, 10),
                })
              }
              sx={{ width: '280px' }}
            />
          )}
          {/* @TODO: since we don't know the full cell count anymore we need to
                         get the payload count to know whether or not to enable the "next" button.
                         We should use redux for this. */}
          {showPagination && (
            <CompactPagination
              count={
                searchOptions.sortOrderKey === SAMPLE_DISTRIBUTION ? 4 : searchOptions.page + 2
              }
              page={searchOptions.page + 1}
              showFirstButton
              renderItem={(item) => (item.type !== 'page' ? <PaginationItem {...item} /> : <></>)}
              onChange={(_event: ChangeEvent<unknown>, newPage: number) =>
                handleSearchOptionAction({
                  type: 'page',
                  value: newPage - 1,
                })
              }
              sx={{ width: '130px' }}
            />
          )}
        </PaginationGroup>

        <Stack direction="row" sx={{ marginLeft: 'auto', alignItems: 'center' }} spacing={2}>
          {showPagination && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="cellSearchOptionsZoom"
                    checked={displayOptions?.centerCrop}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) =>
                      handleSearchOptionAction({
                        type: 'toggleCenterCrop',
                      })
                    }
                  />
                }
                label={<Typography fontSize="0.875rem">Zoom (Z)</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="cellSearchOptionsSharpen"
                    checked={displayOptions?.sharpen}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) =>
                      handleSearchOptionAction({
                        type: 'toggleSharpen',
                      })
                    }
                  />
                }
                label={<Typography fontSize="0.875rem">Sharpen (X)</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="cellSearchOptionsShowSort"
                    checked={searchOptions.showSortFields}
                    onChange={(event) =>
                      handleSearchOptionAction({
                        type: 'showSortFields',
                        checked: event.target.checked,
                      })
                    }
                    name="show_sort_fields"
                  />
                }
                label={<Typography fontSize="0.875rem">Show Sort By (S)</Typography>}
              />
            </>
          )}
          {showPagination && (
            <DeepcellSecondarySelect
              items={Object.entries(SortOrders).map((e) => ({
                key: e[0],
                value: e[0],
                output: e[1].label,
              }))}
              formControlProps={{ sx: { width: '250px' } }}
              name="sort-order-key"
              value={searchOptions.sortOrderKey}
              onChange={(event) =>
                handleSearchOptionAction({
                  type: 'sortOrderKey',
                  value: event.target.value as SortOrderKey,
                })
              }
            />
          )}
        </Stack>
      </SearchOptionsForm>
    </Box>
  )
}

export default CellSearchOptions
