import RouterPrompt from 'components/shared/RouterPrompt'
import useFlags from 'components/shared/useFlags'
import { useEffect } from 'react'
import { useMount } from 'react-use'
import { cellVisualizationsInitialState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { datasetItems } from 'redux/types'
import useLoadCellInfoEffect from './arrow/useLoadCellInfoEffect'
import CellSelectionDrawer from './cellSelectionDrawer/CellSelectionDrawer'
import { ColorByDrawer } from './colorBy/ColorByDrawer'
import CellVisualizationsHeader from './header'
import MainGraphContent from './plot/MainGraphContent'
import useCellVisualizationUrlParams from './useCellVisualizationUrlParams'

export const CellVisualizationsPage = (): JSX.Element => {
    const {
        setStore,
        cellVisualizations: { isDirty },
    } = useCellVisualizationsSlice()
    const { sessionId } = useCellVisualizationUrlParams()

    const { demoEnabled, saveSessionsV2 } = useFlags()
    const isSaveV2 = saveSessionsV2 === 'yes'
    const showSessions = demoEnabled === 'no' && !isSaveV2
    useLoadCellInfoEffect()

    useMount(() => {
        if (!sessionId) return

        const matchingDsi = datasetItems.find((dsi) => dsi.id === `${sessionId}`)
        const { selectedDataset } = cellVisualizationsInitialState
        setStore({
            ...cellVisualizationsInitialState,
            selectedDataset: matchingDsi ?? selectedDataset,
        })
    })

    useEffect(() => {
        if (isDirty) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = () => undefined
        }
    }, [isDirty])

    return (
        <>
            <CellVisualizationsHeader />
            <ColorByDrawer />
            <MainGraphContent />
            <CellSelectionDrawer />
            <RouterPrompt
                when={isDirty && showSessions}
                dialogProps={{
                    titleLabel: 'Warning',
                    description:
                        'You will lose all your edits if you exit the session without saving it. Do you still wish to exit?',
                }}
            />
        </>
    )
}

export default CellVisualizationsPage
