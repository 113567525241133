import { ColDef, GridApi } from 'ag-grid-community'
import { useState } from 'react'
import { LabelingTaskWithActions } from 'utils/api'
import LabelingTaskActionPopover from '../LabelingTaskActionPopover'
import useDueDateAgGridColumn from './useDueDateAgGridColumn'
import useRunIdAgGridColumn from './useRunIdAgGridColumn'

interface UseLabelingTaskTableColumnsProps {
    tasks: LabelingTaskWithActions[]
    onTaskDataChanged: () => void
    gridApi: GridApi | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLabelingTaskTableColumns = ({
    tasks,
    onTaskDataChanged,
    gridApi,
}: UseLabelingTaskTableColumnsProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>()
    const [popoverTask, setPopoverTask] = useState<LabelingTaskWithActions>()

    const { runIdCellStyle, runIdTooltip } = useRunIdAgGridColumn(tasks)
    const { dueDateTooltip, dueDateCellStyle } = useDueDateAgGridColumn()

    const columnDefs: ColDef[] = [
        {
            headerName: '',
            field: 'task_id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width: 30,
            sort: 'asc',
            sortIndex: 2,
        },
        {
            headerName: 'Run Id',
            field: 'run_id',
            sortable: true,
            width: 150,
            cellStyle: runIdCellStyle,
            tooltipValueGetter: runIdTooltip,
        },
        {
            headerName: 'Task ID',
            field: 'task_id',
            width: 90,
            sortable: true,
        },
        { headerName: 'Status', field: 'status', sortable: true, width: 130 },
        {
            headerName: 'Actions',
            field: 'availableActions',
            cellRenderer: 'actionCellRenderer',
            cellRendererParams: {
                handleClick: (
                    event:
                        | React.MouseEvent<HTMLAnchorElement>
                        | React.MouseEvent<HTMLButtonElement>,
                    task: LabelingTaskWithActions
                ) => {
                    setAnchorEl(event.currentTarget)
                    setPopoverTask(task)
                },
            },
            sortable: true,
            width: 100,
        },
        {
            headerName: 'Due',
            field: 'due_date',
            sortable: true,
            sort: 'asc',
            sortIndex: 1,
            width: 100,
            cellStyle: dueDateCellStyle,
            tooltipValueGetter: dueDateTooltip,
        },
        {
            headerName: 'Progress',
            field: 'current_batch_progress',
            cellRenderer: 'progressCellRenderer',
            sortable: true,
            width: 100,
        },
        { headerName: 'Assignee', field: 'assignee', sortable: true, width: 100 },
        {
            headerName: 'Project',
            field: 'project_name',
            autoHeight: true,
            width: 200,
            sortable: true,
        },
        {
            headerName: 'Classes to Label',
            field: 'cell_classes_to_label',
            wrapText: true,
            autoHeight: true,
            width: 220,
            sortable: true,
        },
        { headerName: 'Cell Count', field: 'cell_count', sortable: true, width: 100 },
        {
            headerName: 'Instructions',
            field: 'labeling_instructions',
            wrapText: true,
            autoHeight: true,
            width: 300,
            sortable: true,
        },
        {
            headerName: 'Notes',
            field: 'labeling_notes',
            wrapText: true,
            autoHeight: true,
            width: 300,
            sortable: true,
        },
        {
            headerName: 'Run Description',
            field: 'run_description',
            wrapText: true,
            autoHeight: true,
            width: 300,
            sortable: true,
        },
        {
            headerName: 'Experiment',
            field: 'experiment_name',
            autoHeight: true,
            width: 200,
            sortable: true,
        },
        {
            headerName: 'Instrument',
            field: 'instrument_name',
            width: 160,
            sortable: true,
        },
        { headerName: 'Created By', field: 'created_by', sortable: true, width: 200 },
        {
            headerName: 'Created Time',
            field: 'created_time',
            sortable: true,
            width: 200,
        },
    ]

    const Popover = () => (
        <LabelingTaskActionPopover
            anchorEl={anchorEl}
            gridApi={gridApi}
            handleTaskDataChanged={onTaskDataChanged}
            popoverTask={popoverTask}
            handleClose={() => setAnchorEl(null)}
        />
    )

    return { columnDefs, Popover } as const
}

export default useLabelingTaskTableColumns
