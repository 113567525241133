import { CellId } from '@deepcell/proto_schema_js/deepcell_schema_pb'
import { Box, styled } from '@mui/material'
import Moment from 'moment'
import { useQuery } from 'react-query'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import {
    CellListCellsQueryParams,
    convertErrorToString,
    getCellListCells,
    GetCellListCellsResponse,
} from 'utils/api'
import CellImageControl, { CellDisplayOptions } from '../../shared/CellImageControl'
import ContentLoading from '../../shared/ContentLoading'

Moment.locale('en')
momentLocalizer()

const IMAGE_SIZE = 70

// @TODO Review uses of cell grids for common components.
// This is yet another variant with a bunch of copied and tweaked styles and layout

const Content = styled('div')({
    width: '100%',
    height: 'calc(100% - 60px)',
})

const CellContentGrid = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    lineHeight: 0,
    gap: '1px',
    flexWrap: 'wrap',
})

interface Props {
    reviewedCellListId: number
    exceptionCellListId?: number
    displayOptions?: CellDisplayOptions
}

/**
 * Hook that runs a cell search
 */
function useGetCellListCells(
    cellListId?: number,
    limit = 1000
): {
    result: GetCellListCellsResponse | undefined
    error: string | undefined
} {
    const params: CellListCellsQueryParams = {
        cell_list_id: cellListId,
        limit,
        include_image_urls: true,
    }
    return convertErrorToString(
        useQuery(['getCellListCells', params], getCellListCells, {
            refetchOnWindowFocus: false,
        })
    )
}

function ExceptionCellGrid(props: Props): JSX.Element {
    const { reviewedCellListId, exceptionCellListId, displayOptions } = props
    const { result: reviewedCells } = useGetCellListCells(reviewedCellListId)
    const { result: exceptionCells } = useGetCellListCells(exceptionCellListId)

    const exceptionCellIdSet: Set<string> = new Set(
        exceptionCells?.cells?.map((item) => item.cell?.getCellId()?.toString() || '')
    )

    function isSelected(cellId?: CellId): boolean {
        if (cellId === undefined) return false
        const itemCellIdStr = cellId.toString()
        return exceptionCellIdSet.has(itemCellIdStr)
    }

    return (
        <>
            {!reviewedCells && <ContentLoading />}
            {reviewedCells && (
                <Content>
                    <CellContentGrid>
                        {reviewedCells.cells.map((cellResponse) => (
                            <CellImageControl
                                cellId={cellResponse.cell.getCellId()}
                                highlighted={isSelected(cellResponse.cell.getCellId())}
                                width={IMAGE_SIZE}
                                displayOptions={displayOptions}
                            />
                        ))}
                    </CellContentGrid>
                </Content>
            )}
        </>
    )
}

export default ExceptionCellGrid
